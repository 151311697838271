import React, { useContext, useEffect, useState } from 'react';
import Layout from '../layouts/Main';
import GlobalState from "../contexts/GlobalState";
import { Link } from "react-router-dom";
import SwiperCore, { Pagination, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../components/GoogleAnalytics';

import 'swiper/swiper.min.css';
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/pagination/pagination.min.css";


SwiperCore.use([EffectFade, Pagination, Autoplay]);

export default function Home(props) {

    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [viewPopup, setViewPopup] = useState(true);



    let visited = localStorage["alreadyVisited"];

    useEffect(() => {
        window.triggerScroll();
        if (visited) {
            setViewPopup(false);
            //do not view Popup
        } else {
            //this is the first time
            localStorage["alreadyVisited"] = true;
            setViewPopup(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generalData]);


    return (

        <Layout activePage="home" whiteMenu="true">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['home'].title}</title>
                    <meta name="title" content={generalData?.seo['home']?.title} />
                    <meta name="description" content={generalData?.seo['home']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['home'].title} />
                    <meta property="og:description" content={generalData?.seo['home'].description} />
                    <meta property="og:image" content={generalData?.seo['home'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['home'].title} />
                    <meta property="twitter:description" content={generalData?.seo['home'].description} />
                    <meta property="twitter:image" content={generalData?.seo['home'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <div className="first-section">
                <div className="container-fluid p-0">
                    <div className="row no-gutters justify-content-center align-items-center">
                        <div className="col-12">
                            <div className="bg-dark-banner">
                            </div>
                            <img src={generalData.home_settings.banner_image} alt="Home-Banner" className="banner-home-img" />
                            <div className="home-banner-text-area" animate="right">
                                <div className="row no-gutters justify-content-center">
                                    <div className="col-10 col-md-9">
                                        <h1 className="text-banner-width">{generalData.home_settings.banner_title}</h1>
                                    </div>
                                    <div className="col-10 col-md-9">
                                        <h3 className="text-banner-width">{generalData.home_settings.banner_subtitle}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="home-works-sec">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 pb-2" animate="left">
                            <h1 className="home-big-titles">{generalData.home_settings.works_title}</h1>
                        </div>
                        {
                            generalData.how_it_works.map((singleWorksItem, index) => (
                                <div className="col-12 col-md-4 pb-lg-0 pb-2" animate="right" key={index}>
                                    <img className="icon-home-img" src={singleWorksItem.full_path_image} alt={singleWorksItem.title} />
                                    <h2 className="pt-2">{singleWorksItem.title}</h2>
                                    <p className="lh-6">{singleWorksItem.text}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>


            <div className="home-opinion-sec">
                <div className="opinion-image-filter"></div>
                <img src={generalData.home_settings.opinion_image} className="home-opinion-image" alt="Opinion" animate="left" />
                <div className="opinion-description">
                    <div className="container py-5">
                        <div className="row justify-content-center py-5">
                            <div className="col-10 col-lg-8 text-center">
                                <h1 className="home-big-titles pb-3" animate="right">{generalData.home_settings.opinion_title}</h1>
                                <h2 className="lh-6" animate="right">{generalData.home_settings.opinion_subtitle}</h2>
                                <Link style={{ textDecoration: 'none' }} to={'/get-care'}>
                                    <button className="home-buttons mt-4" animate="left">{generalData.home_settings.opinion_button}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home-works-sec light-background">
                <div className="container py-lg-5">
                    <div className="row">
                        <div className="col-12 pt-5 pt-lg-none">
                            <h1 className="home-big-titles" animate="left">{generalData.home_settings.doctors_title}</h1>
                        </div>
                        <div className="col-12 pb-5">
                            <div className="row pt-3">
                                <Swiper className="pb-2"
                                    slidesPerView={1}
                                    slidesPerGroup={1}
                                    loop="true"
                                    autoplay={{
                                        delay: 4000,
                                        disableOnInteraction: false
                                    }}
                                    pagination={{
                                        el: '.custom-pagination3',
                                        "clickable": true
                                    }}
                                    breakpoints={{
                                        // when window width is >= 320px
                                        500: {
                                            slidesPerView: 2,
                                            slidesPerGroup: 2
                                        },
                                        991: {
                                            slidesPerView: 3,
                                            slidesPerGroup: 3
                                        },
                                    }}
                                >

                                    {generalData ?
                                        generalData.doctors_list.map((singleDoctor, index) => (
                                            singleDoctor.show_in_homepage === 1 ?
                                                <SwiperSlide key={index}>
                                                    <div className="col-12 doctors-page">
                                                        <Link style={{ textDecoration: 'none' }} to={'/our-doctors/' + singleDoctor.id}>
                                                            <div className="doctors-box">
                                                                <img className="doctors-single-image" src={singleDoctor.full_path_image} alt={singleDoctor.name} />
                                                            </div>
                                                            <h3>{singleDoctor.name}</h3>
                                                            <h4>{singleDoctor.degrees}</h4></Link>
                                                        <p>{singleDoctor.small_description}</p>
                                                    </div>
                                                </SwiperSlide>
                                                : null
                                        ))
                                        :
                                        null
                                    }

                                </Swiper>
                                <div className="col-12 d-flex text-center justify-content-center">
                                    <div className="custom-pagination3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {viewPopup ?
                <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-11 col-lg-6">
                                <div className="pop-up-api-box">
                                    <svg className="close-svg" onClick={() => setViewPopup(false)} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                        <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                            <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                            <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                        </g>
                                    </svg>
                                    <div className="content background-light text-center px-5 py-5">
                                        <h1 className="mb-4">{generalData.home_settings.popup_title}</h1>
                                        <p className="my-2">{generalData.home_settings.popup_text}</p>
                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href={generalData.home_settings.popup_link}>
                                            <button onClick={() => setViewPopup(false)} className="home-buttons mt-4">{generalData.home_settings.popup_button}</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }

            <div className="home-services-sec services-page">
                <div className="container py-lg-5 d-none d-lg-block">
                    <div className="row py-5">
                        <div className="col-12">
                            <h1 className="text-center home-big-titles" animate="down">
                                {generalData.home_settings.services_title}
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        {generalData ?
                            generalData.services_list.map((singleService, index) => (
                                (Number(index) < '8') ?
                                    <div className="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 pb-5 text-center" animate="right" key={index}>
                                        <Link style={{ textDecoration: 'none' }} to={'/services/' + singleService.slug}>
                                            <img className="services-single-image" src={singleService.full_path_image} alt={singleService.singleService} />
                                            <h6 className="pt-3">{singleService.title}</h6></Link>
                                    </div>
                                    :
                                    null
                            ))
                            :
                            null
                        }
                    </div>
                    <div className="row pb-5" animate="left">
                        <div className="col-12 text-center">
                            <Link style={{ textDecoration: 'none' }} to={'/services'}>
                                <button className="home-buttons">{generalData.home_settings.services_button}</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="container py-lg-5 d-lg-none">
                    <div className="row py-5">
                        <div className="col-12">
                            <h1 className="text-center home-big-titles" animate="down">
                                {generalData.home_settings.services_title}
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <Swiper className="py-2"
                            slidesPerView={1}
                            slidesPerGroup={1}
                            loop="true"
                            autoplay={{
                                delay: 4000,
                                disableOnInteraction: false
                            }}
                            pagination={{
                                el: '.custom-pagination2',
                                "clickable": true
                            }}
                            breakpoints={{
                                // when window width is >= 320px
                                500: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2
                                },
                                768: {
                                    slidesPerView: 3,
                                    slidesPerGroup: 3
                                }
                            }}
                        >
                            {generalData ?
                                generalData.services_list.map((singleService, index) => (
                                    (Number(index) < '8') ?
                                        <SwiperSlide key={index}>
                                            <div className="col-12 text-center home">
                                                <Link style={{ textDecoration: 'none' }} to={'/services/' + singleService.slug}>
                                                    <img className="services-single-image" src={singleService.full_path_image} alt={singleService.singleService} />
                                                    <h6 className="pt-3">{singleService.title}</h6></Link>
                                            </div>
                                        </SwiperSlide>
                                        :
                                        null
                                ))
                                :
                                null
                            }
                        </Swiper>

                        <div className="col-12 align-self-center text-center pb-4">
                            <div className="custom-pagination2"></div>
                        </div>
                    </div>
                    <div className="row pb-5" animate="left">
                        <div className="col-12 text-center">
                            <Link style={{ textDecoration: 'none' }} to={'/services'}>
                                <button className="home-buttons">{generalData.home_settings.services_button}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
}
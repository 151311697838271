import React, { useContext, useEffect, useState } from 'react';
import Layout from '../layouts/Main';
import { useParams } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";


export default function DoctorsSingle(props) {

    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [experiences, setExperiences] = useState(true);
    const [awards, setAwards] = useState(false);


    useEffect(() => {
        window.triggerScroll();
    }, [generalData]);

    let { slug } = useParams();

    return (

        <Layout activePage="our-doctors" whiteMenu="false">
            <div className="first-section banner-dark single-doctor">
            </div>
            {generalData.doctors_list.map((singleDoctor, index) => (
                singleDoctor.id === Number(slug) ?
                    <div key={index}>
                        <div className="single-doctor-sec">
                            <div className="container">
                                <div className="row pb-5">
                                    <div className="col-8 col-lg-5">
                                        <div className="single-doctor-box">
                                            <img className="single-doctor-image" src={singleDoctor.full_path_image} alt={singleDoctor.name} animate="up" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-7 pl-lg-5" animate="down">
                                        <h1 className="pt-5">{singleDoctor.name}</h1>
                                        <h3 className="pt-2">{singleDoctor.degrees}</h3>
                                        {<div className="content pt-5" dangerouslySetInnerHTML={{ __html: singleDoctor.description }}></div>}
                                    </div>
                                </div>
                                {singleDoctor.doctor_languages.length ?
                                    <div className="row pb-5">
                                        <div className="col-12 col-lg-5">
                                            <h2 className="pb-3" animate="right">Languages</h2>
                                            <div className="row no-gutters">
                                                <div className="col-6 col-lg-12">
                                                    <div className="row no-gutters">
                                                        {
                                                            singleDoctor.doctor_languages.map((singlelanguage, index) => (
                                                                <div className="d-flex pr-4" key={index}>
                                                                    <h4 animate="left">{singlelanguage.title}</h4>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>

                        {
                            singleDoctor.experience || singleDoctor.awards ?
                                <div className="single-doctor-details mt-5">
                                    <div className="container py-5">
                                        <div className="row justify-content-center" animate="down">
                                            <div className="col-12 pt-lg-3 text-center pb-5">
                                                <h1>More Details</h1>
                                            </div>
                                            <div className="col-6 pt-lg-3 text-center pb-5 pr-0">
                                                <h2 className={experiences ? 'active' : ''} onClick={() => { if (!experiences) { setExperiences(!experiences); setAwards(!awards) } }} >EXPERIENCE</h2>
                                            </div>
                                            <div className="col-6 pt-lg-3 text-center pb-5 pl-0">
                                                <h2 className={awards ? 'active' : ''} onClick={() => { if (experiences) { setExperiences(!experiences); setAwards(!awards) } }} >AWARDS & HONORS</h2>
                                            </div>
                                            <div className="col-12" animate="left">
                                                {experiences ?
                                                    <p>{singleDoctor.experience}</p>
                                                    :
                                                    <p>{singleDoctor.awards}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                    :
                    null
            ))
            }
        </Layout>

    );
}
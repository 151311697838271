import React, { useContext, useEffect } from 'react';
import Layout from '../layouts/Main';
import { Link, useParams } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../components/GoogleAnalytics';


export default function ServicesSingle(props) {

    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;


    useEffect(() => {
        window.triggerScroll();
    }, [generalData]);

    let { slug } = useParams();

    const countDoctorsServices = (singleService) => {
        let count = 0;

        if (generalData) {
            generalData.doctors_list.forEach(singleDoctor => {
                singleDoctor.services.forEach(singleServicesRelation => {
                    if (singleServicesRelation.id === singleService.id) {
                        count++;
                    }
                });
            });
        }

        return count;
    }

    return (

        <Layout activePage="services" whiteMenu="false">

            {generalData.services_list.map((singleService, index) => (
                singleService.slug === slug ?

                    <>
                        <Helmet>
                            <title>{singleService?.title}</title>
                            <meta name="title" content={singleService?.title} />
                            <meta name="description" content={singleService?.description} />

                            <meta property="og:type" content="website" />
                            <meta property="og:url" content={window.location} />
                            <meta property="og:title" content={singleService?.title} />
                            <meta property="og:description" content={singleService?.description} />
                            <meta property="og:image" content={singleService?.full_path_image} />

                            <meta property="twitter:card" content="summary_large_image" />
                            <meta property="twitter:url" content={window.location} />
                            <meta property="twitter:title" content={singleService?.title} />
                            <meta property="twitter:description" content={singleService?.description} />
                            <meta property="twitter:image" content={singleService?.full_path_image} />
                        </Helmet>
                        <GoogleAnalytics />
                        <div className="single-services-sec">
                            <div className="container pt-5">
                                <div className="row pt-5">
                                    <div className="col-12 pb-4">
                                        <div className="row no-gutters">
                                            <div className="col-auto my-auto">
                                                <img className="services-single-image" src={singleService.full_path_image} alt={singleService.singleService} animate="down" />
                                            </div>
                                            <div className="col my-auto pl-4">
                                                <h1 animate="down">{singleService.title}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-10 pb-3">
                                        <p animate="down">{singleService.description}</p>
                                    </div>
                                    {
                                        countDoctorsServices(singleService) > 0 && (
                                            <div className="col-12 pb-2">
                                                <h3 animate="down">{generalData.services_settings.specialists_title}</h3>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="container pb-5">
                            <div className="row pb-5">
                                {generalData ?
                                    generalData.doctors_list.map((singleDoctor, index) => (
                                        singleDoctor.services.map((singleServicesRelation, index) => (
                                            singleServicesRelation.id === singleService.id ?
                                                <div className="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 pb-5" animate="top">
                                                    <div className="doctors-page">
                                                        <Link style={{ textDecoration: 'none' }} to={'/our-doctors/' + singleDoctor.id}>
                                                            <div className="doctors-box">
                                                                <img className="doctors-single-image" src={singleDoctor.full_path_image} alt={singleDoctor.name} />
                                                            </div>
                                                            <h3>{singleDoctor.name}</h3>
                                                            <h4>{singleDoctor.degrees}</h4></Link>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        ))
                                    ))
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </>
                    :
                    null
            ))
            }
        </Layout>

    );
}
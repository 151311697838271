import React, { useContext, useEffect, useState } from 'react';
import Layout from '../layouts/Main';
import DarkBanner from '../components/DarkBanner';
import GlobalState from "../contexts/GlobalState";
import Accordion from '../components/Accordion';
import { Helmet } from 'react-helmet';

import GoogleAnalytics from '../components/GoogleAnalytics';
export default function Faqs(props) {


    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [openId, setOpenId] = useState(null);
    const numRows = generalData.faqs_list_items.length;

    useEffect(() => {
        window.triggerScroll();
    }, [generalData, openId]);

    return (

        <Layout activePage="faqs" whiteMenu="false">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['faqs'].title}</title>
                    <meta name="title" content={generalData?.seo['faqs']?.title} />
                    <meta name="description" content={generalData?.seo['faqs']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['faqs'].title} />
                    <meta property="og:description" content={generalData?.seo['faqs'].description} />
                    <meta property="og:image" content={generalData?.seo['faqs'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['faqs'].title} />
                    <meta property="twitter:description" content={generalData?.seo['faqs'].description} />
                    <meta property="twitter:image" content={generalData?.seo['faqs'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <DarkBanner>{generalData.menu_items['faqs']}</DarkBanner>
            <div className="faqs">
                <div className="container py-5">
                    <div className="row">
                        {generalData.faqs_list_items.map((singleFaqItem, index) => (
                            <div className="col-12" key={index}>
                                {Number(index) === Number(numRows - 1) ?
                                    <div className="border-faqs border-faqs-radius-bottom w-100 h-100" animate={Number(index) % 2 === 0 ? 'right' : 'right'}>
                                        <Accordion id={singleFaqItem.id} accordionId={index} title={singleFaqItem.title} content={singleFaqItem.text} openId={openId} setOpenId={setOpenId} />
                                    </div>
                                    :
                                    <div className={index === 0 ? 'border-faqs border-faqs-radius-top w-100 h-100' : 'border-faqs w-100 h-100'} animate={Number(index) % 2 === 0 ? 'right' : 'right'}>
                                        <Accordion id={singleFaqItem.id} accordionId={index} title={singleFaqItem.title} content={singleFaqItem.text} openId={openId} setOpenId={setOpenId} />
                                    </div>
                                }
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
        </Layout>
    );
}
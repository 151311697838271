import React, { useContext, useEffect } from 'react';
import Layout from '../layouts/Main';
import DarkBanner from '../components/DarkBanner';
import GlobalState from "../contexts/GlobalState";
import { Link } from "react-router-dom";
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../components/GoogleAnalytics';

import 'swiper/swiper.min.css';
import "swiper/components/effect-fade/effect-fade.min.css";
import 'swiper/components/navigation/navigation.min.css';

export default function Testimonials(props) {

    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;

    SwiperCore.use([Navigation, Autoplay]);

    useEffect(() => {
        window.triggerScroll();
    }, [generalData]);


    return (

        <Layout activePage="testimonials" whiteMenu="false">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['testimonials'].title}</title>
                    <meta name="title" content={generalData?.seo['testimonials']?.title} />
                    <meta name="description" content={generalData?.seo['testimonials']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['testimonials'].title} />
                    <meta property="og:description" content={generalData?.seo['testimonials'].description} />
                    <meta property="og:image" content={generalData?.seo['testimonials'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['testimonials'].title} />
                    <meta property="twitter:description" content={generalData?.seo['testimonials'].description} />
                    <meta property="twitter:image" content={generalData?.seo['testimonials'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <DarkBanner>{generalData.menu_items['testimonials']}</DarkBanner>
            <div className="testimonials">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 quotes-testimonials">
                            <svg xmlns="http://www.w3.org/2000/svg" width="97" height="85.122" viewBox="0 0 97 85.122">
                                <path id="Path_22" data-name="Path 22" d="M57.214-137.646v-36.622H37.748a80.419,80.419,0,0,1,.825-11.383,33.761,33.761,0,0,1,3.134-10.393,21.131,21.131,0,0,1,5.939-7.423,14.667,14.667,0,0,1,9.238-2.8v-16.5a32.02,32.02,0,0,0-16.167,3.959,33.763,33.763,0,0,0-11.383,10.558,47.3,47.3,0,0,0-6.6,15.342,78.049,78.049,0,0,0-2.145,18.641v36.622Zm60.377,0v-36.622H98.126a80.418,80.418,0,0,1,.825-11.383,33.76,33.76,0,0,1,3.134-10.393,21.131,21.131,0,0,1,5.939-7.423,14.667,14.667,0,0,1,9.238-2.8v-16.5a32.02,32.02,0,0,0-16.167,3.959,33.763,33.763,0,0,0-11.383,10.558,47.3,47.3,0,0,0-6.6,15.342,78.049,78.049,0,0,0-2.145,18.641v36.622Z" transform="translate(-20.592 222.768)" fill="#000074" />
                            </svg>
                        </div>
                        <div className="col-12 py-5">
                            <div className="row no-gutters">
                                <div className="col-2 col-lg-2">
                                    <div className="position-relative w-100 h-100">
                                        <div className="swiper-button-prev" />
                                    </div>
                                </div>
                                <div className="col-8 col-lg-8">
                                    <Swiper className="pb-2 grabbable"
                                        loop="true"
                                        modules={Navigation}
                                        spaceBetween={10}
                                        centeredSlides={true}
                                        autoplay={{
                                            delay: 4000,
                                            disableOnInteraction: false
                                        }}
                                        // navigation={true}
                                        navigation={{
                                            nextEl: ".swiper-button-next",
                                            prevEl: ".swiper-button-prev",
                                        }}
                                    >
                                        {generalData.testimonials_list.map((singleTestimonial, i) => (
                                            <SwiperSlide key={i}>
                                                <div className="row align-items-center my-auto">
                                                    <div className="col-12 col-lg-auto align-testimonials-small">
                                                        <img className="pb-2 pb-lg-0" src={singleTestimonial.full_path_image} alt="customer-profile" />
                                                    </div>
                                                    <div className="col-12 col-md">
                                                        <div className="row no-gutters align-items-center pb-3">
                                                            <div className="col-12 col-lg-auto align-testimonials-small">
                                                                <h2 className="pb-2 pb-lg-0">{singleTestimonial.name}</h2>
                                                            </div>
                                                            <div className="col-12 col-md d-flex align-testimonials-small">
                                                                <div className="stars-rating pl-lg-3 d-flex align-testimonials-small">
                                                                    <div className="pr-1 d-flex align-testimonials-small">
                                                                        {[...Array(5)].map((star, index) => (
                                                                            Number(index) < Number(singleTestimonial.review_stars) ?
                                                                                <div className="pr-2 d-flex" key={index}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.935" height="21.979" viewBox="0 0 22.935 21.979">
                                                                                        <path id="Path_176" data-name="Path 176" d="M197.133,299.944a1.221,1.221,0,0,1-1.188-1.491l1.475-6.5-5-4.388a1.22,1.22,0,0,1,.691-2.133l6.621-.6,2.618-6.125a1.219,1.219,0,0,1,2.242,0l2.618,6.127,6.62.6a1.22,1.22,0,0,1,.693,2.133l-5,4.387,1.475,6.5a1.219,1.219,0,0,1-1.814,1.317l-5.709-3.412-5.709,3.414a1.223,1.223,0,0,1-.625.173Zm2.545-14.994a0,0,0,0,1,0,0Zm7.576,0v0s0,0,0,0Zm0,0" transform="translate(-192 -277.965)" fill="#000074" />
                                                                                    </svg>
                                                                                </div>
                                                                                :
                                                                                <div className="pr-2 d-flex" key={index}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.935" height="21.979" viewBox="0 0 22.935 21.979">
                                                                                        <path id="Path_19" data-name="Path 19" d="M197.133,299.944a1.221,1.221,0,0,1-1.188-1.491l1.475-6.5-5-4.388a1.22,1.22,0,0,1,.691-2.133l6.621-.6,2.618-6.125a1.219,1.219,0,0,1,2.242,0l2.618,6.127,6.62.6a1.22,1.22,0,0,1,.693,2.133l-5,4.387,1.475,6.5a1.219,1.219,0,0,1-1.814,1.317l-5.709-3.412-5.709,3.414a1.223,1.223,0,0,1-.625.173Zm6.334-5.056a1.222,1.222,0,0,1,.625.173l5.388,3.222-1.392-6.134a1.218,1.218,0,0,1,.386-1.188l4.724-4.144-6.251-.567a1.218,1.218,0,0,1-1.013-.739l-2.467-5.781L201,285.512a1.215,1.215,0,0,1-1.01.736l-6.252.567,4.725,4.144a1.217,1.217,0,0,1,.386,1.189l-1.392,6.134,5.388-3.221a1.221,1.221,0,0,1,.625-.173Zm-3.789-9.938a0,0,0,0,1,0,0Zm7.576,0v0s0,0,0,0Zm0,0" transform="translate(-192 -277.965)" fill="#000074" />
                                                                                    </svg>
                                                                                </div>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 align-testimonials-small">
                                                                <p>{singleTestimonial.text}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                        ))
                                        }
                                    </Swiper>
                                </div>
                                <div className="col-2 col-lg-2">
                                    <div className="position-relative w-100 h-100">
                                        <div className="swiper-button-next"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-right quotes-testimonials">
                            <svg xmlns="http://www.w3.org/2000/svg" width="97" height="85.122" viewBox="0 0 97 85.122">
                                <path id="Path_23" data-name="Path 23" d="M57.214-137.646v-36.622H37.748a80.419,80.419,0,0,1,.825-11.383,33.761,33.761,0,0,1,3.134-10.393,21.131,21.131,0,0,1,5.939-7.423,14.667,14.667,0,0,1,9.238-2.8v-16.5a32.02,32.02,0,0,0-16.167,3.959,33.763,33.763,0,0,0-11.383,10.558,47.3,47.3,0,0,0-6.6,15.342,78.049,78.049,0,0,0-2.145,18.641v36.622Zm60.377,0v-36.622H98.126a80.418,80.418,0,0,1,.825-11.383,33.76,33.76,0,0,1,3.134-10.393,21.131,21.131,0,0,1,5.939-7.423,14.667,14.667,0,0,1,9.238-2.8v-16.5a32.02,32.02,0,0,0-16.167,3.959,33.763,33.763,0,0,0-11.383,10.558,47.3,47.3,0,0,0-6.6,15.342,78.049,78.049,0,0,0-2.145,18.641v36.622Z" transform="translate(117.592 -137.646) rotate(180)" fill="#000074" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home-opinion-sec">
                <div className="opinion-image-filter"></div>
                <img src={generalData.home_settings.opinion_image} className="home-opinion-image" alt="Opinions" animate="left" />
                <div className="opinion-description">
                    <div className="container py-5">
                        <div className="row justify-content-center py-5">
                            <div className="col-10 col-lg-8 text-center">
                                <h1 className="home-big-titles pb-3" animate="right">{generalData.home_settings.opinion_title}</h1>
                                <h2 className="lh-6" animate="right">{generalData.home_settings.opinion_subtitle}</h2>
                                <Link style={{ textDecoration: 'none' }} to={'/get-care'}>
                                    <button className="home-buttons mt-4" animate="left">{generalData.home_settings.opinion_button}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    );
}
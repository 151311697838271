import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import Api from "../components/Api";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';
import Meetings from '../components/Meetings';
import moment from 'moment';
// import dateFormat from 'dateformat';
import { dateFormat } from '../Helpers';
import Notification from '../components/Notification';


export default function DoctorDashboard(props) {
    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [loading] = useState(false);
    const [authUser] = authUserGlobal;
    const [getCareCases, setGetCareCases] = useState();
    const [meetingsDetails, setMeetingsDetails] = useState({});
    const [meetingInfo, setMeetingInfo] = useState([]);
    const [meetingPopup, setMeetingPopup] = useState(false);
    const [upcomingMeetings, setUpcomingMeetings] = useState([]);
    const [ , setPreviousMeetings] = useState([]);
    const [notifications, setNotifications] = useState();
    const [totalOutstanding,setTotalOutstanding] = useState();


    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);

    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading, getCareCases]);

    useEffect(() => {
    }, [usersMenuOpen]);

    useEffect(() => {
        axios.get('get-care-count-doctor/' + authUser.id)
            .then((r) => {
                setGetCareCases(r.data)
            })
            .finally(() => {
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        axios.get('get-doctor-total')
            .then((r) => {
                setTotalOutstanding(r.data)
            })
            .finally(() => {
            });
    }, []);

    useEffect(() => {
        setGeneralDataApiOptions({
            url: 'all-user-notifications',
            method: 'get',
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                setNotifications(res.data);
            }
        })
    }, []);

    useEffect(() => {
        axios.get('get-care-cases-doctor')
            .then((r) => {
                setMeetingsDetails(r.data)
                let upcomingMeetingsTemp = [];
                let previousMeetingsTemp = [];
                if (r.data.length > 0) {
                    r.data?.forEach((singleMeeting) => (
                        moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local() > moment() ?
                            upcomingMeetingsTemp.push(singleMeeting)
                            :
                            previousMeetingsTemp.push(singleMeeting)
                    ));
                }
                setPreviousMeetings(previousMeetingsTemp);
                setUpcomingMeetings(upcomingMeetingsTemp);
            })
            .finally(() => {
            });
    }, [meetingPopup]);

    
    const closePop = () => {
        setMeetingPopup(!meetingPopup);
        setMeetingInfo([]);
    };

    return (
        <>
            <UserLayout
                activeUserPage="dashboard"
            />
            <div className={usersMenuOpen ? 'dashboard-content menu-open' : 'dashboard-content'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row">
                        <div className="col-12 col-md-6 pt-3 w-100" animate="right">
                            <p className="dashboard-username">{generalData.doctor_dashboard_settings.greetings_title} {authUser.name ? authUser.name : ''}!</p>
                        </div>
                    </div>
                    <div className="row" animate="right">
                        {getCareCases >= 0 ?
                            <>
                                <div className="col-12 col-md-3 text-center py-3">
                                    <Link style={{ textDecoration: 'none' }} to={getCareCases > 0 ? '/doctor-records' : '#'}>
                                        <div className="border-dashboard-boxes">
                                            <div className="pt-4 pb-3">
                                                <img className="dashboard-icons-backend" src={generalData.doctor_dashboard_settings.case_list_icon} alt="icon" />
                                            </div>
                                            <p className="dashboard-card-title pb-3">{generalData.doctor_dashboard_settings.case_list_title}</p>
                                            <div className="services-count-container">{getCareCases >= 0 ? getCareCases : ''}</div>
                                        </div>
                                    </Link>
                                </div>
                            </>
                            :
                            <div className="col-12 col-md-4 py-3 d-flex justify-content-center">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        }
                        {upcomingMeetings.length >= 0 ?
                            <>
                                <div className="col-12 col-md-3 text-center py-3">
                                    <Link style={{ textDecoration: 'none' }} to="/doctor-calendar">
                                        <div className="border-dashboard-boxes">
                                            <div className="pt-4 pb-3">
                                                <img className="dashboard-icons-backend" src={generalData.doctor_dashboard_settings.meetings_icon} alt="icon" />
                                            </div>
                                            <p className="dashboard-card-title pb-3">{generalData.doctor_dashboard_settings.meetings_title}</p>
                                            <div className="services-count-container">{upcomingMeetings.length >= 0 ? upcomingMeetings.length : 0}</div>
                                        </div>
                                    </Link>
                                </div>
                            </>
                            :
                            <div className="col-12 col-md-4 py-3 d-flex justify-content-center">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        }
                        {/* <div className="col-12 col-md-3 text-center py-3">
                            <Link style={{ textDecoration: 'none' }} to="/doctor-records">
                                <div className="border-dashboard-boxes">
                                    <div className="pt-4 pb-3">
                                        <img className="dashboard-icons-backend" src={generalData.doctor_dashboard_settings.pending_reports_icon} alt="icon" />
                                    </div>
                                    <p className="dashboard-card-title pb-3">{generalData.doctor_dashboard_settings.pending_reports_title}</p>
                                    <div className="services-count-container">0</div>
                                </div>
                            </Link>
                        </div> */}
                        <div className="col-12 col-md-3 text-center py-3">
                            <Link style={{ textDecoration: 'none' }} to="/doctor-payments">
                                <div className="border-dashboard-boxes">
                                    <div className="pt-4 pb-3">
                                        <img className="dashboard-icons-backend" src={generalData.doctor_dashboard_settings.balance_icon} alt="icon" />
                                    </div>
                                    <p className="dashboard-card-title pb-3">{generalData.doctor_dashboard_settings.balance_title}</p>
                                    <div className="services-count-container">{totalOutstanding ? totalOutstanding : 0} GBP</div>
                                </div>
                            </Link>
                        </div>
                        {
                            meetingsDetails?.length > 0 ?
                                <>
                                    <div className="col-12 pt-4">
                                        <h2 className="meeting-dashboard-title m-0">{generalData.doctor_dashboard_settings.mdt_title}</h2>
                                    </div>
                                    <div className="col-12 pt-4 px-4 d-none d-md-block">
                                        <div className="row px-4">
                                            <div className="col-3">
                                                <p className="m-0 above-mdt-titles">{generalData.doctor_dashboard_settings.mdt_name}</p>
                                            </div>
                                            <div className="col-3">
                                                <p className="m-0 above-mdt-titles">{generalData.doctor_dashboard_settings.mdt_date}</p>
                                            </div>
                                            <div className="col-2">
                                                <p className="m-0 above-mdt-titles">{generalData.doctor_dashboard_settings.mdt_time}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row pb-3">
                                            {meetingsDetails?.map((singleMeeting, index) => (
                                                Number(index) < 3 ?
                                                    <div className="col-12 mt-3" key={index}>
                                                        <div className="border-dashboard-boxes p-3 pr-0 pl-0 d-flex flex-column flex-md-row my-auto justify-content-md-between border-20">
                                                            {singleMeeting.doctor?.name ?
                                                                <div className="col-6 col-md-3 my-auto pb-2 pb-md-0">
                                                                    <p className="m-0 my-auto meeting-title-small">{singleMeeting.doctor?.name ? singleMeeting.doctor?.name : ''}</p>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            <div className="col-6 col-md-3 my-auto pb-2 pb-md-0">
                                                                <p className="m-0 my-auto meeting-title-small">{dateFormat(moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local().toString(), "d mmmm yyyy")}</p>
                                                            </div>
                                                            <div className="col-6 col-md-2 my-auto pb-2 pb-md-0">
                                                                <p className="m-0 my-auto meeting-title-small">{moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local().format("h:mm a")}</p>
                                                            </div>
                                                            <div className="col-12 col-md d-flex justify-content-md-end pt-2">
                                                                <a href={singleMeeting.link} target="_blank" rel="noreferrer">
                                                                    <button className="home-buttons request-metting mr-3 px-4 font-w-medium">{generalData.doctor_dashboard_settings.mdt_join}</button>
                                                                </a>
                                                                <button className="home-buttons request-metting px-2 font-w-medium" onClick={() => { setMeetingInfo(singleMeeting); setMeetingPopup(!meetingPopup) }}>{generalData.doctor_dashboard_settings.mdt_reschedule}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            ))
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }

                        {
                            <div className="col-12 w-100 py-4">
                                {notifications?.length > 0 ?
                                    <>
                                        <h2 className="meeting-dashboard-title m-0">CASES UPDATES</h2>
                                        {notifications?.map((singleNotification, index) => (
                                            <Notification notificationInfo={singleNotification} key={index} white={true} />
                                        ))}
                                    </>
                                    :
                                    null
                                }
                            </div>
                        }
                    </div>
                </div>
                {meetingPopup ?
                    <Meetings meeting={false} meetingPopup={() => closePop()} singleMeetingInfo={meetingInfo} />
                    :
                    null
                }

                <Api options={generalDataApiOptions} />
            </div>
        </>
    );
}
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../layouts/Main';
import DarkBanner from '../components/DarkBanner';
import { Link } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../components/GoogleAnalytics';

export default function OurDoctors(props) {

    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [lastPage, setLastPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [doctorsList, setDoctorsList] = useState([]);
    const [loadingForm, setLoadingForm] = useState(false);

    useEffect(() => {
        window.triggerScroll();
    }, [generalData]);

    useEffect(() => {
        setLoadingForm(true);
        axios.get('doctors-paginated?page=' + currentPage, { per_page: 8 }
        )
            .then((r) => {
                setDoctorsList([...doctorsList, ...r.data.doctors_list_paginated.data]);
                window.triggerScroll();
                setCurrentPage(r.data.doctors_list_paginated.current_page);
                setLastPage(r.data.doctors_list_paginated.last_page);
                setLoadingForm(false);
            })
            .finally(() => {
                setLoadingForm(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    return (

        <Layout activePage="our-doctors" whiteMenu="false">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['our-doctors'].title}</title>
                    <meta name="title" content={generalData?.seo['our-doctors']?.title} />
                    <meta name="description" content={generalData?.seo['our-doctors']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['our-doctors'].title} />
                    <meta property="og:description" content={generalData?.seo['our-doctors'].description} />
                    <meta property="og:image" content={generalData?.seo['our-doctors'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['our-doctors'].title} />
                    <meta property="twitter:description" content={generalData?.seo['our-doctors'].description} />
                    <meta property="twitter:image" content={generalData?.seo['our-doctors'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <DarkBanner>{generalData.menu_items['our-doctors']}</DarkBanner>
            <div className="container py-5 doctors-page">
                <div className="row py-5">
                    {
                        doctorsList.map((singleDoctor, index) => (
                            <div className="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 pb-5" animate="right" key={index}>
                                <Link style={{ textDecoration: 'none' }} to={'/our-doctors/' + singleDoctor.id}>
                                    <div className="doctors-box">
                                        <img className="doctors-single-image" src={singleDoctor.full_path_image} alt={singleDoctor.name} />
                                    </div>
                                    <h3>{singleDoctor.name}</h3>
                                    <h4>{singleDoctor.degrees}</h4></Link>
                            </div>
                        ))
                    }
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 d-flex text-center justify-content-center">

                        <div className={loadingForm ? "lds-ring" : "lds-ring d-none"}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        {
                            !!(currentPage !== lastPage) && (
                                <button className={!loadingForm ? "load-more home-buttons" : " hide"} onClick={() => { setCurrentPage(currentPage + 1); }} animate="down">
                                    Load More</button>
                            )
                        }
                    </div>
                </div>
            </div>
        </Layout >
    );
}
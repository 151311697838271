import { useState, useContext, useEffect } from 'react';
import Layout from '../layouts/Main';
import GlobalState from "../contexts/GlobalState";


export default function SignedIn(props) {
    const { generalDataGlobal, authUserGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions] = useState({});
    const [loading] = useState(false);
    const [ , setAuthUser] = authUserGlobal;


    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading]);

    return (

        <Layout activePage="signed-in" whiteMenu="false">
            <div className="container py-5">
                <div className="row justify-content-center text-center py-5">
                    <div className="col-12">
                        <p>You're logged in</p>
                    </div>
                    <div className="col-12" onClick={() => localStorage.setItem('auth_token', '')}>
                        <button onClick={() => setAuthUser()}>Logout</button>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
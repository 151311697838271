import { useState, useContext, useEffect } from 'react';
import Layout from '../layouts/Main';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Api from "../components/Api";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleLogin } from '@react-oauth/google';
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../components/GoogleAnalytics';

// 380634602538-qvrlnp3itgrser5u1lda7k8oaeborojt.apps.googleusercontent.com

export default function SignIn(props) {

    const [, setLogin] = useState();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { generalDataGlobal, authUserGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [loading, setLoading] = useState(false);
    const [, setAuthUser] = authUserGlobal;


    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading]);

    let history = useHistory();

    const login = useGoogleLogin({
        onSuccess: codeResponse => {
            setGeneralDataApiOptions({
                url: 'login-google',
                method: 'post',
                data: {
                    id_token: codeResponse?.access_token,
                },
                callback: res => {
                    // setLogin(res.data);
                    setAuthUser(res.data.user);
                    axios.defaults.headers.common['Authorization'] = res.data.token;
                    localStorage.setItem('auth_token', res.data.token);
                    if (res.data.user.user_types_id === 2) {
                        history.push("/user-dashboard");
                    }
                    if (res.data.user.user_types_id === 1) {
                        history.push("/doctor-dashboard");
                    }
                }
            })
        }
    });

    const responseFacebook = (response) => {
        setLoading(true);
        if (response?.accessToken) {
            setGeneralDataApiOptions({
                url: 'login-facebook',
                method: 'post',
                data: {
                    name: response.name,
                    email: response.email,
                    accessToken: response.accessToken,
                    userID: response.userID
                },
                callback: res => {
                    setLogin(res.data);
                    localStorage.setItem('auth_token', res.data.token);
                    setAuthUser(res.data.user);
                    axios.defaults.headers.common['Authorization'] = res.data.token;
                    if (res.data.user.user_types_id === 2) {
                        history.push("/user-dashboard");
                    }
                    if (res.data.user.user_types_id === 1) {
                        history.push("/doctor-dashboard");
                    }
                }
            })
        }
        setLoading(false);
    };


    const submitLogin = (e) => {
        e.preventDefault();
        setLogin('');

        setGeneralDataApiOptions({
            url: 'login',
            method: 'post',
            data: {
                email: email,
                password: password
            },
            callback: res => {
                setLogin(res.data);
                setAuthUser(res.data.user);
                axios.defaults.headers.common['Authorization'] = res.data.token;
                localStorage.setItem('auth_token', res.data.token);
                if (res.data.user.user_types_id === 2) {
                    history.push("/user-dashboard");
                }
                if (res.data.user.user_types_id === 1) {
                    history.push("/doctor-dashboard");
                }
            }

        })
    }

    return (

        <Layout activePage="sign-in" whiteMenu="false">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['auth-pages'].title}</title>
                    <meta name="title" content={generalData?.seo['auth-pages']?.title} />
                    <meta name="description" content={generalData?.seo['auth-pages']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['auth-pages'].title} />
                    <meta property="og:description" content={generalData?.seo['auth-pages'].description} />
                    <meta property="og:image" content={generalData?.seo['auth-pages'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['auth-pages'].title} />
                    <meta property="twitter:description" content={generalData?.seo['auth-pages'].description} />
                    <meta property="twitter:image" content={generalData?.seo['auth-pages'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <div className="first-section banner-dark sign-in">
                <div className="container">
                    <div className="row justify-content-center text-center">

                        <div className="col-12">
                            <h1 className="sign-in-text">{generalData.authentication_pages_settings.signin_page_title}</h1>
                        </div>
                        <div className="col-12 py-4">
                            <FacebookLogin
                                appId="3033563030250924"
                                fields="name,email"
                                callback={responseFacebook}
                                render={renderProps => (
                                    <button onClick={renderProps.onClick} className="p-0 m-0">
                                        <img className="social-icon-auth" src={generalData.authentication_pages_settings.facebook_icon} alt="Facebook Icon" />
                                    </button>
                                )}
                            />
                            <button onClick={() => login()} className="p-0 m-0">
                                <img className="social-icon-auth" src={generalData.authentication_pages_settings.google_icon} alt="Google Icon" />
                            </button>
                        </div>
                        <div className="col-12 py-2">
                            <h2>{generalData.authentication_pages_settings.or_title}</h2>
                        </div>
                        <form onSubmit={submitLogin} className="login-form w-100">

                            <div className="col-12 col-md-7 mx-auto d-flex flex-column justify-content-center py-2">
                                <div className="row no-gutters w-100">
                                    <div className="col-12 d-flex">
                                        <h3>{generalData.authentication_pages_settings.email_title}</h3>
                                    </div>
                                    <div className="col-12 d-flex">
                                        <input name="email" type="text" onChange={e => setEmail(e.target.value)} value={email} />
                                    </div>
                                    <div className="col-12 d-flex pt-4">
                                        <h3>{generalData.authentication_pages_settings.password_title}</h3>
                                    </div>
                                    <div className="col-12 d-flex">
                                        <input name="password" type="password" onChange={e => setPassword(e.target.value)} value={password} />
                                    </div>
                                </div>
                                <div className="col-12 pt-4">
                                    <Link to={"/forgot-password"} style={{ textDecoration: 'none' }}>
                                        <h4><span>{generalData.authentication_pages_settings.forgot_password_title}</span></h4>
                                    </Link>
                                </div>
                                <div className="col-12 pt-1">
                                    <Link to={"/sign-up"} style={{ textDecoration: 'none' }}>
                                        <h5>{generalData.authentication_pages_settings.signup_text} <span>{generalData.authentication_pages_settings.signup_title}</span></h5>
                                    </Link>
                                </div>
                                <div className="col-12 pt-4" onClick={() => setLoading(!loading)} >
                                    <button type="submit" className="login-button">{generalData.authentication_pages_settings.signin_button}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </Layout>
    );
}
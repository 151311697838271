import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import dateFormat from 'dateformat';
import axios from 'axios';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';


export default function UserRecords(props) {
    const { generalDataGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions] = useState({});
    const [loading] = useState(false);
    const [getCareCases, setGetCareCases] = useState();
    const [getCareCasesFiltered, setGetCareCasesFiltered] = useState();
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('');
    const [ , setCount] = useState(0);


    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);

    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading, getCareCases, getCareCasesFiltered]);

    useEffect(() => {
        axios.get('get-care-all')
            .then((r) => {
                setGetCareCases(r.data)
            })
    }, []);

    useEffect(() => {
    }, [search]);


    useEffect(() => {
    }, [usersMenuOpen]);

    

    const allRecordStatuses = () => {
        const genderOptions = generalData.case_statuses.map(
            singleStatus => ({ value: singleStatus.id, label: singleStatus.status_name }))
        return genderOptions;
    }

    useEffect(() => {
    }, [usersMenuOpen]);

    useEffect(() => {
    }, [filter]);

    useEffect(() => {
        if (getCareCases) {
            setGetCareCasesFiltered(getCareCases)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let newGetCareCasesFiltered = [];
        let searchToLower = search.toLowerCase();
        if (getCareCases) {
            getCareCases.forEach(singleCase => {
                if (
                    (
                        !search ||
                        (
                            singleCase.id.toString().includes(searchToLower) ||
                            singleCase.case_status?.status_name?.toLowerCase().includes(searchToLower) ||
                            singleCase.doctor?.name?.toLowerCase().includes(searchToLower) ||
                            (singleCase.concerns?.map((singleConcern, i) => (
                                (i + 1 === Number(singleCase.concerns.length)) ? singleConcern.concern : singleConcern.concern + ', '
                            ))
                            ).toString().toLowerCase().includes(searchToLower) ||
                            ("Created on " + dateFormat(singleCase['created_at'], "d mmmm yyyy")).toString().toLowerCase().includes(searchToLower)
                        )
                    )
                    &&
                    (
                        !filter?.value
                        ||
                        filter.value === singleCase.case_status_id
                    )
                ) {
                    newGetCareCasesFiltered.push(singleCase);
                }
            });
        }
        setGetCareCasesFiltered(newGetCareCasesFiltered);
    }, [getCareCases, search, filter]);


    return (
        <>
            <UserLayout
                activeUserPage="records" />
            <div className={!usersMenuOpen ? 'dashboard-content' : 'dashboard-content menu-open'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-6 pt-3 w-100" animate="top">
                            <p className="dashboard-title mb-0">{generalData.patient_dashboard_settings.patient_records_title}</p>
                        </div>
                        <div className="col-12 col-md-6 pt-3 w-100">
                            <div className="row align-items-end">
                                <div className="col-12 col-sm-6 pt-3 w-100 pb-3 search-dropdown">
                                    <Dropdown className="search-dropdown position-relative" placeholder="Filter" options={allRecordStatuses()} value={filter} onChange={setFilter} />
                                    {filter !== '' ?
                                        <div onClick={() => setFilter('')} className="clear-filters"></div>
                                        :
                                        null
                                    }
                                </div>
                                <div className="col-12 col-sm-6 pt-0 pt-sm-3 w-100 pb-3">
                                    <input className="search-input position-relative" placeholder="Search" onChange={e => setSearch(e.target.value)} />
                                    <svg className="search-svg" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <g id="_001-loupe" data-name="001-loupe" transform="translate(0 -0.003)">
                                            <g id="Group_2" data-name="Group 2" transform="translate(0 0.003)">
                                                <path id="Path_3" data-name="Path 3" d="M19.756,18.58l-5.687-5.687a7.932,7.932,0,1,0-1.178,1.178l5.687,5.687a.833.833,0,1,0,1.178-1.178ZM7.917,14.17a6.25,6.25,0,1,1,6.25-6.25A6.257,6.257,0,0,1,7.917,14.17Z" transform="translate(0 -0.003)" fill="#25d6ff" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {getCareCases ?
                            <div className="col-12">
                                <div className="row">
                                    {getCareCasesFiltered.length ?
                                        getCareCasesFiltered.map((singleCase, index) => (
                                            <div className="col-12 col-md-6 col-xl-4 py-3" key={index} onChange={() => setCount(Number(index + 1))} animate="left">
                                                <Link style={{ textDecoration: 'none' }} to={singleCase?.case_status?.id === 6 ? '/get-care-logged/' + singleCase['email'] + '/' + singleCase['user_token'] : '/user-records/user-single-record/' + singleCase['email'] + '/' + singleCase['user_token']}>
                                                    <div className="border-dashboard-boxes h-100">
                                                        <div className="py-4 px-4">
                                                            <div className="row">
                                                                {singleCase['doctor_id'] ?
                                                                    <div className="col-auto pr-0" key={index}>
                                                                        <img className="doctor-case-image" src={singleCase?.doctor?.image ? singleCase?.doctor?.full_path_image : '/img/no-pic.jpg'} alt={singleCase?.doctor?.name} />
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                                <div className="col pt-2">
                                                                    <h1 className="case-title-records m-0">Case #</h1>
                                                                    <p className="case-text-records m-0">ID #{singleCase['id']}</p>
                                                                    <div className="single-care-title">
                                                                        {
                                                                            singleCase.concerns.map((singleConcern, i) => (
                                                                                (i + 1 === Number(singleCase.concerns.length)) ? singleConcern.concern : singleConcern.concern + ', '
                                                                                // singleConcern.concern
                                                                            ))
                                                                        }
                                                                    </div>
                                                                    {singleCase['doctor_id'] ?
                                                                        <p className="case-text-records m-0" key={index}>{singleCase.doctor?.name}</p>
                                                                        :
                                                                        ''
                                                                    }
                                                                    <p className="case-text-records m-0">Created On {dateFormat(singleCase['created_at'], "d mmmm yyyy")}</p>
                                                                    <p className="case-status-text-records m-0" style={{ color: generalData.case_statuses[Number(singleCase.case_status_id) - 1].color }}>{generalData.case_statuses[Number(singleCase.case_status_id) - 1].status_name}</p>
                                                                </div>
                                                            </div>
                                                            {/* <p className="pl-2">{x['user_token']}</p> */}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                        :
                                        <div className="col-12 pt-3 w-100" animate="top">
                                            <h2 className="no-results">No Cases</h2>
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            <div className="col-12 pt-3 pl-3 d-flex align-items-center justify-content-center">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
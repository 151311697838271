import { useState, useContext, useEffect } from 'react';
import Api from "../components/Api";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import "react-datepicker/dist/react-datepicker.css";
import Accordion from '../components/Accordion';



export default function UserOnlineSupport(props) {
    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [loading] = useState(false);
    const [authUser] = authUserGlobal;
    const [getCareCases, setGetCareCases] = useState();
    const [birthDate, setBirthDate] = useState();
    const [mobileNumber, setMobileNumber] = useState();
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [message, setMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [openId, setOpenId] = useState(null);
    const numRows = generalData.patient_support_faqs.length;

    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading, getCareCases, openId]);

    useEffect(() => {
    }, [usersMenuOpen]);

    useEffect(() => {
        axios.get('get-care-count/' + authUser.email)
            .then((r) => {
                setGetCareCases(r.data)
            })
            .finally(() => {
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (authUser) {
            setEmail(authUser?.email ? authUser.email : '')
            setName(authUser?.first_name && authUser?.last_name ? authUser?.first_name + ' ' + authUser?.last_name : authUser?.name)
            setMobileNumber(authUser?.phone_number ? authUser?.phone_number : '')
            setBirthDate(authUser?.date_of_birth)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    useEffect(() => {
    }, [getCareCases]);

    const submitContactUs = (e) => {
        e.preventDefault();

        setGeneralDataApiOptions({
            url: 'patient-support',
            method: 'post',
            data: {
                name: name,
                date_of_birth: birthDate,
                email: email,
                phone_number: mobileNumber,
                message: message,
            },
            callback: res => {
                setSuccessMessage(true);
                setMessage('');
                setName('');
                setMobileNumber('');
                setTimeout(() => {
                    setSuccessMessage(false);
                }, 4000);
            }
        })
    }

    return (
        <>
            <UserLayout
                activeUserPage="support"
                darkMenu={true}
            />
            <div className={usersMenuOpen ? 'dashboard-content menu-open bg-white' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont support">
                    <div className="row justify-content-center">
                        <div className="col-12 pt-3 w-100" animate="right">
                            <p className="dashboard-username">{generalData.patient_support_settings.page_title}</p>
                        </div>
                        <div className="col-12" animate="right">
                            <h1 className="support-contact-us-title">{generalData.patient_support_settings.contact_us_title}</h1>
                        </div>
                        <div className="col-12 pt-3" animate="right">
                            <form onSubmit={submitContactUs} autoComplete="off">
                                <div className="row w-100">
                                    <div className="col-12 col-md-6 pb-4">
                                        <h3 className="support-titles">{generalData.patient_support_settings.name_title}</h3>
                                        <input className="client-support-input" type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="col-12 col-md-6 pb-4 get-care-forms">
                                        <h3 className="support-titles">{generalData.patient_support_settings.phone_number_title}</h3>
                                        <PhoneInput className="phone-input-style get-care-input" international defaultCountry="GB" value={mobileNumber} onChange={setMobileNumber} name="mobile_number" type="text" />
                                    </div>
                                    <div className="col-12 pb-4">
                                        <h3 className="support-titles">{generalData.patient_support_settings.message_title}</h3>
                                        <textarea className="client-support-input w-100" value={message} onChange={(e) => setMessage(e.target.value)} />
                                    </div>
                                    <div className="col-12 text-right">
                                        <button className="home-buttons">{generalData.patient_support_settings.send_message_button}</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="col-12 pt-5 pb-3" animate="right">
                            <h1 className="support-contact-us-title">{generalData.patient_support_settings.faqs_title}</h1>
                        </div>
                        <div className="faqs">
                            <div className="col-12">
                                <div className="row pb-5">
                                    {generalData.patient_support_faqs.map((singleFaqItem, index) => (
                                        <div className="col-12" key={index}>
                                            {Number(index) === Number(numRows - 1) ?
                                                <div className="border-faqs border-faqs-radius-bottom w-100 h-100" animate={Number(index) % 2 === 0 ? 'right' : 'right'}>
                                                    <Accordion id={singleFaqItem.id} accordionId={index} title={singleFaqItem.title} content={singleFaqItem.text} openId={openId} setOpenId={setOpenId} />
                                                </div>
                                                :
                                                <div className={index === 0 ? 'border-faqs border-faqs-radius-top w-100 h-100' : 'border-faqs w-100 h-100'} animate={Number(index) % 2 === 0 ? 'right' : 'right'}>
                                                    <Accordion id={singleFaqItem.id} accordionId={index} title={singleFaqItem.title} content={singleFaqItem.text} openId={openId} setOpenId={setOpenId} />
                                                </div>
                                            }
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {successMessage ?
                    <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <svg className="close-svg" onClick={() => setSuccessMessage(false)} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                    <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                    <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                </g>
                            </svg>
                            <div className="content background-light text-center px-5 py-5">
                                <h1>{generalData.patient_support_settings.success_title}</h1>
                                <p className="my-2">{generalData.patient_support_settings.success_message}</p>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <Api options={generalDataApiOptions} />
            </div>
        </>
    );
}
import { useState, useContext, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';
import { useParams } from 'react-router';
import dateFormat from 'dateformat';
import { Swiper, SwiperSlide } from 'swiper/react';
import Api from "../components/Api";
import { sha256 } from 'js-sha256';

import 'swiper/swiper.min.css';
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/pagination/pagination.min.css";
import ProfileCard from '../components/ProfileCard';


const paymentDate = (date) => {
    return `${date.getFullYear()}:${date.getMonth() < 9 ? '0' + Number(Number(date.getMonth()) + 1) : Number(date.getMonth()) + 1}:${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}-${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`;
}

function bin2hex(s) {
    var i, f = 0, a = [];
    s += '';
    f = s.length;

    for (i = 0; i < f; i++) {
        a[i] = s.charCodeAt(i).toString(16).replace(/^([\da-f])$/, "0$1");
    }

    return a.join('');
}



function createHash(chargetotal, Currency, paydate, $shared) {
    let storeId = "7220540956";
    // NOTE: Please DO NOT hardcode the secret in that script. For example read it from a database.
    let sharedSecret = $shared;
    let stringToHash = storeId + paydate + chargetotal + Currency + sharedSecret;
    let ascii = bin2hex(stringToHash);


    return sha256(ascii);
}


export default function UserSingleRecord(props) {
    const userRequestDocsWrapper = useRef(null);
    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [generalDataApiOptions2, setGeneralDataApiOptions2] = useState({});
    const [loading] = useState(false);
    let { token } = useParams();
    let { email } = useParams();
    const swiperRef = useRef(null);
    const [authUser] = authUserGlobal;
    const [singleCare, setSingleCare] = useState([]);
    const [tokenState] = useState(token ? token : '');
    const [emailState] = useState(email ? email : '');
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [pageTab, setPageTab] = useState(1);
    const [doctor, setDoctor] = useState([]);
    const [addedDoctors, setAddedDoctors] = useState([]);
    const [requestSuccessMessage, setReuestSuccessMessage] = useState();
    const [, setLoadingRequest] = useState(false);
    const [, setErrors] = useState();
    const [diagnosis, setDiagnosis] = useState(0);
    const [problems, setProblems] = useState(0);
    const [surgeries, setSurgeries] = useState(0);
    const [medications, setMedications] = useState(0);


    const [payments, setPayments] = useState([]);
    const [oldCurrentPage, setOldCurrentPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingForm, setLoadingForm] = useState();
    const [lastPage, setLastPage] = useState();
    const [search, setSearch] = useState('');
    const [submitSearch, setSubmitSearch] = useState(0);
    const [shared, setShared] = useState('');
    const [date, setDate] = useState();

    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);
    
    useEffect(() => {
        setDate(paymentDate(new Date()));
        axios.get('get-shared')
            .then((r) => {
                setShared(r.data);
            });
    }, []);


    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading, singleCare]);


    useEffect(() => {
        if (singleCare?.id) {
            let page = currentPage === oldCurrentPage ? 1 : currentPage;
            setGeneralDataApiOptions2({
                url: 'user-payments?page=' + page,
                data: {
                    search: search,
                    id: singleCare?.id,
                },
                method: 'post',
                headers: { "Content-Type": "multipart/form-data" },
                callback: res => {
                    if (res.data.payments) {
                        if (currentPage !== oldCurrentPage) {
                            setPayments([...payments, ...res.data.payments.data]);
                            setCurrentPage(res.data.payments.current_page);
                            setLastPage(res.data.payments.last_page);
                            setOldCurrentPage(res.data.payments.current_page);
                        }
                        else {
                            setPayments(res.data.payments.data);
                            setCurrentPage(res.data.payments.current_page);
                            setLastPage(res.data.payments.last_page);
                            setOldCurrentPage(res.data.payments.current_page);
                        }
                        window.triggerScroll();
                        setLoadingForm(false);
                    }
                }
            })
            if (submitSearch === 1) {
                setSubmitSearch(0)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, singleCare?.id, submitSearch === 1]);


    // const payNow = (e, id) => {
    //     setGeneralDataApiOptions({
    //         url: 'user-pay',
    //         data: {
    //             id: id,
    //         },
    //         method: 'post',
    //         headers: { "Content-Type": "multipart/form-data" },
    //         callback: res => {
    //             setSubmitSearch(1);
    //         }
    //     });
    // }

    const getInvoice = (e, id) => {
        setGeneralDataApiOptions({
            url: 'request-pdf-invoice',
            data: {
                id: id,
            },
            method: 'post',
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                if (res.data) {
                    window.open(res.data, "_blank");
                }
            }
        });
    }

    const inputHandler = (files, id) => {
        setReuestSuccessMessage(false);
        if (files.length > 0) {
            let fd = new FormData();
            for (let i = 0; i < files.length; i++) {
                fd.append("attached_documents[]", files[i]);
            };
            fd.append('id', id);

            setGeneralDataApiOptions({
                url: 'add-document-request-user',
                method: 'post',
                data: fd,
                headers: { "Content-Type": "multipart/form-data" },
                callback: res => {
                    setReuestSuccessMessage(true);
                    setErrors();
                    setLoadingRequest(false);
                }
            })
        }
    }


    // const fileHandler = (e, id) => {
    //     inputRef.current.click();
    // }



    useEffect(() => {
    }, [usersMenuOpen]);

    useEffect(() => {
        axios.get('get-care-single/' + emailState + '/' + tokenState)
            .then((r) => {
                if (r.data?.diagnosis.length > 0) {
                    let counter = 0;
                    r.data.diagnosis.forEach((singleDiagnosis) => (
                        singleDiagnosis.medical_diagnosis !== '' ?
                            counter++
                            :
                            null
                    ))
                    setDiagnosis(counter);
                }
                if (r.data?.problems.length > 0) {
                    let counter = 0;
                    r.data.problems.forEach((singleProblem) => (
                        singleProblem.problem !== '' ?
                            counter++
                            :
                            null
                    ))
                    setProblems(counter);
                }
                if (r.data?.surgeries.length > 0) {
                    let counter = 0;
                    r.data.surgeries.forEach((singleProblem) => (
                        singleProblem.surgery !== '' ?
                            counter++
                            :
                            null
                    ))
                    setSurgeries(counter);
                }

                if (r.data?.medications.length > 0) {
                    let counter = 0;
                    r.data.medications.forEach((singleProblem) => (
                        singleProblem.medication !== '' ?
                            counter++
                            :
                            null
                    ))
                    setMedications(counter);
                }
                setSingleCare(r.data)
                setDoctor(r.data.doctor ? r.data.doctor : null);
                setAddedDoctors(r.data.users ? r.data.users : null);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestSuccessMessage]);

    useEffect(() => {
        if (userRequestDocsWrapper.current) {
            userRequestDocsWrapper.current.scrollTop = userRequestDocsWrapper.current.scrollHeight;
        }
    }, [pageTab, singleCare.doc_lab_results?.length]);

    useEffect(() => {
    }, [doctor, addedDoctors]);

    useEffect(() => {
        if (typeof swiperRef !== null) {
            setTimeout(() => {
                swiperRef.current?.swiper.update();
            }, 400)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersMenuOpen, swiperRef]);


    return (
        <>
            <UserLayout
                darkMenu={true}
                activeUserPage="records" />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont">
                    {singleCare.id ?
                        <div className="row">
                            <div className="col-12 col-md-6 pt-3 w-100 d-flex">
                                <Link to="/user-records" className="d-flex my-auto" animate="left">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.992" height="19.986" viewBox="0 0 10.992 19.986">
                                        <path id="Path_77" data-name="Path 77" d="M-4195.109,1774l-8.579,8.578,8.579,8.579" transform="translate(4204.688 -1772.586)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    </svg>
                                </Link>
                                <p className="dashboard-title d-flex m-0 pl-4" animate="left">Case {singleCare.id}</p>
                            </div>
                            {singleCare?.case_status_id !== 5 ?
                                <div className="col-12 pl-5" animate="left">
                                    <a style={{ textDecoration: 'none' }} href={'/get-care-logged/' + singleCare.email + '/' + singleCare.user_token} rel="noreferrer" ><p className="request-box-request-text pl-1">{generalData.patient_records_settings.edit_title}</p></a>
                                </div>
                                :
                                null
                            }
                            <div className="col-12" animate="left">
                                <div className="row">
                                    <ProfileCard
                                        patient_title={generalData.patient_records_settings.patient_title}
                                        image={authUser.image ? authUser.image : '/img/no-pic.jpg'}
                                        name={singleCare.first_name + ' ' + singleCare.last_name}
                                        birthdate={dateFormat(singleCare.date_of_birth, "d mmmm, yyyy")}
                                        id={authUser.id}
                                        user_gender_id={singleCare?.user_gender_id ?
                                            generalData.user_gender.map((SingleGender) => (
                                                SingleGender.id === singleCare.user_gender_id ? SingleGender.gender : null
                                            ))

                                            :
                                            null
                                        }
                                        countries_list_id={
                                            generalData.countries_list.map((SingleCountry, i) => (
                                                SingleCountry.id === singleCare.countries_list_id ? SingleCountry.name : null
                                            ))
                                        }
                                    />

                                    {doctor ?
                                        !addedDoctors.length ?
                                            <div className="col-12 col-md-6 py-3" animate="left">
                                                <div className="border-dashboard-boxes fill-dark h-100">
                                                    <div className="py-4 px-3">
                                                        <h1 className="single-record-box-title pl-2">{generalData.patient_records_settings.doctor_title}</h1>
                                                        {doctor.id ?
                                                            <>
                                                                <div className="row pt-3">
                                                                    <div className="col-auto pr-0">
                                                                        <img className="user-single-records-image" src={doctor.image ? doctor.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                    </div>
                                                                    <div className="col pt-md-2">
                                                                        <p className="user-single-records-name pl-2">{doctor.name}</p>
                                                                        <div className="row">
                                                                            {doctor.doctor_languages.length > 0 ?
                                                                                <div className="col-12 col-md-6">
                                                                                    <p className="pl-2">
                                                                                        {
                                                                                            doctor.doctor_languages.map((doctorLang, i) => (
                                                                                                (i + 1 === Number(doctor.doctor_languages.length)) ? doctorLang.title : doctorLang.title + ', '
                                                                                            ))
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }

                                                                            {doctor.user_gender_id ?
                                                                                <div className="col-12 col-md-6">
                                                                                    <p className="pl-2">{generalData.user_gender.map((SingleGender) => (
                                                                                        SingleGender.id === doctor.user_gender_id ? SingleGender.gender : null
                                                                                    ))
                                                                                    }
                                                                                    </p>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }

                                                                            {doctor.countries_list_id ?
                                                                                <div className="col-12 col-md-6">
                                                                                    <p className="pl-2">{generalData.countries_list.map((SingleCountry) => (
                                                                                        SingleCountry.id === doctor.countries_list_id ? SingleCountry.name : null
                                                                                    ))
                                                                                    }
                                                                                    </p>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-12 col-md-6 w-100">
                                                <div className="row m-0 w-100">
                                                    <Swiper
                                                        ref={swiperRef}
                                                        className="doctors-swiper"
                                                        slidesPerView={1}
                                                        loop="true"
                                                        pagination
                                                        spaceBetween={30}
                                                        centeredSlides={true}
                                                    >
                                                        <SwiperSlide>
                                                            <div className="col-12 py-3 p-0 w-100 h-100">
                                                                <div className="border-dashboard-boxes fill-dark h-100 w-100">
                                                                    <div className="py-4 px-3 w-100">
                                                                        <h1 className="single-record-box-title pl-2">{generalData.patient_records_settings.doctor_title}</h1>
                                                                        {doctor.id ?
                                                                            <>
                                                                                <div className="row pt-3">
                                                                                    <div className="col-auto pr-0">
                                                                                        <img className="user-single-records-image" src={doctor.image ? doctor.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                                    </div>
                                                                                    <div className="col pt-md-2">
                                                                                        <p className="user-single-records-name pl-2">{doctor.name}</p>
                                                                                        <div className="row">
                                                                                            {doctor.doctor_languages.length > 0 ?
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <p className="pl-2">
                                                                                                        {
                                                                                                            doctor.doctor_languages.map((doctorLang, i) => (
                                                                                                                (i + 1 === Number(doctor.doctor_languages.length)) ? doctorLang.title : doctorLang.title + ', '
                                                                                                            ))
                                                                                                        }
                                                                                                    </p>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }

                                                                                            {doctor.user_gender_id ?
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <p className="pl-2">{generalData.user_gender.map((SingleGender) => (
                                                                                                        SingleGender.id === doctor.user_gender_id ? SingleGender.gender : null
                                                                                                    ))
                                                                                                    }
                                                                                                    </p>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }

                                                                                            {doctor.countries_list_id ?
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <p className="pl-2">{generalData.countries_list.map((SingleCountry) => (
                                                                                                        SingleCountry.id === doctor.countries_list_id ? SingleCountry.name : null
                                                                                                    ))
                                                                                                    }
                                                                                                    </p>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                        {addedDoctors.map((singleDoc, index) => (
                                                            <SwiperSlide key={index}>
                                                                <div className="col-12 py-3 p-0 h-100 w-100">
                                                                    <div className="border-dashboard-boxes fill-dark h-100">
                                                                        <div className="py-4 px-3">
                                                                            <h1 className="single-record-box-title pl-2">{generalData.patient_records_settings.doctor_title}</h1>
                                                                            {singleDoc.id ?
                                                                                <>
                                                                                    <div className="row pt-3">
                                                                                        <div className="col-auto pr-0">
                                                                                            <img className="user-single-records-image" src={singleDoc.image ? singleDoc.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                                        </div>
                                                                                        <div className="col pt-md-2">
                                                                                            <p className="user-single-records-name pl-2">{singleDoc.name}</p>
                                                                                            <div className="row">
                                                                                                {singleDoc.doctor_languages ?
                                                                                                    <div className="col-12 col-md-6">
                                                                                                        <p className="pl-2">
                                                                                                            {
                                                                                                                singleDoc.doctor_languages.map((doctorLang, i) => (
                                                                                                                    (i + 1 === Number(singleDoc.doctor_languages.length)) ? doctorLang.title : doctorLang.title + ', '
                                                                                                                ))
                                                                                                            }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }

                                                                                                {singleDoc.user_gender_id ?
                                                                                                    <div className="col-12 col-md-6">
                                                                                                        <p className="pl-2">{generalData.user_gender.map((SingleGender) => (
                                                                                                            SingleGender.id === singleDoc.user_gender_id ? SingleGender.gender : null
                                                                                                        ))
                                                                                                        }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }

                                                                                                {singleDoc.countries_list_id ?
                                                                                                    <div className="col-12 col-md-6">
                                                                                                        <p className="pl-2">{generalData.countries_list.map((SingleCountry) => (
                                                                                                            SingleCountry.id === singleDoc.countries_list_id ? SingleCountry.name : null
                                                                                                        ))
                                                                                                        }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))
                                                        }
                                                    </Swiper>
                                                </div>
                                            </div>
                                        :
                                        null
                                    }

                                    {!singleCare.concerns[0].concern === '' ?
                                        <div className="col-12 pt-3 pb-2">
                                            <div className="border-care-concern p-4">
                                                <div className="care-concern-title-single">Care Concern</div>
                                                <div>
                                                    {
                                                        singleCare.concerns.map((singleConcern, i) => (
                                                            (i + 1 === Number(singleCare.concerns.length)) ? singleConcern.concern : singleConcern.concern + ', '
                                                            // singleConcern.concern
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                    <div className="col-12 pt-4 pb-md-4">
                                        <div className="border-dashboard-boxes fill-dark pl-4 pr-4 pb-4 mb-5 pt-3">
                                            <div className="row no-gutters justify-content-between block-small">
                                                <div className="d-flex">
                                                    <p className={pageTab === 1 ? 'single-case-care-titles active' : 'single-case-care-titles'} onClick={() => setPageTab(1)}>{generalData.patient_records_settings.medical_report_title}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p className={pageTab === 2 ? 'single-case-care-titles active' : 'single-case-care-titles'} onClick={() => setPageTab(2)}>{generalData.patient_records_settings.lab_results_title}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p className={pageTab === 3 ? 'single-case-care-titles active' : 'single-case-care-titles'} onClick={() => setPageTab(3)}>{generalData.patient_records_settings.medical_history_title}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p className={pageTab === 4 ? 'single-case-care-titles active' : 'single-case-care-titles'} onClick={() => setPageTab(4)}>{generalData.patient_records_settings.payments_title}</p>
                                                </div>
                                                <div className="col-12 pt-0 border-bottom" />
                                            </div>
                                            <div className="row align-items-center d-md-none">
                                                <div className="col-12 text-center">
                                                    <div className="row justify-content-between">
                                                        <div className="col-1 d-flex align-items-center justify-content-center"><div className="d-flex swiper-button-prev-small"></div></div>
                                                        <div className="col-8">
                                                            <Swiper
                                                                slidesPerView={1}
                                                                slidesPerGroup={1}
                                                                onSlideChange={(e) => setPageTab(e.realIndex + 1)}
                                                                loop="true"
                                                                navigation={{
                                                                    nextEl: ".swiper-button-next-small",
                                                                    prevEl: ".swiper-button-prev-small",
                                                                }}
                                                            >
                                                                <SwiperSlide><p className='single-case-care-titles active'>{generalData.patient_records_settings.medical_report_title}</p></SwiperSlide>
                                                                <SwiperSlide><p className='single-case-care-titles active'>{generalData.patient_records_settings.lab_results_title}</p></SwiperSlide>
                                                                <SwiperSlide><p className='single-case-care-titles active'>{generalData.patient_records_settings.medical_history_title}</p></SwiperSlide>
                                                                <SwiperSlide><p className='single-case-care-titles active'>{generalData.patient_records_settings.payments_title}</p></SwiperSlide>
                                                            </Swiper>
                                                        </div>
                                                        <div className="col col-1 d-flex align-items-center justify-content-center"><div className="d-flex swiper-button-next-small"></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {pageTab === 1 ?
                                                <div className="row pt-2">
                                                    <div className="col-12 py-2">
                                                        <div className="doctor-request-docs">
                                                            {singleCare.medical_reports?.length > 0 ?
                                                                singleCare.medical_reports.map((singleReport, index) => (
                                                                    <div className="row py-2" key={index}>
                                                                        <div className="col-auto pr-0">
                                                                            {doctor.id === singleReport.doctor_id ?
                                                                                <img className="user-single-records-image small-comment-image" src={doctor.image ? doctor.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                                :
                                                                                addedDoctors.map((singleDoctor, ind) => (
                                                                                    singleDoctor.id === singleReport.doctor_id ?
                                                                                        <img className="user-single-records-image small-comment-image" src={singleDoctor.image ? singleDoctor.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                                        :
                                                                                        null
                                                                                ))
                                                                            }
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    {doctor.id === singleReport.doctor_id ?
                                                                                        <p className="request-doc-title m-0 pb-1">{doctor.name}</p>
                                                                                        :
                                                                                        addedDoctors.map((singleDoctor, ind) => (
                                                                                            singleDoctor.id === singleReport.doctor_id ?
                                                                                                <p className="request-doc-title m-0 pb-1">{singleDoctor.name}</p>
                                                                                                :
                                                                                                null
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <p className="request-box-request-text">{singleReport.report_text}</p>
                                                                                </div>
                                                                                {singleReport.full_path_attached_documents?.length > 0 ?
                                                                                    <div className="col-12">
                                                                                        <div className="row no-gutters">
                                                                                            {singleReport.full_path_attached_documents.map((singleDoc, i) => (
                                                                                                <div className="col-auto pr-2">
                                                                                                    <a href={singleDoc} target="_blank" rel="noreferrer" key={i}>
                                                                                                        {singleDoc.toString().includes('.jpg') || singleDoc.toString().includes('.png') ?
                                                                                                            <img className="comment-box-attachements" rel="noreferrer" src={singleDoc} alt="attachement" />
                                                                                                            :
                                                                                                            singleDoc.toString().includes('.pdf') ?
                                                                                                                <img className="comment-box-attachements" rel="noreferrer" src='/img/pdf.png' alt="pdf" />
                                                                                                                :
                                                                                                                <img className="comment-box-attachements" rel="noreferrer" src='/img/docs.png' alt="docx" />
                                                                                                        }
                                                                                                    </a>
                                                                                                </div>
                                                                                            ))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }

                                                                                <div className="col-12 pt-2">
                                                                                    <p className="chat-date m-0">{dateFormat(singleReport['created_at'], "d mmmm yyyy")}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 py-2 w-75 border-between-content" />
                                                                    </div>
                                                                ))
                                                                :
                                                                <p>{generalData.patient_records_settings.medical_report_not_available}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            {pageTab === 2 ?
                                                <div className="row pt-2">
                                                    <div className="col-12 py-2">
                                                        <div className="doctor-request-docs" ref={userRequestDocsWrapper}>
                                                            {singleCare.doc_lab_results?.length ?
                                                                singleCare.doc_lab_results.map((singleResult, index) => (
                                                                    <div className="row py-2" key={index}>
                                                                        <div className="col-auto pr-0">
                                                                            {doctor.id === singleResult.doctor_id ?
                                                                                <img className="user-single-records-image small-comment-image" src={doctor.image ? doctor.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                                :
                                                                                addedDoctors.map((singleDoctor, ind) => (
                                                                                    singleDoctor.id === singleResult.doctor_id ?
                                                                                        <img className="user-single-records-image small-comment-image" src={singleDoctor.image ? singleDoctor.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                                        :
                                                                                        null
                                                                                ))
                                                                            }
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    {doctor.id === singleResult.doctor_id ?
                                                                                        <p className="request-doc-title m-0 pb-1">{doctor.name}</p>
                                                                                        :
                                                                                        addedDoctors.map((singleDoctor, ind) => (
                                                                                            singleDoctor.id === singleResult.doctor_id ?
                                                                                                <p className="request-doc-title m-0 pb-1">{singleDoctor.name}</p>
                                                                                                :
                                                                                                null
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <p className="request-box-request-text">{singleResult.request_title}</p>
                                                                                </div>
                                                                                {/* {singleResult.full_path_attached_documents.length > 0 ? */}
                                                                                <div className="col-12">
                                                                                    <div className="row no-gutters">
                                                                                        {singleResult.full_path_attached_documents.map((singleDoc, i) => (
                                                                                            <div className="col-auto pr-2">
                                                                                                <a href={singleDoc} target="_blank" rel="noreferrer" key={i}>
                                                                                                    {singleDoc.toString().includes('.jpg') || singleDoc.toString().includes('.png') ?
                                                                                                        <img className="comment-box-attachements" rel="noreferrer" src={singleDoc} alt="attachments" />
                                                                                                        :
                                                                                                        singleDoc.toString().includes('.pdf') ?
                                                                                                            <img className="comment-box-attachements" rel="noreferrer" src='/img/pdf.png' alt="pdf" />
                                                                                                            :
                                                                                                            <img className="comment-box-attachements" rel="noreferrer" src='/img/docs.png' alt="docx" />
                                                                                                    }
                                                                                                </a>
                                                                                            </div>
                                                                                        ))
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                {/* : */}
                                                                                {singleCare?.case_status_id !== 5 ?
                                                                                    <div className="col-12 pt-2">
                                                                                        <input hidden className="upload-chat-files" placeholder="ATTACH DOCUMENTS" id={'upload-chat-input' + index} type="file" multiple="multiple" onChange={(e) => { inputHandler(e.target.files, singleResult.id) }} />
                                                                                        <label className="upload-chat-files" htmlFor={'upload-chat-input' + index}>{singleResult.full_path_attached_documents.length > 0 ? 'CHANGE DOCUMENTS' : 'ATTACH DOCUMENTS'}</label>
                                                                                        {/* <button onClick={(e) => fileHandler()} className="home-buttons fs-14">ADD ATTACHEMENT</button> */}
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                {/* } */}

                                                                                <div className="col-12 pt-2">
                                                                                    <p className="chat-date m-0">{dateFormat(singleResult['created_at'], "d mmmm yyyy")}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 py-2 w-75 border-between-content" />
                                                                    </div>
                                                                ))
                                                                :
                                                                <p>{generalData.patient_records_settings.lab_results_not_available}</p>
                                                            }
                                                            {/* <AlwaysScrollToBottom /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            {pageTab === 3 ?
                                                <div className="row no-gutters pt-4">
                                                    <div className="col-12 col-md-6 pr-md-4 mb-4">
                                                        <div className="border-dashboard-boxes bg-white h-100">
                                                            <h1 className="single-care-medical-titles pt-4 pl-4 pr-4 pb-2 d-flex">{generalData.patient_records_settings.problems_title}</h1>

                                                            <div className="row no-gutters">
                                                                {problems > 0 ?
                                                                    singleCare.problems.map((singleProblem, i) => (
                                                                        <div className="col-12" key={i}>
                                                                            <div className="single-care-items h-100">
                                                                                <div className="row no-gutters align-items-center h-100 pl-2">
                                                                                    {singleProblem.problem ?
                                                                                        <div className="col-auto text-center pl-3 py-auto my-auto">
                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    <div className="col-6 py-2 pl-2 report-names">
                                                                                        {singleProblem.problem}
                                                                                    </div>
                                                                                    <div className="col pr-3 text-right py-2 report-names">
                                                                                        {singleProblem.duration}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <div className="col-12 pb-3">
                                                                        <div className="row align-items-center no-gutters h-100 pl-2">
                                                                            <div className="col-auto text-center pl-3 my-auto">
                                                                                <p className="m-0 report-names">No Problems</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-4">
                                                        <div className="border-dashboard-boxes bg-white h-100">
                                                            <h1 className="single-care-medical-titles pt-4 pl-4 pr-4 pb-2">{generalData.patient_records_settings.diagnosis_title}</h1>
                                                            <div className="row no-gutters">
                                                                {diagnosis > 0 ?
                                                                    singleCare.diagnosis.map((singleDiagnosis, i) => (
                                                                        <div className="col-12" key={i}>
                                                                            <div className="single-care-items h-100">
                                                                                <div className="row align-items-center no-gutters h-100 pl-2">
                                                                                    {singleDiagnosis.medical_diagnosis ?
                                                                                        <div className="col-auto text-center pl-3 my-auto">
                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    <div className="col-6 py-2 pl-2 report-names">
                                                                                        {singleDiagnosis.medical_diagnosis}
                                                                                    </div>
                                                                                    <div className="col pr-3 text-right py-2 report-names">
                                                                                        {singleDiagnosis.date}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <div className="col-12 pb-3">
                                                                        <div className="row align-items-center no-gutters h-100 pl-2">
                                                                            <div className="col-auto text-center pl-3 my-auto">
                                                                                <p className="m-0 report-names">No Diagnosis</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 pr-md-4 mb-4">
                                                        <div className="border-dashboard-boxes bg-white h-100">
                                                            <h1 className="single-care-medical-titles pt-4 pl-4 pr-4 pb-2">{generalData.patient_records_settings.surgeries_title}</h1>
                                                            <div className="row no-gutters">
                                                                {surgeries > 0 ?
                                                                    singleCare.surgeries.map((singleSurgeries, i) => (
                                                                        <div className="col-12" key={i}>
                                                                            <div className="single-care-items h-100">
                                                                                <div className="row no-gutters align-items-center h-100 pl-2">
                                                                                    {singleSurgeries.surgery ?
                                                                                        <div className="col-auto text-center pl-3 my-auto">
                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    <div className="col-6 py-2 pl-2 report-names">
                                                                                        {singleSurgeries.surgery}
                                                                                    </div>
                                                                                    <div className="col pr-3 text-right py-2 report-names">
                                                                                        {singleSurgeries.date}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <div className="col-12 pb-3">
                                                                        <div className="row align-items-center no-gutters h-100 pl-2">
                                                                            <div className="col-auto text-center pl-3 my-auto">
                                                                                <p className="m-0 report-names">No Surgeries</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-4">
                                                        <div className="border-dashboard-boxes bg-white h-100">
                                                            <h1 className="single-care-medical-titles pt-4 pl-4 pr-4 pb-2">{generalData.patient_records_settings.medications_title}</h1>
                                                            <div className="row no-gutters">
                                                                {medications ?
                                                                    singleCare.medications.map((singleMedication, i) => (
                                                                        <div className="col-12" key={i}>
                                                                            <div className="single-care-items h-100">
                                                                                <div className="row no-gutters align-items-center h-100 pl-2">
                                                                                    {singleMedication.medication ?
                                                                                        <div className="col-auto text-center pl-3 my-auto">
                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    <div className="col py-2 pl-2 report-names">
                                                                                        {singleMedication.medication}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <div className="col-12 pb-3">
                                                                        <div className="row align-items-center no-gutters h-100 pl-2">
                                                                            <div className="col-auto text-center pl-3 my-auto">
                                                                                <p className="m-0 report-names">No Medications</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <div className="border-dashboard-boxes bg-white h-100">
                                                            <h1 className="single-care-medical-titles pt-4 pl-4 pr-4 pb-2">{generalData.patient_records_settings.hospital_title}</h1>
                                                            <div className="row no-gutters">
                                                                {singleCare.hospital_services ?
                                                                    singleCare.hospital_services.map((singlehospital, i) => (
                                                                        <div className="col-12" key={i}>
                                                                            <div className="single-care-items h-100">
                                                                                <div className="row no-gutters align-items-center h-100 pl-2">
                                                                                    <div className="col pl-3">
                                                                                        <div className="row no-gutters">
                                                                                            <div className="col-12 py-2 pl-0 report-names">
                                                                                                <span className="color-dark-blue">Hospital Name:</span> {singlehospital.hospital_name}
                                                                                            </div>
                                                                                            <div className="col-12 py-2 pl-0 report-names">
                                                                                                <span className="color-dark-blue">Address: </span>{singlehospital.address}
                                                                                            </div>
                                                                                            <div className="col-12 py-2 pl-0 pl-0 report-names">
                                                                                                <span className="color-dark-blue">Contact Info: </span>{singlehospital.contact_info}
                                                                                            </div>
                                                                                            <div className="col-12 py-2 pl-0 report-names">
                                                                                                <span className="color-dark-blue">Doctor Name: </span>{singlehospital.doctor_name}
                                                                                            </div>
                                                                                            <div className="col-12 py-2 pl-0 report-names">
                                                                                                <span className="color-dark-blue">Doctor Contact: </span> {singlehospital.doctor_contact}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="border-dashboard-boxes bg-white h-100">
                                                            <h1 className="single-care-medical-titles pt-4 pl-4 pr-4 pb-2">{generalData.patient_records_settings.reports_title}</h1>
                                                            <div className="row no-gutters">
                                                                <div className="col-12">
                                                                    <div className="single-care-items h-100">
                                                                        {singleCare.others?.length > 0 ||
                                                                            singleCare.genetics?.length > 0 ||
                                                                            singleCare.investigations?.length > 0 ||
                                                                            singleCare.histopathology?.length > 0 ||
                                                                            singleCare.radiology?.length > 0 ||
                                                                            singleCare.lab_results?.length > 0 ||
                                                                            singleCare.reports?.length > 0 ?
                                                                            <div className="row no-gutters align-items-center h-100 pl-2">
                                                                                {
                                                                                    singleCare.reports.map((singleReport, index) => (
                                                                                        singleReport?.name !== null && singleReport?.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">Written Reports {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }
                                                                                {
                                                                                    singleCare.lab_results.map((singleReport, index) => (
                                                                                        singleReport.name !== null && singleReport.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">Lab Results {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }

                                                                                {
                                                                                    singleCare.radiology.map((singleReport, index) => (
                                                                                        singleReport.name !== null && singleReport.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">Radiology Results {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }

                                                                                {
                                                                                    singleCare.histopathology.map((singleReport, index) => (
                                                                                        singleReport.name !== null && singleReport.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">Histopathology Results {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }


                                                                                {
                                                                                    singleCare.investigations.map((singleReport, index) => (
                                                                                        singleReport.name !== null && singleReport.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">Investigations Results {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }

                                                                                {
                                                                                    singleCare.genetics.map((singleReport, index) => (
                                                                                        singleReport.name !== null && singleReport.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">Genetics Results {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }

                                                                                {
                                                                                    singleCare.others.map((singleReport, index) => (
                                                                                        singleReport.name !== null && singleReport.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">{singleReport.file_name ? singleReport.file_name : 'Other Reports'} {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className="row no-gutters align-items-center h-100 pl-2">
                                                                                <div className="col-12 pl-3 py-2 pl-0 report-names">
                                                                                    <span className="color-dark-blue">No Reports Yet</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            {pageTab === 4 ?
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row justify-content-end">
                                                            <div className="col-12 col-sm-6 col-md-4 pb-3 py-sm-3 text-right">
                                                                <input className="search-input position-relative" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
                                                                <svg onClick={() => setSubmitSearch(1)} className="search-svg" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                    <g id="_001-loupe" data-name="001-loupe" transform="translate(0 -0.003)">
                                                                        <g id="Group_2" data-name="Group 2" transform="translate(0 0.003)">
                                                                            <path id="Path_3" data-name="Path 3" d="M19.756,18.58l-5.687-5.687a7.932,7.932,0,1,0-1.178,1.178l5.687,5.687a.833.833,0,1,0,1.178-1.178ZM7.917,14.17a6.25,6.25,0,1,1,6.25-6.25A6.257,6.257,0,0,1,7.917,14.17Z" transform="translate(0 -0.003)" fill="#25d6ff" />
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="table-wrapper">
                                                                    <table className="payments" width="100%">
                                                                        <thead>
                                                                            <tr>
                                                                                <td>DATE</td>
                                                                                <td>FOR</td>
                                                                                <td>AMOUNT</td>
                                                                                <td>CASE ID</td>
                                                                                <td>STATUS</td>
                                                                                <td> </td>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                payments?.length > 0 ?
                                                                                    payments?.map((singlePayment, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>{dateFormat(singlePayment.created_at, "d mmmm yyyy")}</td>
                                                                                            <td>{singlePayment.doctor?.first_name && singlePayment.doctor?.last_name ? singlePayment.doctor?.first_name + ' ' + singlePayment.doctor?.last_name : (singlePayment.doctor?.name ? singlePayment.doctor?.name : '')}</td>
                                                                                            <td>{singlePayment.amount} GBP</td>
                                                                                            <td>{singlePayment.get_care_info_data_id}</td>
                                                                                            <td className={singlePayment.pay_date ? 'green' : 'orange'}>{singlePayment.pay_date ? 'Paid' : 'Pending'}</td>
                                                                                            <td>
                                                                                                {singlePayment.pay_date &&
                                                                                                    <button onClick={(e) => getInvoice(e, singlePayment.id)} className="home-buttons fs-12 py-1 px-2">INVOICE</button>
                                                                                                }
                                                                                                {/* {singlePayment.pay_date ?
                                                                                                    <button onClick={(e) => getInvoice(e, singlePayment.id)} className="home-buttons fs-12 py-1 px-2">INVOICE</button>
                                                                                                    :
                                                                                                    <form action={'https://test.ipg-online.com/connect/gateway/processing'} method="post">
                                                                                                        <input type="hidden" name="txntype" value="sale" />
                                                                                                        <input type="hidden" name="timezone" value="Europe/London" />
                                                                                                        <input type="hidden" name="hash_algorithm" value="SHA256" />
                                                                                                        <input type="hidden" name="storename" value="7220540956" />
                                                                                                        <input type="hidden" name="hash" value={createHash(singlePayment.amount, 826, date, shared)} />
                                                                                                        <input type="hidden" name="txndatetime" value={date} />
                                                                                                        <input type="hidden" name="mode" value="fullpay" />
                                                                                                        <input type="hidden" name="chargetotal" value={singlePayment.amount} />
                                                                                                        <input type="hidden" name="currency" value="826" />
                                                                                                        <input type="hidden" name="customerid" value={authUser?.id + '-' + singlePayment?.id} />
                                                                                                        <input type="hidden" name="responseSuccessURL" value="https://dev-mediconnect.hellotree.dev/api/payment-success" />
                                                                                                        <input type="hidden" name="responseFailURL" value="https://dev-mediconnect.hellotree.dev/api/payment-fail" />

                                                                                                        {
                                                                                                            shared ?
                                                                                                                <button type="submit"
                                                                                                                    // onClick={(e) => payNow(e, singlePayment.id)} 
                                                                                                                    className="home-buttons fs-12 py-1 px-2">PAY NOW</button>
                                                                                                                :
                                                                                                                null
                                                                                                        }

                                                                                                    </form>
                                                                                                } */}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            !payments?.length > 0 ?
                                                                <div className="row justify-content-center py-3">
                                                                    <p className="m-0">No Payments Available</p>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            !!(currentPage !== lastPage) && (
                                                                <div className="row justify-content-center pt-5">

                                                                    <button className={!loadingForm ? "load-more home-buttons" : " hide"} onClick={() => { setCurrentPage(currentPage + 1); }} animate="down">
                                                                        LOAD MORE</button>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        {singleCare?.final_report?.closed_case === 1 ?
                                            <div className="row pb-5 pb-md-4">
                                                <div className="col-12 align-items-center justify-content-center justify-content-md-end d-flex flex-column flex-md-row">
                                                    <a className={singleCare?.final_report ? 'mb-2 mb-md-0 mr-0 mr-md-3' : ''} target="_blank" rel="noreferrer" href={singleCare.final_report?.full_path_final_report}>
                                                        <button className="home-buttons small-button-font-size" type="button">Final Report</button>
                                                    </a>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col-12 pt-3 pl-3 d-flex justify-content-center">
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    }
                </div >
                <Api options={generalDataApiOptions} />
                <Api options={generalDataApiOptions2} />
            </div >
        </>
    );
}
import React, { useContext, useEffect } from 'react';
import Layout from '../layouts/Main';
import DarkBanner from '../components/DarkBanner';
import GlobalState from "../contexts/GlobalState";
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../components/GoogleAnalytics';

export default function HowCanWeHelp(props) {


    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;


    useEffect(() => {
        window.triggerScroll();
    }, [generalData]);


    return (

        <Layout activePage="how-can-we-help" whiteMenu="false">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['how-can-we-help'].title}</title>
                    <meta name="title" content={generalData?.seo['how-can-we-help']?.title} />
                    <meta name="description" content={generalData?.seo['how-can-we-help']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['how-can-we-help'].title} />
                    <meta property="og:description" content={generalData?.seo['how-can-we-help'].description} />
                    <meta property="og:image" content={generalData?.seo['how-can-we-help'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['how-can-we-help'].title} />
                    <meta property="twitter:description" content={generalData?.seo['how-can-we-help'].description} />
                    <meta property="twitter:image" content={generalData?.seo['how-can-we-help'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <DarkBanner>{generalData.help_settings.title}</DarkBanner>
            <div className="container-fluid p-0 how-can-we-help">
                {generalData ?
                    generalData.help_list.map((singlehelp, index) => (
                        <div className="row no-gutters" animate={Number(index + 1) % 2 === 0 ? 'right' : 'left'} key={index}>
                            <div className={Number(index + 1) % 2 === 0 ? 'col-12 col-lg-6 align-content-center d-none d-lg-block order-lg-2' : 'col-12 col-lg-6 align-content-center d-none d-lg-block'}>
                                <div className="row no-gutters h-100 w-100 justify-content-center">
                                    <div className="col-3 d-flex justify-content-end align-items-center w-100 h-100">
                                        <h1>{Number(index + 1) < 10 ? '0' + Number(index + 1) : Number(index + 1)}</h1>
                                    </div>
                                    <div className="col-7 d-flex flex-column w-100 h-100 justify-content-center pl-lg-3 d-none">
                                        {<div className="content" dangerouslySetInnerHTML={{ __html: singlehelp.text }}></div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 align-content-center d-block d-lg-none h-100">
                                <div className="row no-gutters h-100 w-100">
                                    <div className="col-12 d-flex justify-content-center align-items-center w-100 h-100 pt-4">
                                        <h1>{Number(index + 1) < 10 ? '0' + Number(index + 1) : Number(index + 1)}</h1>
                                    </div>
                                    <div className="col-11 d-flex justify-content-center align-items-center pl-lg-3 d-none flex-column text-center pb-4 mx-auto">
                                        {<div className="content" dangerouslySetInnerHTML={{ __html: singlehelp.text }}></div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="how-can-we-help-image-box">
                                    <img className="how-can-we-help-image" src={singlehelp.full_path_image} alt="help" />
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    null
                }
            </div>

            <div className="how-can-we-help-bottom" animate="right">
                <div className="container py-5">
                    <div className="row justify-content-center py-lg-5 py-0 pb-4">
                        <div className="col-12 col-lg-10 text-center">
                            {<div className="content" dangerouslySetInnerHTML={{ __html: generalData.help_settings.text }}></div>}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
import React, { useContext, useEffect } from 'react';
import Layout from '../layouts/Main';
import DarkBanner from '../components/DarkBanner';
import { Link } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../components/GoogleAnalytics';

export default function Services(props) {

    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;


    useEffect(() => {
        window.triggerScroll();
    }, [generalData]);

    return (

        <Layout activePage="services" whiteMenu="false">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['services'].title}</title>
                    <meta name="title" content={generalData?.seo['services']?.title} />
                    <meta name="description" content={generalData?.seo['services']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['services'].title} />
                    <meta property="og:description" content={generalData?.seo['services'].description} />
                    <meta property="og:image" content={generalData?.seo['services'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['services'].title} />
                    <meta property="twitter:description" content={generalData?.seo['services'].description} />
                    <meta property="twitter:image" content={generalData?.seo['services'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <DarkBanner>
                {generalData.services_settings.page_title}
            </DarkBanner>

            <div className="services-page py-5">
                <div className="container">
                    <div className="row">
                        {generalData ?
                            generalData.services_list.map((singleService, index) => (
                                <div className="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 py-3 py-lg-5" animate="top" key={index}>
                                    <Link style={{ textDecoration: 'none' }} to={'/services/' + singleService.slug}>
                                        <div className="d-flex flex-column flex-grow-1">
                                            <div className="row no-gutters">
                                                <div className="col-12">
                                                    <img className="services-single-image" src={singleService.full_path_image} alt={singleService.singleService} />
                                                </div>
                                                <div className="col-12">
                                                    <h3 className="pt-3">{singleService.title}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="pt-2">{singleService.small_description}</h4></Link>
                                </div>
                            ))
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </Layout>
    );
}
import React, { useState, useContext, useEffect } from 'react';
import Layout from '../layouts/Main';
import Api from "../components/Api";
import GlobalState from "../contexts/GlobalState";
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../components/GoogleAnalytics';

export default function ForgotPassword(props) {

    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [errorsVisible, setErrorsVisible] = useState(false);
    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [loading, setLoading] = useState(false);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});

    const div = document.getElementById('errors-popup')

    useEffect(() => {

        if (document.body.contains(div)) {
            setErrorsVisible(true);
        }
        else {
            setErrorsVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorsVisible]);


    const submitForgotPassword = (e) => {
        e.preventDefault();
        setLoading(true);

        setGeneralDataApiOptions({
            url: 'forgot-password',
            method: 'post',
            data: {
                email: email
            },
            callback: res => {
                setEmail('');
                setLoading(false);
                setSuccessMessage(true);
            }
        })
        if (!successMessage) {
            setErrorsVisible(true);
        }
        setLoading(false);
    }

    return (

        <Layout activePage="forgot-password" whiteMenu="false">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['auth-pages'].title}</title>
                    <meta name="title" content={generalData?.seo['auth-pages']?.title} />
                    <meta name="description" content={generalData?.seo['auth-pages']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['auth-pages'].title} />
                    <meta property="og:description" content={generalData?.seo['auth-pages'].description} />
                    <meta property="og:image" content={generalData?.seo['auth-pages'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['auth-pages'].title} />
                    <meta property="twitter:description" content={generalData?.seo['auth-pages'].description} />
                    <meta property="twitter:image" content={generalData?.seo['auth-pages'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <div className="first-section banner-dark sign-up">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <form onSubmit={submitForgotPassword} className="login-form w-100" onClick={() => setErrorsVisible(false)}>

                            <div className="col-12">
                                <h1 className="forgot-password-text">{generalData.authentication_pages_settings.forgot_pass_title}</h1>
                            </div>
                            <div className="col-10 col-md-4 mx-auto">
                                <h6>{generalData.authentication_pages_settings.forgot_pass_subtitle}</h6>
                            </div>
                            <div className="col-12 col-md-7 mx-auto d-flex flex-column justify-content-center py-2" onClick={() => setErrorsVisible(false)} >
                                <div className="row no-gutters w-100">
                                    <div className="col-12 d-flex pt-5">
                                        <h3>{generalData.authentication_pages_settings.email_title}</h3>
                                    </div>
                                    <div className="col-12 d-flex">
                                        <input name="email" type="text" onChange={e => setEmail(e.target.value)} value={email} />
                                    </div>
                                </div>
                                <div className="col-12 pt-4" onClick={() => setErrorsVisible(false)}>
                                    {successMessage || errorsVisible || loading ?
                                        <button type="submit" disabled className="forgot-password-botton">{generalData.authentication_pages_settings.forgot_button}</button>
                                        :
                                        <button type="submit" className="forgot-password-botton">{generalData.authentication_pages_settings.forgot_button}</button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {successMessage ?
                <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                    <div className="pop-up-api-box">
                        <svg className="close-svg" onClick={() => setSuccessMessage(false)} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                            <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                            </g>
                        </svg>
                        <div className="content background-light text-center px-5 py-5">
                            <p className="my-2">{generalData.authentication_pages_settings.forgot_success}</p>
                        </div>
                    </div>
                </div>
                :
                null
            }

            <Api options={generalDataApiOptions} />
        </Layout>
    );
}
import React, { useState, useRef, useEffect } from 'react';


const Accordion = (props) => {
    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);

    useEffect(() => {
        setHeight(elementRef.current.clientHeight);
    }, [props.openId]);

    return (
        <div className="faqs" onClick={() => setHeight(elementRef.current.clientHeight)}>
            <div className="accordion-title d-flex align-items-center" onClick={() => props.setOpenId(props.openId === props.id ? null : props.id)}>
                <div className="row no-gutters align-items-center w-100 py-2">
                    <div className="pl-3 pr-2 col">
                        <h5 className={props.openId === props.id ? 'active' : ''} animate="right">{props.title}</h5>
                    </div>
                    <div className="col-auto d-flex align-self-right text-right pr-2">
                        <div className="faqs-buttons" animate="right">{props.openId === props.id ? <p>-</p> : <p>+</p>}</div>
                    </div>
                    <div className="pl-3 pr-2 col-12">
                        { props.openId === props.id ?
                            <div style={{ height: height+10 }} className={props.openId === props.id ? 'accordion-content open' : 'accordion-content'} animate="right"><p className='pb-2 pt-2' ref={elementRef} >{props.content}</p></div>
                            :
                            <div className={props.openId === props.id ? 'accordion-content open pb-2 pt-2' : 'accordion-content'} animate="right"><p className='pb-2 pt-2' ref={elementRef} >{props.content}</p></div>  
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Accordion;

export default function ProfileCard(props) {

    return (
        <>
            <div className="col-12 col-md-6 py-3">
                <div className="border-dashboard-boxes fill-dark h-100">
                    <div className="py-4 px-3">
                        <h1 className="single-record-box-title pl-2">{props.patient_title}</h1>
                        <div className="row pt-3">
                            <div className="col-auto pr-0">
                                <img className="user-single-records-image" src={props.image} alt="user-profile" />
                            </div>
                            <div className="col pt-md-2">
                                <p className="user-single-records-name pl-2">{props.name}</p>
                                {props.birthdate || props.id ?
                                    <div className="row">
                                        {props.birthdate ?
                                            <div className="col-12 col-md-6">
                                                <p className="pl-2">{props.birthdate}</p>
                                            </div>
                                            :
                                            null
                                        }
                                        {props.id ?
                                            <div className="col-12 col-md-6">
                                                <p className="pl-2">ID: {props.id}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    null
                                }
                                {props.user_gender_id || props.countries_list_id ?
                                    <div className="row">
                                        {props.user_gender_id ?
                                            <div className="col-12 col-md-6">
                                                <p className="pl-2">{props.user_gender_id}
                                                </p>
                                            </div>
                                            :
                                            null
                                        }
                                        {props.countries_list_id ?
                                            <div className="col-12 col-md-6">
                                                <p className="pl-2">
                                                    {props.countries_list_id}
                                                </p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
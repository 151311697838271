import { useEffect, useState } from "react";
import axios from "axios";

export default function Api(props) {

    const [validationMessages, setValidationMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    const checkValidationErros = (errors) => {
        let newValidationMessages = [];
        for (const key in errors) {
            for (let i = 0; i < errors[key].length; i++) {
                newValidationMessages.push(errors[key][i]);
            }
        }
        setValidationMessages(newValidationMessages);
    }

    useEffect(() => {
        if (props.options.url) {
            if (loading) return;

            setLoading(true);
            setValidationMessages([]);

            axios({
                url: props.options.url,
                method: props.options.method,
                params: props.options.params,
                data: props.options.data,
                headers: props.options.headers ? props.options.header : {
                    Accept: 'application/json'
                },
            })
                .then(props.options.callback)
                .catch(r => {
                    if (r.response?.status === 422) {
                        if (props.options.catch) props.options.catch(r.response.data.errors);
                        checkValidationErros(r.response.data.errors);
                    } else if (r.response?.status === 401) {
                        localStorage.removeItem('auth_token');
                        // history.push('/login');
                    } else if (r.response?.status === 404) {
                        // history.push('/404');
                    } else {
                        //alert(r.message)
                    }
                })
                .finally(() => {
                    if (props.refresh === 'true') {
                        window.location.reload(false);
                    }
                    setLoading(false);
                    if (props.options.finally) props.options.finally();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.options]);

    return (
        <>
            {
                loading && (
                    <div className="loader-forms">
                        <div className="home-loader lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                )
            }
            {
                validationMessages.length > 0 && props.options.url !== 'save-form-1' && (
                    <div id="errors-popup" className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <svg className="close-svg" onClick={() => setValidationMessages([])} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                    <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                    <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                </g>
                            </svg>
                            <div className="content background-light text-center px-5 py-5">
                                {
                                    validationMessages.map((message, i) => (
                                        <p className="my-2" key={i}>{message}</p>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}
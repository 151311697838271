import React, { useState, useContext, useEffect } from 'react';
import Layout from '../layouts/Main';
import { Link } from "react-router-dom";
import Api from "../components/Api";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleLogin } from '@react-oauth/google';
import { useHistory } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../components/GoogleAnalytics';

export default function SignUp(props) {

    const [loading, setLoading] = useState(false);
    const [, setLogin] = useState();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const { generalDataGlobal, authUserGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [, setAuthUser] = authUserGlobal;
    let history = useHistory();


    useEffect(() => {
        window.triggerScroll();
    }, [generalData]);

    const login = useGoogleLogin({
        onSuccess: codeResponse => {
            setGeneralDataApiOptions({
                url: 'login-google',
                method: 'post',
                data: {
                    id_token: codeResponse?.access_token,
                },
                callback: res => {
                    // setLogin(res.data);
                    setAuthUser(res.data.user);
                    axios.defaults.headers.common['Authorization'] = res.data.token;
                    localStorage.setItem('auth_token', res.data.token);
                    if (res.data.user.user_types_id === 2) {
                        history.push("/user-dashboard");
                    }
                    if (res.data.user.user_types_id === 1) {
                        history.push("/doctor-dashboard");
                    }
                }
            })
        }
    });

    const responseFacebook = (response) => {
        setLoading(true);
        if (response?.accessToken) {
            setGeneralDataApiOptions({
                url: 'login-facebook',
                method: 'post',
                data: {
                    name: response.name,
                    email: response.email,
                    accessToken: response.accessToken,
                    userID: response.userID
                },
                callback: res => {
                    setLogin(res.data);
                    localStorage.setItem('auth_token', res.data.token);
                    setAuthUser(res.data.user);
                    axios.defaults.headers.common['Authorization'] = res.data.token;
                    if (res.data.user.user_types_id === 2) {
                        history.push("/user-dashboard");
                    }
                    if (res.data.user.user_types_id === 1) {
                        history.push("/doctor-dashboard");
                    }
                }
            })
        }
        setLoading(false);
    };


    const submitSignUp = (e) => {
        e.preventDefault();
        setLogin('');
        setLoading(true);

        setGeneralDataApiOptions({
            url: 'signup',
            method: 'post',
            data: {
                name: name,
                email: email,
                password: password,
                password_confirmation: confirmPassword,
            },
            callback: res => {
                setLogin(res.data);
                setEmail('');
                setName('');
                setPassword('');
                setConfirmPassword('');
                setSuccessMessage(true);
                setAuthUser(res.data.user);
                axios.defaults.headers.common['Authorization'] = res.data.token;
                localStorage.setItem('auth_token', res.data.token);
            }
        })
        setLoading(false);
    }

    return (

        <Layout activePage="sign-up" whiteMenu="false">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['auth-pages'].title}</title>
                    <meta name="title" content={generalData?.seo['auth-pages']?.title} />
                    <meta name="description" content={generalData?.seo['auth-pages']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['auth-pages'].title} />
                    <meta property="og:description" content={generalData?.seo['auth-pages'].description} />
                    <meta property="og:image" content={generalData?.seo['auth-pages'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['auth-pages'].title} />
                    <meta property="twitter:description" content={generalData?.seo['auth-pages'].description} />
                    <meta property="twitter:image" content={generalData?.seo['auth-pages'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <div className="first-section banner-dark sign-up">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <form onSubmit={submitSignUp} className="login-form w-100" id="sign-up-form">

                            <div className="col-12 pb-5">
                                <h1 className="sign-in-text">{generalData.authentication_pages_settings.signup_page_title}</h1>
                            </div>
                            <div className="col-12 col-md-7 mx-auto d-flex flex-column justify-content-center py-2">
                                <div className="row no-gutters w-100">
                                    <div className="col-12 d-flex">
                                        <h3>{generalData.authentication_pages_settings.name_title}</h3>
                                    </div>
                                    <div className="col-12 d-flex">
                                        <input name="name" type="text" onChange={e => setName(e.target.value)} value={name} />
                                    </div>
                                    <div className="col-12 d-flex pt-4">
                                        <h3>{generalData.authentication_pages_settings.email_title}</h3>
                                    </div>
                                    <div className="col-12 d-flex">
                                        <input name="email" type="text" onChange={e => setEmail(e.target.value)} value={email} />
                                    </div>
                                    <div className="col-12 d-flex pt-4">
                                        <h3>{generalData.authentication_pages_settings.password_title}</h3>
                                    </div>
                                    <div className="col-12 d-flex">
                                        <input name="password" type="password" onChange={e => setPassword(e.target.value)} value={password} />
                                    </div>
                                    <div className="col-12 d-flex pt-4">
                                        <h3>{generalData.authentication_pages_settings.confirm_password_title}</h3>
                                    </div>
                                    <div className="col-12 d-flex">
                                        <input name="confirm-password" type="password" onChange={e => setConfirmPassword(e.target.value)} value={confirmPassword} />
                                    </div>
                                </div>
                                <div className="col-12 pt-5">
                                    <Link to={"/sign-in"} style={{ textDecoration: 'none' }}>
                                        <h5>{generalData.authentication_pages_settings.signin_text} <span>{generalData.authentication_pages_settings.signin_title}</span></h5>
                                    </Link>
                                </div>
                                <div className="col-12 py-4">
                                    <FacebookLogin
                                        appId="3033563030250924"
                                        fields="name,email"
                                        callback={responseFacebook}
                                        render={renderProps => (
                                            <button type="button" onClick={renderProps.onClick} className="mb-3 mr-sm-3 padding-buttons">
                                                <img className="social-icon-auth" src={generalData.authentication_pages_settings.facebook_icon} alt="Facebook Icon" />
                                            </button>
                                        )}
                                    />
                                    {/* 
                                    <GoogleLogin
                                        clientId="380634602538-qvrlnp3itgrser5u1lda7k8oaeborojt.apps.googleusercontent.com"
                                        render={renderProps => (
                                            <button type="button" onClick={renderProps.onClick} className="">
                                                <img className="social-icon-auth" src={generalData.authentication_pages_settings.google_icon} alt="Google Icon" />
                                            </button>
                                        )}
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                    /> */}
                                    <button type="button" onClick={() => login()} className="">
                                        <img className="social-icon-auth" src={generalData.authentication_pages_settings.google_icon} alt="Google Icon" />
                                    </button>
                                </div>
                                <div className="col-12 pt-5" >
                                    <button type="submit" disabled={loading} className="sign-up-button">{generalData.authentication_pages_settings.signup_button}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {successMessage ?
                <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                    <div className="pop-up-api-box">
                        <svg className="close-svg" onClick={() => setSuccessMessage(false)} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                            <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                            </g>
                        </svg>
                        <div className="content background-light text-center px-5 py-5">
                            <p className="my-2">{generalData.authentication_pages_settings.signup_success}</p>
                        </div>
                    </div>
                </div>
                :
                null
            }

            <Api options={generalDataApiOptions} />
        </Layout>
    );
}
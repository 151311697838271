import React, { useState, useContext, useEffect } from 'react';
import Layout from '../layouts/Main';
import Api from "../components/Api";
import GlobalState from "../contexts/GlobalState";
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Helmet } from "react-helmet";
import GoogleAnalytics from '../components/GoogleAnalytics';

export default function ConfirmEmail(props) {

    const { generalDataGlobal, authUserGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [password, setPassword] = useState('');
    const [, setAuthUser] = authUserGlobal;

    let { email, token } = useParams();
    let history = useHistory();


    useEffect(() => {
        window.triggerScroll();
    }, [generalData]);

    const submitConfirmEmail = (e) => {
        e.preventDefault();

        setGeneralDataApiOptions({
            url: 'confirm-email',
            method: 'post',
            data: {
                email: email,
                password: password,
                token: token,
            },
            callback: res => {
                setAuthUser(res.data.user);
                axios.defaults.headers.common['Authorization'] = res.data.token;
                localStorage.setItem('auth_token', res.data.token);
                if (res.data.user.user_types_id === 2) {
                    history.push("/user-dashboard");
                }
                if (res.data.user.user_types_id === 1) {
                    history.push("/doctor-dashboard");
                }
            }
        })
    }

    return (

        <Layout activePage="forgot-password" whiteMenu="false">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['auth-pages'].title}</title>
                    <meta name="title" content={generalData?.seo['auth-pages']?.title} />
                    <meta name="description" content={generalData?.seo['auth-pages']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['auth-pages'].title} />
                    <meta property="og:description" content={generalData?.seo['auth-pages'].description} />
                    <meta property="og:image" content={generalData?.seo['auth-pages'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['auth-pages'].title} />
                    <meta property="twitter:description" content={generalData?.seo['auth-pages'].description} />
                    <meta property="twitter:image" content={generalData?.seo['auth-pages'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <div className="first-section banner-dark sign-up">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <form onSubmit={submitConfirmEmail} className="login-form w-100">
                            <div className="col-12">
                                <h1 className="forgot-password-text">{generalData.authentication_pages_settings.confirm_email_title}</h1>
                            </div>
                            <div className="col-10 col-md-4 mx-auto">
                                <h6>{email}</h6>
                            </div>
                            <div className="col-12 col-md-7 mx-auto d-flex flex-column justify-content-center py-2">
                                <div className="row no-gutters w-100">
                                    <div className="col-12 d-flex pt-5">
                                        <h3>{generalData.authentication_pages_settings.password_title}</h3>
                                    </div>
                                    <div className="col-12 d-flex">
                                        <input name="password" type="password" onChange={e => setPassword(e.target.value)} value={password} />
                                    </div>
                                </div>
                                <div className="col-12 pt-5">
                                    <button type="submit" className="forgot-password-botton">{generalData.authentication_pages_settings.signin_button}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </Layout>
    );
}
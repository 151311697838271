import { useState, useContext, useEffect } from 'react';
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import { format, subHours, startOfMonth } from 'date-fns';
import { Calendar, momentLocalizer, start, end, isSelected, selectable, Day, Week, Month } from 'react-big-calendar';
import getDay from 'date-fns/getDay';
import moment from 'moment';
import calendarStyle from 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import Api from '../components/Api';
import dateFormat from 'dateformat';
import Meetings from '../components/Meetings';


export default function DoctorCalendar(props) {
    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [loading] = useState(false);
    const [authUser] = authUserGlobal;
    const [, setMeetingsDetails] = useState({});
    const [allDoctorEvents, setAllDoctorEvents] = useState({});
    const [meetingSmallPopup, setMeetingSmallPopup] = useState(false);
    const [acceptMeeting, setAcceptMeeting] = useState();
    const [joinMeeting, setJoinMeeting] = useState();
    const [meetingPopup, setMeetingPopup] = useState(false);
    const [popupType, setPopupType] = useState(false);
    const [meetingInfo, setMeetingInfo] = useState([]);
    const [doctor] = useState([]);
    const [addedDoctors] = useState([]);
    const [singleCare] = useState([]);

    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading]);

    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);

    useEffect(() => {
        axios.get('get-care-cases-doctor')
            .then((r) => {
                setMeetingsDetails(r.data);
                if (r.data.length > 0) {
                    setAllDoctorEvents(
                        r.data?.map((singleMeeting) => (
                            {
                                id: singleMeeting.id,
                                title: singleMeeting?.doctor?.name + ' ' + moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local().format("h:mm A")
                                    + ' - ' + moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone + singleMeeting.meeting_duration, 'hours').local().format("h:mm A"),
                                start: new Date(moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local()),
                                end: new Date(moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone + singleMeeting.meeting_duration, 'hours').local()),
                                link: singleMeeting.link,
                                hexColor: singleMeeting.doctor_id === authUser.id
                                    && (singleMeeting.doctors?.map((singleDoctor) => (
                                        singleDoctor?.pivot?.user_id === authUser?.id && singleDoctor?.pivot?.accept_meeting === 1 ?
                                            'green'
                                            :
                                            ''
                                    ))).indexOf('green') > -1
                                    ?
                                    'green'
                                    :
                                    (singleMeeting.doctors?.map((singleDoctor) => (
                                        singleDoctor?.pivot?.user_id === authUser?.id && singleDoctor?.pivot?.accept_meeting === 1 ?
                                            'green'
                                            :
                                            ''
                                    ))).indexOf('green') > -1 ? 'green' : 'red',
                                meetingData: singleMeeting,
                            }
                        ))
                    )
                }
            })
            .finally(() => {
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingSmallPopup, meetingPopup === false]);


    useEffect(() => {
    }, [allDoctorEvents]);

    useEffect(() => {
    }, [joinMeeting]);

    const localizer = momentLocalizer(moment);

    const closePop = () => {
        setMeetingPopup(!meetingPopup);
        setMeetingInfo([]);
    };


    const eventStyleGetter = (event, isSelected) => {
        var style = {
            backgroundColor: event.hexColor,
            borderRadius: '5px',
            color: event.hexColor,
            border: '1px solid ' + event.hexColor,
            display: 'block',
        };
        return {
            style: style
        };
    };

    const acceptMeetingClick = (event) => {
        setGeneralDataApiOptions({
            url: 'accept-meeting-request',
            method: 'post',
            data: {
                meeting_id: event.id,
            },
            callback: res => {
                setMeetingSmallPopup(!meetingSmallPopup);
                setAcceptMeeting();
            }
        })
    };


    const eventsClicked = (event) => {
        if (event.hexColor === 'red') {
            setMeetingSmallPopup(!meetingSmallPopup);
            setAcceptMeeting(event);
        }

        if (event.hexColor === 'green') {
            setMeetingSmallPopup(!meetingSmallPopup);
            setJoinMeeting(event);
        }
    };

    return (
        <>
            <UserLayout
                activeUserPage="calendar"
            />
            <div className={usersMenuOpen ? 'dashboard-content menu-open' : 'dashboard-content'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row">
                        <div className="col-12 col-md-6 pt-3 w-100">
                            <p className="dashboard-username">Calendar</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 pb-5">
                            {allDoctorEvents?.length > 0 ?
                                <Calendar
                                    localizer={localizer}
                                    events={allDoctorEvents}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 900 }}
                                    defaultView={window.innerWidth > 991 ? 'month' : 'day'}
                                    views={['day', 'month']}
                                    eventPropGetter={eventStyleGetter}
                                    onSelectEvent={(event) => eventsClicked(event)}
                                // selectable={true}
                                // view={calendarView}
                                // onView={calendarView}
                                // date={date}
                                // onSelectSlot={(e) => {
                                //     setDate(new Date(e.start));
                                //     setCalendarView('day');
                                // }}
                                />
                                :
                                <p>No Events Yet</p>
                            }
                        </div>
                    </div>
                    {meetingSmallPopup ?
                        <div id="errors-popup" className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <svg className="close-svg" onClick={() => { setMeetingSmallPopup(!meetingSmallPopup); setAcceptMeeting(); setJoinMeeting(); }} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                    <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                        <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                        <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                    </g>
                                </svg>
                                <div className="content background-light px-5 py-5 text-center">
                                    {acceptMeeting ?
                                        <>
                                            <h2 className="accept-meeting-title m-0 text-center">{acceptMeeting?.meetingData?.subject}</h2>
                                            <h3 className="m-0 my-auto meeting-admin-label">{dateFormat(moment.utc(acceptMeeting?.meetingData?.start_date).add(-acceptMeeting?.meetingData?.time_zone, 'hours').local(), "d mmmm yyyy")}</h3>
                                            <h3 className="m-0 my-auto meeting-admin-label">{moment.utc(acceptMeeting?.meetingData?.start_date).add(-acceptMeeting?.meetingData?.time_zone, 'hours').local().format("h:mm a")} - {moment.utc(acceptMeeting?.meetingData?.start_date).add(-acceptMeeting?.meetingData?.time_zone + acceptMeeting?.meetingData?.meeting_duration, 'hours').local().format("h:mm a")}</h3>
                                            <div className="row py-3">
                                                <div className="col-12">
                                                    <div className="row no-gutters text-left">
                                                        <div className="col-auto">
                                                            <img className="meetings-doctor-image" src={acceptMeeting?.meetingData?.doctor?.image ? acceptMeeting?.meetingData?.doctor?.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                        </div>
                                                        <div className="col pl-2">
                                                            <h2 className="accept-meeting-title m-0">{acceptMeeting?.meetingData?.doctor?.name}</h2>
                                                            <h3 className="meeting-admin-label">Organizer</h3>
                                                        </div>
                                                    </div>
                                                    {acceptMeeting?.meetingData?.doctors.map((singleDoctor, ind) => (
                                                        acceptMeeting?.meetingData?.doctor?.id !== singleDoctor?.id ?
                                                            <div className="row no-gutters py-1" key={ind}>
                                                                <div className="col-auto">
                                                                    <img className="meetings-doctor-image" src={singleDoctor?.image ? singleDoctor?.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                </div>
                                                                <div className="col pl-2">
                                                                    <h2 className="accept-meeting-title m-0">{singleDoctor?.name}</h2>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    ))
                                                    }
                                                </div>
                                            </div>
                                            <button onClick={() => acceptMeetingClick(acceptMeeting)} className="home-buttons request-metting px-2 font-w-medium mr-2">Accept</button>
                                            <button className="home-buttons request-metting px-2 font-w-medium" onClick={() => { setPopupType(false); setMeetingInfo(acceptMeeting?.meetingData); setMeetingPopup(!meetingPopup); setMeetingSmallPopup(!meetingSmallPopup) }}>RESCHEDULE</button>
                                        </>
                                        :
                                        null
                                    }

                                    {joinMeeting ?
                                        <>
                                            <h2 className="accept-meeting-title m-0 text-center">{joinMeeting?.meetingData?.subject}</h2>
                                            <h3 className="m-0 my-auto meeting-admin-label">{dateFormat(moment.utc(joinMeeting?.meetingData?.start_date).add(-joinMeeting?.meetingData?.time_zone, 'hours').local(), "d mmmm yyyy")}</h3>
                                            <h3 className="m-0 my-auto meeting-admin-label">{moment.utc(joinMeeting?.meetingData?.start_date).add(-joinMeeting?.meetingData?.time_zone, 'hours').local().format("h:mm a")} - {moment.utc(joinMeeting?.meetingData?.start_date).add(-joinMeeting?.meetingData?.time_zone + joinMeeting?.meetingData?.meeting_duration, 'hours').local().format("h:mm a")}</h3>
                                            <div className="row py-3 text-left">
                                                <div className="col-12">
                                                    <div className="row no-gutters">
                                                        <div className="col-auto">
                                                            <img className="meetings-doctor-image" src={joinMeeting?.meetingData?.doctor?.full_path_image} alt="doc-profile" />
                                                        </div>
                                                        <div className="col pl-2">
                                                            <h2 className="accept-meeting-title m-0">{joinMeeting?.meetingData?.doctor?.name}</h2>
                                                            <h3 className="meeting-admin-label">Organizer</h3>
                                                        </div>
                                                    </div>
                                                    {joinMeeting?.meetingData?.doctors.map((singleDoctor, doxIndex) => (
                                                        joinMeeting?.meetingData?.doctor?.id !== singleDoctor?.id ?
                                                            <div className="row no-gutters py-1" key={doxIndex}>
                                                                <div className="col-auto">
                                                                    <img className="meetings-doctor-image" src={singleDoctor?.image ? singleDoctor?.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                </div>
                                                                <div className="col pl-2">
                                                                    <h2 className="accept-meeting-title m-0">{singleDoctor?.name}</h2>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    ))
                                                    }
                                                </div>
                                            </div>
                                            <a href={joinMeeting?.meetingData?.link} target="_blank" rel="noreferrer" ><button type="button" className="home-buttons request-metting px-2 font-w-medium mr-2">Join</button></a>
                                            <button className="home-buttons request-metting px-2 font-w-medium" onClick={() => { setPopupType(false); setMeetingInfo(joinMeeting?.meetingData); setMeetingPopup(!meetingPopup); setMeetingSmallPopup(!meetingSmallPopup) }}>RESCHEDULE</button>
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                {meetingPopup ?
                    <Meetings meeting={popupType} meetingPopup={() => closePop()} caseDoctor={doctor} addedCaseDoctors={addedDoctors} careId={singleCare.id} singleMeetingInfo={meetingInfo} />
                    :
                    null
                }
                <Api options={generalDataApiOptions} />
            </div>
        </>
    );
}
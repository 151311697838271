import { useState, useContext, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import Api from "../components/Api";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';
import 'react-dropdown/style.css';


export default function DoctorSingleLesson(props) {
    const { generalDataGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [generalDataApiOptions2, setGeneralDataApiOptions2] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [loading] = useState(false);
    const [singleLesson, setSingleLesson] = useState();
    const [lessonVideos, setLessonVideos] = useState();
    const [currentVideo, setCurrentVideo] = useState();
    const [currentTitle, setCurrentTitle] = useState();
    const [lessonTotalTime, setLessonTotalTime] = useState();
    const [watchedVideos, setWatchedVideos] = useState([]);
    const [watchedVideosLoaded, setWatchedVideosLoaded] = useState(1000);
    const [currentPlayingVideo, setCurrentPlayingVideo] = useState(null);
    const [timerSeconds, setTimerSeconds] = useState(0);
    const [lastPlayedVideo,setLastPlayedVideo] = useState(0);
    const [currentVideoThumbnail, setCurrentVideoThumbnail] = useState();


    let { slug } = useParams();
    let { course_slug } = useParams();
    let { lesson_slug } = useParams();

    useEffect(() => {
        window.triggerScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generalData, generalDataApiOptions, loading]);

    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);
    
    useEffect(() => {
        setGeneralDataApiOptions2({
            url: 'doctor-lesson-videos/' + lesson_slug,
            method: 'get',
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                let last = 1000;
                setWatchedVideos(res.data);
                if (res.data?.length === 0) {
                    setWatchedVideosLoaded(0);
                    setLastPlayedVideo(0);
                }
                else if (res.data?.length > 0) {
                    let last_id=0;
                    res.data.forEach((singleWatched, index) => (
                        singleWatched.completed === 1 ?
                            last = index
                            :
                            null
                    ))
                    if (res.data?.length > 0) {
                        res.data.forEach((singleWatched, index) => (
                            last_id = index
                        ));
                        setLastPlayedVideo(last_id);
                    }
                    if (last === 1000) {
                        setWatchedVideosLoaded(0);
                    }
                    else {
                        setWatchedVideosLoaded(last + 1);
                    }
                }
                if (res.data?.length > 0 && last === 1000) {
                    setWatchedVideosLoaded(0);
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (watchedVideosLoaded !== 1000) {
            setGeneralDataApiOptions({
                url: 'doctor-single-lesson/' + course_slug + '/' + lesson_slug,
                method: 'get',
                headers: { "Content-Type": "multipart/form-data" },
                callback: res => {
                    let allDuration = 0;
                    if (res.data?.lessons_videos?.length > 0) {
                        res.data?.lessons_videos.map((singleVideo) => (
                            allDuration = Number(allDuration) + Number(singleVideo.video_duration)
                        ));
                        setLessonTotalTime(new Date(allDuration * 1000).toISOString().substr(11, 8));
                    }
                    if ((res.data?.lessons_videos?.length > 0 && watchedVideos?.length === 0) ||
                        (res.data?.lessons_videos?.length > 0 && watchedVideosLoaded === 0)) {
                        res.data?.lessons_videos.map((singleVideo, index) => (
                            index === 0 ?
                                <>
                                    {
                                        (setCurrentVideo(singleVideo.full_path_video),
                                        setCurrentTitle(singleVideo.video_title),
                                        setCurrentPlayingVideo(singleVideo),
                                        setCurrentVideoThumbnail(singleVideo.full_path_video_thumbnail),
                                        setLastPlayedVideo(index))
                                    }
                                </>
                                :
                                null
                        ));
                        setLessonTotalTime(new Date(allDuration * 1000).toISOString().substr(11, 8));
                    }

                    if (res.data?.lessons_videos?.length > 0 && watchedVideosLoaded !== 0
                        && watchedVideosLoaded !== 1000) {
                        res.data?.lessons_videos.map((singleVideo, index) => (
                            index === watchedVideosLoaded ?
                                <>
                                    {
                                        (setCurrentVideo(singleVideo.full_path_video),
                                        setCurrentTitle(singleVideo.video_title),
                                        setCurrentPlayingVideo(singleVideo),
                                        setCurrentVideoThumbnail(singleVideo.full_path_video_thumbnail),
                                        setLastPlayedVideo(index))
                                    }
                                </>
                                :
                                null
                        ));
                        setLessonTotalTime(new Date(allDuration * 1000).toISOString().substr(11, 8));
                    }
                    setSingleLesson(res.data);
                    if (res.data?.lessons_videos?.length > 0) {
                        setLessonVideos(res.data?.lessons_videos);
                    }
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedVideosLoaded]);

    useEffect(() => {
        setTimeout(() => {
            if (currentPlayingVideo !== null) {
                if (Number(document.getElementById("now-playing-video")?.currentTime) > 0
                    && Number(document.getElementById("now-playing-video")?.currentTime) + 10 < Number(currentPlayingVideo.video_duration)) {
                    axios.get('doctor-insert-lesson-videos/' + lesson_slug + '/' + currentPlayingVideo?.id + '/' + Math.round(document.getElementById("now-playing-video")?.currentTime))
                        .then(res => {
                        });
                }
                if (Number(document.getElementById("now-playing-video")?.currentTime) > 0
                    && Number(document.getElementById("now-playing-video")?.currentTime) + 5 > Number(currentPlayingVideo.video_duration)) {
                    axios.get('doctor-insert-lesson-videos/' + lesson_slug + '/' + currentPlayingVideo?.id + '/' + 999999999)
                        .then(res => {
                            setCurrentPlayingVideo(null);
                        });
                    setTimeout(() => {
                        setWatchedVideosLoaded(Number(watchedVideosLoaded) + 1)
                    }, 7000);
                }
            }
            setTimerSeconds(timerSeconds + 1);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerSeconds, currentPlayingVideo]);

    const titleClicked = (video, index, video_title, singleVideo, video_thumbnail) => {
        if (index <= watchedVideosLoaded) {
            setCurrentVideo(video);
            setCurrentTitle(video_title);
            setCurrentVideoThumbnail(video_thumbnail);
            setCurrentPlayingVideo(singleVideo);
        }
    };

    const downloadAll = () => {
        setGeneralDataApiOptions({
            url: 'download-all-materials',
            method: 'post',
            data: {
                slug: lesson_slug,
            },
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                // window.open(res.data,"_blank");
                const link = document.createElement('a');
                link.href = res.data.replace("storage/", "");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    };

    return (
        <>
            <UserLayout
                activeUserPage="courses"
                darkMenu={true}
            />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row">
                        {/* <div className="col-12 col-md-5 pt-3 w-100" animate="right">
                            <p className="dashboard-title m-0 pt-3">TRAINING COURSES</p>
                        </div> */}
                        <div className="col-12 col-md-5 pt-3 w-100 d-flex" animate="left">
                            <Link to={"/doctor-courses/doctor-single-course-category/" + slug + '/single-course/' + course_slug} className="d-flex my-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.992" height="19.986" viewBox="0 0 10.992 19.986">
                                    <path id="Path_77" data-name="Path 77" d="M-4195.109,1774l-8.579,8.578,8.579,8.579" transform="translate(4204.688 -1772.586)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </svg>
                            </Link>
                            <p className="dashboard-title d-flex m-0 pl-4 my-auto">
                                {
                                    singleLesson?.title
                                }
                            </p>
                        </div>
                    </div>
                    {
                        lessonVideos?.length > 0 ?
                            <div className="row py-4 all-lesson-videos">
                                <div className="col-12 col-md-5">
                                    <div className="border-dashboard-boxes fill-dark p-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="class-video-total-title m-0">{lessonVideos?.length} Videos - Estimated time:  {lessonTotalTime ? lessonTotalTime : ''}</p>
                                            </div>
                                            <hr />
                                            {
                                                lessonVideos.map((singleVideo, index) => (
                                                    <>
                                                        <div className="col-12 cursor-pointer" key={index} onClick={(e) => titleClicked(singleVideo.full_path_video, index, singleVideo.video_title, singleVideo,singleVideo.full_path_video_thumbnail)} >
                                                            <div className="row justify-content-between">
                                                                <div className="col">
                                                                    <p className={currentVideo === singleVideo.full_path_video || index <= lastPlayedVideo ? 'm-0 lesson-video-list-title active' : 'm-0 lesson-video-list-title'}>{singleVideo.video_title}</p>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <p className={currentVideo === singleVideo.full_path_video || index <= lastPlayedVideo ? 'm-0 lesson-video-list-time active' : 'm-0 lesson-video-list-time'}>{new Date(singleVideo.video_duration * 1000).toISOString().substr(11, 8)}</p>
                                                                </div>
                                                            </div>
                                                            {index + 1 === lessonVideos?.length ? null : <hr />}
                                                        </div>
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7 lesson-video pt-4 pt-md-0">
                                    <p className="m-0 lesson-video-title">{currentTitle}</p>
                                    <video id="now-playing-video" className="mt-4 video-lessons" src={currentVideo} width="100%" height="auto" poster={currentVideoThumbnail} controls controlsList="nodownload" />
                                    {singleLesson?.full_path_lesson_materials?.length > 0 ?
                                        <div className="row py-4">
                                            <div className="col-12 w-100">
                                                <div className="border-dark-blue border-radius-25 p-4 h-100">
                                                    <div className="row justify-content-between">
                                                        <div className="col-auto">
                                                            <p className="m-0 lesson-materials-big-title">Lesson Materials</p>
                                                        </div>
                                                        <div className="col-auto">
                                                            <p onClick={() => downloadAll()} className="m-0 download-all-materials cursor-pointer">Download All Materials</p>
                                                        </div>
                                                        {singleLesson?.full_path_lesson_materials?.map((singleMaterial, index) => (
                                                            <div className={index === 0 ? 'col-12 pt-3' : 'col-12 pt-1'} key={index}>
                                                                <a style={{ textDecoration: "none" }} className="lesson-materials-titles" href={singleMaterial} download="test" >
                                                                    {singleMaterial.substring(singleMaterial.lastIndexOf("/") + 1)}
                                                                </a>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                <Api options={generalDataApiOptions} />
                <Api options={generalDataApiOptions2} />
            </div>
        </>
    );
}
import { useState, useContext, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Api from "../components/Api";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import axios from 'axios';


export default function DoctorSingleCourseCategory(props) {
    const { generalDataGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [loading] = useState(false);
    const [courses, setCourses] = useState();
    const [coursesFiltered, setCoursesFiltered] = useState();
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('');

    let { slug } = useParams();

    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);
    
    const allCategories = () => {
        const categoryOptions = generalData.courses_categories.map(
            singleCategory => ({ value: singleCategory.slug, label: singleCategory.title }))
        return categoryOptions;
    }

    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading]);

    useEffect(() => {
        setGeneralDataApiOptions({
            url: 'single-category/' + slug,
            method: 'get',
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                setCourses(res.data);
            }
        })
    }, [slug]);

    useEffect(() => {
        if (filter !== '') {
            history.push('/doctor-courses/doctor-single-course-category/' + filter?.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        let newCoursesFiltered = [];
        let searchToLower = search.toLowerCase();
        if (courses) {
            courses.forEach(singleCourse => {
                if (!search || search === '' ||
                    (
                        singleCourse.title.toString().toLowerCase().includes(searchToLower) ||
                        singleCourse.author_name.toString().toLowerCase().includes(searchToLower) ||
                        singleCourse.price.toString().toLowerCase().includes(searchToLower)
                    )
                ) {
                    newCoursesFiltered.push(singleCourse);
                }
            });
        }
        setCoursesFiltered(newCoursesFiltered);
    }, [courses, search, generalData]);

    useEffect(() => {
        window.triggerScroll();
    }, [search, coursesFiltered]);


    let history = useHistory();

    return (
        <>
            <UserLayout
                activeUserPage="courses"
                darkMenu={true}
            />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row">
                        {/* <div className="col-12 col-md-5 pt-3 w-100" animate="right">
                            <p className="dashboard-title m-0 pt-3">TRAINING COURSES</p>
                        </div> */}
                        <div className="col-12 col-md-5 pt-3 w-100 d-flex" animate="left">
                            <Link to="/doctor-courses" className="d-flex my-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.992" height="19.986" viewBox="0 0 10.992 19.986">
                                    <path id="Path_77" data-name="Path 77" d="M-4195.109,1774l-8.579,8.578,8.579,8.579" transform="translate(4204.688 -1772.586)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </svg>
                            </Link>
                            <p className="dashboard-title d-flex m-0 pl-4 my-auto">
                                {
                                    generalData.courses_categories?.map((singleCategory) => (
                                        singleCategory.slug === slug ?
                                            singleCategory.title
                                            :
                                            null
                                    ))
                                }
                            </p>
                        </div>
                        <div className="col-12 col-md pt-3 w-100">
                            <div className="row justify-content-end">
                                <div className="col-12 col-md pt-3 w-100 pb-3">
                                    <Dropdown className="categories-dropdown position-relative" placeholder="Filter" options={allCategories()} value={filter} onChange={setFilter} />
                                </div>
                                <div className="col-12 col-md pt-0 pt-sm-3 w-100 pb-3 pl-md-0">
                                    <input className="search-input-courses position-relative" placeholder="Search" onChange={e => setSearch(e.target.value)} />
                                    <svg className="search-svg" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <g id="_001-loupe" data-name="001-loupe" transform="translate(0 -0.003)">
                                            <g id="Group_2" data-name="Group 2" transform="translate(0 0.003)">
                                                <path id="Path_3" data-name="Path 3" d="M19.756,18.58l-5.687-5.687a7.932,7.932,0,1,0-1.178,1.178l5.687,5.687a.833.833,0,1,0,1.178-1.178ZM7.917,14.17a6.25,6.25,0,1,1,6.25-6.25A6.257,6.257,0,0,1,7.917,14.17Z" transform="translate(0 -0.003)" fill="#25d6ff" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="col-12 col-md-auto pt-0 pt-sm-3 pb-3 pl-md-0 my-auto">
                                    <Link style={{ textDecoration: "none" }} to="/my-courses">
                                        <p className="m-0 my-courses-title">My Courses</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row py-4">
                        {
                            courses && courses?.length > 0 ?
                                coursesFiltered && coursesFiltered?.length > 0 ?
                                    coursesFiltered.map((singleCourse, index) => (
                                        <div className={index === 0 ? 'col-12 col-sm-6 col-md-3 pt-sm-4' : 'col-12 col-sm-6 col-md-3 pt-4'} key={index} animate="right">
                                            <Link style={{ textDecoration: "none" }} to={'/doctor-courses/doctor-single-course-category/'+slug+'/single-course/'+singleCourse.slug} >
                                                {
                                                    singleCourse.image ?
                                                        <div className="category-image-square">
                                                            <img className="category-image" src={singleCourse.full_path_image} alt={singleCourse.slug} />
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <p className="m-0 pt-2 category-name w-100">{singleCourse.title}</p>
                                                <h3 className="class-author-name">{singleCourse.author_name}</h3>
                                                <h3 className="class-price">{singleCourse.price} GBP</h3>
                                            </Link>
                                        </div>
                                    ))
                                    :
                                    <div className="col-12" animate="right">
                                        <p className="no-results">No Courses Available For Your Search.</p>
                                    </div>
                                :
                                courses?.length === 0 ?
                                    <div className="col-12" animate="right">
                                        <p className="no-results">No Courses Available.</p>
                                    </div>
                                    :
                                    null
                        }
                    </div>
                </div>
                <Api options={generalDataApiOptions} />
            </div>
        </>
    );
}
import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-ERPNQLQPY0";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-ERPNQLQPY0');
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null; // The component doesn't need to render anything
};

export default GoogleAnalytics;
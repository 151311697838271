import { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Api from "../components/Api";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';
import { dateFormat } from '../Helpers';
import { sha256 } from 'js-sha256';
import { useParams } from 'react-router';



const paymentDate = (date) => {
    return `${date.getFullYear()}:${date.getMonth() < 9 ? '0' + Number(Number(date.getMonth()) + 1) : Number(date.getMonth()) + 1}:${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}-${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`;
}

function bin2hex(s) {
    var i, f = 0, a = [];
    s += '';
    f = s.length;

    for (i = 0; i < f; i++) {
        a[i] = s.charCodeAt(i).toString(16).replace(/^([\da-f])$/, "0$1");
    }

    return a.join('');
}



function createHash(chargetotal, Currency, paydate, $shared) {
    let storeId = "7220540956";
    // NOTE: Please DO NOT hardcode the secret in that script. For example read it from a database.
    let sharedSecret = $shared;
    let stringToHash = storeId + paydate + chargetotal + Currency + sharedSecret;
    let ascii = bin2hex(stringToHash);


    return sha256(ascii);
}


export default function UserPayment(props) {

    let { reason } = useParams();
    const { authUserGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [authUser] = authUserGlobal;
    const [payments, setPayments] = useState([]);
    const [oldCurrentPage, setOldCurrentPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingForm, setLoadingForm] = useState();
    const [lastPage, setLastPage] = useState();
    const [outstanding, setOutstanding] = useState();
    const [filter, setFilter] = useState(0);
    const [search, setSearch] = useState('');
    const [submitSearch, setSubmitSearch] = useState(0);
    const [previousPayments, setPreviousPayments] = useState();
    const [date, setDate] = useState();
    const [failReason] = useState(reason ? reason : '');
    const [reasonPopup, setReasonPopup] = useState(false);
    const [shared, setShared] = useState('');

    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);

    useEffect(() => {
        setDate(paymentDate(new Date()));
        axios.get('get-shared')
            .then((r) => {
                setShared(r.data);
            });
    }, []);

    useEffect(() => {
        if (failReason) {
            setReasonPopup(true);
        }
    }, [failReason]);

    useEffect(() => {
        let page = currentPage === oldCurrentPage ? 1 : currentPage;

        setGeneralDataApiOptions({
            url: 'user-payments?page=' + page,
            data: {
                filter: filter,
                search: search,
            },
            method: 'post',
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                if (res.data?.last_three?.length > 0) {
                    setPreviousPayments(res.data?.last_three);
                }
                if (res.data?.total_amount) {
                    setOutstanding(res.data.total_amount)
                } else {
                    setOutstanding(0)
                }
                if (res.data.payments) {
                    if (currentPage !== oldCurrentPage) {
                        setPayments([...payments, ...res.data.payments.data]);
                        setCurrentPage(res.data.payments.current_page);
                        setLastPage(res.data.payments.last_page);
                        setOldCurrentPage(res.data.payments.current_page);
                    }
                    else {
                        setPayments(res.data.payments.data);
                        setCurrentPage(res.data.payments.current_page);
                        setLastPage(res.data.payments.last_page);
                        setOldCurrentPage(res.data.payments.current_page);
                    }
                    window.triggerScroll();
                    setLoadingForm(false);
                }
            }
        })
        if (submitSearch === 1) {
            setSubmitSearch(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, filter, submitSearch === 1]);


    let history = useHistory();


    // const payNow = (e, id) => {
    //     setGeneralDataApiOptions({
    //         // url: 'user-pay',
    //         url: '/do-payment',
    //         data: {
    //             id: id,
    //         },
    //         method: 'post',
    //         headers: { "Content-Type": "multipart/form-data" },
    //         callback: res => {
    //             setSubmitSearch(1);
    //         }
    //     });
    // }

    const getInvoice = (e, id) => {
        setGeneralDataApiOptions({
            url: 'request-pdf-invoice',
            data: {
                id: id,
            },
            method: 'post',
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                if (res.data) {
                    window.open(res.data, "_blank");
                }
            }
        });
    }


    return (
        <>
            <UserLayout
                darkMenu={true}
                activeUserPage="payments"
            />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row">
                        <div className="col-12 col-md-6 pt-3 w-100">
                            <p className="dashboard-username m-0">MY BALANCE</p>
                        </div>
                        <div className="col-12 w-100 py-4">
                            <div className="border-dashboard-boxes fill-dark p-5">
                                <div className="row justify-content-between">
                                    <div className="col-auto">
                                        <p className="m-0 outstanding-amount">{outstanding} GBP</p>
                                    </div>
                                    {/* <div className="col-auto my-auto">

                                        {
                                            date && outstanding && shared ?
                                                <form action={'https://test.ipg-online.com/connect/gateway/processing'} method="post">
                                                    <input type="hidden" name="txntype" value="sale" />
                                                    <input type="hidden" name="timezone" value="Europe/London" />
                                                    <input type="hidden" name="hash_algorithm" value="SHA256" />
                                                    <input type="hidden" name="storename" value="7220540956" />
                                                    <input type="hidden" name="hash" value={createHash(outstanding, 826, date, shared)} />
                                                    <input type="hidden" name="txndatetime" value={date} />
                                                    <input type="hidden" name="mode" value="fullpay" />
                                                    <input type="hidden" name="chargetotal" value={outstanding} />
                                                    <input type="hidden" name="currency" value="826" />
                                                    <input type="hidden" name="customerid" value={authUser?.id + '-ALL'} />
                                                    <input type="hidden" name="responseSuccessURL" value="https://dev-mediconnect.hellotree.dev/api/payment-success" />
                                                    <input type="hidden" name="responseFailURL" value="https://dev-mediconnect.hellotree.dev/api/payment-fail" />
                                                    <button type="submit" className="home-buttons fs-14">PAY NOW</button>
                                                    <button type="submit"
                                                onClick={(e) => payNow(e, singlePayment.id)} 
                                                className="home-buttons fs-12 py-1 px-2">PAY NOW</button>
                                                </form>
                                                :
                                                null
                                        }
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {previousPayments?.length > 0 ?
                        <div className="row pt-5">
                            <div className="col-auto">
                                <p className="m-0 payments-title">TRANSACTIONS</p>
                            </div>
                            <div className="col-12 pt-4">
                                <div className="border-dashboard-boxes fill-dark p-4">
                                    <div className="row">
                                        {
                                            previousPayments.map((singlePayment, index) => (
                                                <div className="col-12" key={index}>
                                                    <div className="row justify-content-between">
                                                        <div className="col-auto">
                                                            <p className="m-0 last-three-date">{dateFormat(singlePayment.pay_date, "d mmmm yyyy")}</p>
                                                        </div>
                                                        <div className="col-auto">
                                                            <p className="m=0 last-three-amount">GBP {singlePayment.amount}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className="row pt-5">
                        <div className="col-auto">
                            <p className="m-0 payments-title">PAYMENTS</p>
                        </div>
                        <div className="col-auto my-auto">
                            <p onClick={() => setFilter(0)} className={filter === 0 ? 'm-0 payment-status-title active' : 'm-0 payment-status-title'}>ALL</p>
                        </div>
                        <div className="col-auto my-auto">
                            <p onClick={() => setFilter(1)} className={filter === 1 ? 'm-0 payment-status-title active' : 'm-0 payment-status-title'}>PENDING</p>
                        </div>
                        <div className="col-auto my-auto">
                            <p onClick={() => setFilter(2)} className={filter === 2 ? 'm-0 payment-status-title active' : 'm-0 payment-status-title'}>PAID</p>
                        </div>
                    </div>

                    <div className="row py-5">
                        <div className="col-12">
                            <div className="border-dashboard-boxes fill-dark p-3">
                                <div className="row justify-content-end">
                                    <div className="col-12 col-sm-6 col-md-4 pb-3 py-sm-3 text-right">
                                        <form onSubmit={() => setSubmitSearch(1)}>
                                            <input className="search-input position-relative" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
                                            <svg onClick={() => setSubmitSearch(1)} className="search-svg" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="_001-loupe" data-name="001-loupe" transform="translate(0 -0.003)">
                                                    <g id="Group_2" data-name="Group 2" transform="translate(0 0.003)">
                                                        <path id="Path_3" data-name="Path 3" d="M19.756,18.58l-5.687-5.687a7.932,7.932,0,1,0-1.178,1.178l5.687,5.687a.833.833,0,1,0,1.178-1.178ZM7.917,14.17a6.25,6.25,0,1,1,6.25-6.25A6.257,6.257,0,0,1,7.917,14.17Z" transform="translate(0 -0.003)" fill="#25d6ff" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </form>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-wrapper">
                                            <table className="payments" width="100%">
                                                <thead>
                                                    <tr>
                                                        <td>DATE</td>
                                                        <td>FOR</td>
                                                        <td>AMOUNT</td>
                                                        <td>CASE ID</td>
                                                        <td>STATUS</td>
                                                        <td> </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        payments?.length > 0 && date ?
                                                            payments?.map((singlePayment, index) => (
                                                                <tr key={index}>
                                                                    <td>{singlePayment.full_date}</td>
                                                                    <td>{singlePayment.doctor?.first_name && singlePayment.doctor?.last_name ? singlePayment.doctor?.first_name + ' ' + singlePayment.doctor?.last_name : (singlePayment.doctor?.name ? singlePayment.doctor?.name : '')}</td>
                                                                    <td>{singlePayment.amount} GBP</td>
                                                                    <td>{singlePayment.get_care_info_data_id}</td>
                                                                    <td className={singlePayment.pay_date ? 'green' : 'orange'}>{singlePayment.pay_date ? 'Paid' : 'Pending'}</td>
                                                                    <td>
                                                                        {singlePayment.pay_date &&
                                                                            <button onClick={(e) => getInvoice(e, singlePayment.id)} className="home-buttons fs-12 py-1 px-2">INVOICE</button>
                                                                        }
                                                                        {/* {singlePayment.pay_date ?
                                                                            <button onClick={(e) => getInvoice(e, singlePayment.id)} className="home-buttons fs-12 py-1 px-2">INVOICE</button>
                                                                            :
                                                                            <form action={'https://test.ipg-online.com/connect/gateway/processing'} method="post">
                                                                                <input type="hidden" name="txntype" value="sale" />
                                                                                <input type="hidden" name="timezone" value="Europe/London" />
                                                                                <input type="hidden" name="hash_algorithm" value="SHA256" />
                                                                                <input type="hidden" name="storename" value="7220540956" />
                                                                                <input type="hidden" name="hash" value={createHash(singlePayment.amount, 826, date, shared)} />
                                                                                <input type="hidden" name="txndatetime" value={date} />
                                                                                <input type="hidden" name="mode" value="fullpay" />
                                                                                <input type="hidden" name="chargetotal" value={singlePayment.amount} />
                                                                                <input type="hidden" name="currency" value="826" />
                                                                                <input type="hidden" name="customerid" value={authUser?.id + '-' + singlePayment?.id} />
                                                                                <input type="hidden" name="responseSuccessURL" value="https://dev-mediconnect.hellotree.dev/api/payment-success" />
                                                                                <input type="hidden" name="responseFailURL" value="https://dev-mediconnect.hellotree.dev/api/payment-fail" />

                                                                                {
                                                                                    shared ?
                                                                                        <button type="submit"
                                                                                            // onClick={(e) => payNow(e, singlePayment.id)} 
                                                                                            className="home-buttons fs-12 py-1 px-2">PAY NOW</button>
                                                                                        :
                                                                                        null
                                                                                }

                                                                            </form>
                                                                        } */}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            :
                                                            null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !payments?.length > 0 ?
                                        <div className="row justify-content-center py-3">
                                            <p className="m-0">No Payments Available</p>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    !!(currentPage !== lastPage) && (
                                        <div className="row justify-content-center pt-5">

                                            <button className={!loadingForm ? "load-more home-buttons" : " hide"} onClick={() => { setCurrentPage(currentPage + 1); }} animate="down">
                                                LOAD MORE</button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    {/* <div className="row justify-content-center py-4">
                        <div className="col-12 d-flex text-center justify-content-center">

                            <div className={loadingForm ? "lds-ring" : "lds-ring d-none"}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            {
                                !!(currentPage != lastPage) && (
                                    <button className={!loadingForm ? "load-more home-buttons" : " hide"} onClick={() => { setCurrentPage(currentPage + 1); }} animate="down">
                                        Load More</button>
                                )
                            }
                        </div>
                    </div> */}
                </div>
                {reasonPopup ?
                    <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <svg className="close-svg" onClick={() => { setReasonPopup(false); history.push('/user-payments') }} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                    <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                    <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                </g>
                            </svg>
                            <div className="content background-light text-center px-5 py-5">
                                <p className="my-2">{failReason}</p>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <Api options={generalDataApiOptions} />
            </div>
        </>
    );
}
import React, { useEffect, useState, useContext } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { useParams } from 'react-router';
import GlobalState from "../contexts/GlobalState";
import Api from "../components/Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import UserLayout from "../components/UsersMenu";
import { useHistory } from "react-router-dom";
import axios from 'axios';

// import CountrySelect from "react-country-select";

const formatDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + Number(Number(date.getMonth()) + 1) : Number(date.getMonth()) + 1}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
}

let emptyProblemObject = { problem: '', duration: '' };
let emptyDiagnosisObject = { medical_diagnosis: '', date: '' };
let emptySurgeriesObject = { surgery: '', date: '' };
let emptyMedicationsObject = { medication: '' };
let emptyHospitalServicesObject = { hospital_name: '', address: '', contact_info: '', doctor_name: '', doctor_contact: '' }
let emptyConcernsObject = { concern: '' };
let emptyRadiologyObject = { report: '', report_file: '' };
let emptyLabResultsObject = { report: '', report_file: '' };
let emptyReportsObject = { report: '', report_file: '' };
let emptyHistopathologyObject = { report: '', report_file: '' };
let emptyInvestigationsObject = { report: '', report_file: '' };
let emptyGeneticsObject = { report: '', report_file: '' };
let emptyOthersObject = { file_name: '', report: '', report_file: '' };

export default function GetCareLogged(props) {


    let { token } = useParams();
    let { email } = useParams();
    const [submitApiOptions, setSubmitApiOptions] = useState({});
    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [authUser] = authUserGlobal;
    const [home_number, setHomeNumber] = useState('');
    const [mobile_number, setMobileNumber] = useState('');
    const [checkbox, setCheckbox] = useState(0);
    const [formCurrentPage, setFormCurrentPage] = useState(1);
    const [formData, setFormData] = useState();
    const [tokenState, setTokenState] = useState(token ? token : '');
    const [emailState] = useState({ email } ? email : '');
    const [getCareData, setGetCareData] = useState({});
    const [errors, setErrors] = useState({});
    const [formSuccess, setFormSuccess] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [dbCheckbox, setDbCheckbox] = useState();
    const [passwordCheckbox, setPasswordCheckbox] = useState(0);
    const [birthDate] = useState();
    const [country, setCountry] = useState({});
    const [dbCountry, setDbCountry] = useState();
    const [gender, setGender] = useState({});
    const [dbGender, setDbGender] = useState();
    const [usersMenuOpen] = usersMenuOpenGlobal;

    let history = useHistory();

    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);
    
    useEffect(() => {
        if (Object.keys(errors).length) {
            if (checkIfErrorHasKey('password') || checkIfErrorHasKey('password_confirmation')) {
                setPasswordCheckbox(1)
            }
            if (checkIfErrorHasKey('user_gender_id') || checkIfErrorHasKey('first_name') || checkIfErrorHasKey('last_name') || checkIfErrorHasKey('email') || checkIfErrorHasKey('date_of_birth') || checkIfErrorHasKey('mobile_number') ||
                checkIfErrorHasKey('home_number') || checkIfErrorHasKey('post_code') || checkIfErrorHasKey('countries_list_id') || checkIfErrorHasKey('address')
                || checkIfErrorHasKey('password') || checkIfErrorHasKey('password_confirmation')) {
                setFormCurrentPage(1);
            } else if (checkIfErrorHasKey('problems') || checkIfErrorHasKey('diagnosis') || checkIfErrorHasKey('problems') || checkIfErrorHasKey('surgeries')
                || checkIfErrorHasKey('medications') || checkIfErrorHasKey('hospital_services') || checkIfErrorHasKey('concerns') ||
                checkIfErrorHasKey('checkbox')) {
                setFormCurrentPage(2);
                if (checkIfErrorHasKey('checkbox')) {
                    setTimeout(() => {
                        window.scrollTo(0, document.getElementById("checkboxDiv").offsetTop);
                    }, 400);
                }
            } else {
                setFormCurrentPage(3);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    const checkIfErrorHasKey = (key) => {
        let flag = false;
        Object.keys(errors).forEach(errorKey => {
            if (errorKey.startsWith(key)) flag = true;
        });
        return flag;
    }

    const submit = (e) => {
        let fd = new FormData();
        fd.append('token', tokenState);
        fd.append('save', 0);
        Object.keys(formData).forEach(formDataKey => {
            if (Array.isArray(formData[formDataKey])) {
                formData[formDataKey].forEach((obj, index) => {
                    Object.keys(formData[formDataKey][index]).forEach(subkey => {
                        fd.append(formDataKey + '[' + index + '][' + subkey + ']', formData[formDataKey][index][subkey]);
                    });
                });
            } else {
                fd.append(formDataKey, formData[formDataKey]);
            }
        });


        fd.append('email', authUser.email);
        setSubmitApiOptions({
            url: 'save-form-1',
            method: 'post',
            data: fd,
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                setTokenState(res.data.user_token)
                setFormSuccess(true);
                history.push("/user-dashboard");
            },
            catch: res => {
                if (res) {
                    setErrors(res);
                }
            }
        });
    }

    const saveForm1 = (e) => {
        let fd = new FormData();
        fd.append('token', tokenState);
        fd.append('save', 1);
        Object.keys(formData).forEach(formDataKey => {
            if (Array.isArray(formData[formDataKey])) {
                formData[formDataKey].forEach((obj, index) => {
                    Object.keys(formData[formDataKey][index]).forEach(subkey => {
                        fd.append(formDataKey + '[' + index + '][' + subkey + ']', formData[formDataKey][index][subkey] ? formData[formDataKey][index][subkey] : '');
                    });
                });
            } else {
                fd.append(formDataKey, formData[formDataKey] ? formData[formDataKey] : '');
            }
        });

        fd.append('email', authUser.email);
        setErrors({});
        setSubmitApiOptions({
            url: 'save-form-1',
            method: 'post',
            data: fd,
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                setTokenState(res.data.user_token);
                setSaveSuccess(true);
                history.push("/user-dashboard");
            },
            catch: res => {
                setErrors(res);
            }
        });
    }



    const changeInput = (newValue, key, index = null, subKey = null) => {
        let newFormData = { ...formData };
        if (index === null && subKey === null) newFormData[key] = newValue;
        else newFormData[key][index][subKey] = newValue;
        setFormData(newFormData);
    }

    const inputProps = (key, index = null, subKey = null) => {
        return {
            value: (index === null && subKey === null) ? formData[key] : formData[key][index][subKey],
            onChange: e => changeInput(e.target.value, key, index, subKey)
        }
    }

    const dateProps = (key, index, subKey) => {
        return {
            value: formData[key][index][subKey],
            onChange: newValue => {
                let newFormData = { ...formData };
                newFormData[key][index][subKey] = formatDate(newValue);
                setFormData(newFormData);
            }
        }
    }

    const singleDateProps = (key) => {
        return {
            value: formData[key],
            onChange: newValue => {
                let newFormData = { ...formData };
                newFormData[key] = formatDate(newValue);
                setFormData(newFormData);
            }
        }
    }

    const inputNameId = (field, i, subField) => {
        return field + '.' + i + '.' + subField
    }

    const addToFormData = (key) => {
        let emptyObject = {};
        switch (key) {
            default:
            case 'problems':
                emptyObject = { ...emptyProblemObject };
                break;
            case 'diagnosis':
                emptyObject = { ...emptyDiagnosisObject };
                break;
            case 'surgeries':
                emptyObject = { ...emptySurgeriesObject };
                break;
            case 'medications':
                emptyObject = { ...emptyMedicationsObject };
                break;
            case 'hospital_services':
                emptyObject = { ...emptyHospitalServicesObject };
                break;
            case 'concerns':
                emptyObject = { ...emptyConcernsObject };
                break;

            case 'reports':
                emptyObject = { ...emptyReportsObject };
                break;
            case 'la_results':
                emptyObject = { ...emptyLabResultsObject };
                break;
            case 'radiology':
                emptyObject = { ...emptyRadiologyObject };
                break;
            case 'histopathology':
                emptyObject = { ...emptyHistopathologyObject };
                break;
            case 'investigations':
                emptyObject = { ...emptyInvestigationsObject };
                break;
            case 'genetics':
                emptyObject = { ...emptyGeneticsObject };
                break;
            case 'others':
                emptyObject = { ...emptyOthersObject };
                break;
        }

        let newFormData = { ...formData };
        newFormData[key].push(emptyObject);
        setFormData(newFormData);
    }

    const removeFromFormData = (key, index) => {
        let newFormData = { ...formData };
        newFormData[key].splice(index, 1);
        setFormData(newFormData);
    }



    const initEmptyFormData = () => {
        setFormData({
            first_name: '',
            last_name: '',
            date_of_birth: '',
            email: authUser?.email,
            user_gender_id: '',
            mobile_number: '',
            home_number: '',
            countries_list_id: '',
            post_code: '',
            password: '',
            password_confirmation: '',
            address: '',
            checkbox: 0,
            problems: [
                { ...emptyProblemObject },
                { ...emptyProblemObject },
                { ...emptyProblemObject },
            ],
            diagnosis: [
                { ...emptyDiagnosisObject },
                { ...emptyDiagnosisObject },
                { ...emptyDiagnosisObject },
            ],
            surgeries: [
                { ...emptySurgeriesObject },
                { ...emptySurgeriesObject },
                { ...emptySurgeriesObject },
            ],
            medications: [
                { ...emptyMedicationsObject },
                { ...emptyMedicationsObject },
                { ...emptyMedicationsObject },
            ],
            hospital_services: [
                { ...emptyHospitalServicesObject },
            ],
            concerns: [
                { ...emptyConcernsObject },
            ],
            reports: [
                { ...emptyReportsObject },
            ],
            lab_results: [
                { ...emptyLabResultsObject },
            ],
            radiology: [
                { ...emptyRadiologyObject },
            ],
            histopathology: [
                { ...emptyHistopathologyObject },
            ],
            investigations: [
                { ...emptyInvestigationsObject },
            ],
            genetics: [
                { ...emptyGeneticsObject },
            ],
            others: [
                { ...emptyOthersObject },
            ],
        });
    }

    useEffect(() => {
    }, [formData, home_number, mobile_number, birthDate]);



    useEffect(() => {
        setDbCountry(country.value)
    }, [country]);

    // useEffect(() => {
    //     setDbGender(gender.value)
    // }, [gender]);

    useEffect(() => {
        if (dbCountry) {
            setCountry({ value: dbCountry, label: dbCountry })
        }
    }, [dbCountry]);

    useEffect(() => {
        if (dbGender) {
            setGender({ value: dbGender, label: allGenderOptions()[dbGender ? Number(Number(dbGender) - 1) : ''] })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbGender]);


    useEffect(() => {
    }, [passwordCheckbox]);

    useEffect(() => {
        if (formData) {
            let newFormData = { ...formData };
            newFormData['mobile_number'] = mobile_number;
            newFormData['home_number'] = home_number;
            newFormData['countries_list_id'] = country.value;
            newFormData['user_gender_id'] = gender.value;
            newFormData['checkbox'] = checkbox ? checkbox : 0;
            setFormData(newFormData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [home_number, mobile_number, checkbox, birthDate]);

    useEffect(() => {
        if (formData) {
            let newFormData = { ...formData };
            newFormData['countries_list_id'] = country.value;
            setFormData(newFormData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country]);


    useEffect(() => {
        if (formData) {
            let newFormData = { ...formData };
            newFormData['user_gender_id'] = gender.value;
            setFormData(newFormData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gender]);


    useEffect(() => {
        if (formData) {
            let newFormData = { ...formData };
            newFormData['checkbox'] = dbCheckbox;
            setFormData(newFormData);
            setCheckbox(dbCheckbox)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbCheckbox]);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.triggerScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formCurrentPage]);


    useEffect(() => {
        window.triggerScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generalData]);

    useEffect(() => {
    }, [saveSuccess, formSuccess]);

    // useEffect(() => {
    //     setDbGender(gender)
    // }, [gender]);




    useEffect(() => {

        if (tokenState) {
            setGetCareData({
                url: 'get-care-data/' + emailState + '/' + tokenState,
                method: 'get',
                callback: res => {
                    setFormData(res.data)
                    setHomeNumber(res.data.home_number)
                    setMobileNumber(res.data.mobile_number)
                    if (res.data.countries_list_id !== null && res.data.countries_list_id !== '') {
                        setDbCountry(res.data.countries_list_id)
                    }
                    setDbGender(res.data.user_gender_id)
                    setDbCheckbox(res.data.checkbox)
                }
            });
        }
        // if (authUser?.id) {
        //     setGetCareData({
        //         url: 'get-care-data-logged' + '/' + authUser.email,
        //         method: 'get',
        //         callback: res => {
        //             if (!res.data) {
        //                 initEmptyFormData();
        //             } else {
        //                 setFormData(res.data)
        //                 setHomeNumber(res.data.home_number)
        //                 setDbCountry(res.data.countries_list_id)
        //                 setMobileNumber(res.data.mobile_number)
        //                 setCheckbox(res.data.checkbox)
        //             }
        //         }
        //     });
        // }
        else {
            initEmptyFormData();
            setCheckbox(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const allCountriesOption = () => {
        const countriesOptions = generalData.countries_list.map(
            singleCountry => ({ value: singleCountry.id, label: singleCountry.name }))
        return countriesOptions;
    }

    const allGenderOptions = () => {
        const genderOptions = generalData.user_gender.map(
            singlegender => ({ value: singlegender.id, label: singlegender.gender }))
        return genderOptions;
    }




    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#000074',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#000074',
                fontFamily: 'Rubik',
                backgroundColor: isDisabled ? '' : '',
            };
        },
        control: provided => ({
            ...provided,
            height: 48,
            width: '100%',
            border: "1px solid #000074",
            backgroundColor: 'white',
            outline: "none",
            // boxShadow: "none",
            '&:hover': { borderColor: '#000074' },
            boxShadow: 0,
            borderRadius: "50px",
        }),
        input: (base, state) => ({
            ...base,
            color: "#004",
            fontSize: "16px",
            height: "100%",
            marginTop: "-8px",
            '& input': {
                font: 'inherit',
            },
        })
    };


    const customStylesRed = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#000074',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#000074',
                fontFamily: 'Rubik',
                backgroundColor: isDisabled ? '' : '',
            };
        },
        control: provided => ({
            ...provided,
            height: 48,
            width: '100%',
            border: "1px solid red !important",
            backgroundColor: 'white',
            outline: "none",
            // boxShadow: "none",
            '&:hover': { borderColor: 'red !important' },
            boxShadow: 0,
            borderRadius: "50px",
        }),
        input: (base, state) => ({
            ...base,
            color: "red",
            fontSize: "16px",
            height: "100%",
            marginTop: "-8px",
            '& input': {
                font: 'inherit',
            },
        })
    };


    return (
        <>
            <UserLayout
                darkMenu={true}
                activeUserPage="dashboard" />
            <div className={!usersMenuOpen ? 'dashboard-content bg-white' : 'dashboard-content bg-white menu-open'}>
                <div className="container py-5 equal-spacing-cont">
                    <div className="row justify-content-center">
                        <div className="col-10 col-lg-8 d-flex justify-content-between py-5" >
                            <div className="position-relative w-100 d-flex">
                                <div onClick={() => setFormCurrentPage(1)} className={formCurrentPage === 1 ? 'page-numbering-bullet custom-page-bullet-1 active' : 'page-numbering-bullet custom-page-bullet-1'}>1</div>
                                <div onClick={() => setFormCurrentPage(2)} className={formCurrentPage === 2 ? 'page-numbering-bullet custom-page-bullet-2 active' : 'page-numbering-bullet custom-page-bullet-2'}>2</div>
                                <div onClick={() => setFormCurrentPage(3)} className={formCurrentPage === 3 ? 'page-numbering-bullet custom-page-bullet-3 active' : 'page-numbering-bullet custom-page-bullet-3'}>3</div>
                                <div className="line-seperator-get-care" />
                                <div className="get-care-bullet1-title">
                                    <p className={formCurrentPage === 1 ? 'space-line-break bullet-desc active' : 'space-line-break bullet-desc'}>{generalData.get_care_settings.bullet1_title}</p>
                                </div>
                                <div className="get-care-bullet2-title">
                                    <p className={formCurrentPage === 2 ? 'space-line-break bullet-desc active' : 'space-line-break bullet-desc'}>{generalData.get_care_settings.bullet2_title}</p>
                                </div>
                                <div className="get-care-bullet3-title">
                                    <p className={formCurrentPage === 3 ? 'space-line-break bullet-desc active' : 'space-line-break bullet-desc'}>{generalData.get_care_settings.bullet3_title}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {
                    formData ?
                        <div className="get-care-forms">
                            <form onSubmit={submit}>
                                {formCurrentPage === 1 ?
                                    <div className="get-care-1">
                                        <div className="container py-5">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pb-4">
                                                    <h3>{generalData.get_care_settings.first_name_title}</h3>
                                                    <input className={errors.first_name ? 'red-errors-borders' : ''} type="text" id="first_name" name="first_name" {...inputProps('first_name')} />
                                                    <div id="first_name" className="help-block with-errors">{errors.first_name ? errors.first_name : ''}</div>
                                                </div>
                                                <div className="col-12 col-md-6 pb-4">
                                                    <h3>{generalData.get_care_settings.last_name_title}</h3>
                                                    <input className={errors.last_name ? 'red-errors-borders get-care-input' : 'get-care-input'} type="text" name="last_name" {...inputProps('last_name')} />
                                                    <div id="last_name" className="help-block with-errors">{errors.last_name ? errors.last_name : ''}</div>
                                                </div>
                                                <div className="col-12 col-md-6 pb-4">
                                                    <h3>{generalData.get_care_settings.date_of_birth_title}</h3>
                                                    <DatePicker maxDate={new Date()} placeholderText="YYYY-MM-DD" peekNextMonth
                                                        dateFormat="yyyy-MM-dd"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select" className={errors.date_of_birth ? 'red-errors-borders clickable' : 'clickable'} {...singleDateProps('date_of_birth')} />
                                                    {/* <input className={errors.date_of_birth ? 'red-errors-borders' : ''} type="date" name="date_of_birth" {...inputProps('date_of_birth')} /> */}
                                                    <div id="date_of_birth" className="help-block with-errors">{errors.date_of_birth ? errors.date_of_birth : ''}</div>
                                                </div>
                                                <div className="col-12 col-md-6 pb-4">
                                                    <h3>Gender</h3>
                                                    <Dropdown menuClassName='dropdown-menu-gender' className={errors.user_gender_id ? 'get-care-dropdown-input red-errors-borders' : 'get-care-dropdown-input'} placeholder="Select Gender" options={allGenderOptions()} onChange={setGender} value={allGenderOptions()[dbGender ? Number(Number(dbGender) - 1) : '']} />
                                                    <div id="user_gender_id" className="help-block with-errors">{errors.user_gender_id ? errors.user_gender_id : ''}</div>
                                                </div>
                                                <div className="col-12 col-md-6 pb-4">
                                                    <h3>{generalData.get_care_settings.mobile_number_title}</h3>
                                                    <PhoneInput className={errors.mobile_number ? 'red-errors-borders phone-input-style get-care-input' : 'phone-input-style get-care-input'} international defaultCountry="GB" value={mobile_number} onChange={setMobileNumber} name="mobile_number" type="text" />
                                                    <div id="mobile_number" className="help-block with-errors">{errors.mobile_number ? errors.mobile_number : ''}</div>
                                                </div>
                                                <div className="col-12 col-md-6 pb-4">
                                                    <h3>{generalData.get_care_settings.home_number_title}</h3>
                                                    <PhoneInput international defaultCountry="GB" value={home_number} onChange={setHomeNumber} name="home_number" type="text" className={errors.home_number ? 'red-errors-borders phone-input-style get-care-input' : 'phone-input-style get-care-input'} />
                                                    <div id="home_number" className="help-block with-errors">{errors.home_number ? errors.home_number : ''}</div>
                                                </div>
                                                <div className="col-12 col-md-6 pb-4">
                                                    <h3>{generalData.get_care_settings.country_title}</h3>
                                                    <Select
                                                        className="custom-country-select"
                                                        value={allCountriesOption()[dbCountry ? Number(Number(dbCountry) - 1) : '']}
                                                        // value={country}
                                                        onChange={setCountry}
                                                        placeholder='Select Country'
                                                        styles={!errors.countries_list_id ? customStyles : customStylesRed}
                                                        options={allCountriesOption()}
                                                        maxMenuHeight={200} />
                                                    {/* <input className={errors.country ? 'red-errors-borders' : ''} type="text" name="country" {...inputProps('country')} /> */}
                                                    <div id="countries_list_id" className="help-block with-errors">{errors.countries_list_id ? 'Country is required' : ''}</div>
                                                </div>
                                                <div className="col-12 col-md-6 pb-4">
                                                    <h3>{generalData.get_care_settings.post_code_title}</h3>
                                                    <input type="text" name="post_code" className={errors.post_code ? 'red-errors-borders get-care-input' : 'get-care-input'} {...inputProps('post_code')} />
                                                    <div id="post_code" className="help-block with-errors">{errors.post_code ? errors.post_code : ''}</div>
                                                </div>
                                                <div className="col-12 pb-4">
                                                    <h3>{generalData.get_care_settings.address_title}</h3>
                                                    <input className={errors.address ? 'red-errors-borders get-care-input' : 'get-care-input'} type="text" name="address" {...inputProps('address')} />
                                                    <div id="address" className="help-block with-errors">{errors.address ? errors.address : ''}</div>
                                                </div>
                                                {
                                                    !authUser?.id && (
                                                        <>
                                                            <div className="col-12 pb-1">
                                                                <div className="row">
                                                                    <div className="col-auto pr-0">
                                                                        {!passwordCheckbox ?
                                                                            <div className="password-tick-checkbox" onClick={() => setPasswordCheckbox(1)} />
                                                                            :
                                                                            <div className="square-checkbox position-relative" onClick={() => setPasswordCheckbox(0)}>
                                                                                <img src='/img/check.png' alt="tick" />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="col">
                                                                        <h3><span className="clickable" onClick={passwordCheckbox === 0 ? () => setPasswordCheckbox(1) : () => setPasswordCheckbox(0)}>{generalData.get_care_settings.signup_title}</span></h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {passwordCheckbox === 1 ?
                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        <div className="col-12 col-md-6 pb-4">
                                                                            <h3>{generalData.get_care_settings.password_title}</h3>
                                                                            <input className={errors.password ? 'red-errors-borders get-care-input' : 'get-care-input'} name="password" type="password" {...inputProps('password')} />
                                                                            <div id="password" className="help-block with-errors">{errors.password ? errors.password : ''}</div>
                                                                        </div>
                                                                        <div className="col-12 col-md-6 pb-4">
                                                                            <h3>{generalData.get_care_settings.confirm_password}</h3>
                                                                            <input className={errors.password_confirmation ? 'red-errors-borders get-care-input' : 'get-care-input'} name="password_confirmation" type="password" {...inputProps('password_confirmation')} />
                                                                            <div id="password_confirmation" className="help-block with-errors">{errors.password_confirmation ? errors.password_confirmation : ''}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </>
                                                    )
                                                }
                                                <div className="col-12 pt-3 d-flex justify-content-center justify-content-lg-end">
                                                    <button type="button" onClick={() => saveForm1()} className="f-flex home-buttons mr-3 w-change">{generalData.get_care_settings.save_title}</button>
                                                    <button type="button" onClick={() => setFormCurrentPage(2)} className="d-flex home-buttons w-change">{generalData.get_care_settings.next_title}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                {formCurrentPage === 2 ?
                                    <div className="get-care-2">
                                        <div className="container py-5">
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <h1>{generalData.get_care_settings.problems_title}</h1>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <h6><span onClick={() => addToFormData('problems')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>
                                            <div className="row pt-3 pb-4">
                                                <div className="col-12 pb-3 pb-lg-0">
                                                    <h3>{generalData.get_care_settings.problems_subtitle}</h3>
                                                </div>
                                                <div className="col-12 col-lg-6 pb-3 pb-lg-0">
                                                    <h3>{generalData.get_care_settings.problems_input_title}</h3>
                                                </div>
                                                <div className="col-12 col-lg-6 pl-lg-0 pb-2 pb-lg-0">
                                                    <h3>{generalData.get_care_settings.duration_input_title}</h3>
                                                </div>
                                                <div className="col-12">
                                                    {formData.problems.map((x, i) => {
                                                        return (

                                                            <div className="row align-items-center pb-4 pb-lg-0" key={i}>
                                                                <div className="col-11 col-lg-5 pb-2 py-lg-3 pr-lg-0 position-relative-inputs">
                                                                    <input className={errors['' + inputNameId('problems', i, 'problem') + ''] ? 'red-errors-borders get-care-with-numbers' : 'get-care-with-numbers'} type="text" name="problem" {...inputProps('problems', i, 'problem')} />
                                                                    <div className="forms-index-div">{i + 1}. </div>
                                                                    <div className="d-block d-lg-none x-button-forms" onClick={formData.problems.length > 1 ? () => removeFromFormData('problems', i) : null}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                            <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                                <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                                    <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                    <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                                </g>
                                                                                <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                                    <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                    <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 py-lg-3 d-none d-lg-block">
                                                                </div>
                                                                <div className="col-11 col-lg-5 py-lg-3 px-lg-0">
                                                                    <input className={errors['' + inputNameId('problems', i, 'duration') + ''] ? 'red-errors-borders' : ''} type="text" name="duration"  {...inputProps('problems', i, 'duration')} />
                                                                </div>
                                                                <div className="col py-3 my-auto text-center text-lg-right d-none d-lg-block" onClick={formData.problems.length > 1 ? () => removeFromFormData('problems', i) : null}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                        <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                            <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                                <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                            </g>
                                                                            <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                                <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>



                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <h1>{generalData.get_care_settings.diagnosis_title}</h1>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <h6><span onClick={() => addToFormData('diagnosis')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>
                                            <div className="row py-4">
                                                <div className="col-12 col-lg-6 pb-2 pb-lg-0">
                                                    <h3>{generalData.get_care_settings.diagnosis_input_title}</h3>
                                                </div>
                                                <div className="col-12 col-lg-6 pl-lg-0 pb-2 pb-lg-0">
                                                    <h3>{generalData.get_care_settings.date_input_title}</h3>
                                                </div>
                                                <div className="col-12">
                                                    {formData.diagnosis.map((x, i) => {
                                                        return (
                                                            <div className="row align-items-center pb-4 pb-lg-0" key={i}>
                                                                <div className="col-11 col-lg-5 pb-2 py-lg-3 pr-lg-0 position-relative-inputs">
                                                                    <input className={errors['' + inputNameId('diagnosis', i, 'medical_diagnosis') + ''] ? 'red-errors-borders get-care-with-numbers' : 'get-care-with-numbers'} type="text" name="medical_diagnosis" {...inputProps('diagnosis', i, 'medical_diagnosis')} />
                                                                    <div className="forms-index-div">{i + 1}. </div>
                                                                    <div className="d-block d-lg-none x-button-forms" onClick={formData.diagnosis.length > 1 ? () => removeFromFormData('diagnosis', i) : null}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                            <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                                <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                                    <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                    <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                                </g>
                                                                                <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                                    <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                    <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 py-lg-3 d-none d-lg-block">
                                                                </div>
                                                                <div className="col-11 col-lg-5 py-lg-3 px-lg-0">
                                                                    <DatePicker maxDate={new Date()} placeholderText="YYYY-MM-DD" peekNextMonth
                                                                        dateFormat="yyyy-MM-dd"
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select" className={errors['' + inputNameId('diagnosis', i, 'date') + ''] ? 'red-errors-borders' : ''} {...dateProps('diagnosis', i, 'date')} />
                                                                    {/* <input className={errors['' + inputNameId('diagnosis', i, 'medical_diagnosis') + ''] ? 'red-errors-borders' : ''} type="date" name="date" {...inputProps('diagnosis', i, 'date')} /> */}
                                                                </div>
                                                                <div className="col py-3 my-auto text-center text-lg-right d-none d-lg-block" onClick={formData.diagnosis.length > 1 ? () => removeFromFormData('diagnosis', i) : null}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                        <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                            <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                                <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                            </g>
                                                                            <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                                <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <h1>{generalData.get_care_settings.surgeries_title}</h1>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <h6><span onClick={() => addToFormData('surgeries')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>
                                            <div className="row py-4">
                                                <div className="col-12">
                                                    <h3>{generalData.get_care_settings.surgeries_subtitle}</h3>
                                                </div>
                                                <div className="col-12 col-lg-6 pl-lg-0 pb-lg-2 d-none d-lg-block">
                                                </div>
                                                <div className="col-12 col-lg-6 pl-lg-0 pb-2 pb-lg-0 d-none d-lg-block">
                                                    <h3>{generalData.get_care_settings.date_input_title}</h3>
                                                </div>
                                                <div className="col-12">
                                                    {formData.surgeries.map((x, i) => {
                                                        return (
                                                            <div className="row align-items-center pb-4 pb-lg-0" key={i}>
                                                                <div className="col-11 col-lg-5 pb-2 py-lg-3 pr-lg-0">
                                                                    <input className={errors['' + inputNameId('surgeries', i, 'surgery') + ''] ? 'red-errors-borders get-care-with-numbers' : 'get-care-with-numbers'} type="text" name="surgery" {...inputProps('surgeries', i, 'surgery')} />
                                                                    <div className="forms-index-div">{i + 1}. </div>
                                                                    <div className="d-block d-lg-none x-button-forms" onClick={formData.surgeries.length > 1 ? () => removeFromFormData('surgeries', i) : null}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                            <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                                <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                                    <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                    <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                                </g>
                                                                                <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                                    <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                    <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 py-lg-3 d-none d-lg-block">
                                                                </div>
                                                                <div className="col-11 col-lg-5 py-lg-3 px-lg-0">
                                                                    <DatePicker maxDate={new Date()} placeholderText="YYYY-MM-DD" peekNextMonth
                                                                        dateFormat="yyyy-MM-dd"
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select" className={errors['' + inputNameId('surgeries', i, 'date') + ''] ? 'red-errors-borders clickable' : 'clickable'} {...dateProps('surgeries', i, 'date')} />
                                                                    {/* <input className={errors['' + inputNameId('surgeries', i, 'date') + ''] ? 'red-errors-borders' : ''} type="date" name="date" {...inputProps('surgeries', i, 'date')} /> */}
                                                                </div>
                                                                <div className="col py-3 my-auto text-center text-lg-right d-none d-lg-block" onClick={formData.surgeries.length > 1 ? () => removeFromFormData('surgeries', i) : null}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                        <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                            <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                                <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                            </g>
                                                                            <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                                <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <h1>{generalData.get_care_settings.medication_title}</h1>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <h6><span onClick={() => addToFormData('medications')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>
                                            <div className="row py-4">
                                                <div className="col-12">
                                                    <h3>{generalData.get_care_settings.medication_input_title}</h3>
                                                </div>
                                                <div className="col-12">
                                                    {formData.medications.map((x, i) => {
                                                        return (
                                                            <div className="row align-items-center py-2" key={i}>
                                                                <div className="col">
                                                                    <input className="get-care-with-numbers" type="text" name="medication" {...inputProps('medications', i, 'medication')} />
                                                                    <div className="forms-index-div-full">{i + 1}. </div>
                                                                </div>
                                                                <div className="col-auto my-auto text-right" onClick={formData.medications.length > 1 ? () => removeFromFormData('medications', i) : null}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                        <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                            <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                                <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                            </g>
                                                                            <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                                <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <h1>{generalData.get_care_settings.hospital_title}</h1>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <h6><span onClick={() => addToFormData('hospital_services')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 pb-5">
                                                    {formData.hospital_services.map((x, i) => {
                                                        return (
                                                            <div className="row pt-5 justify-content-end align-items-center" key={i}>
                                                                <div className="col">
                                                                    <div className="row">
                                                                        <div className="col-4 d-none d-lg-block">
                                                                            <h3>{generalData.get_care_settings.hospital_name}</h3>
                                                                        </div>
                                                                        <div className="col-4 d-none d-lg-block">
                                                                            <h3>{generalData.get_care_settings.hospital_address}</h3>
                                                                        </div>
                                                                        <div className="col-4 d-none d-lg-block">
                                                                            <h3>{generalData.get_care_settings.hospital_contact_info}</h3>
                                                                        </div>
                                                                        <div className="col-12 pb-2 d-lg-none">
                                                                            <h3>{generalData.get_care_settings.hospital_name}</h3>
                                                                        </div>
                                                                        <div className="col-12 col-lg-4 py-lg-3">
                                                                            <input type="text" name="hospital_name"  {...inputProps('hospital_services', i, 'hospital_name')} />
                                                                        </div>
                                                                        <div className="col-12 pt-3 pb-2 d-lg-none">
                                                                            <h3>{generalData.get_care_settings.hospital_address}</h3>
                                                                        </div>
                                                                        <div className="col-12 col-lg-4 py-lg-3">
                                                                            <input type="text" name="hospital_address" {...inputProps('hospital_services', i, 'address')} />
                                                                        </div>
                                                                        <div className="col-12 pt-3 pb-2 d-lg-none">
                                                                            <h3>{generalData.get_care_settings.hospital_contact_info}</h3>
                                                                        </div>
                                                                        <div className="col-12 col-lg-4 py-lg-3">
                                                                            <input type="text" name="contact_info"  {...inputProps('hospital_services', i, 'contact_info')} />
                                                                        </div>
                                                                        <div className="col-6 d-none d-lg-block">
                                                                            <h3>{generalData.get_care_settings.hospital_doctor_name}</h3>
                                                                        </div>
                                                                        <div className="col-6 d-none d-lg-block">
                                                                            <h3>{generalData.get_care_settings.hospital_doctor_contact}</h3>
                                                                        </div>
                                                                        <div className="col-12 pt-3 pb-2 d-lg-none">
                                                                            <h3>{generalData.get_care_settings.hospital_doctor_name}</h3>
                                                                        </div>
                                                                        <div className="col-12 col-lg-6 py-lg-3">
                                                                            <input type="text" name="doctor_name" {...inputProps('hospital_services', i, 'doctor_name')} />
                                                                        </div>
                                                                        <div className="col-12 pt-3 pb-2 d-lg-none">
                                                                            <h3>{generalData.get_care_settings.hospital_doctor_contact}</h3>
                                                                        </div>
                                                                        <div className="col-12 col-lg-6 py-lg-3">
                                                                            <input type="text" name="doctor_contact" {...inputProps('hospital_services', i, 'doctor_contact')} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-auto my-auto text-right" onClick={formData.hospital_services.length > 1 ? () => removeFromFormData('hospital_services', i) : null}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                        <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                            <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                                <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                            </g>
                                                                            <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                                <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <h1>{generalData.get_care_settings.concern_title}</h1>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <h6><span onClick={() => addToFormData('concerns')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>
                                            <div className="row py-4">
                                                <div className="col-12">
                                                    <h3>{generalData.get_care_settings.concern_subtitle}</h3>
                                                </div>
                                                <div className="col-12">
                                                    {formData.concerns.map((x, i) => {
                                                        return (
                                                            <div className="row" key={i}>
                                                                <div className="col py-3">
                                                                    <input className="get-care-with-numbers" type="text" name="concern" {...inputProps('concerns', i, 'concern')} />
                                                                    <div className="forms-index-div-full-2">{i + 1}. </div>
                                                                </div>
                                                                <div className="col-auto py-3 my-auto text-right" onClick={formData.concerns.length > 1 ? () => removeFromFormData('concerns', i) : null}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                        <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                            <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                                <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                            </g>
                                                                            <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                                <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-auto">
                                                    {checkbox === 0 ?
                                                        <div className="square-checkbox mt-2" onClick={() => setCheckbox(1)} />
                                                        :
                                                        <div className="square-checkbox mt-2 position-relative" onClick={() => setCheckbox(0)}>
                                                            <img src='/img/check.png' alt="tick" />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col" onClick={checkbox === 0 ? () => setCheckbox(1) : () => setCheckbox(0)}>
                                                    {<div className="content clickable" dangerouslySetInnerHTML={{ __html: generalData.get_care_settings.accept_text }}></div>}
                                                </div>
                                                <div id="checkboxDiv" className="col-12 help-block with-errors">{errors['checkbox'] ? 'You Must Approve the Terms' : ''}</div>
                                            </div>
                                            <div className="row py-5 text-lg-right text-center">
                                                <div className="col-12">
                                                    <button type="button" onClick={() => setFormCurrentPage(1)} className="home-buttons w-change ml-3 mb-2">{generalData.get_care_settings.previous_button}</button>
                                                    <button type="button" onClick={() => saveForm1()} className="home-buttons w-change ml-3 mb-2">{generalData.get_care_settings.save_title}</button>
                                                    <button type="button" onClick={() => setFormCurrentPage(3)} className="home-buttons w-change ml-3 mb-2">{generalData.get_care_settings.next_title}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                {formCurrentPage === 3 ?
                                    <div className="get-care-3">
                                        <div className="container py-5">
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    <h1>{generalData.get_care_settings.reports_title} <span>{generalData.get_care_settings.reports_small_desc}</span></h1>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <h6><span onClick={() => addToFormData('reports')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>
                                            {formData.reports.map((x, i) => {
                                                return (
                                                    <div className="row" key={i}>
                                                        <div className="col py-3">
                                                            <input className="input-left-with-index clickable" type="file" name="report" onChange={e => changeInput(e.target.files[0], 'reports', i, 'report_file')} />
                                                            <div className="forms-index-div-full-2">{i + 1}. </div>
                                                            {!formData.reports[i].report_file && formData?.reports[i]?.report?.name !== 'null' && x.report !== 'null' ?
                                                                <div className="file-name-placeholder d-flex justify-content-end justify-content-md-start">
                                                                    {
                                                                        formData?.reports[0]?.report?.name ?
                                                                            formData.reports[0].report.name
                                                                            :
                                                                            x.report
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }

                                                            {formData.reports[i]?.report_file?.name ?
                                                                <div className="file-name-placeholder d-flex justify-content-end justify-content-md-start">
                                                                    {
                                                                        formData.reports[i]?.report_file?.name
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="col-auto py-3 my-auto text-right" onClick={formData.reports.length > 1 ? () => removeFromFormData('reports', i) : null}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                    <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                        <circle cx="13" cy="13" r="13" stroke="none" />
                                                                        <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                    </g>
                                                                    <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                        <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                        <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }

                                            <div className="row align-items-center pt-4">
                                                <div className="col-8">
                                                    <h1>{generalData.get_care_settings.lab_results_title}</h1>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <h6><span onClick={() => addToFormData('lab_results')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>

                                            {formData.lab_results.map((x, i) => {
                                                return (
                                                    <div className="row" key={i}>
                                                        <div className="col py-3">
                                                            <input className="input-left-with-index clickable" type="file" name="report" onChange={e => changeInput(e.target.files[0], 'lab_results', i, 'report_file')} />
                                                            <div className="forms-index-div-full-2">{i + 1}. </div>
                                                            {!formData.lab_results[i].report_file && x.report !== 'null' && formData?.lab_results[i]?.report?.name !== 'null' ?
                                                                <div className="file-name-placeholder d-flex justify-content-end justify-content-md-start">
                                                                    {
                                                                        formData?.lab_results[0]?.report?.name ?
                                                                            formData.lab_results[0].report.name
                                                                            :
                                                                            x.report
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            {formData.lab_results[i]?.report_file?.name ?
                                                                <div className="file-name-placeholder d-flex justify-content-end justify-content-md-start">
                                                                    {
                                                                        formData.lab_results[i]?.report_file?.name
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="col-auto py-3 my-auto text-right" onClick={formData.lab_results.length > 1 ? () => removeFromFormData('lab_results', i) : null}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                    <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                        <circle cx="13" cy="13" r="13" stroke="none" />
                                                                        <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                    </g>
                                                                    <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                        <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                        <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }

                                            <div className="row align-items-center pt-4">
                                                <div className="col-8">
                                                    <h1>{generalData.get_care_settings.radiology_title}</h1>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <h6><span onClick={() => addToFormData('radiology')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>

                                            {formData.radiology.map((x, i) => {
                                                return (
                                                    <div className="row" key={i}>
                                                        <div className="col py-3">
                                                            <input className="input-left-with-index clickable" type="file" name="report" onChange={e => changeInput(e.target.files[0], 'radiology', i, 'report_file')} />
                                                            <div className="forms-index-div-full-2">{i + 1}. </div>
                                                            {!formData.radiology[i].report_file && formData?.radiology[i]?.report?.name !== 'null' && x.report !== 'null' ?
                                                                <div className="file-name-placeholder d-flex justify-content-end justify-content-md-start">
                                                                    {
                                                                        formData?.radiology[0]?.report?.name ?
                                                                            formData.radiology[0].report.name
                                                                            :
                                                                            x.report
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            {formData.radiology[i].report_file?.name ?
                                                                <div className="file-name-placeholder d-flex justify-content-end justify-content-md-start">
                                                                    {
                                                                        formData.radiology[i].report_file?.name
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="col-auto py-3 my-auto text-right" onClick={formData.radiology.length > 1 ? () => removeFromFormData('radiology', i) : null}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                    <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                        <circle cx="13" cy="13" r="13" stroke="none" />
                                                                        <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                    </g>
                                                                    <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                        <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                        <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                            <div className="row align-items-center pt-4">
                                                <div className="col-8">
                                                    <h1>{generalData.get_care_settings.hispathology_title}</h1>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <h6><span onClick={() => addToFormData('histopathology')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>

                                            {formData.histopathology.map((x, i) => {
                                                return (
                                                    <div className="row" key={i}>
                                                        <div className="col py-3">
                                                            <input className="input-left-with-index clickable" type="file" name="report" onChange={e => changeInput(e.target.files[0], 'histopathology', i, 'report_file')} />
                                                            <div className="forms-index-div-full-2">{i + 1}. </div>
                                                            {!formData.histopathology[i].report_file && formData.histopathology[i].report?.name !== 'null' && x.report !== 'null' ?
                                                                <div className="file-name-placeholder d-flex justify-content-end justify-content-md-start">
                                                                    {
                                                                        formData?.histopathology[0]?.report?.name ?
                                                                            formData.histopathology[0].report.name
                                                                            :
                                                                            x.report
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            {formData.histopathology[i].report_file?.name ?
                                                                <div className="file-name-placeholder d-flex justify-content-end justify-content-md-start">
                                                                    {
                                                                        formData.histopathology[i].report_file?.name
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="col-auto py-3 my-auto text-right" onClick={formData.histopathology.length > 1 ? () => removeFromFormData('histopathology', i) : null}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                    <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                        <circle cx="13" cy="13" r="13" stroke="none" />
                                                                        <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                    </g>
                                                                    <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                        <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                        <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }

                                            <div className="row align-items-center pt-4">
                                                <div className="col-8">
                                                    <h1>{generalData.get_care_settings.investigations_title}</h1>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <h6><span onClick={() => addToFormData('investigations')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>

                                            {formData.investigations.map((x, i) => {
                                                return (
                                                    <div className="row" key={i}>
                                                        <div className="col py-3">
                                                            <input className="input-left-with-index clickable" type="file" name="report" onChange={e => changeInput(e.target.files[0], 'investigations', i, 'report_file')} />
                                                            <div className="forms-index-div-full-2">{i + 1}. </div>
                                                            {!formData.investigations[i].report_file && formData?.investigations[i]?.report?.name !== 'null' && x.report !== 'null' ?
                                                                <div className="file-name-placeholder d-flex justify-content-end justify-content-md-start">
                                                                    {
                                                                        formData?.investigations[0]?.report?.name ?
                                                                            formData.investigations[0].report.name
                                                                            :
                                                                            x.report
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            {formData.investigations[i].report_file?.name ?
                                                                <div className="file-name-placeholder d-flex justify-content-end justify-content-md-start">
                                                                    {
                                                                        formData.investigations[i].report_file?.name
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="col-auto py-3 my-auto text-right" onClick={formData.investigations.length > 1 ? () => removeFromFormData('investigations', i) : null}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                    <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                        <circle cx="13" cy="13" r="13" stroke="none" />
                                                                        <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                    </g>
                                                                    <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                        <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                        <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }

                                            <div className="row align-items-center pt-4">
                                                <div className="col-8">
                                                    <h1>{generalData.get_care_settings.genetic_title}</h1>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <h6><span onClick={() => addToFormData('genetics')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>

                                            {formData.genetics.map((x, i) => {
                                                return (
                                                    <div className="row" key={i}>
                                                        <div className="col py-3">
                                                            <input className="input-left-with-index clickable" type="file" name="report" onChange={e => changeInput(e.target.files[0], 'genetics', i, 'report_file')} />
                                                            <div className="forms-index-div-full-2">{i + 1}. </div>
                                                            {!formData.genetics[i].report_file && formData?.genetics[i]?.report?.name !== 'null' && x.report !== 'null' ?
                                                                <div className="file-name-placeholder d-flex justify-content-end justify-content-md-start">
                                                                    {
                                                                        formData?.genetics[0]?.report?.name ?
                                                                            formData.genetics[0].report.name
                                                                            :
                                                                            x.report
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            {formData.genetics[i].report_file?.name ?
                                                                <div className="file-name-placeholder d-flex justify-content-end justify-content-md-start">
                                                                    {
                                                                        formData.genetics[i].report_file?.name
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="col-auto py-3 my-auto text-right" onClick={formData.genetics.length > 1 ? () => removeFromFormData('genetics', i) : null}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                    <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                        <circle cx="13" cy="13" r="13" stroke="none" />
                                                                        <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                    </g>
                                                                    <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                        <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                        <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }

                                            <div className="row align-items-center pt-4">
                                                <div className="col-8">
                                                    <h1>{generalData.get_care_settings.others_title}</h1>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <h6><span onClick={() => addToFormData('others')}>{generalData.get_care_settings.add_more_button}</span></h6>
                                                </div>
                                                <div className="col-12">
                                                    <div className="full-w-line-forms w-100" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    {formData.others.map((x, i) => {
                                                        return (
                                                            <div className="row align-items-center pt-2" key={i}>
                                                                <div className="col">
                                                                    <div className="row no-gutters w-100">
                                                                        <div className="col-12 col-md-6 py-2 pr-0 pr-md-5">
                                                                            <h1>{generalData.get_care_settings.others_name_title}</h1>
                                                                            <input type="text" name="file_name" {...inputProps('others', i, 'file_name')} />
                                                                        </div>
                                                                        <div className="col-12 col-md-6 py-2 pl-0 pl-md-5">
                                                                            <h1>{generalData.get_care_settings.others_attach_title}</h1>
                                                                            <input className="input-left-pad clickable" type="file" name="report" onChange={e => changeInput(e.target.files[0], 'others', i, 'report_file')} />
                                                                            {!formData.others[i].report_file && formData.others[0].report?.name !== 'null' && x.report !== 'null' ?
                                                                                <div className="file-name-placeholder-small d-flex justify-content-end justify-content-md-start">
                                                                                    {
                                                                                        formData?.others[0]?.report?.name ?
                                                                                            formData.others[0].report.name
                                                                                            :
                                                                                            x.report
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                            {formData.others[i].report_file?.name ?
                                                                                <div className="file-name-placeholder-small d-flex justify-content-start">
                                                                                    {
                                                                                        formData.others[i].report_file?.name
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-auto py-2 my-auto text-right" onClick={formData.others.length > 1 ? () => removeFromFormData('others', i) : null}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                                                        <g id="Group_416" data-name="Group 416" transform="translate(-573.758 -529.758)">
                                                                            <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(573.758 529.758)" fill="#fff" stroke="#25d6ff" strokeWidth="1">
                                                                                <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                            </g>
                                                                            <g id="Group_240" data-name="Group 240" transform="translate(578.633 542.494) rotate(-45)">
                                                                                <line id="Line_100" data-name="Line 100" y2="12.348" transform="translate(5.597 0)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                                <line id="Line_101" data-name="Line 101" x2="11.602" transform="translate(0 6.174)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                            <div className="row py-5 text-lg-right text-center">
                                                <div className="col-12">
                                                    <button type="button" onClick={() => setFormCurrentPage(2)} className="home-buttons w-change ml-3 mb-2" >{generalData.get_care_settings.previous_button}</button>
                                                    <button type="button" onClick={() => saveForm1()} className="home-buttons w-change ml-3 mb-2">{generalData.get_care_settings.save_title}</button>
                                                    <button type="button" onClick={() => submit()} className="home-buttons w-change ml-3 mb-2">{generalData.get_care_settings.submit_button}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </form>
                            {formSuccess ?
                                <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                    <div className="pop-up-api-box">
                                        <svg className="close-svg" onClick={() => setFormSuccess(false)} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                            <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                                <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                            </g>
                                        </svg>
                                        <div className="content background-light text-center px-5 py-5">
                                            <p className="my-2">{generalData.get_care_settings.success_message}</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {saveSuccess ?
                                <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                    <div className="pop-up-api-box">
                                        <svg className="close-svg" onClick={() => setSaveSuccess(false)} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                            <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                                <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                            </g>
                                        </svg>
                                        <div className="content background-light text-center px-5 py-5">
                                            <p className="my-2">{generalData.get_care_settings.save_success}</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                        :
                        <div className="loader-forms">
                            <div className="home-loader lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                }
                <Api options={getCareData} />
                <Api options={submitApiOptions} />
            </div>
        </>
    );

}
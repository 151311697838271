import { useState, useContext, useEffect } from 'react';
// import dateFormat from 'dateformat';
import moment from 'moment';
import Api from './Api';
import GlobalState from '../contexts/GlobalState';
import Meetings from './Meetings';
import { dateFormat } from '../Helpers';
import { Link } from 'react-router-dom';

export default function Notification(props) {

    const { authUserGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [authUser] = authUserGlobal;
    const [meetingSmallPopup, setMeetingSmallPopup] = useState(false);
    const [acceptMeeting, setAcceptMeeting] = useState();
    const [meetingInfo, setMeetingInfo] = useState();
    const [meetingPopup, setMeetingPopup] = useState(false);


    useEffect(() => {
        if (props.notificationInfo?.get_care_info_data?.upcoming_meetings?.length > 0) {
            props.notificationInfo?.get_care_info_data?.upcoming_meetings?.map((singleMeeting) => (
                singleMeeting.doctors?.length > 0 ?
                    singleMeeting.doctors?.map((singleDoctor) => (
                        singleMeeting.id === props.notificationInfo?.task_id && singleDoctor.id === authUser.id && singleDoctor?.pivot?.accept_meeting !== 1 ?
                            <>{(setAcceptMeeting(singleMeeting),
                                setMeetingInfo(singleMeeting))}</>
                            :
                            singleMeeting.id === props.notificationInfo?.task_id && singleDoctor.id === authUser.id ?
                                setMeetingInfo(singleMeeting)
                                :
                                null
                    ))
                    :
                    null
            ));
        }
        if (props.notificationInfo?.get_care_info_data?.upcoming_meetings?.length > 0) {
            props.notificationInfo?.get_care_info_data?.upcoming_meetings?.map((singleMeeting) => (
                singleMeeting.id === props.notificationInfo?.task_id && singleMeeting.doctor_id === authUser.id ?
                    setMeetingInfo(singleMeeting)
                    :
                    null
            ));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const closePop = () => {
        setMeetingPopup(!meetingPopup);
    };


    const acceptMeetingClick = (event) => {
        setGeneralDataApiOptions({
            url: 'accept-meeting-request',
            method: 'post',
            data: {
                meeting_id: event.id,
            },
            callback: res => {
                setMeetingSmallPopup(!meetingSmallPopup);
                setAcceptMeeting();
            }
        })
    };


    const eventsClicked = (event) => {
        setMeetingSmallPopup(!meetingSmallPopup);
        // setAcceptMeeting(event);
    };

    return (
        <>

            <Link style={{ textDecoration: 'none' }} to={authUser.user_types_id === 1 ? '/doctor-records/doctor-single-record/' + props.notificationInfo?.get_care_info_data?.email + '/' + props.notificationInfo?.get_care_info_data.user_token : '/user-records/user-single-record/' + props.notificationInfo?.get_care_info_data?.email + '/' + props.notificationInfo?.get_care_info_data.user_token}>
                <div className={props.white === true ? 'border-dashboard-boxes d-flex flex-row mt-3' : 'border-dashboard-boxes fill-dark d-flex flex-row mt-3'}>
                    <div className="col-12">
                        <div className="row p-3">
                            <div className="col-12 col-sm-auto text-center text-md-start p-0">
                                {
                                    props.notificationInfo?.notification_type?.slug === 'case-assigned' && !props.white ?
                                        <img className="notification-user-image" src={'/img/no-pic.jpg'} alt="User"/>
                                        :
                                        <img className="notification-user-image" alt="User" src={props.notificationInfo?.user?.image ? props.notificationInfo?.user?.full_path_image : '/img/no-pic.jpg'} />
                                }
                            </div>
                            <div className="col-auto py-1">
                                <h4 className="notification-user-name m-0">
                                    {props.notificationInfo?.notification_type?.slug === 'case-assigned' ? 'Admin' :
                                        props.notificationInfo?.user_id === null ? 'YOU' :
                                            props.notificationInfo?.user?.first_name && props.notificationInfo?.user?.last_name ? props.notificationInfo?.user?.first_name + ' ' + props.notificationInfo?.user?.last_name : props.notificationInfo?.user?.name}
                                    {
                                        props.notificationInfo?.notification_type?.slug === 'request-documents' ?
                                            <>
                                                <span className="notification-text m-0"> requested a lab test on your</span>
                                                <span className="notification-user-name"> Case#{props.notificationInfo?.get_care_info_data_id}</span>
                                            </>
                                            :
                                            null
                                    }
                                </h4>
                                {
                                    props.notificationInfo?.notification_type?.slug === 'new-meeting' || props.notificationInfo?.notification_type?.slug === 'reschedule-meeting' ?
                                        <h3 className="notification-text m-0">{props.notificationInfo?.notification_type?.slug === 'reschedule-meeting' ? 'Rescheduled The Appointment ' : 'Appointment Request for'} <span className="notification-user-name">Case#{props.notificationInfo?.get_care_info_data_id}</span>{props.notificationInfo?.notification_type?.slug === 'reschedule-meeting' ? ' To ' : ' On '}
                                            <span className="notification-user-name">
                                                {props.notificationInfo?.get_care_info_data?.previous_meetings.map((singleMeeting) => (
                                                    singleMeeting.id === props.notificationInfo?.task_id ?
                                                        dateFormat(singleMeeting['start_date'], "d mmmm yyyy")
                                                        :
                                                        null
                                                )
                                                )}
                                                {props.notificationInfo?.get_care_info_data?.upcoming_meetings.map((singleMeeting) => (
                                                    singleMeeting.id === props.notificationInfo?.task_id ?
                                                        dateFormat(singleMeeting['start_date'], "d mmmm yyyy")
                                                        :
                                                        null
                                                )
                                                )}</span> at <span className="notification-user-name">
                                                {props.notificationInfo?.get_care_info_data?.upcoming_meetings.map((singleMeeting) => (
                                                    singleMeeting.id === props.notificationInfo?.task_id ?
                                                        moment(singleMeeting.start_date).format("h:mm a")
                                                        :
                                                        null
                                                )
                                                )}
                                                {props.notificationInfo?.get_care_info_data?.previous_meetings.map((singleMeeting) => (
                                                    singleMeeting.id === props.notificationInfo?.task_id ?
                                                        moment(singleMeeting.start_date).format("h:mm a")
                                                        :
                                                        null
                                                )
                                                )}
                                            </span></h3>
                                        :
                                        null
                                }
                                {
                                    props.notificationInfo?.notification_type?.slug === 'request-documents' ?
                                        <>
                                            {props.notificationInfo?.get_care_info_data?.doc_lab_results?.map((singleResult) => (
                                                singleResult.id === props.notificationInfo?.task_id ?
                                                    <h3 className="notification-text m-0"> requested {singleResult.request_title}</h3>
                                                    :
                                                    null
                                            )
                                            )}

                                        </>
                                        :
                                        null
                                }
                                {
                                    props.notificationInfo?.notification_type?.slug === 'close-case' ?
                                        <>
                                            {props.notificationInfo?.get_care_info_data?.final_report ?
                                                <h3 className="notification-text m-0"> <span className="green-notification">Submitted</span> his final report to <span className="notification-user-name"> Case#{props.notificationInfo?.get_care_info_data_id}</span></h3>
                                                :
                                                null

                                            }
                                        </>
                                        :
                                        null
                                }
                                {
                                    props.notificationInfo?.notification_type?.slug === 'upload-documents' ?
                                        <>
                                            {props.notificationInfo?.get_care_info_data?.doc_lab_results?.map((singleResult) => (
                                                singleResult.id === props.notificationInfo?.task_id ?
                                                    <h3 className="notification-text m-0"> Uploaded The {singleResult.request_title} Requested By <span className="notification-user-name">{
                                                        singleResult?.doctor?.id === authUser?.id ? 'YOU' :
                                                            singleResult?.doctor?.first_name && singleResult?.doctor?.last_name ? singleResult?.doctor?.first_name + ' ' + singleResult?.doctor?.last_name : singleResult?.doctor?.name ? singleResult?.doctor?.name : ''}</span></h3>
                                                    :
                                                    null
                                            )
                                            )}

                                        </>
                                        :
                                        null
                                }

                                {/* {
                                    props.notificationInfo?.notification_type?.slug === 'upload-documents' ?
                                        <>
                                            {props.notificationInfo?.get_care_info_data?.doc_lab_results?.map((singleResult) => (
                                                singleResult.id === props.notificationInfo?.task_id ?
                                                    <h3 className="notification-text m-0"> Uploaded The {singleResult.request_title} Requested By <span className="notification-user-name">{
                                                        singleResult?.doctor?.id === authUser?.id ? 'YOU' :
                                                            singleResult?.doctor?.first_name && singleResult?.doctor?.last_name ? singleResult?.doctor?.first_name + ' ' + singleResult?.doctor?.last_name : singleResult?.doctor?.name ? singleResult?.doctor?.name : ''}</span></h3>
                                                    :
                                                    null
                                            )
                                            )}

                                        </>
                                        :
                                        null
                                } */}

                                {
                                    props.notificationInfo?.notification_type?.slug === 'payment-request' ?
                                        <>
                                            <h3 className="notification-text m-0">Requested a new Payment on Case <span className="notification-user-name">{props.notificationInfo?.get_care_info_data_id}</span> </h3>
                                        </>
                                        :
                                        null
                                }
                                {
                                    props.notificationInfo?.notification_type?.slug === 'paid-payment' ?
                                        <>
                                            <h3 className="notification-text m-0">Setteled the requested payment for Case <span className="notification-user-name">{props.notificationInfo?.get_care_info_data_id}</span> </h3>
                                        </>
                                        :
                                        null
                                }


                                {
                                    props.notificationInfo?.notification_type?.slug === 'add-doctor' ?
                                        <>
                                            {props.notificationInfo?.get_care_info_data?.users?.map((singleResult) => (
                                                singleResult.id === props.notificationInfo?.task_id ?
                                                    <h3 className="notification-text m-0">Added <span className="notification-user-name">{singleResult?.id === authUser?.id ? 'YOU' : singleResult?.first_name && singleResult?.last_name ? singleResult?.first_name + ' ' + singleResult?.last_name : singleResult?.name ? singleResult?.name : ''}</span> To the <span className="notification-user-name"> Case#{props.notificationInfo?.get_care_info_data_id} </span></h3>
                                                    :
                                                    null
                                            )
                                            )}

                                        </>
                                        :
                                        null
                                }

                                {
                                    props.notificationInfo?.notification_type?.slug === 'case-assigned' ?
                                        <h3 className="notification-text m-0">Admin assigned <span className="notification-user-name">
                                            {
                                                props.notificationInfo?.user_id === null && authUser.user_types_id === 1 ? 'YOU' :
                                                    props.notificationInfo?.get_care_info_data?.doctor?.first_name && props.notificationInfo?.get_care_info_data?.doctor?.last_name ?
                                                        props.notificationInfo?.get_care_info_data?.doctor?.first_name + ' ' + props.notificationInfo?.get_care_info_data?.doctor?.last_name
                                                        :
                                                        props.notificationInfo?.get_care_info_data?.doctor?.name ?
                                                            props.notificationInfo?.get_care_info_data?.doctor?.name
                                                            :
                                                            ''
                                            }
                                        </span> To the <span className="notification-user-name"> Case#{props.notificationInfo?.get_care_info_data_id} </span></h3>
                                        :
                                        null
                                }
                            </div>
                            {props.notificationInfo?.notification_type?.slug === 'new-meeting' || props.notificationInfo?.notification_type?.slug === 'reschedule-meeting' ?
                                <div className="col justify-content-center justify-content-xl-end d-flex my-auto">
                                    {acceptMeeting && !props.white ?
                                        <button onClick={(ev) => {
                                            ev.preventDefault();
                                            eventsClicked(props.notificationInfo?.notification_type?.task_id);
                                        }} className="home-buttons p-0 px-3 mr-2 font-size-14">ACCEPT</button>
                                        :
                                        !props.white ?
                                            <a onClick={(e) => e.stopPropagation()} href={(props.notificationInfo?.get_care_info_data?.upcoming_meetings.map((singleMeeting) => (
                                                singleMeeting.id === props.notificationInfo?.task_id ?
                                                    singleMeeting.link : null))).toString().replace(/,/g, '')} target="_blank" rel="noreferrer">
                                                <button type="button" className="home-buttons p-0 px-3 mr-2 font-size-14">JOIN</button>
                                            </a>
                                            :
                                            null
                                    }
                                    {
                                        meetingInfo && !props.white ?
                                            <button type="button" className="home-buttons p-0 px-3 font-size-14" onClick={(ev) => {
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                                setMeetingPopup(!meetingPopup);
                                            }}>RESCHEDULE</button>
                                            :
                                            null
                                    }
                                </div>
                                :
                                null
                            }

                            {props.notificationInfo?.notification_type?.slug === 'close-case' && !props.white ?
                                <div className="col justify-content-center justify-content-xl-end d-flex my-auto">
                                    <a target="_blank" rel="noreferrer" href={props.notificationInfo?.get_care_info_data?.final_report?.full_path_final_report} className="home-buttons p-0 px-3 mr-0 mr-sm-2 mb-2 mb-sm-0 font-size-14">SEE REPORT</a>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    {meetingPopup ?
                        <Meetings notif={true} meeting={false} meetingPopup={() => closePop()} singleMeetingInfo={meetingInfo} />
                        :
                        null
                    }
                    {meetingSmallPopup ?
                        <div id="errors-popup" onClick={(ev) => {
                            ev.preventDefault();
                        }} className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <svg className="close-svg" onClick={() => setMeetingSmallPopup(!meetingSmallPopup)} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                    <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                        <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                        <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                    </g>
                                </svg>
                                <div className="content background-light px-5 py-5 text-center">
                                    {acceptMeeting ?
                                        <>
                                            <h2 className="accept-meeting-title m-0">{acceptMeeting?.subject}</h2>
                                            {/* <h3 className="m-0 my-auto meeting-admin-labelnull ">{dateFormat(moment.utc(acceptMeeting?.start_date).add(-acceptMeeting?.time_zone, 'hours').local(), "d mmmm yyyy")}</h3> */}
                                            <h3 className="m-0 my-auto meeting-admin-label">{moment.utc(acceptMeeting?.start_date).add(-acceptMeeting?.time_zone, 'hours').local().format("h:mm a")} - {moment.utc(acceptMeeting?.start_date).add(-acceptMeeting?.time_zone + acceptMeeting?.meeting_duration, 'hours').local().format("h:mm a")}</h3>
                                            <div className="row py-3 text-left">
                                                <div className="col-12">
                                                    <div className="row no-gutters">
                                                        <div className="col-auto">
                                                            <img className="meetings-doctor-image" src={acceptMeeting?.doctor?.image ? acceptMeeting?.doctor?.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                        </div>
                                                        <div className="col pl-2">
                                                            <h2 className="accept-meeting-title m-0">{acceptMeeting?.doctor?.name ? acceptMeeting?.doctor?.name : ''}</h2>
                                                            <h3 className="meeting-admin-label">Organizer</h3>
                                                        </div>
                                                    </div>
                                                    {acceptMeeting?.doctors.map((singleDoctor) => (
                                                        acceptMeeting?.doctor?.id !== singleDoctor?.id ?
                                                            <div className="row no-gutters py-1">
                                                                <div className="col-auto">
                                                                    <img className="meetings-doctor-image" src={singleDoctor?.image ? singleDoctor?.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                </div>
                                                                <div className="col pl-2">
                                                                    <h2 className="accept-meeting-title m-0">{singleDoctor?.name ? singleDoctor?.name : ''}</h2>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    ))
                                                    }
                                                </div>
                                            </div>
                                            <button onClick={(ev) => {
                                                acceptMeetingClick(acceptMeeting);
                                            }} className="home-buttons py-0 px-3 font-size-14">Accept</button>
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    <Api options={generalDataApiOptions} />
                </div>
            </Link>

        </>
    );
}
import React, { useContext, useEffect } from 'react';
import Layout from '../layouts/Main';
import DarkBanner from '../components/DarkBanner';
import GlobalState from "../contexts/GlobalState";
import { Helmet } from "react-helmet";
import GoogleAnalytics from '../components/GoogleAnalytics';

export default function AboutUs(props) {


    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;


    useEffect(() => {
        window.triggerScroll();
    }, [generalData]);


    return (

        <Layout activePage="about-us" whiteMenu="false">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['about-us'].title}</title>
                    <meta name="title" content={generalData?.seo['about-us']?.title} />
                    <meta name="description" content={generalData?.seo['about-us']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['about-us'].title} />
                    <meta property="og:description" content={generalData?.seo['about-us'].description} />
                    <meta property="og:image" content={generalData?.seo['about-us'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['about-us'].title} />
                    <meta property="twitter:description" content={generalData?.seo['about-us'].description} />
                    <meta property="twitter:image" content={generalData?.seo['about-us'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <DarkBanner>{generalData.about_us_settings.page_title}</DarkBanner>
            <div className="about-us-sec1">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-9 col-lg-6 pt-5 pb-2" animate="left">
                            <h1>{generalData.about_us_settings.section1_title}</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" width="109" height="76.028" viewBox="0 0 109 76.028">
                                <g id="Group_568" data-name="Group 568" transform="translate(-185.838 -164.39)">
                                    <g id="Group_566" data-name="Group 566" transform="translate(256.879 187.062)">
                                        <path id="Path_178" data-name="Path 178" d="M227.107,221.443a8.286,8.286,0,0,1-7.163-4.115l-21.4-36.791a8.28,8.28,0,0,1,14.314-8.329L234.258,209a8.279,8.279,0,0,1-7.151,12.444Z" transform="translate(-197.422 -168.087)" fill="#25d6ff" />
                                    </g>
                                    <g id="Group_567" data-name="Group 567" transform="translate(185.838 164.39)">
                                        <path id="Path_179" data-name="Path 179" d="M257.4,240.418a8.284,8.284,0,0,1-7.182-4.146l-30.9-53.657a3.347,3.347,0,0,0-2.042-1.539,3.407,3.407,0,0,0-3.833,5l13.922,23.918a19.968,19.968,0,0,1-34.442,20.214l-5.814-9.218a8.281,8.281,0,1,1,14.014-8.825l5.949,9.438a3.6,3.6,0,0,0,2.208,1.8,3.449,3.449,0,0,0,2.582-.362,3.406,3.406,0,0,0,1.227-4.655L199.114,194.36a19.966,19.966,0,1,1,34.577-19.975L264.571,228a8.279,8.279,0,0,1-7.169,12.413Z" transform="translate(-185.838 -164.39)" fill="#25d6ff" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className="row pb-lg-5">
                        <div className="col-12 col-lg-5 order-lg-2 py-3" animate="right">
                            <div className="image-box-sec1">
                                <img src={generalData.about_us_settings.section1_image} alt="section-1" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-7 py-3" animate="left">
                            {<div className="content" dangerouslySetInnerHTML={{ __html: generalData.about_us_settings.section1_text }}></div>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-us-sec2">
                <div className="container py-5">
                    <div className="row py-lg-5">
                        <div className="col-12 col-lg-7">
                            <h1 animate="right">{generalData.about_us_settings.approach_title}</h1>
                        </div>
                        <div className="col-12 pt-3" animate="left">
                            {<div className="content" dangerouslySetInnerHTML={{ __html: generalData.about_us_settings.approach_text }}></div>}
                        </div>
                    </div>
                    <div className="row pb-lg-5">
                        <div className="col-12 col-lg-5 py-3 order-lg-3">
                            <div className="image-box-sec2" animate="left">
                                <img src={generalData.about_us_settings.plan_image} alt="plan-section" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-9">
                            <h1 animate="left">{generalData.about_us_settings.plan_title}</h1>
                        </div>
                        <div className="col-12 col-lg-7 py-3" animate="right">
                            {<div className="content" dangerouslySetInnerHTML={{ __html: generalData.about_us_settings.plan_text }}></div>}
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    );
}
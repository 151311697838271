import React, { useContext, useEffect, useState } from 'react';
import Layout from '../layouts/Main';
import DarkBanner from '../components/DarkBanner';
import GlobalState from "../contexts/GlobalState";
import Api from "../components/Api";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import DatePicker from "react-datepicker";
import GoogleAnalytics from '../components/GoogleAnalytics';

import { Helmet } from "react-helmet";
import "react-datepicker/dist/react-datepicker.css";



export default function ContactUs(props) {


    const [name, setName] = useState('');
    const [, setDateOfBirth] = useState('');
    const [email, setEmail] = useState('');
    const [value, setValue] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [startDate, setStartDate] = useState();
    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;


    useEffect(() => {
        window.triggerScroll();
    }, [generalData, value]);

    const submitContactUs = (e) => {
        e.preventDefault();

        setGeneralDataApiOptions({
            url: 'contact-us-data',
            method: 'post',
            data: {
                name: name,
                date_of_birth: startDate,
                email: email,
                phone_number: value,
                message: message,
            },
            callback: res => {
                setSuccessMessage(true);
                setEmail('');
                setName('');
                setMessage('');
                setValue('');
                setDateOfBirth('');
                setStartDate('');
            }
        })
    }

    return (

        <Layout activePage="contact-us" whiteMenu="false">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['contact-us'].title}</title>
                    <meta name="title" content={generalData?.seo['contact-us']?.title} />
                    <meta name="description" content={generalData?.seo['contact-us']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['contact-us'].title} />
                    <meta property="og:description" content={generalData?.seo['contact-us'].description} />
                    <meta property="og:image" content={generalData?.seo['contact-us'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['contact-us'].title} />
                    <meta property="twitter:description" content={generalData?.seo['contact-us'].description} />
                    <meta property="twitter:image" content={generalData?.seo['contact-us'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <DarkBanner>{generalData.conact_us_settings.page_title}</DarkBanner>
            <div className="contact-us">
                <div className="container py-5">
                    <div className="row py-lg-5">
                        <form onSubmit={submitContactUs} autoComplete="off" className="w-100">
                            <div className="col-12">
                                <h1>{generalData.conact_us_settings.form_title}</h1>
                            </div>
                            <div className="col-12 pt-3 pt-lg-4">
                                <h3>{generalData.conact_us_settings.name_title}</h3>
                                <input onChange={e => setName(e.target.value)} value={name} name="name" type="text" />
                            </div>
                            <div className="col-12 pt-3 pt-lg-4">
                                <h3>{generalData.conact_us_settings.date_of_birth_title}</h3>
                                <DatePicker className="clickable" maxDate={new Date()} placeholderText="MM/DD/YYYY" peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select" selected={startDate} onChange={(date) => setStartDate(date)} />
                            </div>
                            <div className="col-12 pt-3 pt-lg-4">
                                <h3>{generalData.conact_us_settings.email_title}</h3>
                                <input onChange={e => setEmail(e.target.value)} value={email} name="email" type="text" />
                            </div>
                            <div className="col-12 pt-3 pt-lg-4">
                                <h3>{generalData.conact_us_settings.phone_number_title}</h3>
                                <PhoneInput international defaultCountry="GB" value={value} onChange={setValue} name="phone_number" type="text" className="phone-input-style" />
                            </div>
                            <div className="col-12 pt-3 pt-lg-4">
                                <h3>{generalData.conact_us_settings.message_title}</h3>
                                <textarea onChange={e => setMessage(e.target.value)} value={message} name="message" type="text" />
                            </div>
                            <div className="col-12 text-lg-right text-center pt-5">
                                <button type="submit" className="home-buttons">{generalData.conact_us_settings.button_title}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {successMessage ?
                <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                    <div className="pop-up-api-box">
                        <svg className="close-svg" onClick={() => setSuccessMessage(false)} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                            <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                            </g>
                        </svg>
                        <div className="content background-light text-center px-5 py-5">
                            <h1>{generalData.conact_us_settings.success_title}</h1>
                            <p className="my-2">{generalData.conact_us_settings.success_message}</p>
                        </div>
                    </div>
                </div>
                :
                null
            }
            <Api options={generalDataApiOptions} />
        </Layout>
    );
}
import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import dateFormat from 'dateformat';


export default function DoctorViewProfile(props) {
    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions] = useState({});
    const [loading] = useState(false);
    const [authUser] = authUserGlobal;
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [getCareCases, setGetCareCases] = useState();
    const [totalOutstanding,setTotalOutstanding] = useState();
    const [doctorCourses,setDoctorCourses] = useState({});

    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);

    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading]);

    useEffect(() => {
        axios.get('get-doctor-courses')
        .then((r) => {
            setDoctorCourses(r.data)
        })
        .finally(() => {
        });
    }, []);

    useEffect(() => {
        axios.get('get-doctor-total')
            .then((r) => {
                setTotalOutstanding(r.data)
            })
            .finally(() => {
            });
    }, []);

    useEffect(() => {
        axios.get('get-care-count-doctor/' + authUser.id)
            .then((r) => {
                setGetCareCases(r.data)
            })
            .finally(() => {
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
    }, []);

    return (
        <>
            <UserLayout
                darkMenu={true} />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid view-user-profile equal-spacing-cont" animate="right">
                    <div className="row">
                        <div className="col-12 col-md-6 pt-3 w-100">
                            <p className="dashboard-title" animate="left">{generalData.doctor_profile_settings.page_title}</p>
                        </div>
                        <div className="col-12">
                            <div className="border-dashboard-boxes fill-dark h-100">
                                <div className="py-4 px-3">
                                    <div className="row justify-content-center">
                                        <div className="col-12 text-right pb-3 d-block d-lg-none pr-4">
                                            <Link to={'/doctor-edit-profile'} className="view-profile-edit">Edit</Link>
                                        </div>
                                        <div className="col-12 col-lg-auto text-center">
                                            <img className="user-single-records-image view-profile-image mx-auto d-block" src={authUser.image ? authUser.image : '/img/no-pic.jpg'} alt="user-profile" />
                                            {
                                                authUser.first_name ?

                                                    <h2 className="view-user-profile-username">{authUser.first_name} {authUser.last_name ? authUser.last_name : null}</h2>
                                                    :
                                                    null
                                            }
                                        </div>

                                        <div className="col-12 col-lg h-100">
                                            <div className="row flex-start">
                                                <div className="col-12 text-right pb-3 d-none d-lg-block pr-4">
                                                    <Link to={'/doctor-edit-profile'} className="view-profile-edit">Edit</Link>
                                                </div>
                                                <div className="col-1 col-1-lg d-none d-lg-block"></div>
                                                <div className="col-12 col-lg pt-3 col-md-11 d-flex">
                                                    <div className="row no-gutters w-100">
                                                        {authUser.email ?
                                                            <div className="col-12 col-md-6 col-lg-4 pb-4">
                                                                <h3 className="view-profile-titles">{generalData.doctor_profile_settings.email_title}</h3>
                                                                <h4 className="view-profile-data-titles">{authUser.email}</h4>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {authUser.date_of_birth ?
                                                            <div className="col-12 col-md-6 col-lg-4 pb-4">
                                                                <h3 className="view-profile-titles">{generalData.doctor_profile_settings.date_of_birth_title}</h3>

                                                                <h4 className="view-profile-data-titles">{dateFormat(authUser.date_of_birth, "d mmmm, yyyy")}</h4>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {authUser.doctor_specialization ?
                                                            <div className="col-12 col-md-6 col-lg-4 pb-4">
                                                                <h3 className="view-profile-titles">{generalData.doctor_profile_settings.specialization_title}</h3>
                                                                <h4 className="view-profile-data-titles">{authUser.doctor_specialization?.specialization}</h4>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {authUser.phone_number ?
                                                            <div className="col-12 col-md-6 col-lg-4 pb-4">
                                                                <h3 className="view-profile-titles">{generalData.doctor_profile_settings.phone_number_title}</h3>
                                                                <h4 className="view-profile-data-titles">{authUser.phone_number}</h4>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {authUser.user_gender_id ?
                                                            <div className="col-12 col-md-6 col-lg-4 pb-4">
                                                                <h3 className="view-profile-titles">{generalData.doctor_profile_settings.gender_title}</h3>
                                                                <h4 className="view-profile-data-titles">
                                                                    {
                                                                        generalData.user_gender.map((singleGender) => (
                                                                            singleGender.id === authUser.user_gender_id ?
                                                                                singleGender.gender
                                                                                :
                                                                                null
                                                                        ))
                                                                    }
                                                                </h4>

                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {
                                                            authUser.doctor_languages?.length > 0 ?
                                                                <div className="col-12 col-md-6 col-lg-4 pb-4">
                                                                    <h3 className="view-profile-titles">{generalData.doctor_profile_settings.languages_title}</h3>
                                                                    <h4 className="view-profile-data-titles">
                                                                        {
                                                                            authUser.doctor_languages?.map((singleLanguage, index) => (
                                                                                index + 1 === authUser.doctor_languages?.length ? singleLanguage.title : singleLanguage.title + ', '
                                                                            ))
                                                                        }
                                                                    </h4>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {authUser.experience ?
                                                            <div className="col-12 pb-4">
                                                                <h3 className="view-profile-titles">{generalData.doctor_profile_settings.experience_title}</h3>
                                                                <div className="view-profile-richtextbox">
                                                                    {<div className="content" dangerouslySetInnerHTML={{ __html: authUser?.experience }}></div>}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {authUser.awards ?
                                                            <div className="col-12 pb-4 pt-2">
                                                                <h3 className="view-profile-titles">{generalData.doctor_profile_settings.awards_title}</h3>
                                                                <div className="view-profile-richtextbox">
                                                                    {<div className="content" dangerouslySetInnerHTML={{ __html: authUser?.awards }}></div>}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 py-3">
                            <div className="row">
                                <div className="col-12 col-md-6 text-center py-3">
                                    <Link style={{ textDecoration: 'none' }} to="/doctor-records">
                                        <div className="border-dashboard-boxes border-dark-blue" animate="right">
                                            <div className="pt-4 pb-3">
                                                <img className="dashboard-icons-backend" src={generalData.doctor_profile_settings.reports_icon} alt="icon" />
                                            </div>
                                            <p className="dashboard-card-title pb-3">{generalData.doctor_profile_settings.reports_title}</p>
                                            <div className="services-count-container">{getCareCases ? getCareCases : 0}</div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-12 col-md-6 text-center py-3">
                                    <Link style={{ textDecoration: 'none' }} to="/my-courses">
                                        <div className="border-dashboard-boxes border-dark-blue" animate="right">
                                            <div className="pt-4 pb-3">
                                                <img className="dashboard-icons-backend" src={generalData.doctor_profile_settings.courses_icon} alt="icon" />
                                            </div>
                                            <p className="dashboard-card-title pb-3">{generalData.doctor_profile_settings.courses_title}</p>
                                            <div className="services-count-container">{doctorCourses?.length}</div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-12 text-center py-3">
                                    <Link style={{ textDecoration: 'none' }} to="/doctor-payments">
                                        <div className="border-dashboard-boxes border-dark-blue" animate="right">
                                            <div className="pt-4 pb-3">
                                                <img className="dashboard-icons-backend" src={generalData.doctor_profile_settings.withdrawals_icon} alt="icon" />
                                            </div>
                                            <p className="dashboard-card-title pb-3">{generalData.doctor_profile_settings.withdrawals_title}</p>
                                            <div className="services-count-container">{totalOutstanding ? totalOutstanding : 0} GBP</div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
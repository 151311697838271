import { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Api from "../components/Api";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import axios from 'axios';

const formatDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + Number(Number(date.getMonth()) + 1) : Number(date.getMonth()) + 1}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
}

export default function UserEditProfile(props) {
    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal , notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [loading] = useState(false);
    const [authUser, setAuthUser] = authUserGlobal;
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [birthDate, setBirthDate] = useState();
    const [gender, setGender] = useState({});
    const [mobileNumber, setMobileNumber] = useState();
    const [successMessage, setSuccessMessage] = useState(false);
    const [documentType, setDocumentType] = useState({});
    const [profilePicture, setProfilePicture] = useState();
    const [authUserApiOptions, setAuthUserApiOptions] = useState({});
    const [document, setDocument] = useState();
    const [documentLink, setDocumentLink] = useState();
    const [tempPicture, setTempPicture] = useState();
    const [tempFile, setTempFile] = useState();
    const [saveSuccess, setSaveSuccess] = useState(false);

    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);

    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading]);

    useEffect(() => {
        if (authUser.first_name) {
            setFirstName(authUser.first_name);
        };

        if (authUser.last_name) {
            setLastName(authUser.last_name);
        };

        if (authUser.email) {
            setEmail(authUser.email);
        }

        if (authUser.date_of_birth) {
            setBirthDate(authUser.date_of_birth);
        }

        if (authUser.user_gender_id) {
            setGender({ value: authUser.user_gender_id, label: allGenderOptions()[authUser.user_gender_id ? Number(Number(authUser.user_gender_id) - 1) : ''].label });
        }

        if (authUser.email) {
            setEmail(authUser.email);
        }
        if (authUser.phone_number) {
            setMobileNumber(authUser.phone_number)
        }

        if (authUser.document_type_id) {
            setDocumentType({
                value: authUser.document_type_id, label: allDocumentsOptions()
                [authUser.document_type_id ? Number(Number(authUser.document_type_id) - 1) : ''].label
            });
        }

        if (authUser.document_scan) {
            setDocumentLink(authUser.document_scan);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const allGenderOptions = () => {
        const genderOptions = generalData.user_gender.map(
            singlegender => ({ value: singlegender.id, label: singlegender.gender }))
        return genderOptions;
    }

    const allDocumentsOptions = () => {
        const documentOptions = generalData.document_type.map(
            singledocument => ({ value: singledocument.id, label: singledocument.name }))
        return documentOptions;
    }

    const profilePictureHandler = (uploadedFiles) => {
        if (uploadedFiles.length > 0) {
            setProfilePicture(uploadedFiles);
            let file = URL.createObjectURL(uploadedFiles[0])
            setTempPicture(file);
        }
    }

    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0])
            setTempFile(file);
        }
    }


    const submitUserEditProfile = (e) => {
        setSuccessMessage(false);
        e.preventDefault();
        let fd = new FormData();

        fd.append('first_name', firstName);
        fd.append('last_name', lastName);
        fd.append('date_of_birth', birthDate);

        if (gender?.value) {
            fd.append('user_gender_id', gender?.value);
        }
        fd.append('phone_number', mobileNumber);
        fd.append('email', email);
        if (documentType?.value) {
            fd.append('document_type_id', documentType.value)
        };
        if (profilePicture) {
            fd.append('image', profilePicture[0])
        }

        if (document) {
            fd.append('document_scan', document[0])
        }

        setGeneralDataApiOptions({
            url: 'edit-user-profile',
            method: 'post',
            data: fd,
            callback: res => {
                setSuccessMessage(true);
                setTimeout(() => {
                    setSaveSuccess(true);
                }, 200);
                setTimeout(() => {
                    setSaveSuccess(false);
                    history.push('/user-view-profile');
                }, 3000);
            }
        })
    }

    useEffect(() => {
        setAuthUserApiOptions({
            url: 'user-data',
            callback: res => {
                setAuthUser(res.data);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMessage === true]);

    let history = useHistory();

    return (
        <>
            <UserLayout
                darkMenu={true} />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont">
                    <form onSubmit={submitUserEditProfile} className="edit-user-profile">
                        <div className="row">
                            <div className="col-12 col-md-6 pt-3 w-100" animate="right">
                                <p className="dashboard-title" animate="left">{generalData.patient_profile_settings.edit_profile_title}</p>
                            </div>
                            <div className="col-12 pb-4">
                                <div className="border-dashboard-boxes fill-dark h-100" animate="right">
                                    <div className="py-4 px-3">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-lg-auto text-center">
                                                <img className="user-single-records-image mx-auto d-block" src={tempPicture ? tempPicture : authUser.image ? authUser.image : '/img/no-pic.jpg'} alt="user-profile" />
                                                <input hidden className="upload-comment-files" id='upload-comment-files' type="file" onChange={(e) => { profilePictureHandler(e.target.files) }} />
                                                <label className="edit-user-profile-pic-text d-inline-block text-center pt-2" htmlFor="upload-comment-files">EDIT</label>
                                            </div>
                                            <div className="col-12 col-lg pt-3 h-100">
                                                <div className="row flex-start">
                                                    <div className="col-12 col-lg-6 pb-4">
                                                        <h3 className="edit-profile-labels to-capital">{generalData.patient_profile_settings.first_name_title}</h3>
                                                        <input className="edit-user-profile w-100" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-lg-6 pb-4">
                                                        <h3 className="edit-profile-labels to-capital">{generalData.patient_profile_settings.last_name_title}</h3>
                                                        <input className="edit-user-profile w-100" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-lg-6 pb-4">
                                                        <h3 className="edit-profile-labels to-capital">{generalData.patient_profile_settings.date_of_birth_title}</h3>
                                                        <DatePicker maxDate={new Date()} placeholderText="YYYY-MM-DD" peekNextMonth
                                                            dateFormat="yyyy-MM-dd"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            className="edit-user-profile w-100"
                                                            value={birthDate} onChange={(e) => setBirthDate(formatDate(e))} />
                                                    </div>
                                                    <div className="col-12 col-lg-6 pb-4">
                                                        <h3 className="edit-profile-labels to-capital">{generalData.patient_profile_settings.gender_title}</h3>
                                                        <Dropdown menuClassName='dropdown-menu-gender' className="edit-user-profile" placeholder="Select Gender" options={allGenderOptions()} onChange={setGender} value={gender} />
                                                    </div>
                                                    <div className="col-12 col-lg-6 pb-4">
                                                        <h3 className="edit-profile-labels to-capital">{generalData.patient_profile_settings.phone_number_title}</h3>
                                                        <PhoneInput className='edit-user-profile h-100 w-100' international defaultCountry="GB" value={mobileNumber} onChange={setMobileNumber} name="mobile_number" type="text" />
                                                    </div>
                                                    <div className="col-12 col-lg-6 pb-4">
                                                        <h3 className="edit-profile-labels to-capital">{generalData.patient_profile_settings.email_title}</h3>
                                                        <input className="edit-user-profile w-100" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-lg-6 pb-4">
                                                        <h3 className="edit-profile-labels to-capital">{generalData.patient_profile_settings.document_title}</h3>
                                                        <Dropdown menuClassName='dropdown-menu-gender' className="edit-user-profile" placeholder="Select Document Type" options={allDocumentsOptions()} onChange={setDocumentType} value={documentType} />
                                                    </div>
                                                    <div className="col-12 col-lg-6 pb-4">
                                                        <div className="row justify-content-between no-gutters">
                                                            <h3 className="edit-profile-labels to-capital">{generalData.patient_profile_settings.document_scan_title}</h3>
                                                            {documentLink || tempFile ?
                                                                <a className="scanned-documnent-title" href={tempFile ? tempFile : documentLink} target="_blank" rel="noreferrer">
                                                                    View Document
                                                                </a>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="row no-gutters">
                                                            <input hidden id='upload-document-file' type="file" onChange={(e) => { documentHandler(e.target.files) }} />
                                                            <label className="edit-user-profile position-relative" htmlFor="upload-document-file">{documentLink ? "Change Document" : generalData.patient_profile_settings.document_scan_placeholder}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 text-lg-right text-center">
                                                        <button type="submit" className="home-buttons py-1 px-5">{generalData.patient_profile_settings.form_button}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {saveSuccess ?
                    <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box doctor-popup-width add-doc-bg">
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <svg className="close-svg" onClick={() => setSaveSuccess(!saveSuccess)} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                        <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                            <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                            <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                        </g>
                                    </svg>
                                    <div className="content background-light text-center px-1 py-5 w-100">
                                        <h1>Changes Successfully Saved</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <Api options={generalDataApiOptions} />
                <Api options={authUserApiOptions} />
            </div >
        </>
    );
}
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import HowCanWeHelpYou from "./pages/HowCanWeHelp";
import OurDoctors from "./pages/OurDoctors";
import DoctorsSingle from "./pages/DoctorsSingle";
import Services from "./pages/Services";
import GetCare from "./pages/GetCare";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ServicesSingle from "./pages/ServicesSingle";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ConfirmEmail from "./pages/ConfirmEmail";
import { useEffect, useState, Fragment, useContext } from "react";
import axios from "axios";
import ScrollToTop from "./ScrollToTop";
import Api from "./components/Api";
import GlobalState from "./contexts/GlobalState";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Testimonials from "./pages/Testimonials";
import Faqs from "./pages/Faqs";
import SignedIn from "./pages/SignedIn";
import UserDashboard from "./pages/UserDashboard";
import DoctorDashboard from "./pages/DoctorDashboard";
import UserNotifications from "./pages/UserNotifications";
import UserRecords from "./pages/UserRecords";
import UserPayments from "./pages/UserPayments";
import GetCareLogged from "./pages/GetCareLogged";
import UserOnlineSupport from "./pages/UserOnlineSupport";
import DoctorRecords from "./pages/DoctorRecords";
import DoctorSingleRecord from "./pages/DoctorSingleRecord";
import UserSingleRecord from "./pages/UserSingleRecord";
import DoctorNotifications from "./pages/DoctorNotifications";
import DoctorCalendar from "./pages/DoctorCalendar";
import DoctorCourses from "./pages/DoctorCourses";
import DoctorPayments from "./pages/DoctorPayments";
import UserEditProfile from "./pages/UserEditProfile";
import UserViewProfile from "./pages/UserViewProfile";
import DoctorViewProfile from "./pages/DoctorViewProfile";
import DoctorEditProfile from "./pages/DoctorEditProfile";
import DoctorFinalReport from "./pages/DoctorFinalReport";
import UserTestResults from "./pages/UserTestResults";
import DoctorSingleCourseCategory from "./pages/DoctorSingleCourseCategory";
import DoctorSingleCourse from "./pages/DoctorSingleCourse";
import DoctorCourseCheckout from "./pages/DoctorCourseCheckout";
import DoctorSingleLesson from "./pages/DoctorSingleLesson";
import MyCourses from "./pages/MyCourses";


// axios.defaults.baseURL = 'http://localhost/mediconnect-backend/public/api/';
//axios.defaults.baseURL = 'https://mediconnect.hellotree.dev/api';
// axios.defaults.baseURL = 'https://dev-mediconnect.hellotree.dev/api';
axios.defaults.baseURL = 'https://specialist-medic.com/api';

function App() {

	const [generalData, setGeneralData] = useState();
	const [authUser, setAuthUser] = useState();
	const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
	const [authUserApiOptions, setAuthUserApiOptions] = useState({});
	const [usersMenuOpen, setUsersMenuOpen] = useState(window.innerWidth > 991);
	const [notificationsCount, setNotificationsCount] = useState();


	// window.innerWidth < 991

	useEffect(() => {
		if (localStorage.getItem('auth_token')) {
			// Axios add default header authorization
			axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');

			// Call API to validate the token and get the user details

			axios.get('pending-notifications')
				.then((r) => {
					setNotificationsCount(r.data);
				});


			setAuthUserApiOptions({
				url: 'user-data',
				callback: res => {
					setAuthUser(res.data);
				}
			});

			// Create Auth state and add it to the provider
		}

		setGeneralDataApiOptions({
			url: 'general',
			callback: res => {
				setGeneralData(res.data);
			}
		})
	}, []);

	return (
		<>
			<Api options={generalDataApiOptions} />
			<Api options={authUserApiOptions} />
			{
				!generalData ?
					<div className="loader-home">
						<div className="home-loader lds-ring">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
					:
					<GlobalState.Provider value={{
						generalDataGlobal: [generalData, setGeneralData],
						authUserGlobal: [authUser, setAuthUser],
						usersMenuOpenGlobal: [usersMenuOpen, setUsersMenuOpen],
						notificationsGlobal: [notificationsCount, setNotificationsCount],
					}}>
						<Router>
							<Fragment>
								<ScrollToTop />
								<Switch>
									<Routes />
								</Switch>
							</Fragment>
						</Router>
					</GlobalState.Provider>
			}
		</>
	);
}

const AuthRoute = (props) => {

	const { authUserGlobal } = useContext(GlobalState);
	const [authUser] = authUserGlobal;
	const [hasAccess, setHasAccess] = useState();

	useEffect(() => {
		if (authUser) setHasAccess((authUser.user_types_id === 2 && !!props.user) || (authUser.user_types_id === 1 && !!props.doctor));
		else setHasAccess(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authUser]);

	return hasAccess === undefined ?
		null
		:
		<Route {...props}>
			{
				hasAccess ?
					props.children
					:
					<Redirect to="/sign-in" />
			}
		</Route>
}

function Routes() {
	return (
		<>
			<Route exact path="/">
				<Home />
			</Route>
			<Route exact path="/how-can-we-help">
				<HowCanWeHelpYou />
			</Route>
			<Route exact path="/our-doctors">
				<OurDoctors />
			</Route>
			<Route exact path="/our-doctors/:slug">
				<DoctorsSingle />
			</Route>
			<Route exact path="/services">
				<Services />
			</Route>
			<Route exact path="/services/:slug">
				<ServicesSingle />
			</Route>
			<Route exact path="/get-care">
				<GetCare />
			</Route>
			<Route exact path="/get-care/:email/:token">
				<GetCare />
			</Route>
			<Route exact path="/sign-in">
				<SignIn />
			</Route>
			<Route exact path="/sign-up">
				<SignUp />
			</Route>
			<Route exact path="/forgot-password">
				<ForgotPassword />
			</Route>
			<Route exact path="/reset-password/:email/:token">
				<ResetPassword />
			</Route>
			<Route exact path="/confirm-email/:email/:token">
				<ConfirmEmail />
			</Route>
			<Route exact path="/terms-conditions">
				<TermsConditions />
			</Route>
			<Route exact path="/privacy-policy">
				<PrivacyPolicy />
			</Route>
			<Route exact path="/contact-us">
				<ContactUs />
			</Route>
			<Route exact path="/about-us">
				<AboutUs />
			</Route>
			<Route exact path="/testimonials">
				<Testimonials />
			</Route>
			<Route exact path="/faqs">
				<Faqs />
			</Route>
			<Route exact path="/signed-in">
				<SignedIn />
			</Route>
			<AuthRoute exact path="/user-dashboard" user={true}>
				<UserDashboard />
			</AuthRoute>
			<AuthRoute exact path="/user-notifications" user={true}>
				<UserNotifications />
			</AuthRoute>
			<AuthRoute exact path="/user-records" user={true}>
				<UserRecords />
			</AuthRoute>
			<AuthRoute exact path="/user-payments" user={true}>
				<UserPayments />
			</AuthRoute>
			<AuthRoute exact path="/user-payments/:reason" user={true}>
				<UserPayments />
			</AuthRoute>
			<AuthRoute exact path="/user-online-support" user={true}>
				<UserOnlineSupport />
			</AuthRoute>
			<AuthRoute exact path="/get-care-logged" user={true}>
				<GetCareLogged />
			</AuthRoute>
			<AuthRoute exact path="/get-care-logged/:email/:token" user={true}>
				<GetCareLogged />
			</AuthRoute>
			<AuthRoute exact path="/user-records/user-single-record/:email/:token" user={true}>
				<UserSingleRecord />
			</AuthRoute>
			<AuthRoute exact path="/user-edit-profile" user={true}>
				<UserEditProfile />
			</AuthRoute>
			<AuthRoute exact path="/user-test-results" user={true}>
				<UserTestResults />
			</AuthRoute>
			<AuthRoute exact path="/user-view-profile" user={true}>
				<UserViewProfile />
			</AuthRoute>
			<AuthRoute exact path="/doctor-dashboard" doctor={true}>
				<DoctorDashboard />
			</AuthRoute>
			<AuthRoute exact path="/doctor-records" doctor={true}>
				<DoctorRecords />
			</AuthRoute>
			<AuthRoute exact path="/doctor-calendar" doctor={true}>
				<DoctorCalendar />
			</AuthRoute>
			<AuthRoute exact path="/doctor-courses" doctor={true}>
				<DoctorCourses />
			</AuthRoute>
			<AuthRoute exact path="/my-courses" doctor={true}>
				<MyCourses />
			</AuthRoute>
			<AuthRoute exact path="/doctor-courses/doctor-single-course-category/:slug" doctor={true}>
				<DoctorSingleCourseCategory />
			</AuthRoute>
			<AuthRoute exact path="/doctor-courses/doctor-single-course-category/:slug/single-course/:course_slug" doctor={true}>
				<DoctorSingleCourse />
			</AuthRoute>
			<AuthRoute exact path="/doctor-courses/doctor-single-course-category/:slug/single-course/:course_slug/single-lesson/:lesson_slug" doctor={true}>
				<DoctorSingleLesson />
			</AuthRoute>
			<AuthRoute exact path="/doctor-courses/doctor-single-course-category/:slug/single-course/checkout/:course_slug" doctor={true}>
				<DoctorCourseCheckout />
			</AuthRoute>
			<AuthRoute exact path="/doctor-courses/doctor-single-course-category/:slug/single-course/checkout/:course_slug/:reason" doctor={true}>
				<DoctorCourseCheckout />
			</AuthRoute>
			<AuthRoute exact path="/doctor-payments" doctor={true}>
				<DoctorPayments />
			</AuthRoute>
			<AuthRoute exact path="/doctor-notifications" doctor={true}>
				<DoctorNotifications />
			</AuthRoute>
			<AuthRoute exact path="/doctor-records/doctor-single-record/:email/:token" doctor={true}>
				<DoctorSingleRecord />
			</AuthRoute>
			<AuthRoute exact path="/doctor-view-profile" doctor={true}>
				<DoctorViewProfile />
			</AuthRoute>
			<AuthRoute exact path="/doctor-edit-profile" doctor={true}>
				<DoctorEditProfile />
			</AuthRoute>
			<AuthRoute exact path="/doctor-records/doctor-single-record/doctor-final-report/:email/:token" doctor={true}>
				<DoctorFinalReport />
			</AuthRoute>
		</>
	)
}

export default App;
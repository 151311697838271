import { useState, useContext, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Api from "../components/Api";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import 'react-dropdown/style.css';
import axios from 'axios';


export default function DoctorSingleCourse(props) {
    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [loading] = useState(false);
    const [authUser] = authUserGlobal;
    const [singleCourse, setSingleCourse] = useState();
    const [courseLearnings, setCourseLearnings] = useState();
    const [courseLessons, setCourseLessons] = useState();
    const [alreadyBought, setAlreadyBought] = useState([]);
    const [enrolledUsers, setEnrolledUsers] = useState([]);
    const [lastFinishedLesson, setLastFinishedLesson] = useState(0);


    let { slug } = useParams();
    let { course_slug } = useParams();

    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);
    
    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading]);

    useEffect(() => {
        setGeneralDataApiOptions({
            url: 'doctor-single-course/' + course_slug,
            method: 'get',
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                if (res.data['single_course']) {
                    setSingleCourse(res.data['single_course']);
                }
                if (res.data['single_course']?.learnings?.length > 0) {
                    setCourseLearnings(res.data['single_course']?.learnings);
                }
                if (res.data['single_course']?.lessons?.length > 0) {
                    setCourseLessons(res.data['single_course']?.lessons);
                }
                if (res.data['single_course']?.enrolled_users?.length > 0) {
                    setEnrolledUsers(res.data['single_course']?.enrolled_users);
                    res.data['single_course']?.enrolled_users?.forEach((singleUser) => (
                        singleUser.doctor_id === authUser?.id ?
                            setAlreadyBought([...alreadyBought, singleUser])
                            :
                            null
                    ))
                }
                if (!res.data['all_videos_watched']?.length > 0) {
                    setLastFinishedLesson(res.data['single_course']?.lessons[0]?.id);
                }
                if (res.data['all_videos_watched']?.length > 0) {
                    let max_lesson = 0;
                    res.data['all_videos_watched']?.forEach((singleVideo) => (
                        singleVideo.course_lessons_id > max_lesson ?
                            max_lesson = singleVideo.course_lessons_id
                            :
                            null
                    ))

                    let total_finished = 0;
                    res.data['all_videos_watched']?.forEach((singleVideo) => (
                        singleVideo.completed === 1 && singleVideo.course_lessons_id === max_lesson ?
                            total_finished = total_finished + 1
                            :
                            null
                    ))

                    let total_lesson_videos = 0;
                    if (res.data['single_course']?.lessons?.length > 0) {
                        res.data['single_course']?.lessons?.forEach((singleLesson) => (
                            singleLesson.id === max_lesson ?
                                total_lesson_videos = Number(singleLesson.lessons_videos?.length)
                                :
                                null
                        ))
                    }

                    if (total_finished === total_lesson_videos) {
                        setLastFinishedLesson(Number(max_lesson) + 1);
                    }
                    else {
                        setLastFinishedLesson(Number(max_lesson));
                    }
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    let history = useHistory();

    return (
        <>
            <UserLayout
                activeUserPage="courses"
                darkMenu={true}
            />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row">
                        {/* <div className="col-12 col-md-5 pt-3 w-100" animate="right">
                            <p className="dashboard-title m-0 pt-3">TRAINING COURSES</p>
                        </div> */}
                        <div className="col-12 col-md-5 pt-3 w-100 d-flex" animate="left">
                            {/* <Link to={() => history.goBack()} className="d-flex my-auto"> */}
                                <svg onClick={() => history.goBack()} className="d-flex my-auto cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="10.992" height="19.986" viewBox="0 0 10.992 19.986">
                                    <path id="Path_77" data-name="Path 77" d="M-4195.109,1774l-8.579,8.578,8.579,8.579" transform="translate(4204.688 -1772.586)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </svg>
                            {/* </Link> */}
                            <p className="dashboard-title d-flex m-0 pl-4 my-auto">
                                {
                                    singleCourse?.title
                                }
                            </p>
                        </div>
                    </div>

                    <div className="row py-4">
                        {
                            singleCourse ?
                                <>
                                    <div className="col-12">
                                        <div className="row h-100">
                                            <div className="col-12 col-md-6 pb-4 pb-md-0">
                                                <div className="border-dashboard-boxes fill-dark p-4">
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <img className="class-author-image" src={singleCourse?.author_image ? singleCourse.full_path_author_image : ''} alt={singleCourse.slug} />
                                                        </div>
                                                        <div className="col d-flex my-auto">
                                                            <p className="m-0 class-author-name">{singleCourse.author_name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 justify-content-center">
                                                <div className="border-dashboard-boxes fill-dark p-4 h-100">
                                                    <div className="row h-100">
                                                        <div className="col-12 d-flex my-auto justify-content-center">
                                                            <p className="m-0 class-enrolled-number">{enrolledUsers?.length}<span> already enrolled</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 py-5">
                                        <div className="row h-100">
                                            <div className={courseLearnings?.length > 0 ? 'col-12 col-md-7 pb-4 pb-md-0' : 'col-12 pb-4 pb-md-0'} >
                                                <div className="border-dark-blue border-radius-25 p-4 h-100">
                                                    <h2 className="course-border-blue-title">Course Details</h2>
                                                    {
                                                        <div className="content course-description"
                                                            dangerouslySetInnerHTML={{ __html: singleCourse.course_details }}>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                courseLearnings?.length > 0 ?
                                                    <div className="col-12 col-md-5">
                                                        <div className="border-dark-blue border-radius-25 p-4 h-100">
                                                            <h2 className="course-border-blue-title">What You Will Learn</h2>
                                                            <div className="row">
                                                                {
                                                                    courseLearnings?.map((singleLearning, index) => (
                                                                        <div className="col-12" key={index}>
                                                                            <div className="row pt-4">
                                                                                <div className="col-auto">
                                                                                    <img className="learnings-image" src={singleLearning.full_path_icon} alt={singleLearning.title + index} />
                                                                                </div>
                                                                                <div className="col">
                                                                                    <p className="m-0 class-learnings-title">{singleLearning.title}</p>
                                                                                    <p className="m-0 class-learnings-desc">{singleLearning.description}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    {
                                        courseLessons?.length > 0 ?
                                            <div className="col-12 pb-5">
                                                <div className="border-dashboard-boxes fill-dark p-4 h-100">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h2 className="course-border-blue-title">Get Started</h2>
                                                            <div className="row py-2 all-lessons-div justify-content-between">
                                                                {
                                                                    courseLessons?.map((singleLesson, index) => (
                                                                        <>
                                                                            {alreadyBought?.length > 0 && singleLesson.id <= lastFinishedLesson && singleLesson.lessons_videos?.length > 0 ?
                                                                                <div className='col-12 col-md-9 position-relative lesson-big-div-open'>
                                                                                    <Link style={{ textDecoration: "none" }} to={alreadyBought?.length > 0 && singleLesson.id <= lastFinishedLesson ? '/doctor-courses/doctor-single-course-category/' + slug + '/single-course/' + course_slug + '/single-lesson/' + singleLesson.slug : '#a'}>
                                                                                        <div className={Number(courseLessons?.length) === Number(index) + 1 ? 'ml-2 pl-3 pb-3' : 'lessons-div ml-2 pl-3 pb-3'} >
                                                                                            <div className="lessons-circle"><span>{singleLesson.id < lastFinishedLesson ?
                                                                                                <>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.204" height="15.912" viewBox="0 0 20.204 15.912">
                                                                                                        <path id="Path_29" data-name="Path 29" d="M1229.488-4161.567l5.353,5.353,12.729-12.729" transform="translate(-1228.428 4170.004)" fill="none" stroke="#fff" stroke-width="3" />
                                                                                                    </svg>
                                                                                                </>
                                                                                                : index + 1}</span></div>
                                                                                            <div className="row no-gutters w-100">
                                                                                                <div className="col-12 pl-3">
                                                                                                    <p className="m-0 pt-1 course-lesson-title">{singleLesson.title}</p>
                                                                                                    <p className="m-0 pt-3 course-lesson-text">{singleLesson.description}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                                :
                                                                                <div className='col-12 col-md-9 position-relative lesson-big-div-closed'>
                                                                                    <div className={Number(courseLessons?.length) === Number(index) + 1 ? 'ml-2 pl-3 pb-3' : 'lessons-div ml-2 pl-3 pb-3'} >
                                                                                        <div className="lessons-circle"><span>{singleLesson.id < lastFinishedLesson ?
                                                                                            <>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.204" height="15.912" viewBox="0 0 20.204 15.912">
                                                                                                    <path id="Path_29" data-name="Path 29" d="M1229.488-4161.567l5.353,5.353,12.729-12.729" transform="translate(-1228.428 4170.004)" fill="none" stroke="#fff" stroke-width="3" />
                                                                                                </svg>
                                                                                            </>
                                                                                            : index + 1}</span></div>
                                                                                        <div className="row no-gutters w-100">
                                                                                            <div className="col-12 pl-3">
                                                                                                <p className="m-0 pt-1 course-lesson-title">{singleLesson.title}</p>
                                                                                                <p className="m-0 pt-3 course-lesson-text">{singleLesson.description}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {!alreadyBought?.length > 0 && Number(courseLessons?.length) === Number(index) + 1 ?
                                                                                <div className="col-auto d-flex flex-column justify-content-end pb-3">
                                                                                    <Link to={'/doctor-courses/doctor-single-course-category/' + slug + '/single-course/checkout/' + singleCourse.slug} >
                                                                                        <button className="home-buttons">PURCHASE COURSE</button>
                                                                                    </Link>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </>
                                :
                                null
                        }
                    </div>
                </div>
                <Api options={generalDataApiOptions} />
            </div>
        </>
    );
}
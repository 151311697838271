import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';
import dateFormat from 'dateformat';


export default function Main(props) {
    const { generalDataGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions] = useState({});
    const [loading] = useState(false);
    const [getCareCases, setGetCareCases] = useState();
    const [usersMenuOpen] = usersMenuOpenGlobal;


    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);
    
    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading, getCareCases]);

    useEffect(() => {
        axios.get('get-care-all')
            .then((r) => {
                setGetCareCases(r.data)
            })
    }, []);

    return (
        <>
            <UserLayout
                darkMenu={true} />
            <div className={!usersMenuOpen ? 'dashboard-content bg-white' : 'dashboard-content bg-white menu-open'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-6 pt-3 w-100 d-flex" animate="left">
                            <Link to="/user-view-profile" className="d-flex my-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.992" height="19.986" viewBox="0 0 10.992 19.986">
                                    <path id="Path_77" data-name="Path 77" d="M-4195.109,1774l-8.579,8.578,8.579,8.579" transform="translate(4204.688 -1772.586)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </svg>
                            </Link>
                            <p className="dashboard-title d-flex m-0 pl-4">My Test Results</p>
                        </div>
                    </div>
                    <div className="row pt-4">
                        {getCareCases?.length > 0 ?
                            getCareCases.map((singleCase, index) => (
                                singleCase.final_report?.closed_case === 1 ?
                                    <div className="col-12 pb-3" key={index}>
                                        <div className="border-dashboard-boxes border-dark-blue p-3">
                                            <div className="row no-gutters">
                                                <div className="col-auto p-0">
                                                    <img className="doctor-case-image" src={singleCase.doctor?.image ? singleCase.doctor?.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                </div>
                                                <div className="col p-0 m-0 pl-2">
                                                    <p className="m-0 p-0 previous-care-title">Case#{singleCase.id}</p>
                                                    <p className="m-0 p-0 mb-2 meeting-title-small">{singleCase.final_report?.specialist_summary}</p>
                                                    {singleCase.final_report?.final_report?.toString().includes('.pdf') ?
                                                        <a href={singleCase.final_report?.full_path_final_report} target="_blank" rel="noreferrer">
                                                            <img className="comment-box-attachements" rel="noreferrer" src='/img/pdf.png' alt="pdf" />
                                                        </a>
                                                        :
                                                        null
                                                    }
                                                    <h5 className="meeting-admin-label m-0 mt-2">{dateFormat(singleCase.final_report?.created_at, "d mmmm yyyy")}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            ))
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';
import { useParams } from 'react-router';
import dateFormat from 'dateformat';
import SwiperCore, { Pagination, Autoplay, EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Api from "../components/Api";

import moment from 'moment';
import 'swiper/swiper.min.css';
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/pagination/pagination.min.css";
import 'swiper/components/navigation/navigation.min.css';
import { useRef } from 'react';
import Meetings from '../components/Meetings';

SwiperCore.use([EffectFade, Pagination, Autoplay, Navigation]);

export default function DoctorSingleRecord(props) {
    const doctorRequestDocsWrapper = useRef(null);
    const doctorChatWrapper = useRef(null);
    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [generalDataApiOptions2, setGeneralDataApiOptions2] = useState({});
    const [closedCaseApi, setClosedCaseApi] = useState({});
    const [loading] = useState(false);
    let { token } = useParams();
    let { email } = useParams();
    const swiperRef = useRef(null);
    const [authUser] = authUserGlobal;
    const [singleCare, setSingleCare] = useState([]);
    const [userImage, setUserImage] = useState();
    const [addDoctor, setAddDoctor] = useState(false);
    const [tokenState] = useState(token ? token : '');
    const [emailState] = useState(email ? email : '');
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [pageTab, setPageTab] = useState(1);
    const [name, setName] = useState('');
    const [doctorEmail, setDoctorEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState();
    const [successMesssage, setSuccessMessage] = useState(0);
    const [formLoader, setFormLoader] = useState(false);
    const [doctor, setDoctor] = useState([]);
    const [addedDoctors, setAddedDoctors] = useState([]);
    const [requestTitle, setRequestTitle] = useState();
    const [requestSuccessMessage, setReuestSuccessMessage] = useState();
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [reportText, setReportText] = useState('');
    const [formData, setFormData] = useState();
    const [publicComment, setPublicComment] = useState({ label: "Private", value: "1" });
    const [publicCommentRadio, setPublicCommentRadio] = useState(1);
    const [eventType, setEventType] = useState({ label: "Upcoming", value: "0" });
    const [closeEpisodePopup, setCloseEpisodePopup] = useState(false);
    const [meetingPopup, setMeetingPopup] = useState(false);
    const [previousReports, setPreviousReports] = useState([]);
    const [popupType, setPopupType] = useState(false);
    const [meetingInfo, setMeetingInfo] = useState([]);
    const [upcomingMeetings, setUpcomingMeetings] = useState([]);
    const [previousMeetings, setPreviousMeetings] = useState([]);
    const [diagnosis, setDiagnosis] = useState(0);
    const [problems, setProblems] = useState(0);
    const [surgeries, setSurgeries] = useState(0);
    const [medications, setMedications] = useState(0);


    const [payments, setPayments] = useState([]);
    const [oldCurrentPage, setOldCurrentPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingForm, setLoadingForm] = useState();
    const [lastPage, setLastPage] = useState();
    const [search, setSearch] = useState('');
    const [submitSearch, setSubmitSearch] = useState(0);


    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);
    
    
    useEffect(() => {
        window.triggerScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generalData, generalDataApiOptions, loading, singleCare, userImage, pageTab]);



    useEffect(() => {
        if (singleCare?.id) {
            let page = currentPage === oldCurrentPage ? 1 : currentPage;
            setGeneralDataApiOptions2({
                url: 'doctor-payments?page=' + page,
                data: {
                    search: search,
                    id: singleCare?.id
                },
                method: 'post',
                headers: { "Content-Type": "multipart/form-data" },
                callback: res => {
                    if (res.data.payments) {
                        if (currentPage !== oldCurrentPage) {
                            setPayments([...payments, ...res.data.payments.data]);
                            setCurrentPage(res.data.payments.current_page);
                            setLastPage(res.data.payments.last_page);
                            setOldCurrentPage(res.data.payments.current_page);
                        }
                        else {
                            setPayments(res.data.payments.data);
                            setCurrentPage(res.data.payments.current_page);
                            setLastPage(res.data.payments.last_page);
                            setOldCurrentPage(res.data.payments.current_page);
                        }
                        window.triggerScroll();
                        setLoadingForm(false);
                    }
                }
            })
            if (submitSearch === 1) {
                setSubmitSearch(0)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, submitSearch === 1, singleCare]);

    // useEffect(() => {
    //     axios.get('get-care-single-doctor' + '/' + emailState + '/' + tokenState)
    //         .then((r) => {
    //             setSingleCare(r.data)
    //             setDoctor(r.data.doctor ? r.data.doctor : null);
    //             setAddedDoctors(r.data.users ? r.data.users : null);
    //         })
    // }, []);

    useEffect(() => {
        setLoadingRequest(true);
        axios.get('get-care-single-doctor/' + emailState + '/' + tokenState)
            .then((r) => {
                if (r.data?.diagnosis.length > 0) {
                    let counter = 0;
                    r.data.diagnosis.forEach((singleDiagnosis) => (
                        singleDiagnosis.medical_diagnosis !== '' ?
                            counter++
                            :
                            null
                    ))
                    setDiagnosis(counter);
                }
                if (r.data?.problems.length > 0) {
                    let counter = 0;
                    r.data.problems.forEach((singleProblem) => (
                        singleProblem.problem !== '' ?
                            counter++
                            :
                            null
                    ))
                    setProblems(counter);
                }
                if (r.data?.surgeries.length > 0) {
                    let counter = 0;
                    r.data.surgeries.forEach((singleProblem) => (
                        singleProblem.surgery !== '' ?
                            counter++
                            :
                            null
                    ))
                    setSurgeries(counter);
                }

                if (r.data?.medications.length > 0) {
                    let counter = 0;
                    r.data.medications.forEach((singleProblem) => (
                        singleProblem.medication !== '' ?
                            counter++
                            :
                            null
                    ))
                    setMedications(counter);
                }
                setSingleCare(r.data);
                setDoctor(r.data.doctor ? r.data.doctor : null);
                setAddedDoctors(r.data.users ? r.data.users : null);
                let upcomingMeetingsTemp = [];
                let previousMeetingsTemp = [];
                if (r.data.all_meetings?.length > 0) {
                    r.data?.all_meetings?.forEach((singleMeeting) => (
                        moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local() > moment() ?
                            upcomingMeetingsTemp.push(singleMeeting)
                            :
                            previousMeetingsTemp.push(singleMeeting)
                    ));
                }
                setPreviousMeetings(previousMeetingsTemp);
                setUpcomingMeetings(upcomingMeetingsTemp);
                // setAllCaseDoctors(...r.data.users ? r.data.users : null);
                setTimeout(() => {
                    setLoadingRequest(false);
                }, 1000);
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMesssage, requestSuccessMessage, closeEpisodePopup === false, meetingPopup]);

    useEffect(() => {
    }, [upcomingMeetings, previousMeetings]);

    useEffect(() => {
        setLoadingRequest(true);
        axios.post('doctor-previous-cases', {
            user_id: singleCare.user_id
        })
            .then((r) => {
                setPreviousReports(r.data[0]);
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleCare]);

    useEffect(() => {
        if (singleCare.user_id) {
            axios.get('user-profile/' + singleCare.user_id)
                .then((r) => {
                    setUserImage(r.data)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleCare]);

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeEpisodePopup === true]);

    useEffect(() => {
    }, [errors]);

    useEffect(() => {
    }, [formLoader]);

    useEffect(() => {
    }, [successMesssage]);

    const submitAddDoctor = (e) => {
        e.preventDefault();
        setErrors([]);
        setFormLoader(true);
        setSuccessMessage(0);

        axios
            .post('add-doctor', {
                'name': name,
                'email': doctorEmail,
                'message': message,
                'user_token': singleCare.user_token,
                'id': singleCare.id,
            })
            .then(r => {
                setName('');
                setDoctorEmail('');
                setMessage('');
                setSuccessMessage(r.data);
                setFormLoader(false);
                setTimeout(() => {
                    closeDoctorPopup();
                }, 3000);
            })
            .catch(r => {
                setErrors(r.response.data.errors)
                setFormLoader(false);
                // setLoadingForm(false)
            });
    }

    // const AlwaysScrollToBottom = () => {
    //     const elementRef = useRef();
    //     useEffect(() => elementRef.current.scrollIntoView());
    //     return <div ref={elementRef} />;
    // };



    const closeDoctorPopup = (e) => {
        setErrors();
        setName();
        setDoctorEmail();
        setMessage();
        setAddDoctor(false);
        setSuccessMessage(false);
    }

    let commentStatuses = [
        { label: "Public", value: "0" },
        { label: "Private", value: "1" }
    ];

    let eventTypes = [
        { label: "Upcoming", value: "0" },
        { label: "Previous", value: "1" }
    ];


    useEffect(() => {
        if (typeof swiperRef !== null) {
            setTimeout(() => {
                swiperRef.current?.swiper.update();
            }, 400)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersMenuOpen, swiperRef]);

    useEffect(() => {
        if (doctorRequestDocsWrapper.current) {
            doctorRequestDocsWrapper.current.scrollTop = doctorRequestDocsWrapper.current.scrollHeight;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleCare.doc_lab_results, pageTab]);


    useEffect(() => {
        if (doctorChatWrapper.current) {
            doctorChatWrapper.current.scrollTop = doctorChatWrapper.current.scrollHeight;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleCare.doc_lab_results, pageTab]);


    const submitDocumentRequest = (e) => {
        e.preventDefault();
        setReuestSuccessMessage(false);
        setErrors();
        setLoadingRequest(true);

        axios.post('add-document-request', {
            request_title: requestTitle,
            get_care_info_data_id: singleCare.id,
        })
            .then(r => {
                setRequestTitle('');
                setReuestSuccessMessage(true);
                setErrors();
                setLoadingRequest(false);
            })
            .catch(r => {
                setErrors(r.response.data.errors)
                setLoadingRequest(false);
            });
    }

    const closeCase = (e) => {
        setReuestSuccessMessage(false);
        e.preventDefault();

        setClosedCaseApi({
            url: 'doctor-close-case',
            method: 'post',
            data: {
                get_care_info_data_id: singleCare.id,
            },
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                setLoadingRequest(false);
                setCloseEpisodePopup(false);
            }
        })
    };

    const inputHandler = (files, id) => {

        if (files.length > 0) {
            let fd = new FormData();
            for (let i = 0; i < files.length; i++) {
                fd.append("attached_documents[]", files[i]);
            };
            setFormData(fd);
        }
    }

    const submitChatRequest = (e) => {
        setReuestSuccessMessage(false);
        e.preventDefault();
        setErrors();
        setLoadingRequest(true);

        let fd = new FormData();

        if (formData) {
            fd = formData;
        }

        fd.append('report_text', reportText);
        fd.append('get_care_info_data_id', singleCare.id);
        if (publicCommentRadio === 0) {
            fd.append('private', publicCommentRadio)
        }
        else {
            fd.append('private', publicComment === 0 || publicComment === 1 ? publicComment : publicComment.value);
        }

        setGeneralDataApiOptions({
            url: 'add-chat-request',
            method: 'post',
            data: fd,
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                setReuestSuccessMessage(true);
                setReportText('');
                setFormData('');
                setPublicComment({ label: "Private", value: "1" });
                setPublicCommentRadio(1);
                setLoadingRequest(false);
            }
        })
    }

    const closePop = () => {
        setMeetingPopup(!meetingPopup);
        setMeetingInfo([]);
    };


    return (
        <>
            <UserLayout
                darkMenu={true}
                activeUserPage="records" />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont">
                    {singleCare?.id ?
                        <div className="row">
                            <div className="col-12 col-md-6 pt-3 w-100 d-flex">
                                <Link to="/doctor-records" className="d-flex my-auto" animate="left">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.992" height="19.986" viewBox="0 0 10.992 19.986">
                                        <path id="Path_77" data-name="Path 77" d="M-4195.109,1774l-8.579,8.578,8.579,8.579" transform="translate(4204.688 -1772.586)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    </svg>
                                </Link>
                                <p className="dashboard-title d-flex m-0 pl-3" animate="left">Case {singleCare.id}</p>
                            </div>
                            <div className="col-12" animate="left">
                                <div className="row">
                                    <div className="col-12 col-md-6 py-3">
                                        <div className="border-dashboard-boxes fill-dark h-100">
                                            <div className="py-4 px-3">
                                                <h1 className="single-record-box-title pl-2">{generalData.doctor_cases_settings.patient_title}</h1>
                                                <div className="row pt-3">
                                                    <div className="col-auto pr-0">
                                                        <img className="user-single-records-image" src={!userImage === ' ' ? userImage : '/img/no-pic.jpg'} alt="user-profile" />
                                                    </div>
                                                    <div className="col pt-md-2">
                                                        <p className="user-single-records-name pl-2">{singleCare.first_name + ' ' + singleCare.last_name}</p>
                                                        <div className="row">
                                                            <div className="col-12 col-md-6">
                                                                <p className="pl-2">{dateFormat(singleCare.date_of_birth, "d mmmm, yyyy")}</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <p className="pl-2">ID: {singleCare.user_id}</p>
                                                            </div>
                                                        </div>
                                                        {singleCare.user_gender_id || singleCare.countries_list_id ?
                                                            <div className="row">
                                                                {singleCare.user_gender_id ?
                                                                    <div className="col-12 col-md-6">
                                                                        <p className="pl-2">{generalData.user_gender.map((SingleGender) => (
                                                                            SingleGender.id === singleCare.user_gender_id ? SingleGender.gender : null
                                                                        ))
                                                                        }
                                                                        </p>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                                {singleCare.countries_list_id ?
                                                                    <div className="col-12 col-md-6">
                                                                        <p className="pl-2">
                                                                            {
                                                                                generalData.countries_list.map((SingleCountry, i) => (
                                                                                    SingleCountry.id === singleCare.countries_list_id ? SingleCountry.name : null
                                                                                ))
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {doctor ?
                                        !addedDoctors.length ?
                                            <div className="col-12 col-md-6 py-3" animate="left">
                                                <div className="border-dashboard-boxes fill-dark h-100">
                                                    <div className="py-4 px-3">
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <h1 className="single-record-box-title pl-2">{generalData.doctor_cases_settings.doctor_title}</h1>
                                                            </div>
                                                            {singleCare?.case_status_id !== 5 ?
                                                                <div className="col text-right">
                                                                    <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" onClick={() => setAddDoctor(true)}>
                                                                        <g id="Group_243" data-name="Group 243" transform="translate(-574 -530)">
                                                                            <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(574 530)" fill="none" stroke="#25d6ff" strokeWidth="1">
                                                                                <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                            </g>
                                                                            <g id="Group_240" data-name="Group 240" transform="translate(582.895 538.21)">
                                                                                <line id="Line_100" data-name="Line 100" y2="9.579" transform="translate(4.447 0)" fill="none" stroke="#25d6ff" strokeWidth="2" />
                                                                                <line id="Line_101" data-name="Line 101" x2="9" transform="translate(0.105 4.79)" fill="none" stroke="#25d6ff" strokeWidth="2" />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        {doctor.id ?
                                                            <>
                                                                <div className="row pt-3">
                                                                    <div className="col-auto pr-0">
                                                                        <img className="user-single-records-image" src={doctor.image ? doctor.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                    </div>
                                                                    <div className="col pt-md-2">
                                                                        <p className="user-single-records-name pl-2">{doctor?.name}</p>
                                                                        <div className="row">
                                                                            {doctor.doctor_languages.length > 0 ?
                                                                                <div className="col-12 col-md-6">
                                                                                    <p className="pl-2">
                                                                                        {
                                                                                            doctor.doctor_languages.map((doctorLang, i) => (
                                                                                                (i + 1 === Number(doctor.doctor_languages.length)) ? doctorLang.title : doctorLang.title + ', '
                                                                                            ))
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }

                                                                            {doctor.user_gender_id ?
                                                                                <div className="col-12 col-md-6">
                                                                                    <p className="pl-2">{generalData.user_gender.map((SingleGender) => (
                                                                                        SingleGender.id === doctor.user_gender_id ? SingleGender.gender : null
                                                                                    ))
                                                                                    }
                                                                                    </p>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }

                                                                            {doctor.countries_list_id ?
                                                                                <div className="col-12 col-md-6">
                                                                                    <p className="pl-2">{generalData.countries_list.map((SingleCountry) => (
                                                                                        SingleCountry.id === doctor.countries_list_id ? SingleCountry.name : null
                                                                                    ))
                                                                                    }
                                                                                    </p>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-12 col-md-6 w-100">
                                                <div className="row m-0 w-100 h-100">
                                                    <Swiper
                                                        ref={swiperRef}
                                                        className="doctors-swiper"
                                                        slidesPerView={1}
                                                        loop="true"
                                                        pagination
                                                        spaceBetween={30}
                                                        centeredSlides={true}
                                                    >
                                                        <SwiperSlide>
                                                            <div className="col-12 py-3 p-0 w-100 h-100">
                                                                <div className="border-dashboard-boxes fill-dark h-100 w-100">
                                                                    <div className="py-4 px-3 w-100">
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                                <h1 className="single-record-box-title pl-2">{generalData.doctor_cases_settings.doctor_title}</h1>
                                                                            </div>
                                                                            {singleCare?.case_status_id !== 5 ?
                                                                                <div className="col text-right">
                                                                                    <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" onClick={() => setAddDoctor(true)}>
                                                                                        <g id="Group_243" data-name="Group 243" transform="translate(-574 -530)">
                                                                                            <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(574 530)" fill="none" stroke="#25d6ff" strokeWidth="1">
                                                                                                <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                                <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                                            </g>
                                                                                            <g id="Group_240" data-name="Group 240" transform="translate(582.895 538.21)">
                                                                                                <line id="Line_100" data-name="Line 100" y2="9.579" transform="translate(4.447 0)" fill="none" stroke="#25d6ff" strokeWidth="2" />
                                                                                                <line id="Line_101" data-name="Line 101" x2="9" transform="translate(0.105 4.79)" fill="none" stroke="#25d6ff" strokeWidth="2" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                        {doctor.id ?
                                                                            <>
                                                                                <div className="row pt-3">
                                                                                    <div className="col-auto pr-0">
                                                                                        <img className="user-single-records-image" src={doctor.image ? doctor.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                                    </div>
                                                                                    <div className="col pt-md-2">
                                                                                        <p className="user-single-records-name pl-2">{doctor.name}</p>
                                                                                        <div className="row">
                                                                                            {doctor.doctor_languages.length > 0 ?
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <p className="pl-2">
                                                                                                        {
                                                                                                            doctor.doctor_languages.map((doctorLang, i) => (
                                                                                                                (i + 1 === Number(doctor.doctor_languages.length)) ? doctorLang.title : doctorLang.title + ', '
                                                                                                            ))
                                                                                                        }
                                                                                                    </p>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }

                                                                                            {doctor.user_gender_id ?
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <p className="pl-2">{generalData.user_gender.map((SingleGender) => (
                                                                                                        SingleGender.id === doctor.user_gender_id ? SingleGender.gender : null
                                                                                                    ))
                                                                                                    }
                                                                                                    </p>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }

                                                                                            {doctor.countries_list_id ?
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <p className="pl-2">{generalData.countries_list.map((SingleCountry) => (
                                                                                                        SingleCountry.id === doctor.countries_list_id ? SingleCountry.name : null
                                                                                                    ))
                                                                                                    }
                                                                                                    </p>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                        {addedDoctors.map((singleDoc, index) => (
                                                            <SwiperSlide key={index}>
                                                                <div className="col-12 py-3 p-0 h-100 w-100">
                                                                    <div className="border-dashboard-boxes fill-dark h-100">
                                                                        <div className="py-4 px-3">
                                                                            <div className="row">
                                                                                <div className="col-auto">
                                                                                    <h1 className="single-record-box-title pl-2">{generalData.doctor_cases_settings.doctor_title}</h1>
                                                                                </div>
                                                                                {singleCare?.case_status_id !== 5 ?
                                                                                    <div className="col text-right">
                                                                                        <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" onClick={() => setAddDoctor(true)}>
                                                                                            <g id="Group_243" data-name="Group 243" transform="translate(-574 -530)">
                                                                                                <g id="Ellipse_105" data-name="Ellipse 105" transform="translate(574 530)" fill="none" stroke="#25d6ff" strokeWidth="1">
                                                                                                    <circle cx="13" cy="13" r="13" stroke="none" />
                                                                                                    <circle cx="13" cy="13" r="12.5" fill="none" />
                                                                                                </g>
                                                                                                <g id="Group_240" data-name="Group 240" transform="translate(582.895 538.21)">
                                                                                                    <line id="Line_100" data-name="Line 100" y2="9.579" transform="translate(4.447 0)" fill="none" stroke="#25d6ff" strokeWidth="2" />
                                                                                                    <line id="Line_101" data-name="Line 101" x2="9" transform="translate(0.105 4.79)" fill="none" stroke="#25d6ff" strokeWidth="2" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </div>
                                                                            {singleDoc.id ?
                                                                                <>
                                                                                    <div className="row pt-3">
                                                                                        <div className="col-auto pr-0">
                                                                                            <img className="user-single-records-image" src={singleDoc.image ? singleDoc.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                                        </div>
                                                                                        <div className="col pt-md-2">
                                                                                            <p className="user-single-records-name pl-2">{singleDoc.name}</p>
                                                                                            <div className="row">
                                                                                                {singleDoc.doctor_languages ?
                                                                                                    <div className="col-6">
                                                                                                        <p className="pl-2">
                                                                                                            {
                                                                                                                singleDoc.doctor_languages.map((doctorLang, i) => (
                                                                                                                    (i + 1 === Number(singleDoc.doctor_languages.length)) ? doctorLang.title : doctorLang.title + ', '
                                                                                                                ))
                                                                                                            }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }

                                                                                                {singleDoc.user_gender_id ?
                                                                                                    <div className="col-6">
                                                                                                        <p className="pl-2">{generalData.user_gender.map((SingleGender) => (
                                                                                                            SingleGender.id === singleDoc.user_gender_id ? SingleGender.gender : null
                                                                                                        ))
                                                                                                        }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }

                                                                                                {singleDoc.countries_list_id ?
                                                                                                    <div className="col-6">
                                                                                                        <p className="pl-2">{generalData.countries_list.map((SingleCountry) => (
                                                                                                            SingleCountry.id === singleDoc.countries_list_id ? SingleCountry.name : null
                                                                                                        ))
                                                                                                        }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))
                                                        }
                                                    </Swiper>
                                                </div>
                                            </div>
                                        :
                                        null
                                    }

                                    <div className="col-12 py-4">
                                        <div className="row no-gutters flex-column flex-md-row justify-content-md-between">
                                            <div className="col-auto pb-2 pb-md-0">
                                                <h1 className="meetings-title m-0 my-auto">MDT Meetings</h1>
                                            </div>
                                            <div className="col-auto d-flex">
                                                {singleCare?.case_status_id !== 5 && addedDoctors?.length > 0 ?
                                                    <button className="home-buttons request-metting mr-3 font-w-medium" onClick={() => { setPopupType(true); setMeetingPopup(!meetingPopup) }} >REQUEST MEETING</button>
                                                    :
                                                    null
                                                }
                                                <Dropdown className="events-dropdown" options={eventTypes} value={eventType} onChange={setEventType} />
                                            </div>
                                            {/* {
                                                eventType.value === "0" ?
                                                    singleCare?.upcoming_meetings?.length > 0 ?
                                                        singleCare?.upcoming_meetings?.map((singleMeeting, index) => (
                                                            <div className="col-12 blue-borders mt-3 p-3 d-flex flex-column flex-md-row justify-content-md-between" key={index}>
                                                                <p className="m-0 my-auto meeting-title-small">{singleMeeting.doctor?.name}</p>
                                                                <p className="m-0 my-auto meeting-title-small">{dateFormat(moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local(), "d mmmm yyyy")}</p>
                                                                <p className="m-0 my-auto meeting-title-small">{moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local().format("h:mm a")}</p>
                                                                <div className="d-flex justify-content-center">
                                                                    <a href={singleMeeting.link} target="_blank" rel="noreferrer">
                                                                        <button className="home-buttons request-metting mr-3 px-4 font-w-medium">JOIN</button>
                                                                    </a>
                                                                    <button className="home-buttons request-metting px-2 font-w-medium" onClick={() => { setPopupType(false); setMeetingInfo(singleMeeting); setMeetingPopup(!meetingPopup) }}>RESCHEDULE</button>
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <div className="col-12 pt-4">
                                                            <h4 className="no-data-available m-0">No Meetings For This Case.</h4>
                                                        </div>
                                                    :
                                                    singleCare?.previous_meetings?.length > 0 ?
                                                        singleCare?.previous_meetings?.map((singleMeeting, index) => (
                                                            <div className="col-12 blue-borders mt-3 p-3 d-flex flex-column flex-md-row justify-content-md-between" key={index}>
                                                                <p className="m-0 my-auto meeting-title-small">{singleMeeting.doctor?.name}</p>
                                                                <p className="m-0 my-auto meeting-title-small">{dateFormat(moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local(), "d mmmm yyyy")}</p>
                                                                <p className="m-0 my-auto meeting-title-small">{moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local().format("h:mm a")}</p>
                                                            </div>
                                                        ))
                                                        :
                                                        <div className="col-12 pt-4">
                                                            <h4 className="no-data-available m-0">No Previous Meetings For This Case.</h4>
                                                        </div>

                                            } */}
                                            {
                                                eventType.value === "0" ?
                                                    upcomingMeetings?.length > 0 ?
                                                        upcomingMeetings?.map((singleMeeting, index) => (
                                                            <div className="col-12 blue-borders mt-3 p-3 d-flex flex-column flex-md-row justify-content-md-between" key={index}>
                                                                <p className="m-0 my-auto meeting-title-small">{singleMeeting.doctor?.name}</p>
                                                                <p className="m-0 my-auto meeting-title-small">{dateFormat(moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local(), "d mmmm yyyy")}</p>
                                                                <p className="m-0 my-auto meeting-title-small">{moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local().format("h:mm a")}</p>
                                                                <div className="d-flex justify-content-center">
                                                                    <a href={singleMeeting.link} target="_blank" rel="noreferrer">
                                                                        <button className="home-buttons request-metting mr-3 px-4 font-w-medium">JOIN</button>
                                                                    </a>
                                                                    <button className="home-buttons request-metting px-2 font-w-medium" onClick={() => { setPopupType(false); setMeetingInfo(singleMeeting); setMeetingPopup(!meetingPopup) }}>RESCHEDULE</button>
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <div className="col-12 pt-4">
                                                            <h4 className="no-data-available m-0">No Meetings For This Case.</h4>
                                                        </div>
                                                    :
                                                    previousMeetings?.length > 0 ?
                                                        previousMeetings?.map((singleMeeting, index) => (
                                                            <div className="col-12 blue-borders mt-3 p-3 d-flex flex-column flex-md-row justify-content-md-between" key={index}>
                                                                <p className="m-0 my-auto meeting-title-small">{singleMeeting.doctor?.name}</p>
                                                                <p className="m-0 my-auto meeting-title-small">{dateFormat(moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local(), "d mmmm yyyy")}</p>
                                                                <p className="m-0 my-auto meeting-title-small">{moment.utc(singleMeeting.start_date).add(-singleMeeting.time_zone, 'hours').local().format("h:mm a")}</p>
                                                            </div>
                                                        ))
                                                        :
                                                        <div className="col-12 pt-4">
                                                            <h4 className="no-data-available m-0">No Previous Meetings For This Case.</h4>
                                                        </div>

                                            }
                                        </div>
                                    </div>

                                    {!singleCare.concerns[0].concern === '' ?
                                        <div className="col-12 pt-3 pb-2">
                                            <div className="border-care-concern p-4">
                                                <div className="care-concern-title-single">Care Concern</div>
                                                <div>
                                                    {
                                                        singleCare.concerns.map((singleConcern, i) => (
                                                            (i + 1 === Number(singleCare.concerns.length)) ? singleConcern.concern : singleConcern.concern + ', '
                                                            // singleConcern.concern
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                    <div className="col-12 pt-4 pb-md-4">
                                        <div className="border-dashboard-boxes fill-dark pl-4 pr-4 pb-4 mb-5 pt-3">
                                            <div className="row no-gutters justify-content-between block-small">
                                                <div className="d-flex">
                                                    <p className={pageTab === 1 ? 'single-case-care-titles active' : 'single-case-care-titles'} onClick={() => setPageTab(1)}>{generalData.doctor_cases_settings.medical_report_title}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p className={pageTab === 2 ? 'single-case-care-titles active' : 'single-case-care-titles'} onClick={() => setPageTab(2)}>{generalData.doctor_cases_settings.lab_results_title}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p className={pageTab === 3 ? 'single-case-care-titles active' : 'single-case-care-titles'} onClick={() => setPageTab(3)}>{generalData.doctor_cases_settings.medical_history_title}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p className={pageTab === 4 ? 'single-case-care-titles active' : 'single-case-care-titles'} onClick={() => setPageTab(4)}>{generalData.doctor_cases_settings.payments_title}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <p className={pageTab === 5 ? 'single-case-care-titles active' : 'single-case-care-titles'} onClick={() => setPageTab(5)}>{generalData.doctor_cases_settings.previous_records_title}</p>
                                                </div>
                                                <div className="col-12 pt-0 border-bottom" />
                                            </div>
                                            <div className="row align-items-center d-md-none">
                                                <div className="col-12 text-center">
                                                    <div className="row justify-content-between">
                                                        <div className="col-1 d-flex align-items-center justify-content-center"><div className="d-flex swiper-button-prev-small"></div></div>
                                                        <div className="col-8">
                                                            <Swiper
                                                                modules={Navigation}
                                                                slidesPerView={1}
                                                                spaceBetween={10}
                                                                onSlideChange={(e) => setPageTab(e.realIndex + 1)}
                                                                loop="true"
                                                                // navigation={{
                                                                //     clickable: "true"
                                                                // }}
                                                                // navigation={true}
                                                                navigation={{
                                                                    nextEl: ".swiper-button-next-small",
                                                                    prevEl: ".swiper-button-prev-small",
                                                                }}
                                                            >
                                                                <SwiperSlide><p className='single-case-care-titles active'>{generalData.doctor_cases_settings.medical_report_title}</p></SwiperSlide>
                                                                <SwiperSlide><p className='single-case-care-titles active'>{generalData.doctor_cases_settings.lab_results_title}</p></SwiperSlide>
                                                                <SwiperSlide><p className='single-case-care-titles active'>{generalData.doctor_cases_settings.medical_history_title}</p></SwiperSlide>
                                                                <SwiperSlide><p className='single-case-care-titles active'>{generalData.doctor_cases_settings.payments_title}</p></SwiperSlide>
                                                                <SwiperSlide><p className='single-case-care-titles active'>{generalData.doctor_cases_settings.previous_records_title}</p></SwiperSlide>
                                                            </Swiper>
                                                        </div>
                                                        <div className="col col-1 d-flex align-items-center justify-content-center"><div className="d-flex swiper-button-next-small"></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {pageTab === 1 ?
                                                <div className="row pt-2">
                                                    <div className="col-12 py-2">
                                                        <div className="doctor-request-docs" ref={doctorChatWrapper}>
                                                            {singleCare.medical_reports ?
                                                                singleCare.medical_reports.map((singleReport, index) => (
                                                                    <div className="row py-2" key={index}>
                                                                        <div className="col-auto pr-0">
                                                                            {doctor.id === singleReport.doctor_id ?
                                                                                <img className="user-single-records-image small-comment-image" src={doctor.image ? doctor.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                                :
                                                                                addedDoctors.map((singleDoctor, ind) => (
                                                                                    singleDoctor.id === singleReport.doctor_id ?
                                                                                        <img className="user-single-records-image small-comment-image" src={singleDoctor.image ? singleDoctor.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                                        :
                                                                                        null
                                                                                ))
                                                                            }
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    {doctor.id === singleReport.doctor_id ?
                                                                                        <p className="request-doc-title m-0 pb-1">{doctor.name}</p>
                                                                                        :
                                                                                        addedDoctors.map((singleDoctor, ind) => (
                                                                                            singleDoctor.id === singleReport.doctor_id ?
                                                                                                <p className="request-doc-title m-0 pb-1">{singleDoctor.name}</p>
                                                                                                :
                                                                                                null
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <p className="request-box-request-text">{singleReport.report_text}</p>
                                                                                </div>
                                                                                {singleReport.full_path_attached_documents.length > 0 ?
                                                                                    <div className="col-12">
                                                                                        <div className="row no-gutters">
                                                                                            {singleReport.full_path_attached_documents.map((singleDoc, i) => (
                                                                                                <div className="col-auto pr-2">
                                                                                                    <a href={singleDoc} target="_blank" rel="noreferrer" key={i}>
                                                                                                        {singleDoc.toString().includes('.jpg') || singleDoc.toString().includes('.png') ?
                                                                                                            <img className="comment-box-attachements" alt="document" rel="noreferrer" src={singleDoc} />
                                                                                                            :
                                                                                                            singleDoc.toString().includes('.pdf') ?
                                                                                                                <img className="comment-box-attachements" rel="noreferrer" src='/img/pdf.png' alt="document"/>
                                                                                                                :
                                                                                                                <img className="comment-box-attachements" rel="noreferrer" src='/img/docs.png' alt="document" />
                                                                                                        }
                                                                                                    </a>
                                                                                                </div>
                                                                                            ))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                <div className="col-12 pt-2">
                                                                                    <p className="chat-date m-0">{dateFormat(singleReport['created_at'], "d mmmm yyyy")}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 py-2 w-75 border-between-content" />
                                                                    </div>
                                                                ))
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>

                                                    {singleCare?.case_status_id !== 5 ?
                                                        <div className="col-12 pt-2">
                                                            <div className="row">
                                                                <div className="col-12 col-md-auto pr-0 pb-3 pb-md-0 text-center text-md-left">
                                                                    <img className="user-single-records-image width-44" src={authUser.image ? authUser.image : '/img/no-pic.jpg'} alt="user-profile" />
                                                                </div>
                                                                <div className="col-12 col-md h-100">
                                                                    <div className="row no-gutters h-100">
                                                                        <div className="col-12 h-100">
                                                                            <form className="w-100 h-100" disabled={loadingRequest} onSubmit={submitChatRequest}>
                                                                                <div className="row no-gutters justify-content-center">
                                                                                    <div className="col-12 d-flex">
                                                                                        <input onChange={e => setReportText(e.target.value)} value={reportText} placeholder="Type Here..." className="chat-input" type="text" name="request-doc-input" />
                                                                                        <input hidden className="upload-comment-files" id='upload-comment-files' type="file" multiple="multiple" onChange={(e) => { inputHandler(e.target.files) }} />
                                                                                        {authUser.id === singleCare.doctor_id ?
                                                                                            <label className="upload-comment-files" htmlFor="upload-comment-files" >
                                                                                                <svg id="_001-attachment" data-name="001-attachment" xmlns="http://www.w3.org/2000/svg" width="18.985" height="16.696" viewBox="0 0 18.985 16.696">
                                                                                                    <g id="Group_118" data-name="Group 118" transform="translate(0)">
                                                                                                        <path id="Path_65" data-name="Path 65" d="M17.251,32.908a5.922,5.922,0,0,0-8.373,0l-7.67,7.681a4.135,4.135,0,0,0,5.844,5.852l7.67-7.681a2.352,2.352,0,0,0,0-3.32,2.342,2.342,0,0,0-3.315,0L5,41.855a.557.557,0,0,0,.787.788l6.406-6.415a1.23,1.23,0,0,1,1.742,0,1.236,1.236,0,0,1,0,1.744l-7.67,7.681a3.022,3.022,0,0,1-4.271-4.277L9.665,33.7a4.811,4.811,0,0,1,6.8,6.809l-6.406,6.415a.557.557,0,1,0,.787.788l6.406-6.415a5.934,5.934,0,0,0,0-8.384Z" transform="translate(0 -31.174)" fill="#25d6ff" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </label>
                                                                                            :
                                                                                            <label className="upload-comment-files-not-main " htmlFor="upload-comment-files" >
                                                                                                <svg id="_001-attachment" data-name="001-attachment" xmlns="http://www.w3.org/2000/svg" width="18.985" height="16.696" viewBox="0 0 18.985 16.696">
                                                                                                    <g id="Group_118" data-name="Group 118" transform="translate(0)">
                                                                                                        <path id="Path_65" data-name="Path 65" d="M17.251,32.908a5.922,5.922,0,0,0-8.373,0l-7.67,7.681a4.135,4.135,0,0,0,5.844,5.852l7.67-7.681a2.352,2.352,0,0,0,0-3.32,2.342,2.342,0,0,0-3.315,0L5,41.855a.557.557,0,0,0,.787.788l6.406-6.415a1.23,1.23,0,0,1,1.742,0,1.236,1.236,0,0,1,0,1.744l-7.67,7.681a3.022,3.022,0,0,1-4.271-4.277L9.665,33.7a4.811,4.811,0,0,1,6.8,6.809l-6.406,6.415a.557.557,0,1,0,.787.788l6.406-6.415a5.934,5.934,0,0,0,0-8.384Z" transform="translate(0 -31.174)" fill="#25d6ff" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </label>
                                                                                        }
                                                                                        {authUser.id === singleCare.doctor_id ?
                                                                                            <Dropdown className="comments-dropdown block-small" options={commentStatuses} value={publicComment} onChange={setPublicComment} />
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                        <button className="chat-button pr-3 pl-3" type="submit"><span className="d-none d-lg-block">SEND</span>
                                                                                            <img className="send-button d-block d-lg-none" src={'/img/send.svg'} alt="Send" />
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-12 pt-3 d-block d-md-none d-flex justify-content-center align-items-center">
                                                                                        <div className="row no-gutters">
                                                                                            <div onClick={() => publicCommentRadio === 1 ? setPublicCommentRadio(0) : setPublicCommentRadio(1)} className={publicCommentRadio === 1 ? 'radio-public-comment col-auto my-auto' : 'radio-public-comment-selected col-auto my-auto'} />
                                                                                            <div className="col pl-2">
                                                                                                <h3 onClick={() => publicCommentRadio === 1 ? setPublicCommentRadio(0) : setPublicCommentRadio(1)} className="public-radio-text">Public</h3>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>

                                                :
                                                null
                                            }
                                            {pageTab === 2 ?
                                                <div className="row pt-2">
                                                    <div className="col-12 py-2">
                                                        <div className="doctor-request-docs" ref={doctorRequestDocsWrapper}>
                                                            {singleCare.doc_lab_results ?
                                                                singleCare.doc_lab_results.map((singleResult, index) => (
                                                                    <div className="row py-2" key={index}>
                                                                        <div className="col-auto pr-0">
                                                                            {doctor.id === singleResult.doctor_id ?
                                                                                <img className="user-single-records-image small-comment-image" src={doctor.image ? doctor.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                                :
                                                                                addedDoctors.map((singleDoctor, ind) => (
                                                                                    singleDoctor.id === singleResult.doctor_id ?
                                                                                        <img className="user-single-records-image small-comment-image" src={singleDoctor.image ? singleDoctor.full_path_image : '/img/no-pic.jpg'} alt="doc-profile" />
                                                                                        :
                                                                                        null
                                                                                ))
                                                                            }
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    {doctor.id === singleResult.doctor_id ?
                                                                                        <p className="request-doc-title m-0 pb-1">{doctor.name}</p>
                                                                                        :
                                                                                        addedDoctors.map((singleDoctor, ind) => (
                                                                                            singleDoctor.id === singleResult.doctor_id ?
                                                                                                <p className="request-doc-title m-0 pb-1">{singleDoctor.name}</p>
                                                                                                :
                                                                                                null
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <p className="request-box-request-text">{singleResult.request_title}</p>
                                                                                </div>
                                                                                {singleResult.full_path_attached_documents.length > 0 ?
                                                                                    <div className="col-12">
                                                                                        <div className="row no-gutters">
                                                                                            {singleResult.full_path_attached_documents.map((singleDoc, i) => (
                                                                                                <div className="col-auto pr-2">
                                                                                                    <a href={singleDoc} target="_blank" rel="noreferrer" key={i}>
                                                                                                        {singleDoc.toString().includes('.jpg') || singleDoc.toString().includes('.png') ?
                                                                                                            <img className="comment-box-attachements" alt="document" rel="noreferrer" src={singleDoc} />
                                                                                                            :
                                                                                                            singleDoc.toString().includes('.pdf') ?
                                                                                                                <img className="comment-box-attachements" alt="document" rel="noreferrer" src='/img/pdf.png' />
                                                                                                                :
                                                                                                                <img className="comment-box-attachements" alt="document" rel="noreferrer" src='/img/docs.png' />
                                                                                                        }
                                                                                                    </a>
                                                                                                </div>
                                                                                            ))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                <div className="col-12 pt-2">
                                                                                    <p className="chat-date m-0">{dateFormat(singleResult['created_at'], "d mmmm yyyy")}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 py-2 w-75 border-between-content" />
                                                                    </div>
                                                                ))
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>

                                                    {singleCare?.case_status_id !== 5 ?
                                                        <div className="col-12 pt-2">
                                                            <div className="row">
                                                                <div className="col-auto pr-0">
                                                                    <img className="user-single-records-image small-comment-image" src={authUser.image ? authUser.image : '/img/no-pic.jpg'} alt="user-profile" />
                                                                </div>
                                                                <div className="col">
                                                                    <div className="row no-gutters">
                                                                        <div className="col-12">
                                                                            <p className="request-doc-title">Request Test</p>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <form className="w-100" disabled={loadingRequest} onSubmit={submitDocumentRequest}>
                                                                                <div className="row no-gutters justify-content-center">
                                                                                    <div className="col-12 col-lg pr-2 pb-2">
                                                                                        <input onChange={e => setRequestTitle(e.target.value)} value={requestTitle} placeholder="Type Here..." className="request-doc-input w-100" type="text" name="request-doc-input" />
                                                                                        {
                                                                                            errors?.request_title ?
                                                                                                <p className="m-0 pt-1 color-red">{errors.request_title}</p>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col-12 col-md-auto text-lg-right">
                                                                                        <div className="w-95">
                                                                                            {!loadingRequest ?
                                                                                                <button type="submit" disabled={loadingRequest} className="home-buttons">Send</button>
                                                                                                :
                                                                                                <div className="lds-ring pt-2">
                                                                                                    <div></div>
                                                                                                    <div></div>
                                                                                                    <div></div>
                                                                                                    <div></div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        :
                                                        null
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                            {pageTab === 3 ?
                                                <div className="row no-gutters pt-4">
                                                    <div className="col-12 col-md-6 pr-md-4 mb-4">
                                                        <div className="border-dashboard-boxes bg-white h-100">
                                                            <h1 className="single-care-medical-titles pt-4 pl-4 pr-4 pb-2 d-flex">{generalData.patient_records_settings.problems_title}</h1>

                                                            <div className="row no-gutters">
                                                                {problems > 0 ?
                                                                    singleCare.problems.map((singleProblem, i) => (
                                                                        <div className="col-12" key={i}>
                                                                            <div className="single-care-items h-100">
                                                                                <div className="row no-gutters align-items-center h-100 pl-2">
                                                                                    {singleProblem.problem ?
                                                                                        <div className="col-auto text-center pl-3 py-auto my-auto">
                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    <div className="col-6 py-2 pl-2 report-names">
                                                                                        {singleProblem.problem}
                                                                                    </div>
                                                                                    <div className="col pr-3 text-right py-2 report-names">
                                                                                        {singleProblem.duration}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <div className="col-12 pb-3">
                                                                        <div className="row align-items-center no-gutters h-100 pl-2">
                                                                            <div className="col-auto text-center pl-3 my-auto">
                                                                                <p className="m-0 report-names">No Problems</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-4">
                                                        <div className="border-dashboard-boxes bg-white h-100">
                                                            <h1 className="single-care-medical-titles pt-4 pl-4 pr-4 pb-2">{generalData.patient_records_settings.diagnosis_title}</h1>
                                                            <div className="row no-gutters">
                                                                {diagnosis > 0 ?
                                                                    singleCare.diagnosis.map((singleDiagnosis, i) => (
                                                                        <div className="col-12" key={i}>
                                                                            <div className="single-care-items h-100">
                                                                                <div className="row align-items-center no-gutters h-100 pl-2">
                                                                                    {singleDiagnosis.medical_diagnosis ?
                                                                                        <div className="col-auto text-center pl-3 my-auto">
                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    <div className="col-6 py-2 pl-2 report-names">
                                                                                        {singleDiagnosis.medical_diagnosis}
                                                                                    </div>
                                                                                    <div className="col pr-3 text-right py-2 report-names">
                                                                                        {singleDiagnosis.date}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <div className="col-12 pb-3">
                                                                        <div className="row align-items-center no-gutters h-100 pl-2">
                                                                            <div className="col-auto text-center pl-3 my-auto">
                                                                                <p className="m-0 report-names">No Diagnosis</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 pr-md-4 mb-4">
                                                        <div className="border-dashboard-boxes bg-white h-100">
                                                            <h1 className="single-care-medical-titles pt-4 pl-4 pr-4 pb-2">{generalData.patient_records_settings.surgeries_title}</h1>
                                                            <div className="row no-gutters">
                                                                {surgeries > 0 ?
                                                                    singleCare.surgeries.map((singleSurgeries, i) => (
                                                                        <div className="col-12" key={i}>
                                                                            <div className="single-care-items h-100">
                                                                                <div className="row no-gutters align-items-center h-100 pl-2">
                                                                                    {singleSurgeries.surgery ?
                                                                                        <div className="col-auto text-center pl-3 my-auto">
                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    <div className="col-6 py-2 pl-2 report-names">
                                                                                        {singleSurgeries.surgery}
                                                                                    </div>
                                                                                    <div className="col pr-3 text-right py-2 report-names">
                                                                                        {singleSurgeries.date}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <div className="col-12 pb-3">
                                                                        <div className="row align-items-center no-gutters h-100 pl-2">
                                                                            <div className="col-auto text-center pl-3 my-auto">
                                                                                <p className="m-0 report-names">No Surgeries</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-4">
                                                        <div className="border-dashboard-boxes bg-white h-100">
                                                            <h1 className="single-care-medical-titles pt-4 pl-4 pr-4 pb-2">{generalData.patient_records_settings.medications_title}</h1>
                                                            <div className="row no-gutters">
                                                                {medications ?
                                                                    singleCare.medications.map((singleMedication, i) => (
                                                                        <div className="col-12" key={i}>
                                                                            <div className="single-care-items h-100">
                                                                                <div className="row no-gutters align-items-center h-100 pl-2">
                                                                                    {singleMedication.medication ?
                                                                                        <div className="col-auto text-center pl-3 my-auto">
                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    <div className="col py-2 pl-2 report-names">
                                                                                        {singleMedication.medication}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <div className="col-12 pb-3">
                                                                        <div className="row align-items-center no-gutters h-100 pl-2">
                                                                            <div className="col-auto text-center pl-3 my-auto">
                                                                                <p className="m-0 report-names">No Medications</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <div className="border-dashboard-boxes bg-white h-100">
                                                            <h1 className="single-care-medical-titles pt-4 pl-4 pr-4 pb-2">{generalData.patient_records_settings.hospital_title}</h1>
                                                            <div className="row no-gutters">
                                                                {singleCare.hospital_services ?
                                                                    singleCare.hospital_services.map((singlehospital, i) => (
                                                                        <div className="col-12" key={i}>
                                                                            <div className="single-care-items h-100">
                                                                                <div className="row no-gutters align-items-center h-100 pl-2">
                                                                                    <div className="col pl-3">
                                                                                        <div className="row no-gutters">
                                                                                            <div className="col-12 py-2 pl-0 report-names">
                                                                                                <span className="color-dark-blue">Hospital Name:</span> {singlehospital.hospital_name}
                                                                                            </div>
                                                                                            <div className="col-12 py-2 pl-0 report-names">
                                                                                                <span className="color-dark-blue">Address: </span>{singlehospital.address}
                                                                                            </div>
                                                                                            <div className="col-12 py-2 pl-0 pl-0 report-names">
                                                                                                <span className="color-dark-blue">Contact Info: </span>{singlehospital.contact_info}
                                                                                            </div>
                                                                                            <div className="col-12 py-2 pl-0 report-names">
                                                                                                <span className="color-dark-blue">Doctor Name: </span>{singlehospital.doctor_name}
                                                                                            </div>
                                                                                            <div className="col-12 py-2 pl-0 report-names">
                                                                                                <span className="color-dark-blue">Doctor Contact: </span> {singlehospital.doctor_contact}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="border-dashboard-boxes bg-white h-100">
                                                            <h1 className="single-care-medical-titles pt-4 pl-4 pr-4 pb-2">{generalData.patient_records_settings.reports_title}</h1>
                                                            <div className="row no-gutters">
                                                                <div className="col-12">
                                                                    <div className="single-care-items h-100">
                                                                        {singleCare.others?.length > 0 ||
                                                                            singleCare.genetics?.length > 0 ||
                                                                            singleCare.investigations?.length > 0 ||
                                                                            singleCare.histopathology?.length > 0 ||
                                                                            singleCare.radiology?.length > 0 ||
                                                                            singleCare.lab_results?.length > 0 ||
                                                                            singleCare.reports?.length > 0 ?
                                                                            <div className="row no-gutters align-items-center h-100 pl-2">
                                                                                {
                                                                                    singleCare.reports.map((singleReport, index) => (
                                                                                        singleReport?.name !== null && singleReport?.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">Written Reports {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }
                                                                                {
                                                                                    singleCare.lab_results.map((singleReport, index) => (
                                                                                        singleReport.name !== null && singleReport.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">Lab Results {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }

                                                                                {
                                                                                    singleCare.radiology.map((singleReport, index) => (
                                                                                        singleReport.name !== null && singleReport.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">Radiology Results {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }

                                                                                {
                                                                                    singleCare.histopathology.map((singleReport, index) => (
                                                                                        singleReport.name !== null && singleReport.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">Histopathology Results {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }


                                                                                {
                                                                                    singleCare.investigations.map((singleReport, index) => (
                                                                                        singleReport.name !== null && singleReport.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">Investigations Results {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }

                                                                                {
                                                                                    singleCare.genetics.map((singleReport, index) => (
                                                                                        singleReport.name !== null && singleReport.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">Genetics Results {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }

                                                                                {
                                                                                    singleCare.others.map((singleReport, index) => (
                                                                                        singleReport.name !== null && singleReport.report !== null && singleReport?.report !== 'null' ?
                                                                                            <>
                                                                                                <div className="col-12 col-md-6">
                                                                                                    <div className="row no-gutters">
                                                                                                        <div className="col-auto pl-3 text-center py-auto my-auto">
                                                                                                            <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                                                                <circle id="Ellipse_97" data-name="Ellipse 97" cx="3" cy="3" r="3" fill="#25d6ff" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="col py-2 pl-2">
                                                                                                            <a style={{ textDecoration: 'none' }} className="report-names" href={singleReport.full_path_report} rel="noreferrer" target="_blank">{singleReport.file_name ? singleReport.file_name : 'Other Reports'} {index + 1} </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className="row no-gutters align-items-center h-100 pl-2">
                                                                                <div className="col-12 pl-3 py-2 pl-0 report-names">
                                                                                    <span className="color-dark-blue">No Reports Yet</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            {pageTab === 4 ?
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row justify-content-end">
                                                            <div className="col-12 col-sm-6 col-md-4 pb-3 py-sm-3 text-right">
                                                                <input className="search-input position-relative" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
                                                                <svg onClick={() => setSubmitSearch(1)} className="search-svg" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                    <g id="_001-loupe" data-name="001-loupe" transform="translate(0 -0.003)">
                                                                        <g id="Group_2" data-name="Group 2" transform="translate(0 0.003)">
                                                                            <path id="Path_3" data-name="Path 3" d="M19.756,18.58l-5.687-5.687a7.932,7.932,0,1,0-1.178,1.178l5.687,5.687a.833.833,0,1,0,1.178-1.178ZM7.917,14.17a6.25,6.25,0,1,1,6.25-6.25A6.257,6.257,0,0,1,7.917,14.17Z" transform="translate(0 -0.003)" fill="#25d6ff" />
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="table-wrapper">
                                                                    <table className="payments" width="100%">
                                                                        <thead>
                                                                            <tr>
                                                                                <td>DATE</td>
                                                                                <td>FROM</td>
                                                                                <td>AMOUNT</td>
                                                                                <td>CASE ID</td>
                                                                                <td>STATUS</td>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                payments?.length > 0 ?
                                                                                    payments?.map((singlePayment, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>{dateFormat(singlePayment.created_at, "d mmmm yyyy")}</td>
                                                                                            <td>{(singlePayment.user?.first_name || singlePayment.user?.last_name) ? singlePayment.user?.first_name + ' ' + singlePayment.user?.last_name : (singlePayment?.user?.name ? singlePayment?.user?.name : '' )}</td>
                                                                                            <td>{singlePayment.doctor_amount} GBP</td>
                                                                                            <td>{singlePayment.get_care_info_data_id}</td>
                                                                                            <td className={singlePayment.pay_date ? 'green' : 'orange'}>{singlePayment.pay_date ? 'Paid' : 'Pending'}</td>
                                                                                        </tr>
                                                                                    ))
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            !payments?.length > 0 ?
                                                                <div className="row justify-content-center py-3">
                                                                    <p className="m-0">No Payments Available</p>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            !!(currentPage !== lastPage) && (
                                                                <div className="row justify-content-center pt-5">

                                                                    <button className={!loadingForm ? "load-more home-buttons" : " hide"} onClick={() => { setCurrentPage(currentPage + 1); }} animate="down">
                                                                        LOAD MORE</button>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                                :
                                                null
                                            }

                                            {pageTab === 5 ?
                                                previousReports?.length > 0 ?
                                                    <div className="row no-gutters pt-2">
                                                        {previousReports.map((singleReport, index) => (
                                                            singleReport.closed_case === 1 ?

                                                                <div className="col-12 px-3 my-2 py-3 border-dashboard-boxes-big" key={index}>
                                                                    <div className="row no-gutters align-items-center">
                                                                        <div className="col-auto pr-2">
                                                                            <img className="previous-reports-image" src={doctor.image ? doctor.full_path_image : '/img/no-pic.jpg'} alt="user" />
                                                                        </div>
                                                                        <div className="col-auto pt-2">
                                                                            <h3 className="previous-care-title">Case #{singleReport.get_care_info_data.id}</h3>
                                                                            <div className="previous-small-text">
                                                                                {
                                                                                    singleReport.get_care_info_data.concerns.map((singleConcern, i) => (
                                                                                        (i + 1 === Number(singleReport.get_care_info_data.concerns.length)) ? singleConcern.concern : singleConcern.concern + ', '
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                            <p className="previous-small-text m-0">Created On {dateFormat(singleReport.get_care_info_data['created_at'], "d mmmm yyyy")}</p>
                                                                        </div>
                                                                        <div className="col-12 col-sm d-flex justify-content-center justify-content-sm-end pt-2 pt-sm-0">
                                                                            <a href={singleReport.full_path_final_report} target="_blank" rel="noreferrer">
                                                                                <button className="previous-reports">VIEW REPORT</button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        ))
                                                        }
                                                    </div>
                                                    :
                                                    <div className="row no-gutters pt-4">
                                                        <div className="col-12">
                                                            <h4 className="no-data-available">No Previous Records</h4>
                                                        </div>
                                                    </div>
                                                :
                                                null
                                            }
                                        </div>
                                        {authUser.id === singleCare.doctor_id && singleCare?.final_report?.closed_case !== 1 ?
                                            <div className="row pb-5 pb-md-4">
                                                <div className="col-12 align-items-center justify-content-center justify-content-md-end d-flex flex-column flex-md-row">
                                                    <Link className={singleCare?.final_report ? 'mb-2 mb-md-0 mr-0 mr-md-3' : ''} to={'/doctor-records/doctor-single-record/doctor-final-report/' + email + '/' + token}>
                                                        <button className="home-buttons small-button-font-size pt-1 pb-1 font-size-14" type="button">{generalData.case_report_settings.fill_report_button}</button>
                                                    </Link>
                                                    {authUser.id === singleCare.doctor_id && singleCare?.final_report ?
                                                        <button className="home-buttons small-button-font-size pt-1 pb-1 font-size-14" onClick={() => setCloseEpisodePopup(!closeEpisodePopup)} type="button">CLOSE EPISODE</button>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            {addDoctor ?
                                <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                    <div className="pop-up-api-box doctor-popup-width add-doc-bg">
                                        <div className="container">
                                            <div className="row align-items-center justify-content-center">
                                                <svg className="close-svg" onClick={() => closeDoctorPopup()} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                                    <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                                        <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                        <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                                    </g>
                                                </svg>
                                                <div className="content background-light text-center px-1 py-5 w-100">
                                                    <h1>{generalData.doctor_cases_settings.patient_title}</h1>
                                                    <div className="col-12">
                                                        <form onSubmit={submitAddDoctor} disabled={formLoader} autoComplete="off" className="w-100">
                                                            <div className="row justify-content-center">
                                                                <div className="col-12 col-lg-7 pt-3 pt-lg-4 w-100">
                                                                    <input className="input-popup" onChange={e => setName(e.target.value)} value={name} name="name" type="text" placeholder={generalData.doctor_cases_settings.add_doctor_name} />
                                                                    {
                                                                        errors ?
                                                                            <p className="m-0 pt-1 color-red">{errors?.name}</p>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                <div className="col-12 col-lg-7 pt-3 pt-lg-4 w-100">
                                                                    <input className="input-popup" onChange={e => setDoctorEmail(e.target.value)} value={doctorEmail} name="email" type="text" placeholder={generalData.doctor_cases_settings.add_doctor_email} />
                                                                    {
                                                                        errors ?
                                                                            <p className="m-0 pt-1 color-red">{errors?.email}</p>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                <div className="col-12 col-lg-7 pt-3 pt-lg-4 w-100">
                                                                    <textarea className="input-popup" onChange={e => setMessage(e.target.value)} value={message} name="message" type="text" placeholder={generalData.doctor_cases_settings.add_doctor_message} />
                                                                    {
                                                                        errors ?
                                                                            <p className="m-0 pt-1 color-red">{errors.message}</p>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>

                                                                {

                                                                    successMesssage === 1 ?
                                                                        <div className="col-12">
                                                                            <p className="m-0 pt-1 color-green">{generalData.doctor_cases_settings.add_doctor_success}</p>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    successMesssage === 2 ?
                                                                        <div className="col-12">
                                                                            <p className="m-0 pt-1 color-green">{generalData.doctor_cases_settings.add_doctor_email_sent}</p>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    errors ?
                                                                        <div className="col-12">
                                                                            <p className="m-0 pt-1 color-red">{errors.custom_errors}</p>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    formLoader ?
                                                                        <div className="col-12 pt-3 pl-3">
                                                                            <div className="lds-ring">
                                                                                <div></div>
                                                                                <div></div>
                                                                                <div></div>
                                                                                <div></div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                                <div className="col-12 text-center pt-3">
                                                                    <button type="submit" disabled={formLoader} className="home-buttons pr-5 pl-5">ADD</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                        :
                        <div className="col-12 pt-3 pl-3 d-flex justify-content-center">
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    }
                </div>
                {closeEpisodePopup ?
                    <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box doctor-popup-width add-doc-bg">
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <svg className="close-svg" onClick={() => setCloseEpisodePopup(false)} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                        <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                            <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                            <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                        </g>
                                    </svg>
                                    <div className="content background-light text-center px-1 py-5 w-100">
                                        <h1>{generalData.case_report_settings.close_case_title}</h1>
                                        <h5 className="py-4">{generalData.case_report_settings.close_case_text}{singleCare.id}</h5>
                                        <div className="col-12">
                                            <form onSubmit={closeCase} disabled={formLoader} autoComplete="off" className="w-100">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 text-center pt-3">
                                                        <button type="submit" disabled={formLoader} className="home-buttons pr-5 pl-5">
                                                            {generalData.case_report_settings.close_case_button}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }

                {meetingPopup ?
                    <Meetings meeting={popupType} meetingPopup={() => closePop()} caseDoctor={doctor} addedCaseDoctors={addedDoctors} careId={singleCare.id} singleMeetingInfo={meetingInfo} />
                    :
                    null
                }
                <Api options={generalDataApiOptions} />
                <Api options={closedCaseApi} />
                <Api options={generalDataApiOptions2} />
            </div>
        </>
    );
}
import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import dateFormat from 'dateformat';


export default function Main(props) {
    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions] = useState({});
    const [loading] = useState(false);
    const [authUser] = authUserGlobal;
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [getCareCases, setGetCareCases] = useState();
    const [closedCares, setClosedCares] = useState();


    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading]);

    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);

    useEffect(() => {
        let closedCasesTemp = 0;
        axios.get('get-care-all')
            .then((r) => {
                if (r.data?.length > 0) {
                    r.data?.forEach((singleCase) => (
                        singleCase.final_report?.closed_case === 1 ? closedCasesTemp++ : null
                    ));
                }
                setClosedCares(closedCasesTemp);
                setGetCareCases(r.data?.length);
                // setGetCareCases(r.data)
            });
    }, []);

    return (
        <>
            <UserLayout
                darkMenu={true} />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid view-user-profile equal-spacing-cont" animate="right">
                    <div className="row">
                        <div className="col-12 col-md-6 pt-3 w-100">
                            <p className="dashboard-title" animate="left">{generalData.patient_profile_settings.view_profile_title}</p>
                        </div>
                        <div className="col-12">
                            <div className="border-dashboard-boxes fill-dark h-100">
                                <div className="py-4 px-3">
                                    <div className="row justify-content-center">
                                        <div className="col-12 text-right pb-3 d-block d-lg-none pr-4">
                                            <Link to={'/user-edit-profile'} className="view-profile-edit">Edit</Link>
                                        </div>
                                        <div className="col-12 col-lg-auto text-center">
                                            <img className="user-single-records-image view-profile-image mx-auto d-block" src={authUser.image ? authUser.image : '/img/no-pic.jpg'} alt="user-profile" />
                                            {
                                                authUser.first_name ?

                                                    <h2 className="view-user-profile-username">{authUser.first_name} {authUser.last_name ? authUser.last_name : null}</h2>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="col-12 col-lg pt-3 pt-lg-0 h-100">
                                            <div className="row flex-start">
                                                <div className="col-12 text-right pb-3 d-none d-lg-block pr-4">
                                                    <Link to={'/user-edit-profile'} className="view-profile-edit">Edit</Link>
                                                </div>
                                                <div className="col-1 d-none d-lg-block"></div>
                                                <div className="col-12 col-lg-11 d-flex">
                                                    <div className="row no-gutters w-100">
                                                        <div className="col-12 col-md-6 pb-4 pr-0 pr-md-2">
                                                            <h3 className="view-profile-titles">{generalData.patient_profile_settings.email_title}</h3>
                                                            {authUser.email ?
                                                                <h4 className="view-profile-data-titles">{authUser.email}</h4>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pb-4">
                                                            <h3 className="view-profile-titles">{generalData.patient_profile_settings.date_of_birth_title}</h3>
                                                            {authUser.date_of_birth ?
                                                                <h4 className="view-profile-data-titles">{dateFormat(authUser.date_of_birth, "d mmmm, yyyy")}</h4>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pb-4">
                                                            <h3 className="view-profile-titles">{generalData.patient_profile_settings.phone_number_title}</h3>
                                                            {authUser.phone_number ?
                                                                <h4 className="view-profile-data-titles">{authUser.phone_number}</h4>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pb-4">
                                                            <h3 className="view-profile-titles">{generalData.patient_profile_settings.gender_title}</h3>
                                                            {authUser.user_gender_id ?
                                                                <h4 className="view-profile-data-titles">
                                                                    {
                                                                        generalData.user_gender.map((singleGender) => (
                                                                            singleGender.id === authUser.user_gender_id ?
                                                                                singleGender.gender
                                                                                :
                                                                                null
                                                                        ))
                                                                    }
                                                                </h4>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 py-3">
                            <div className="row">
                                <div className="col-12 col-md-6 text-center pt-3 pb-3 pb-md-0">
                                    <Link style={{ textDecoration: 'none' }} to="/user-records">
                                        <div className="border-dashboard-boxes border-dark-blue" animate="right">
                                            <div className="pt-4 pb-3">
                                                <img className="dashboard-icons-backend" src={generalData.patient_profile_settings.records_icon} alt="icon" />
                                            </div>
                                            <p className="dashboard-card-title pb-3">{generalData.patient_profile_settings.records_title}</p>
                                            <div className="services-count-container">{getCareCases ? getCareCases : 0}</div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-12 col-md-6 text-center pt-3">
                                    <Link style={{ textDecoration: 'none' }} to={closedCares > 0 ? '/user-test-results' : '#'}>
                                        <div className="border-dashboard-boxes border-dark-blue" animate="right">
                                            <div className="pt-4 pb-3">
                                                <img className="dashboard-icons-backend" src={generalData.patient_profile_settings.results_icon} alt="icon" />
                                            </div>
                                            <p className="dashboard-card-title pb-3">{generalData.patient_profile_settings.results_title}</p>
                                            <div className="services-count-container">{closedCares}</div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pb-3">
                            <div className="row">
                                <div className="col-12 col-md-6 py-3">
                                    <Link style={{ textDecoration: 'none' }} to={'/user-records'} >
                                        <div className="border-dashboard-boxes fill-dark h-100">
                                            <div className="py-4 px-4">
                                                <div className="row align-items-center justify-content-between">
                                                    <div className="col-auto">
                                                        <img className="dashboard-icons-backend" src={generalData.patient_profile_settings.medical_history_icon} alt="icon" />
                                                    </div>
                                                    <div className="col-auto">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="43.209" height="27.337" viewBox="0 0 43.209 27.337">
                                                            <g id="Group_115" data-name="Group 115" transform="translate(1 1.414)">
                                                                <g id="Group_86" data-name="Group 86">
                                                                    <line id="Line_19" data-name="Line 19" x2="40.371" transform="translate(0 12.867)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                                                                    <path id="Path_34" data-name="Path 34" d="M625.35,259.56l12.771,12.77-11.738,11.738" transform="translate(-597.326 -259.56)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="row pt-5">
                                                    <div className="col-12">
                                                        <h4 className="view-profile-data-titles">{generalData.patient_profile_settings.medical_history_title}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-12 col-md-6 py-3">
                                    <Link style={{ textDecoration: 'none' }} to={'/user-payments'} >
                                        <div className="border-dashboard-boxes fill-dark h-100">
                                            <div className="py-4 px-4">
                                                <div className="row align-items-center justify-content-between">
                                                    <div className="col-auto">
                                                        <img className="dashboard-icons-backend" src={generalData.patient_profile_settings.payment_record_icon} alt="icon" />
                                                    </div>
                                                    <div className="col-auto">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="43.209" height="27.337" viewBox="0 0 43.209 27.337">
                                                            <g id="Group_115" data-name="Group 115" transform="translate(1 1.414)">
                                                                <g id="Group_86" data-name="Group 86">
                                                                    <line id="Line_19" data-name="Line 19" x2="40.371" transform="translate(0 12.867)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                                                                    <path id="Path_34" data-name="Path 34" d="M625.35,259.56l12.771,12.77-11.738,11.738" transform="translate(-597.326 -259.56)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="row pt-5">
                                                    <div className="col-12">
                                                        <h4 className="view-profile-data-titles">{generalData.patient_profile_settings.payment_record_title}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
import { useState, useContext, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Api from "../components/Api";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';
import 'react-dropdown/style.css';
import { sha256 } from 'js-sha256';


const paymentDate = (date) => {
    return `${date.getFullYear()}:${date.getMonth() < 9 ? '0' + Number(Number(date.getMonth()) + 1) : Number(date.getMonth()) + 1}:${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}-${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`;
}

function bin2hex(s) {
    var i, f = 0, a = [];
    s += '';
    f = s.length;

    for (i = 0; i < f; i++) {
        a[i] = s.charCodeAt(i).toString(16).replace(/^([\da-f])$/, "0$1");
    }

    return a.join('');
}



function createHash(chargetotal, Currency, paydate, $shared) {
    let storeId = "7220540956";
    // NOTE: Please DO NOT hardcode the secret in that script. For example read it from a database.
    let sharedSecret = $shared;
    let stringToHash = storeId + paydate + chargetotal + Currency + sharedSecret;
    let ascii = bin2hex(stringToHash);


    return sha256(ascii);
}

export default function DoctorCourseCheckout(props) {
    let { reason } = useParams();
    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [loading] = useState(false);
    const [authUser] = authUserGlobal;
    const [singleCourse, setSingleCourse] = useState();
    const [ , setCourseLearnings] = useState();
    const [ , setCourseLessons] = useState();
    const [date, setDate] = useState();
    const [failReason] = useState(reason ? reason : '');
    const [reasonPopup, setReasonPopup] = useState(false);
    const [shared, setShared] = useState('');

    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);

    let { slug } = useParams();
    let { course_slug } = useParams();

    useEffect(() => {
        setDate(paymentDate(new Date()));
        axios.get('get-shared')
            .then((r) => {
                setShared(r.data);
            });
    }, []);

    useEffect(() => {
        if (failReason) {
            setReasonPopup(true);
        }
    }, [failReason]);

    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading]);

    useEffect(() => {
        setGeneralDataApiOptions({
            url: 'doctor-single-course/' + course_slug,
            method: 'get',
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                setSingleCourse(res.data['single_course']);
                if (res.data['single_course']?.learnings?.length > 0) {
                    setCourseLearnings(res.data['single_course']?.learnings);
                }
                if (res.data['single_course']?.lessons?.length > 0) {
                    setCourseLessons(res.data['single_course']?.lessons);
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // const checkoutCourse = (e) => {
    //     setGeneralDataApiOptions({
    //         url: 'doctor-buy-course/' + slug + '/' + course_slug,
    //         method: 'get',
    //         callback: res => {
    //             history.push('/doctor-courses/doctor-single-course-category/' + slug + '/single-course/' + course_slug);
    //         }
    //     })
    // }


    let history = useHistory();

    return (
        <>
            <UserLayout
                activeUserPage="courses"
                darkMenu={true}
            />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row">
                        <div className="col-12 col-md-5 pt-3 w-100 d-flex" animate="left">
                            <Link to={'/doctor-courses/doctor-single-course-category/' + slug + '/single-course/' + course_slug} className="d-flex my-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.992" height="19.986" viewBox="0 0 10.992 19.986">
                                    <path id="Path_77" data-name="Path 77" d="M-4195.109,1774l-8.579,8.578,8.579,8.579" transform="translate(4204.688 -1772.586)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </svg>
                            </Link>
                            <p className="dashboard-title d-flex m-0 pl-4 my-auto">
                                CHECKOUT
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        {
                            singleCourse ?
                                <>
                                    <div className="col-12 py-4">
                                        <div className="row h-100">
                                            <div className="col-12 col-xl-7 mb-4 mb-xl-0 pb-4 pb-md-0" >
                                                <div className="border-dark-blue border-radius-25 p-4 h-100">
                                                    <h2 className="course-border-blue-title">{singleCourse.title}</h2>
                                                    <div className="row pt-2">
                                                        <div className="col-auto pr-0">
                                                            <img className="checkout-course-image" src={singleCourse.full_path_image} alt={singleCourse.title} />
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col-12 d-flex justify-content-between">
                                                                    <p className="m-0 checkout-author-name">{singleCourse.author_name}</p>
                                                                    <p className="m-0 checkout-desc-price">{singleCourse.price} GBP</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    {
                                                                        <div className="content course-description"
                                                                            dangerouslySetInnerHTML={{ __html: singleCourse.course_details }}>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                singleCourse ?
                                                    <div className="col-12 col-xl-5 d-flex flex-column">
                                                        <div className="border-dashboard-boxes fill-dark p-4 h-100">
                                                            <h2 className="dashboard-title">SUMMARY</h2>
                                                            <div className="row px-3 h-100">
                                                                <div className="col-12 d-flex justify-content-between pt-4 pb-2">
                                                                    <p className="m-0 course-border-blue-small-title">{singleCourse.title}</p>
                                                                    <p className="m-0 course-border-blue-small-title">GBP {singleCourse.price}</p>
                                                                </div>
                                                                <hr className="mx-3 my-1" />
                                                                <div className="col-12 d-flex justify-content-between pt-2">
                                                                    <p className="m-0 course-border-blue-title">TOTAL: </p>
                                                                    <p className="m-0 course-border-blue-title">GBP {singleCourse.price}</p>
                                                                </div>
                                                                <div className="col-12 d-flex justify-content-between pt-5 pb-2">
                                                                    <p className="m-0 course-border-blue-small-title">By placing your order you agree to the terms and conditions</p>
                                                                </div>
                                                                {/* <div className="col-12 text-center py-4">
                                                                    <form action={'https://test.ipg-online.com/connect/gateway/processing'} method="post">
                                                                        <input type="hidden" name="txntype" value="sale" />
                                                                        <input type="hidden" name="timezone" value="Europe/London" />
                                                                        <input type="hidden" name="hash_algorithm" value="SHA256" />
                                                                        <input type="hidden" name="storename" value="7220540956" />
                                                                        <input type="hidden" name="hash" value={createHash(singleCourse.price, 826, date, shared)} />
                                                                        <input type="hidden" name="txndatetime" value={date} />
                                                                        <input type="hidden" name="mode" value="fullpay" />
                                                                        <input type="hidden" name="chargetotal" value={singleCourse.price} />
                                                                        <input type="hidden" name="currency" value="826" />
                                                                        <input type="hidden" name="customerid" value={authUser?.id + '.' + slug + '.' + course_slug} />
                                                                        <input type="hidden" name="responseSuccessURL" value="https://dev-mediconnect.hellotree.dev/api/course-payment-success" />
                                                                        <input type="hidden" name="responseFailURL" value="https://dev-mediconnect.hellotree.dev/api/course-payment-fail" />
                                                                        {
                                                                            shared && date ?
                                                                                <button type="submit" className="home-buttons">CHECKOUT</button>
                                                                                :
                                                                                null
                                                                        }
                                                                    </form>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }
                    </div>
                    {reasonPopup ?
                        <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <svg className="close-svg" onClick={() => { setReasonPopup(false); history.push('/doctor-courses/doctor-single-course-category/'+slug+'/single-course/checkout/'+course_slug) }} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                    <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                        <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                        <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                    </g>
                                </svg>
                                <div className="content background-light text-center px-5 py-5">
                                    <p className="my-2">{failReason}</p>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    <Api options={generalDataApiOptions} />
                </div>
            </div>
        </>
    );
}
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import GlobalState from "../contexts/GlobalState";


function Main(props) {

    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [authUser, setAuthUser] = authUserGlobal;
    const [usersMenuOpen, setUsersMenuOpen] = usersMenuOpenGlobal;
    const [menuClicked, setMenuClicked] = useState(false);
    const [notificationsCount] = notificationsGlobal;


    useEffect(() => {
    }, [generalData]);


    useEffect(() => {
        if (window.innerWidth < 991 && usersMenuOpen) {
            document.getElementsByTagName("html")[0].classList.remove('lock-scroll');
            setUsersMenuOpen(!usersMenuOpen);
        }
        // if (inViewport(document.getElementById("burger-button")) && usersMenuOpen) {
        //     document.getElementsByTagName("html")[0].classList.remove('lock-scroll');
        //     setUsersMenuOpen(!usersMenuOpen);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuClicked]);


    return (
        generalData ?
            <>
                <div className={'users-menu ' + (usersMenuOpen ? 'open' : '') + (props.darkMenu ? ' menu-bg-dark' : '')}>
                    {/* <div className={}> */}
                    {usersMenuOpen ?
                        <div className={'users-menu-open users-menu-content'}>
                            <img src='/img/home-dark-logo.png' alt="main-logo" className="logo" />
                            <img src={authUser.image ? authUser.image : '/img/no-pic.jpg'} alt="user-profile" className="user-image" />
                            <h1 className="user-name"><span>{authUser.first_name && authUser.last_name ? authUser.first_name + ' ' + authUser.last_name : authUser.name}</span></h1>
                            <Link style={{ textDecoration: 'none' }} to={authUser.user_types_id === 2 ? '/user-view-profile' : '/doctor-view-profile'} onClick={() => setMenuClicked(!menuClicked)}><p className="view-profile"><span>{generalData.dashboard_settings.view_profile_title}</span></p></Link>
                            <hr />
                            {authUser.user_types_id === 2 ?
                                <Link style={{ textDecoration: 'none' }} to="/user-dashboard" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={props.activeUserPage === "dashboard" ? 'users-menu-items active d-flex' : 'users-menu-items d-flex'}>
                                        <svg id="fi-rr-layout-fluid" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Path_34" data-name="Path 34" d="M2,11H13a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2H2A2,2,0,0,0,0,2V9a2,2,0,0,0,2,2ZM2,2H13V9H2Z" fill="#C7C7C7" />
                                            <path id="Path_35" data-name="Path 35" d="M22,0H19a2,2,0,0,0-2,2V9a2,2,0,0,0,2,2h3a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm0,9H19V2h3Z" fill="#C7C7C7" />
                                            <path id="Path_36" data-name="Path 36" d="M5,13H2a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2H5a2,2,0,0,0,2-2V15A2,2,0,0,0,5,13Zm0,9H2V15H5Z" fill="#C7C7C7" />
                                            <path id="Path_37" data-name="Path 37" d="M22,13H11a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V15A2,2,0,0,0,22,13Zm0,9H11V15H22Z" fill="#C7C7C7" />
                                        </svg>
                                        <h2 className="users-menu-title"><span>{generalData.dashboard_settings.dashboard_title}</span></h2>
                                    </div>
                                </Link>
                                :
                                <Link style={{ textDecoration: 'none' }} to="/doctor-dashboard" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={props.activeUserPage === "dashboard" ? 'users-menu-items active d-flex' : 'users-menu-items d-flex'}>
                                        <svg id="fi-rr-layout-fluid" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Path_34" data-name="Path 34" d="M2,11H13a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2H2A2,2,0,0,0,0,2V9a2,2,0,0,0,2,2ZM2,2H13V9H2Z" fill="#C7C7C7" />
                                            <path id="Path_35" data-name="Path 35" d="M22,0H19a2,2,0,0,0-2,2V9a2,2,0,0,0,2,2h3a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm0,9H19V2h3Z" fill="#C7C7C7" />
                                            <path id="Path_36" data-name="Path 36" d="M5,13H2a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2H5a2,2,0,0,0,2-2V15A2,2,0,0,0,5,13Zm0,9H2V15H5Z" fill="#C7C7C7" />
                                            <path id="Path_37" data-name="Path 37" d="M22,13H11a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V15A2,2,0,0,0,22,13Zm0,9H11V15H22Z" fill="#C7C7C7" />
                                        </svg>
                                        <h2 className="users-menu-title"><span>{generalData.dashboard_settings.dashboard_title}</span></h2>
                                    </div>
                                </Link>
                            }
                            {authUser.user_types_id === 2 ?
                                <Link style={{ textDecoration: 'none' }} to="/user-notifications" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={notificationsCount > 0 ? 'users-menu-items red d-flex' : props.activeUserPage === "notifications" ? 'users-menu-items active d-flex' : 'users-menu-items d-flex'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.991" height="24" viewBox="0 0 18.991 24">
                                            <path id="_001-bell" data-name="001-bell" d="M72.033,19.1A3.729,3.729,0,0,1,70.9,17.556,17.855,17.855,0,0,1,69.718,10.5c0-.009,0-.018,0-.027A6.81,6.81,0,0,0,65.709,4.3V2.674A2.674,2.674,0,0,0,63.04,0h-.221A2.674,2.674,0,0,0,60.15,2.674V4.3a6.81,6.81,0,0,0-4.009,6.2,17.855,17.855,0,0,1-1.181,7.055A3.729,3.729,0,0,1,53.826,19.1a.684.684,0,0,0-.375.777.715.715,0,0,0,.705.55H59.31a3.62,3.62,0,0,0,7.24,0H71.7a.715.715,0,0,0,.705-.55A.684.684,0,0,0,72.033,19.1ZM61.556,2.674a1.265,1.265,0,0,1,1.263-1.265h.221A1.265,1.265,0,0,1,64.3,2.674V3.842a6.81,6.81,0,0,0-2.747,0V2.674Zm1.373,19.918a2.219,2.219,0,0,1-2.214-2.165h4.427A2.219,2.219,0,0,1,62.929,22.591Zm2.831-3.574h-10a7.371,7.371,0,0,0,.368-.647,17.8,17.8,0,0,0,1.42-7.87,5.382,5.382,0,1,1,10.764,0c0,.009,0,.017,0,.026a17.766,17.766,0,0,0,1.42,7.842,7.372,7.372,0,0,0,.368.647Z" transform="translate(-53.434)" fill="#c7c7c7" />
                                        </svg>
                                        <h2 className="users-menu-title"><span>{generalData.dashboard_settings.notifications_title}</span></h2>
                                    </div>
                                </Link>
                                :
                                <Link style={{ textDecoration: 'none' }} to="/doctor-notifications" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={notificationsCount > 0 ? 'users-menu-items red d-flex' : props.activeUserPage === "notifications" ? 'users-menu-items active d-flex' : 'users-menu-items d-flex'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.991" height="24" viewBox="0 0 18.991 24">
                                            <path id="_001-bell" data-name="001-bell" d="M72.033,19.1A3.729,3.729,0,0,1,70.9,17.556,17.855,17.855,0,0,1,69.718,10.5c0-.009,0-.018,0-.027A6.81,6.81,0,0,0,65.709,4.3V2.674A2.674,2.674,0,0,0,63.04,0h-.221A2.674,2.674,0,0,0,60.15,2.674V4.3a6.81,6.81,0,0,0-4.009,6.2,17.855,17.855,0,0,1-1.181,7.055A3.729,3.729,0,0,1,53.826,19.1a.684.684,0,0,0-.375.777.715.715,0,0,0,.705.55H59.31a3.62,3.62,0,0,0,7.24,0H71.7a.715.715,0,0,0,.705-.55A.684.684,0,0,0,72.033,19.1ZM61.556,2.674a1.265,1.265,0,0,1,1.263-1.265h.221A1.265,1.265,0,0,1,64.3,2.674V3.842a6.81,6.81,0,0,0-2.747,0V2.674Zm1.373,19.918a2.219,2.219,0,0,1-2.214-2.165h4.427A2.219,2.219,0,0,1,62.929,22.591Zm2.831-3.574h-10a7.371,7.371,0,0,0,.368-.647,17.8,17.8,0,0,0,1.42-7.87,5.382,5.382,0,1,1,10.764,0c0,.009,0,.017,0,.026a17.766,17.766,0,0,0,1.42,7.842,7.372,7.372,0,0,0,.368.647Z" transform="translate(-53.434)" fill="#c7c7c7" />
                                        </svg>
                                        <h2 className="users-menu-title"><span>{generalData.dashboard_settings.notifications_title}</span></h2>
                                    </div>
                                </Link>
                            }
                            {authUser.user_types_id === 2 ?
                                <Link style={{ textDecoration: 'none' }} to="/user-records" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={props.activeUserPage === "records" ? 'users-menu-items active d-flex' : 'users-menu-items d-flex'}>
                                        <svg id="Group_369" data-name="Group 369" xmlns="http://www.w3.org/2000/svg" width="26.51" height="26.51" viewBox="0 0 26.51 26.51">
                                            <path id="Path_136" data-name="Path 136" d="M25.936,0H8.372A.574.574,0,0,0,7.8.574v1.97a.574.574,0,0,1-.574.574H4.473a.575.575,0,0,0-.574.575v1.97a.574.574,0,0,1-.574.575H.574A.574.574,0,0,0,0,6.812V25.936a.574.574,0,0,0,.574.574H18.139a.574.574,0,0,0,.574-.574v-1.97a.574.574,0,0,1,.574-.574h2.75a.575.575,0,0,0,.574-.574v-1.97a.574.574,0,0,1,.574-.575h2.75a.574.574,0,0,0,.574-.574V.574A.574.574,0,0,0,25.936,0ZM17.154,24.351a.6.6,0,0,1-.6.6H2.16a.6.6,0,0,1-.6-.6V8.4a.6.6,0,0,1,.6-.6H16.553a.6.6,0,0,1,.6.6Zm3.9-3.036a.518.518,0,0,1-.518.518h-1.3a.518.518,0,0,1-.518-.518V6.755a.518.518,0,0,0-.518-.518H5.976a.518.518,0,0,1-.518-.518V5.2a.518.518,0,0,1,.518-.518H20.535a.518.518,0,0,1,.518.518Zm3.9-3.119a.518.518,0,0,1-.518.518h-1.3a.518.518,0,0,1-.518-.518V3.637a.518.518,0,0,0-.518-.518H9.875A.518.518,0,0,1,9.357,2.6V2.077a.518.518,0,0,1,.518-.518H24.433a.518.518,0,0,1,.518.518Z" fill="#c7c7c7" />
                                            <path id="Path_137" data-name="Path 137" d="M61.135,191.623h6a.9.9,0,0,0,.9-.9v-1.439a3.9,3.9,0,0,0-1.719-3.231,3.118,3.118,0,1,0-4.36,0,3.9,3.9,0,0,0-1.719,3.231v1.439A.9.9,0,0,0,61.135,191.623Zm3-9.357a1.559,1.559,0,1,1-1.559,1.559A1.559,1.559,0,0,1,64.134,182.266Zm-2.339,7.017a2.339,2.339,0,0,1,4.678,0v.78H61.794Z" transform="translate(-57.116 -171.35)" fill="#c7c7c7" />
                                            <path id="Path_138" data-name="Path 138" d="M243.28,180.706h-1.559a.78.78,0,0,0,0,1.559h1.559a.78.78,0,0,0,0-1.559Z" transform="translate(-228.466 -171.349)" fill="#c7c7c7" />
                                            <path id="Path_139" data-name="Path 139" d="M243.28,240.941h-1.559a.78.78,0,1,0,0,1.559h1.559a.78.78,0,1,0,0-1.559Z" transform="translate(-228.466 -228.466)" fill="#c7c7c7" />
                                            <rect id="Rectangle_417" data-name="Rectangle 417" width="3.119" height="1.559" rx="0.78" transform="translate(12.475 15.594)" fill="#c7c7c7" />
                                            <rect id="Rectangle_418" data-name="Rectangle 418" width="3.119" height="1.559" rx="0.78" transform="translate(12.475 18.713)" fill="#c7c7c7" />
                                            <rect id="Rectangle_419" data-name="Rectangle 419" width="12.475" height="1.559" rx="0.78" transform="translate(3.119 21.832)" fill="#c7c7c7" />
                                        </svg>
                                        <h2 className="users-menu-title"><span>{generalData.dashboard_settings.records_title}</span></h2>
                                    </div>
                                </Link>
                                :
                                <Link style={{ textDecoration: 'none' }} to="/doctor-records" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={props.activeUserPage === "records" ? 'users-menu-items active d-flex' : 'users-menu-items d-flex'}>
                                        <svg id="Group_369" data-name="Group 369" xmlns="http://www.w3.org/2000/svg" width="26.51" height="26.51" viewBox="0 0 26.51 26.51">
                                            <path id="Path_136" data-name="Path 136" d="M25.936,0H8.372A.574.574,0,0,0,7.8.574v1.97a.574.574,0,0,1-.574.574H4.473a.575.575,0,0,0-.574.575v1.97a.574.574,0,0,1-.574.575H.574A.574.574,0,0,0,0,6.812V25.936a.574.574,0,0,0,.574.574H18.139a.574.574,0,0,0,.574-.574v-1.97a.574.574,0,0,1,.574-.574h2.75a.575.575,0,0,0,.574-.574v-1.97a.574.574,0,0,1,.574-.575h2.75a.574.574,0,0,0,.574-.574V.574A.574.574,0,0,0,25.936,0ZM17.154,24.351a.6.6,0,0,1-.6.6H2.16a.6.6,0,0,1-.6-.6V8.4a.6.6,0,0,1,.6-.6H16.553a.6.6,0,0,1,.6.6Zm3.9-3.036a.518.518,0,0,1-.518.518h-1.3a.518.518,0,0,1-.518-.518V6.755a.518.518,0,0,0-.518-.518H5.976a.518.518,0,0,1-.518-.518V5.2a.518.518,0,0,1,.518-.518H20.535a.518.518,0,0,1,.518.518Zm3.9-3.119a.518.518,0,0,1-.518.518h-1.3a.518.518,0,0,1-.518-.518V3.637a.518.518,0,0,0-.518-.518H9.875A.518.518,0,0,1,9.357,2.6V2.077a.518.518,0,0,1,.518-.518H24.433a.518.518,0,0,1,.518.518Z" fill="#c7c7c7" />
                                            <path id="Path_137" data-name="Path 137" d="M61.135,191.623h6a.9.9,0,0,0,.9-.9v-1.439a3.9,3.9,0,0,0-1.719-3.231,3.118,3.118,0,1,0-4.36,0,3.9,3.9,0,0,0-1.719,3.231v1.439A.9.9,0,0,0,61.135,191.623Zm3-9.357a1.559,1.559,0,1,1-1.559,1.559A1.559,1.559,0,0,1,64.134,182.266Zm-2.339,7.017a2.339,2.339,0,0,1,4.678,0v.78H61.794Z" transform="translate(-57.116 -171.35)" fill="#c7c7c7" />
                                            <path id="Path_138" data-name="Path 138" d="M243.28,180.706h-1.559a.78.78,0,0,0,0,1.559h1.559a.78.78,0,0,0,0-1.559Z" transform="translate(-228.466 -171.349)" fill="#c7c7c7" />
                                            <path id="Path_139" data-name="Path 139" d="M243.28,240.941h-1.559a.78.78,0,1,0,0,1.559h1.559a.78.78,0,1,0,0-1.559Z" transform="translate(-228.466 -228.466)" fill="#c7c7c7" />
                                            <rect id="Rectangle_417" data-name="Rectangle 417" width="3.119" height="1.559" rx="0.78" transform="translate(12.475 15.594)" fill="#c7c7c7" />
                                            <rect id="Rectangle_418" data-name="Rectangle 418" width="3.119" height="1.559" rx="0.78" transform="translate(12.475 18.713)" fill="#c7c7c7" />
                                            <rect id="Rectangle_419" data-name="Rectangle 419" width="12.475" height="1.559" rx="0.78" transform="translate(3.119 21.832)" fill="#c7c7c7" />
                                        </svg>
                                        <h2 className="users-menu-title"><span>{generalData.dashboard_settings.cases_title}</span></h2>
                                    </div>
                                </Link>
                            }
                            {authUser.user_types_id === 2 ?
                                // <Link style={{ textDecoration: 'none' }} to="/user-calendar" onClick={() => setMenuClicked(!menuClicked)}>
                                //     <div className={props.activeUserPage === "calendar" ? 'users-menu-items active d-flex' : 'users-menu-items d-flex'}>
                                //         <svg id="fi-rr-calendar" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                //             <path id="Path_31" data-name="Path 31" d="M19,2H18V1a1,1,0,1,0-2,0V2H8V1A1,1,0,1,0,6,1V2H5A5.006,5.006,0,0,0,0,7V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7A5.006,5.006,0,0,0,19,2ZM2,7A3,3,0,0,1,5,4H19a3,3,0,0,1,3,3V8H2ZM19,22H5a3,3,0,0,1-3-3V10H22v9A3,3,0,0,1,19,22Z" fill="#c7c7c7" />
                                //             <circle id="Ellipse_70" data-name="Ellipse 70" cx="1.5" cy="1.5" r="1.5" transform="translate(10.5 13.5)" fill="#c7c7c7" />
                                //             <circle id="Ellipse_71" data-name="Ellipse 71" cx="1.5" cy="1.5" r="1.5" transform="translate(5.5 13.5)" fill="#c7c7c7" />
                                //             <circle id="Ellipse_72" data-name="Ellipse 72" cx="1.5" cy="1.5" r="1.5" transform="translate(15.5 13.5)" fill="#c7c7c7" />
                                //         </svg>
                                //         <h2 className="users-menu-title"><span>{generalData.dashboard_settings.calendar_title}</span></h2>
                                //     </div>
                                // </Link>
                                null
                                :
                                <Link style={{ textDecoration: 'none' }} to="/doctor-calendar" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={props.activeUserPage === "calendar" ? 'users-menu-items active d-flex' : 'users-menu-items d-flex'}>
                                        <svg id="fi-rr-calendar" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Path_31" data-name="Path 31" d="M19,2H18V1a1,1,0,1,0-2,0V2H8V1A1,1,0,1,0,6,1V2H5A5.006,5.006,0,0,0,0,7V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7A5.006,5.006,0,0,0,19,2ZM2,7A3,3,0,0,1,5,4H19a3,3,0,0,1,3,3V8H2ZM19,22H5a3,3,0,0,1-3-3V10H22v9A3,3,0,0,1,19,22Z" fill="#c7c7c7" />
                                            <circle id="Ellipse_70" data-name="Ellipse 70" cx="1.5" cy="1.5" r="1.5" transform="translate(10.5 13.5)" fill="#c7c7c7" />
                                            <circle id="Ellipse_71" data-name="Ellipse 71" cx="1.5" cy="1.5" r="1.5" transform="translate(5.5 13.5)" fill="#c7c7c7" />
                                            <circle id="Ellipse_72" data-name="Ellipse 72" cx="1.5" cy="1.5" r="1.5" transform="translate(15.5 13.5)" fill="#c7c7c7" />
                                        </svg>
                                        <h2 className="users-menu-title"><span>{generalData.dashboard_settings.calendar_title}</span></h2>
                                    </div>
                                </Link>
                            }
                            {authUser.user_types_id === 2 ?
                                <Link style={{ textDecoration: 'none' }} to="/user-payments" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={props.activeUserPage === "payments" ? 'users-menu-items active d-flex' : 'users-menu-items d-flex'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17" viewBox="0 0 25 17">
                                            <g id="_001-credit-card" data-name="001-credit-card" transform="translate(0.5 0.5)">
                                                <g id="Group_55" data-name="Group 55" transform="translate(0 0)">
                                                    <g id="Group_54" data-name="Group 54">
                                                        <path id="Path_41" data-name="Path 41" d="M21.5,85.333H2.5a2.5,2.5,0,0,0-2.5,2.5v11a2.5,2.5,0,0,0,2.5,2.5h19a2.5,2.5,0,0,0,2.5-2.5v-11A2.5,2.5,0,0,0,21.5,85.333Zm1.5,13.5a1.5,1.5,0,0,1-1.5,1.5H2.5a1.5,1.5,0,0,1-1.5-1.5v-11a1.5,1.5,0,0,1,1.5-1.5h19a1.5,1.5,0,0,1,1.5,1.5v11Z" transform="translate(0 -85.333)" fill="none" stroke="#c4c4c4" strokeWidth="1" />
                                                    </g>
                                                </g>
                                                <g id="Group_57" data-name="Group 57" transform="translate(0 3)">
                                                    <g id="Group_56" data-name="Group 56">
                                                        <path id="Path_42" data-name="Path 42" d="M23.5,149.333H.5a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h23a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,23.5,149.333Zm-.5,3H1v-2H23v2Z" transform="translate(0 -149.333)" fill="none" stroke="#c4c4c4" strokeWidth="1" />
                                                    </g>
                                                </g>
                                                <g id="Group_63" data-name="Group 63" transform="translate(17 9)">
                                                    <g id="Group_62" data-name="Group 62">
                                                        <path id="Path_45" data-name="Path 45" d="M365.167,277.333h-1a1.5,1.5,0,0,0-1.5,1.5v1a1.5,1.5,0,0,0,1.5,1.5h1a1.5,1.5,0,0,0,1.5-1.5v-1A1.5,1.5,0,0,0,365.167,277.333Zm.5,2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z" transform="translate(-362.667 -277.333)" fill="none" stroke="#c4c4c4" strokeWidth="1" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <h2 className="users-menu-title"><span>{generalData.dashboard_settings.payments_title}</span></h2>
                                    </div>
                                </Link>
                                :
                                null
                            }
                            {authUser.user_types_id === 1 ?
                                <Link style={{ textDecoration: 'none' }} to="/doctor-courses" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={props.activeUserPage === "courses" ? 'users-menu-items active d-flex' : 'users-menu-items d-flex'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22.777" height="22.777" viewBox="0 0 22.777 22.777">
                                            <g id="_001-online-test" data-name="001-online-test" transform="translate(0.25 0.25)">
                                                <path id="Path_142" data-name="Path 142" d="M94.967,80.333H80.986a.653.653,0,1,0,0,1.305H94.967a.653.653,0,1,0,0-1.305Z" transform="translate(-76.838 -76.838)" fill="#c7c7c7" stroke="#c3c3c3" strokeWidth="0.5" />
                                                <path id="Path_145" data-name="Path 145" d="M199.077,176.733H185.094a.653.653,0,0,0,0,1.305h13.983a.653.653,0,1,0,0-1.305Z" transform="translate(-180.948 -169.043)" fill="#c7c7c7" stroke="#c3c3c3" strokeWidth="0.5" />
                                                <path id="Path_146" data-name="Path 146" d="M199.1,273.133H185.147a.653.653,0,0,0,0,1.305H199.1a.653.653,0,0,0,0-1.305Z" transform="translate(-180.97 -261.249)" fill="#c7c7c7" stroke="#c3c3c3" strokeWidth="0.5" />
                                                <path id="Path_147" data-name="Path 147" d="M20.227,0H2.051A2.05,2.05,0,0,0,0,2.051V17.43a2.05,2.05,0,0,0,2.051,2.051H7.69v1.491H5.546a.653.653,0,1,0,0,1.305H16.731a.653.653,0,1,0,0-1.305H14.588V19.481h5.639a2.05,2.05,0,0,0,2.051-2.051V2.051A2.05,2.05,0,0,0,20.227,0ZM13.282,20.972H9V19.481h4.287Zm7.69-3.542a.746.746,0,0,1-.745.745H2.051a.746.746,0,0,1-.745-.745v-.885a2.04,2.04,0,0,0,.745.14H20.227a2.05,2.05,0,0,0,.745-.139Zm0-2.8a.746.746,0,0,1-.745.745H2.051a.746.746,0,0,1-.745-.745V2.051a.746.746,0,0,1,.745-.745H20.227a.746.746,0,0,1,.746.745Z" fill="#c7c7c7" stroke="#c3c3c3" strokeWidth="0.5" />
                                            </g>
                                        </svg>
                                        <h2 className="users-menu-title"><span>{generalData.dashboard_settings.courses_title}</span></h2>
                                    </div>
                                </Link>
                                :
                                null
                            }
                            {authUser.user_types_id === 2 ?
                                <Link style={{ textDecoration: 'none' }} to="/user-online-support" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={props.activeUserPage === "support" ? 'users-menu-items active d-flex' : 'users-menu-items d-flex'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.387" height="26.51" viewBox="0 0 24.387 26.51">
                                            <path id="_002-customer-service" data-name="002-customer-service" d="M24.385,12.01A12.193,12.193,0,0,0,0,12.01c0,.114,0,.087,0,4.058A2.835,2.835,0,0,0,2.832,18.9h0a2.835,2.835,0,0,0,2.832-2.832V12.1A2.835,2.835,0,0,0,2.832,9.265a2.864,2.864,0,0,0-.335.02,10.124,10.124,0,0,1,19.393,0,2.868,2.868,0,0,0-.335-.02A2.835,2.835,0,0,0,18.723,12.1v3.97A2.835,2.835,0,0,0,21.555,18.9h0l.082,0a6.253,6.253,0,0,1-5.567,3.415h-.889a3.163,3.163,0,1,0,0,2.071h.889a8.325,8.325,0,0,0,8.317-8.315h0V12.1C24.387,12.068,24.386,12.039,24.385,12.01ZM2.832,11.336a.762.762,0,0,1,.761.761v3.97a.762.762,0,0,1-.761.761h0a.762.762,0,0,1-.761-.761c0-2.63,0-3.722,0-4A.761.761,0,0,1,2.832,11.336Zm9.361,13.1a1.092,1.092,0,1,1,1.092-1.092A1.093,1.093,0,0,1,12.194,24.439Zm10.123-8.372a.762.762,0,0,1-.761.761h0a.762.762,0,0,1-.761-.761V12.1a.761.761,0,0,1,1.521-.032C22.318,12.314,22.315,12.012,22.316,16.068Zm0,0" fill="#c7c7c7" />
                                        </svg>
                                        <h2 className="users-menu-title"><span>{generalData.dashboard_settings.online_support_title}</span></h2>
                                    </div>
                                </Link>
                                :
                                <Link style={{ textDecoration: 'none' }} to="/doctor-payments" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={props.activeUserPage === "payments" ? 'users-menu-items active d-flex' : 'users-menu-items d-flex'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17" viewBox="0 0 25 17">
                                            <g id="_001-credit-card" data-name="001-credit-card" transform="translate(0.5 0.5)">
                                                <g id="Group_55" data-name="Group 55" transform="translate(0 0)">
                                                    <g id="Group_54" data-name="Group 54">
                                                        <path id="Path_41" data-name="Path 41" d="M21.5,85.333H2.5a2.5,2.5,0,0,0-2.5,2.5v11a2.5,2.5,0,0,0,2.5,2.5h19a2.5,2.5,0,0,0,2.5-2.5v-11A2.5,2.5,0,0,0,21.5,85.333Zm1.5,13.5a1.5,1.5,0,0,1-1.5,1.5H2.5a1.5,1.5,0,0,1-1.5-1.5v-11a1.5,1.5,0,0,1,1.5-1.5h19a1.5,1.5,0,0,1,1.5,1.5v11Z" transform="translate(0 -85.333)" fill="none" stroke="#c4c4c4" strokeWidth="1" />
                                                    </g>
                                                </g>
                                                <g id="Group_57" data-name="Group 57" transform="translate(0 3)">
                                                    <g id="Group_56" data-name="Group 56">
                                                        <path id="Path_42" data-name="Path 42" d="M23.5,149.333H.5a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h23a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,23.5,149.333Zm-.5,3H1v-2H23v2Z" transform="translate(0 -149.333)" fill="none" stroke="#c4c4c4" strokeWidth="1" />
                                                    </g>
                                                </g>
                                                <g id="Group_63" data-name="Group 63" transform="translate(17 9)">
                                                    <g id="Group_62" data-name="Group 62">
                                                        <path id="Path_45" data-name="Path 45" d="M365.167,277.333h-1a1.5,1.5,0,0,0-1.5,1.5v1a1.5,1.5,0,0,0,1.5,1.5h1a1.5,1.5,0,0,0,1.5-1.5v-1A1.5,1.5,0,0,0,365.167,277.333Zm.5,2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z" transform="translate(-362.667 -277.333)" fill="none" stroke="#c4c4c4" strokeWidth="1" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <h2 className="users-menu-title"><span>{generalData.dashboard_settings.payments_title}</span></h2>
                                    </div>
                                </Link>
                            }
                            <Link style={{ textDecoration: 'none' }} to="/" onClick={() => setAuthUser()}>
                                <div className="users-menu-items d-flex" onClick={() => localStorage.setItem('auth_token', '')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g id="_001-log-out" data-name="001-log-out" transform="translate(24 24) rotate(180)">
                                            <g id="Group_49" data-name="Group 49">
                                                <g id="Group_48" data-name="Group 48">
                                                    <path id="Path_38" data-name="Path 38" d="M20.25,0H3.75A3.754,3.754,0,0,0,0,3.75v.938a.938.938,0,0,0,1.875,0V3.75A1.877,1.877,0,0,1,3.75,1.875h16.5A1.877,1.877,0,0,1,22.125,3.75v16.5a1.877,1.877,0,0,1-1.875,1.875H3.75A1.877,1.877,0,0,1,1.875,20.25v-.938a.938.938,0,0,0-1.875,0v.938A3.754,3.754,0,0,0,3.75,24h16.5A3.754,3.754,0,0,0,24,20.25V3.75A3.754,3.754,0,0,0,20.25,0Z" fill="#bebebe" />
                                                </g>
                                            </g>
                                            <g id="Group_51" data-name="Group 51" transform="translate(0.001 6.234)">
                                                <g id="Group_50" data-name="Group 50">
                                                    <path id="Path_39" data-name="Path 39" d="M2.182,139.435l-.006-.006a.939.939,0,0,1,0-1.328l3.482-3.5a.937.937,0,1,0-1.33-1.322l-3.48,3.5a2.816,2.816,0,0,0,0,3.974L4.326,144.3a.937.937,0,1,0,1.338-1.313Z" transform="translate(-0.028 -133)" fill="#bebebe" />
                                                </g>
                                            </g>
                                            <g id="Group_53" data-name="Group 53" transform="translate(4.688 11.062)">
                                                <g id="Group_52" data-name="Group 52">
                                                    <path id="Path_40" data-name="Path 40" d="M110.922,236h-9.984a.937.937,0,1,0,0,1.875h9.984a.938.938,0,1,0,0-1.875Z" transform="translate(-100 -236)" fill="#bebebe" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <h2 className="users-menu-title"><span>{generalData.dashboard_settings.logout_title}</span></h2>
                                </div>
                            </Link>
                            <div className="users-menu-items d-flex hide-on-small" onClick={() => setUsersMenuOpen(!usersMenuOpen)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
                                    <circle id="Ellipse_130" data-name="Ellipse 130" cx="14.5" cy="14.5" r="14.5" fill={props.darkMenu ? 'white' : '#f7f7f7'} />
                                    <path id="Path_4336" data-name="Path 4336" d="M-13112.842-4524.966l-4.445,4.446,4.445,4.446" transform="translate(13128.637 4535.05)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </svg>
                            </div>
                        </div>
                        :
                        <div className={props.darkMenu ? 'users-menu-minimized users-menu-content menu-bg-dark d-none d-lg-block' : 'users-menu-minimized users-menu-content d-none d-lg-block'}>
                            <div className="logo-minimized">
                                <svg id="Group_500" data-name="Group 500" xmlns="http://www.w3.org/2000/svg" width="37.556" height="28.296" viewBox="0 0 37.556 28.296">
                                    <g id="Group_497" data-name="Group 497" transform="translate(23.351 8.33)">
                                        <path id="Path_123" data-name="Path 123" d="M208.53,188.053a3.1,3.1,0,0,1-2.681-1.54l-8.007-13.768a3.1,3.1,0,0,1,5.357-3.117l8.007,13.768a3.1,3.1,0,0,1-2.676,4.657Z" transform="translate(-197.422 -168.087)" fill="#25d6ff" />
                                    </g>
                                    <g id="Group_498" data-name="Group 498">
                                        <path id="Path_124" data-name="Path 124" d="M210.346,192.687a2.8,2.8,0,0,1-2.459-1.543l-10.581-19.97a1.156,1.156,0,0,0-.7-.573,1.088,1.088,0,0,0-.884.126,1.27,1.27,0,0,0-.546.771,1.381,1.381,0,0,0,.118.963l4.768,8.9a7.946,7.946,0,0,1,.718,5.633,7.349,7.349,0,0,1-3.157,4.54,6.322,6.322,0,0,1-5.182.778,6.882,6.882,0,0,1-4.175-3.428l-1.991-3.431a3.247,3.247,0,0,1,.888-4.252,2.707,2.707,0,0,1,3.911.968l2.037,3.513a1.25,1.25,0,0,0,.756.669,1.1,1.1,0,0,0,.884-.135,1.243,1.243,0,0,0,.54-.772,1.353,1.353,0,0,0-.12-.961l-4.787-8.941a7.946,7.946,0,0,1-.685-5.643,7.336,7.336,0,0,1,3.186-4.513,6.3,6.3,0,0,1,5.186-.742,6.891,6.891,0,0,1,4.154,3.463L212.8,188.067a3.232,3.232,0,0,1-1.042,4.209A2.654,2.654,0,0,1,210.346,192.687Z" transform="translate(-185.838 -164.39)" fill="#25d6ff" />
                                    </g>
                                </svg>
                            </div>
                            <Link style={{ textDecoration: 'none' }} to={authUser.user_types_id === 2 ? '/user-view-profile' : '/doctor-view-profile'} >
                                <img src={authUser.image ? authUser.image : '/img/no-pic.jpg'} alt="user" className="user-image-minimized" />
                            </Link>

                            <Link style={{ textDecoration: 'none' }} to={authUser.user_types_id === 2 ? '/user-dashboard' : '/doctor-dashboard'} >
                                <div className={props.activeUserPage === "dashboard" ? 'users-menu-items-minimized active d-flex' : 'users-menu-items-minimized d-flex'}>
                                    <svg className="mx-auto mt-5" id="fi-rr-layout-fluid" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Path_34" data-name="Path 34" d="M2,11H13a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2H2A2,2,0,0,0,0,2V9a2,2,0,0,0,2,2ZM2,2H13V9H2Z" fill="#C7C7C7" />
                                        <path id="Path_35" data-name="Path 35" d="M22,0H19a2,2,0,0,0-2,2V9a2,2,0,0,0,2,2h3a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm0,9H19V2h3Z" fill="#C7C7C7" />
                                        <path id="Path_36" data-name="Path 36" d="M5,13H2a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2H5a2,2,0,0,0,2-2V15A2,2,0,0,0,5,13Zm0,9H2V15H5Z" fill="#C7C7C7" />
                                        <path id="Path_37" data-name="Path 37" d="M22,13H11a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V15A2,2,0,0,0,22,13Zm0,9H11V15H22Z" fill="#C7C7C7" />
                                    </svg>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={authUser.user_types_id === 2 ? '/user-notifications' : '/doctor-notifications'} >
                                <div className={notificationsCount > 0 ? 'users-menu-items red d-flex' : props.activeUserPage === "notifications" ? 'users-menu-items-minimized active d-flex' : 'users-menu-items-minimized d-flex'}>
                                    <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="18.991" height="24" viewBox="0 0 18.991 24">
                                        <path id="_001-bell" data-name="001-bell" d="M72.033,19.1A3.729,3.729,0,0,1,70.9,17.556,17.855,17.855,0,0,1,69.718,10.5c0-.009,0-.018,0-.027A6.81,6.81,0,0,0,65.709,4.3V2.674A2.674,2.674,0,0,0,63.04,0h-.221A2.674,2.674,0,0,0,60.15,2.674V4.3a6.81,6.81,0,0,0-4.009,6.2,17.855,17.855,0,0,1-1.181,7.055A3.729,3.729,0,0,1,53.826,19.1a.684.684,0,0,0-.375.777.715.715,0,0,0,.705.55H59.31a3.62,3.62,0,0,0,7.24,0H71.7a.715.715,0,0,0,.705-.55A.684.684,0,0,0,72.033,19.1ZM61.556,2.674a1.265,1.265,0,0,1,1.263-1.265h.221A1.265,1.265,0,0,1,64.3,2.674V3.842a6.81,6.81,0,0,0-2.747,0V2.674Zm1.373,19.918a2.219,2.219,0,0,1-2.214-2.165h4.427A2.219,2.219,0,0,1,62.929,22.591Zm2.831-3.574h-10a7.371,7.371,0,0,0,.368-.647,17.8,17.8,0,0,0,1.42-7.87,5.382,5.382,0,1,1,10.764,0c0,.009,0,.017,0,.026a17.766,17.766,0,0,0,1.42,7.842,7.372,7.372,0,0,0,.368.647Z" transform="translate(-53.434)" fill="#c7c7c7" />
                                    </svg>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={authUser.user_types_id === 2 ? '/user-records' : '/doctor-records'}>
                                <div className={props.activeUserPage === "records" ? 'users-menu-items-minimized active d-flex' : 'users-menu-items-minimized d-flex'}>
                                    <svg className="mx-auto" id="Group_369" data-name="Group 369" xmlns="http://www.w3.org/2000/svg" width="26.51" height="26.51" viewBox="0 0 26.51 26.51">
                                        <path id="Path_136" data-name="Path 136" d="M25.936,0H8.372A.574.574,0,0,0,7.8.574v1.97a.574.574,0,0,1-.574.574H4.473a.575.575,0,0,0-.574.575v1.97a.574.574,0,0,1-.574.575H.574A.574.574,0,0,0,0,6.812V25.936a.574.574,0,0,0,.574.574H18.139a.574.574,0,0,0,.574-.574v-1.97a.574.574,0,0,1,.574-.574h2.75a.575.575,0,0,0,.574-.574v-1.97a.574.574,0,0,1,.574-.575h2.75a.574.574,0,0,0,.574-.574V.574A.574.574,0,0,0,25.936,0ZM17.154,24.351a.6.6,0,0,1-.6.6H2.16a.6.6,0,0,1-.6-.6V8.4a.6.6,0,0,1,.6-.6H16.553a.6.6,0,0,1,.6.6Zm3.9-3.036a.518.518,0,0,1-.518.518h-1.3a.518.518,0,0,1-.518-.518V6.755a.518.518,0,0,0-.518-.518H5.976a.518.518,0,0,1-.518-.518V5.2a.518.518,0,0,1,.518-.518H20.535a.518.518,0,0,1,.518.518Zm3.9-3.119a.518.518,0,0,1-.518.518h-1.3a.518.518,0,0,1-.518-.518V3.637a.518.518,0,0,0-.518-.518H9.875A.518.518,0,0,1,9.357,2.6V2.077a.518.518,0,0,1,.518-.518H24.433a.518.518,0,0,1,.518.518Z" fill="#c7c7c7" />
                                        <path id="Path_137" data-name="Path 137" d="M61.135,191.623h6a.9.9,0,0,0,.9-.9v-1.439a3.9,3.9,0,0,0-1.719-3.231,3.118,3.118,0,1,0-4.36,0,3.9,3.9,0,0,0-1.719,3.231v1.439A.9.9,0,0,0,61.135,191.623Zm3-9.357a1.559,1.559,0,1,1-1.559,1.559A1.559,1.559,0,0,1,64.134,182.266Zm-2.339,7.017a2.339,2.339,0,0,1,4.678,0v.78H61.794Z" transform="translate(-57.116 -171.35)" fill="#c7c7c7" />
                                        <path id="Path_138" data-name="Path 138" d="M243.28,180.706h-1.559a.78.78,0,0,0,0,1.559h1.559a.78.78,0,0,0,0-1.559Z" transform="translate(-228.466 -171.349)" fill="#c7c7c7" />
                                        <path id="Path_139" data-name="Path 139" d="M243.28,240.941h-1.559a.78.78,0,1,0,0,1.559h1.559a.78.78,0,1,0,0-1.559Z" transform="translate(-228.466 -228.466)" fill="#c7c7c7" />
                                        <rect id="Rectangle_417" data-name="Rectangle 417" width="3.119" height="1.559" rx="0.78" transform="translate(12.475 15.594)" fill="#c7c7c7" />
                                        <rect id="Rectangle_418" data-name="Rectangle 418" width="3.119" height="1.559" rx="0.78" transform="translate(12.475 18.713)" fill="#c7c7c7" />
                                        <rect id="Rectangle_419" data-name="Rectangle 419" width="12.475" height="1.559" rx="0.78" transform="translate(3.119 21.832)" fill="#c7c7c7" />
                                    </svg>
                                </div>
                            </Link>
                            {authUser.user_types_id === 1 ?
                                <Link style={{ textDecoration: 'none' }} to='/doctor-calendar' >
                                    <div className={props.activeUserPage === "calendar" ? 'users-menu-items-minimized active d-flex' : 'users-menu-items-minimized d-flex'}>
                                        <svg className="mx-auto" id="fi-rr-calendar" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Path_31" data-name="Path 31" d="M19,2H18V1a1,1,0,1,0-2,0V2H8V1A1,1,0,1,0,6,1V2H5A5.006,5.006,0,0,0,0,7V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7A5.006,5.006,0,0,0,19,2ZM2,7A3,3,0,0,1,5,4H19a3,3,0,0,1,3,3V8H2ZM19,22H5a3,3,0,0,1-3-3V10H22v9A3,3,0,0,1,19,22Z" fill="#c7c7c7" />
                                            <circle id="Ellipse_70" data-name="Ellipse 70" cx="1.5" cy="1.5" r="1.5" transform="translate(10.5 13.5)" fill="#c7c7c7" />
                                            <circle id="Ellipse_71" data-name="Ellipse 71" cx="1.5" cy="1.5" r="1.5" transform="translate(5.5 13.5)" fill="#c7c7c7" />
                                            <circle id="Ellipse_72" data-name="Ellipse 72" cx="1.5" cy="1.5" r="1.5" transform="translate(15.5 13.5)" fill="#c7c7c7" />
                                        </svg>
                                    </div>
                                </Link>
                                :
                                null
                            }
                            {authUser.user_types_id === 1 ?
                                <Link style={{ textDecoration: 'none' }} to="/doctor-courses" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={props.activeUserPage === "courses" ? 'users-menu-items-minimized active d-flex' : 'users-menu-items-minimized d-flex'}>
                                        <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="22.777" height="22.777" viewBox="0 0 22.777 22.777">
                                            <g id="_001-online-test" data-name="001-online-test" transform="translate(0.25 0.25)">
                                                <path id="Path_142" data-name="Path 142" d="M94.967,80.333H80.986a.653.653,0,1,0,0,1.305H94.967a.653.653,0,1,0,0-1.305Z" transform="translate(-76.838 -76.838)" fill="#c7c7c7" stroke="#c3c3c3" strokeWidth="0.5" />
                                                <path id="Path_145" data-name="Path 145" d="M199.077,176.733H185.094a.653.653,0,0,0,0,1.305h13.983a.653.653,0,1,0,0-1.305Z" transform="translate(-180.948 -169.043)" fill="#c7c7c7" stroke="#c3c3c3" strokeWidth="0.5" />
                                                <path id="Path_146" data-name="Path 146" d="M199.1,273.133H185.147a.653.653,0,0,0,0,1.305H199.1a.653.653,0,0,0,0-1.305Z" transform="translate(-180.97 -261.249)" fill="#c7c7c7" stroke="#c3c3c3" strokeWidth="0.5" />
                                                <path id="Path_147" data-name="Path 147" d="M20.227,0H2.051A2.05,2.05,0,0,0,0,2.051V17.43a2.05,2.05,0,0,0,2.051,2.051H7.69v1.491H5.546a.653.653,0,1,0,0,1.305H16.731a.653.653,0,1,0,0-1.305H14.588V19.481h5.639a2.05,2.05,0,0,0,2.051-2.051V2.051A2.05,2.05,0,0,0,20.227,0ZM13.282,20.972H9V19.481h4.287Zm7.69-3.542a.746.746,0,0,1-.745.745H2.051a.746.746,0,0,1-.745-.745v-.885a2.04,2.04,0,0,0,.745.14H20.227a2.05,2.05,0,0,0,.745-.139Zm0-2.8a.746.746,0,0,1-.745.745H2.051a.746.746,0,0,1-.745-.745V2.051a.746.746,0,0,1,.745-.745H20.227a.746.746,0,0,1,.746.745Z" fill="#c7c7c7" stroke="#c3c3c3" strokeWidth="0.5" />
                                            </g>
                                        </svg>
                                    </div>
                                </Link>
                                :
                                null
                            }

                            <Link style={{ textDecoration: 'none' }} to={authUser.user_types_id === 2 ? '/user-payments' : '/doctor-payments'} onClick={() => setMenuClicked(!menuClicked)}>
                                <div className={props.activeUserPage === "payments" ? 'users-menu-items-minimized active d-flex' : 'users-menu-items-minimized d-flex'}>
                                    <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="25" height="17" viewBox="0 0 25 17">
                                        <g id="_001-credit-card" data-name="001-credit-card" transform="translate(0.5 0.5)">
                                            <g id="Group_55" data-name="Group 55" transform="translate(0 0)">
                                                <g id="Group_54" data-name="Group 54">
                                                    <path id="Path_41" data-name="Path 41" d="M21.5,85.333H2.5a2.5,2.5,0,0,0-2.5,2.5v11a2.5,2.5,0,0,0,2.5,2.5h19a2.5,2.5,0,0,0,2.5-2.5v-11A2.5,2.5,0,0,0,21.5,85.333Zm1.5,13.5a1.5,1.5,0,0,1-1.5,1.5H2.5a1.5,1.5,0,0,1-1.5-1.5v-11a1.5,1.5,0,0,1,1.5-1.5h19a1.5,1.5,0,0,1,1.5,1.5v11Z" transform="translate(0 -85.333)" fill="none" stroke="#c4c4c4" strokeWidth="1" />
                                                </g>
                                            </g>
                                            <g id="Group_57" data-name="Group 57" transform="translate(0 3)">
                                                <g id="Group_56" data-name="Group 56">
                                                    <path id="Path_42" data-name="Path 42" d="M23.5,149.333H.5a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h23a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,23.5,149.333Zm-.5,3H1v-2H23v2Z" transform="translate(0 -149.333)" fill="none" stroke="#c4c4c4" strokeWidth="1" />
                                                </g>
                                            </g>
                                            <g id="Group_63" data-name="Group 63" transform="translate(17 9)">
                                                <g id="Group_62" data-name="Group 62">
                                                    <path id="Path_45" data-name="Path 45" d="M365.167,277.333h-1a1.5,1.5,0,0,0-1.5,1.5v1a1.5,1.5,0,0,0,1.5,1.5h1a1.5,1.5,0,0,0,1.5-1.5v-1A1.5,1.5,0,0,0,365.167,277.333Zm.5,2.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z" transform="translate(-362.667 -277.333)" fill="none" stroke="#c4c4c4" strokeWidth="1" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </Link>
                            {authUser.user_types_id === 2 ?
                                <Link style={{ textDecoration: 'none' }} to="/user-online-support" onClick={() => setMenuClicked(!menuClicked)}>
                                    <div className={props.activeUserPage === "support" ? 'users-menu-items-minimized active d-flex' : 'users-menu-items-minimized d-flex'}>
                                        <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24.387" height="26.51" viewBox="0 0 24.387 26.51">
                                            <path id="_002-customer-service" data-name="002-customer-service" d="M24.385,12.01A12.193,12.193,0,0,0,0,12.01c0,.114,0,.087,0,4.058A2.835,2.835,0,0,0,2.832,18.9h0a2.835,2.835,0,0,0,2.832-2.832V12.1A2.835,2.835,0,0,0,2.832,9.265a2.864,2.864,0,0,0-.335.02,10.124,10.124,0,0,1,19.393,0,2.868,2.868,0,0,0-.335-.02A2.835,2.835,0,0,0,18.723,12.1v3.97A2.835,2.835,0,0,0,21.555,18.9h0l.082,0a6.253,6.253,0,0,1-5.567,3.415h-.889a3.163,3.163,0,1,0,0,2.071h.889a8.325,8.325,0,0,0,8.317-8.315h0V12.1C24.387,12.068,24.386,12.039,24.385,12.01ZM2.832,11.336a.762.762,0,0,1,.761.761v3.97a.762.762,0,0,1-.761.761h0a.762.762,0,0,1-.761-.761c0-2.63,0-3.722,0-4A.761.761,0,0,1,2.832,11.336Zm9.361,13.1a1.092,1.092,0,1,1,1.092-1.092A1.093,1.093,0,0,1,12.194,24.439Zm10.123-8.372a.762.762,0,0,1-.761.761h0a.762.762,0,0,1-.761-.761V12.1a.761.761,0,0,1,1.521-.032C22.318,12.314,22.315,12.012,22.316,16.068Zm0,0" fill="#c7c7c7" />
                                        </svg>
                                    </div>
                                </Link>
                                :
                                null
                            }
                            <Link style={{ textDecoration: 'none' }} to="/" onClick={() => setAuthUser()}>
                                <div className="users-menu-items-minimized d-flex" onClick={() => localStorage.setItem('auth_token', '')}>
                                    <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g id="_001-log-out" data-name="001-log-out" transform="translate(24 24) rotate(180)">
                                            <g id="Group_49" data-name="Group 49">
                                                <g id="Group_48" data-name="Group 48">
                                                    <path id="Path_38" data-name="Path 38" d="M20.25,0H3.75A3.754,3.754,0,0,0,0,3.75v.938a.938.938,0,0,0,1.875,0V3.75A1.877,1.877,0,0,1,3.75,1.875h16.5A1.877,1.877,0,0,1,22.125,3.75v16.5a1.877,1.877,0,0,1-1.875,1.875H3.75A1.877,1.877,0,0,1,1.875,20.25v-.938a.938.938,0,0,0-1.875,0v.938A3.754,3.754,0,0,0,3.75,24h16.5A3.754,3.754,0,0,0,24,20.25V3.75A3.754,3.754,0,0,0,20.25,0Z" fill="#bebebe" />
                                                </g>
                                            </g>
                                            <g id="Group_51" data-name="Group 51" transform="translate(0.001 6.234)">
                                                <g id="Group_50" data-name="Group 50">
                                                    <path id="Path_39" data-name="Path 39" d="M2.182,139.435l-.006-.006a.939.939,0,0,1,0-1.328l3.482-3.5a.937.937,0,1,0-1.33-1.322l-3.48,3.5a2.816,2.816,0,0,0,0,3.974L4.326,144.3a.937.937,0,1,0,1.338-1.313Z" transform="translate(-0.028 -133)" fill="#bebebe" />
                                                </g>
                                            </g>
                                            <g id="Group_53" data-name="Group 53" transform="translate(4.688 11.062)">
                                                <g id="Group_52" data-name="Group 52">
                                                    <path id="Path_40" data-name="Path 40" d="M110.922,236h-9.984a.937.937,0,1,0,0,1.875h9.984a.938.938,0,1,0,0-1.875Z" transform="translate(-100 -236)" fill="#bebebe" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </Link>
                            <div className="users-menu-items-minimized d-flex hide-on-small" onClick={() => setUsersMenuOpen(!usersMenuOpen)}>
                                <svg className="minimized-expand" xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
                                    <circle id="Ellipse_130" data-name="Ellipse 130" cx="14.5" cy="14.5" r="14.5" fill={props.darkMenu ? 'white' : '#f7f7f7'} />
                                    <path id="Path_4336" data-name="Path 4336" d="M-13112.842-4524.966l-4.445,4.446,4.445,4.446" transform="translate(13128.637 4535.05)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </svg>
                            </div>
                        </div>
                    }
                    {/* </div> */}
                </div>

                <div className={props.darkMenu ? 'mobile-users-menu menu-bg-dark d-block d-lg-none' : 'mobile-users-menu d-block d-lg-none'}>
                    <div className="container-fluid">
                        <div className="d-flex jusity-content-end">
                            <div id="burger-button" className={"nav-icon2 burger-button" + (usersMenuOpen ? " open" : "")} onClick={() => { setUsersMenuOpen(!usersMenuOpen); !usersMenuOpen ? document.getElementsByTagName("html")[0].classList.add('lock-scroll') : document.getElementsByTagName("html")[0].classList.remove('lock-scroll') }}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="mobile-users-menu d-block d-lg-none">
                <div className={"nav-icon2 burger-button" + (usersMenuOpen ? " open" : "")} onClick={() => { setUsersMenuOpen(!usersMenuOpen); !usersMenuOpen ? document.getElementsByTagName("html")[0].classList.add('lock-scroll') : document.getElementsByTagName("html")[0].classList.remove('lock-scroll') }}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div> */}
                {authUser.user_types_id === 2 ?
                    <div className="chat-with-us">
                        <svg xmlns="http://www.w3.org/2000/svg" width="49.867" height="50.783" viewBox="0 0 49.867 50.783">
                            <g id="_001-chat" data-name="001-chat" transform="translate(-3.366)">
                                <g id="Group_36" data-name="Group 36" transform="translate(3.366)">
                                    <path id="Path_25" data-name="Path 25" d="M28.415,0h-.082A24.913,24.913,0,0,0,8.795,40.438l-3.633,8.45A1.354,1.354,0,0,0,5.87,50.67a1.383,1.383,0,0,0,.776.1l13.321-2.34a24.006,24.006,0,0,0,8.3,1.442A24.934,24.934,0,0,0,28.415,0ZM19.871,19.947h7.347a1.361,1.361,0,0,1,0,2.721H19.871a1.361,1.361,0,0,1,0-2.721Zm16.872,9.524H19.871a1.361,1.361,0,0,1,0-2.721H36.742a1.361,1.361,0,1,1,0,2.721Z" transform="translate(-3.366)" fill="#25d6ff" />
                                </g>
                            </g>
                        </svg>
                    </div>
                    :
                    null
                }
            </>
            :
            null
    );
}

export default Main;
import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';


export default function UserDashboard(props) {
    const { generalDataGlobal, authUserGlobal, usersMenuOpenGlobal , notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [loading] = useState(false);
    const [authUser] = authUserGlobal;
    const [getCareCases, setGetCareCases] = useState();
    const [onlieSupport, setOnlineSupport] = useState();
    const [userInvoices, setUserInvoices] = useState();


    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);
    
    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading, getCareCases]);

    useEffect(() => {
        axios.get('user-online-support')
            .then((r) => {
                setOnlineSupport(r.data)
            })
            .finally(() => {
            });
    }, []);

    useEffect(() => {
        axios.get('user-invoices')
            .then((r) => {
                setUserInvoices(r.data)
            })
            .catch(() => {
                setUserInvoices(0);
            });
    }, []);

    useEffect(() => {
        axios.get('get-care-count/' + authUser.email)
            .then((r) => {
                setGetCareCases(r.data)
            })
            .finally(() => {
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
    }, [getCareCases]);


    return (
        <>
            <UserLayout
                activeUserPage="dashboard"
            />
            <div className={usersMenuOpen ? 'dashboard-content menu-open' : 'dashboard-content'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 pt-3 w-100" animate="right">
                            <p className="dashboard-username m-0">{generalData.dashboard_settings.greetings_title} {authUser.first_name && authUser.last_name ? authUser.first_name + ' ' + authUser.last_name : authUser.name}!</p>
                        </div>
                        <div className="col-12 col-md-6 pb-1 pb-md-0 pt-3 w-100 text-md-right" animate="right">
                            <p className="dashboard-getcare m-0"><Link to="/get-care-logged" style={{ textDecoration: 'none' }}><span>{generalData.dashboard_settings.get_care_title} </span></Link></p>
                        </div>

                        {getCareCases >= 0 ?
                            <>
                                <div className="col-12 col-md-4 text-center py-3">
                                    <Link style={{ textDecoration: 'none' }} to="/user-records">
                                        <div className="border-dashboard-boxes" animate="right">
                                            <div className="pt-4 pb-3">
                                                <img className="dashboard-icons-backend" src={generalData.patient_dashboard_settings.records_icon} alt="icon" />
                                            </div>
                                            <p className="dashboard-card-title pb-3">{generalData.patient_dashboard_settings.records_title}</p>
                                            <div className="services-count-container">{getCareCases ? getCareCases : 0}</div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-12 col-md-4 text-center py-3">
                                    <Link style={{ textDecoration: 'none' }} to="/user-payments">
                                        <div className="border-dashboard-boxes" animate="right">
                                            <div className="pt-4 pb-3">
                                                <img className="dashboard-icons-backend" src={generalData.patient_dashboard_settings.invoices_icon} alt="icon" />
                                            </div>
                                            <p className="dashboard-card-title pb-3">{generalData.patient_dashboard_settings.invoices_title}</p>
                                            <div className="services-count-container">{userInvoices}</div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-12 col-md-4 text-center py-3">
                                    <Link style={{ textDecoration: 'none' }} to="/user-online-support">
                                        <div className="border-dashboard-boxes" animate="right">
                                            <div className="pt-4 pb-3">
                                                <img className="dashboard-icons-backend" src={generalData.patient_dashboard_settings.care_center_icon} alt="icon" />
                                            </div>
                                            <p className="dashboard-card-title pb-3">{generalData.patient_dashboard_settings.care_center_title}</p>
                                            <div className="services-count-container">{onlieSupport}</div>
                                        </div>
                                    </Link>
                                </div>
                            </>
                            :
                            <div className="col-12 text-center py-3">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
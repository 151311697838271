import React, { useContext, useEffect } from 'react';
import Layout from '../layouts/Main';
import DarkBanner from '../components/DarkBanner';
import GlobalState from "../contexts/GlobalState";
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../components/GoogleAnalytics';

export default function PrivacyPolicy(props) {


    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;


    useEffect(() => {
        window.triggerScroll();
    }, [generalData]);


    return (

        <Layout activePage="privacy-policy" whiteMenu="false">
            {generalData?.seo &&
                <Helmet>
                    <title>{generalData?.seo['privacy-policy'].title}</title>
                    <meta name="title" content={generalData?.seo['privacy-policy']?.title} />
                    <meta name="description" content={generalData?.seo['privacy-policy']?.description} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:title" content={generalData?.seo['privacy-policy'].title} />
                    <meta property="og:description" content={generalData?.seo['privacy-policy'].description} />
                    <meta property="og:image" content={generalData?.seo['privacy-policy'].full_path_image} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location} />
                    <meta property="twitter:title" content={generalData?.seo['privacy-policy'].title} />
                    <meta property="twitter:description" content={generalData?.seo['privacy-policy'].description} />
                    <meta property="twitter:image" content={generalData?.seo['privacy-policy'].full_path_image} />
                </Helmet>
            }
            <GoogleAnalytics />
            <DarkBanner>{generalData.privacy_policy_settings.title}</DarkBanner>
            <div className="privacy-policy">
                <div className="container py-5">
                    <div className="row pb-lg-5">
                        <div className="col-12">
                            {<div className="content" dangerouslySetInnerHTML={{ __html: generalData.privacy_policy_settings.text }} animate="left"></div>}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
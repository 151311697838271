import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import GlobalState from "../contexts/GlobalState";


function Main(props) {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { generalDataGlobal, authUserGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [authUser] = authUserGlobal;


    useEffect(() => {
    }, [generalData]);

    return (
        <>
            <div className="header-menu d-flex justify-content-between">
                {!mobileMenuOpen ?
                    <Link to={"/"} style={{ textDecoration: 'none' }}><img src={props.whiteMenu === 'true' ? '/img/home-logo.png' : '/img/home-dark-logo.png'} alt="main-logo" className="main-logo" /></Link>
                    :
                    <Link to={"/"} style={{ textDecoration: 'none' }}><img src="/img/home-dark-logo.png" alt="main-logo" className="main-logo" /></Link>
                }
                <div className={props.whiteMenu === 'true' ? 'menu-item menu-white d-none d-lg-inline-block' : 'menu-item d-none d-lg-inline-block menu-dark'}>
                    <Link to={"/"} style={{ textDecoration: 'none' }} className={props.activePage === 'home' ? 'menu-active mr-4' : 'mr-4'}>{generalData.menu_items['home']}</Link>
                    <Link to={"/how-can-we-help"} style={{ textDecoration: 'none' }} className={props.activePage === 'how-can-we-help' ? 'menu-active mr-4' : 'mr-4'}>{generalData.menu_items['how-can-we-help']}</Link>
                    <Link to={"/our-doctors"} style={{ textDecoration: 'none' }} className={props.activePage === 'our-doctors' ? 'menu-active mr-4' : 'mr-4'}>{generalData.menu_items['our-doctors']}</Link>
                    <Link to={"/services"} style={{ textDecoration: 'none' }} className={props.activePage === 'services' ? 'menu-active mr-4' : 'mr-4'}>{generalData.menu_items['services']}</Link>
                    <Link to={authUser?.id ? "/get-care-logged" : "/get-care"} style={{ textDecoration: 'none' }} className="mr-4"><button><span>{generalData.menu_items['get-care']}</span></button></Link>
                    {authUser ?
                        <Link to={ authUser.user_types_id === 2 ? '/user-dashboard' : '/doctor-dashboard'} style={{ textDecoration: 'none' }} className={props.activePage === 'home' ? '' : ''}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="31.044" height="32.759" viewBox="0 0 31.044 32.759">
                                <path id="Path_6" data-name="Path 6" d="M1177.12,1401.509a1.4,1.4,0,0,1-1.37-1.426v-.988a12.733,12.733,0,0,0-12.261-12.261q-.236-.008-.471-.009a12.734,12.734,0,0,0-12.717,12.27v.048s0,.978,0,1a1.4,1.4,0,0,1-1.4,1.368h-.029a1.4,1.4,0,0,1-1.37-1.426v-.94a15.215,15.215,0,0,1,10-14.124l2.151-.778-1.751-1.471a7.912,7.912,0,0,1-2.83-6.073v-.137a7.945,7.945,0,1,1,13.063,6.212l-1.746,1.471,2.147.776a15.218,15.218,0,0,1,10,14.135s0,.966,0,.982a1.406,1.406,0,0,1-1.4,1.374Zm-14.1-29.964a5.157,5.157,0,1,0,.581.033l-.033-.033Z" transform="translate(-1147.502 -1368.75)" fill="#25d6ff" />
                            </svg>
                        </Link>
                        :
                        <Link to={"/sign-in"} style={{ textDecoration: 'none' }} className={props.activePage === 'home' ? '' : ''}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="31.044" height="32.759" viewBox="0 0 31.044 32.759">
                                <path id="Path_6" data-name="Path 6" d="M1177.12,1401.509a1.4,1.4,0,0,1-1.37-1.426v-.988a12.733,12.733,0,0,0-12.261-12.261q-.236-.008-.471-.009a12.734,12.734,0,0,0-12.717,12.27v.048s0,.978,0,1a1.4,1.4,0,0,1-1.4,1.368h-.029a1.4,1.4,0,0,1-1.37-1.426v-.94a15.215,15.215,0,0,1,10-14.124l2.151-.778-1.751-1.471a7.912,7.912,0,0,1-2.83-6.073v-.137a7.945,7.945,0,1,1,13.063,6.212l-1.746,1.471,2.147.776a15.218,15.218,0,0,1,10,14.135s0,.966,0,.982a1.406,1.406,0,0,1-1.4,1.374Zm-14.1-29.964a5.157,5.157,0,1,0,.581.033l-.033-.033Z" transform="translate(-1147.502 -1368.75)" fill="#25d6ff" />
                            </svg>
                        </Link>
                    }
                </div>

                <div className="d-flex d-lg-none my-auto">
                    <div className="d-flex user-toggle">
                        {authUser ?
                            <Link onClick={() => setMobileMenuOpen(false)} to={ authUser.user_types_id === 2 ? '/user-dashboard' : '/doctor-dashboard'} style={{ textDecoration: 'none' }} className={props.activePage === 'home' ? 'mr-3' : 'mr-3'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="31.044" height="32.759" viewBox="0 0 31.044 32.759">
                                    <path id="Path_6" data-name="Path 6" d="M1177.12,1401.509a1.4,1.4,0,0,1-1.37-1.426v-.988a12.733,12.733,0,0,0-12.261-12.261q-.236-.008-.471-.009a12.734,12.734,0,0,0-12.717,12.27v.048s0,.978,0,1a1.4,1.4,0,0,1-1.4,1.368h-.029a1.4,1.4,0,0,1-1.37-1.426v-.94a15.215,15.215,0,0,1,10-14.124l2.151-.778-1.751-1.471a7.912,7.912,0,0,1-2.83-6.073v-.137a7.945,7.945,0,1,1,13.063,6.212l-1.746,1.471,2.147.776a15.218,15.218,0,0,1,10,14.135s0,.966,0,.982a1.406,1.406,0,0,1-1.4,1.374Zm-14.1-29.964a5.157,5.157,0,1,0,.581.033l-.033-.033Z" transform="translate(-1147.502 -1368.75)" fill="#25d6ff" />
                                </svg>
                            </Link>
                            :
                            <Link onClick={() => setMobileMenuOpen(false)} to={"/sign-in"} style={{ textDecoration: 'none' }} className={props.activePage === 'home' ? 'mr-3' : 'mr-3'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="31.044" height="32.759" viewBox="0 0 31.044 32.759">
                                    <path id="Path_6" data-name="Path 6" d="M1177.12,1401.509a1.4,1.4,0,0,1-1.37-1.426v-.988a12.733,12.733,0,0,0-12.261-12.261q-.236-.008-.471-.009a12.734,12.734,0,0,0-12.717,12.27v.048s0,.978,0,1a1.4,1.4,0,0,1-1.4,1.368h-.029a1.4,1.4,0,0,1-1.37-1.426v-.94a15.215,15.215,0,0,1,10-14.124l2.151-.778-1.751-1.471a7.912,7.912,0,0,1-2.83-6.073v-.137a7.945,7.945,0,1,1,13.063,6.212l-1.746,1.471,2.147.776a15.218,15.218,0,0,1,10,14.135s0,.966,0,.982a1.406,1.406,0,0,1-1.4,1.374Zm-14.1-29.964a5.157,5.157,0,1,0,.581.033l-.033-.033Z" transform="translate(-1147.502 -1368.75)" fill="#25d6ff" />
                                </svg>
                            </Link>
                        }
                    </div>
                    <div className={props.whiteMenu === 'false' || mobileMenuOpen ? 'burger-menu-button dark-menu-button d-lg-none my-auto' : 'burger-menu-button white-menu-button d-lg-none my-auto'}>
                        <div className={"nav-icon1 burger-button" + (mobileMenuOpen ? " open" : "")} onClick={() => { setMobileMenuOpen(!mobileMenuOpen); !mobileMenuOpen ? document.getElementsByTagName("html")[0].classList.add('lock-scroll') : document.getElementsByTagName("html")[0].classList.remove('lock-scroll') }}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"d-lg-none py-5 text-center mobile-menu " + (mobileMenuOpen ? "fade-in" : "")}>
                <div className={ mobileMenuOpen ? 'overlay-content active' : 'overlay-content'}>
                    <div className="mb-2 mobile-menu-item">
                        <Link className={mobileMenuOpen ? 'd-block' : 'd-none'} onClick={() => { setMobileMenuOpen(!mobileMenuOpen); !mobileMenuOpen ? document.getElementsByTagName("html")[0].classList.add('lock-scroll') : document.getElementsByTagName("html")[0].classList.remove('lock-scroll') }} to={"/"} style={{ textDecoration: 'none' }} >{generalData.menu_items['home']}</Link>
                    </div>
                    <div className="mb-2 mobile-menu-item">
                        <Link className={mobileMenuOpen ? 'd-block' : 'd-none'} onClick={() => { setMobileMenuOpen(!mobileMenuOpen); !mobileMenuOpen ? document.getElementsByTagName("html")[0].classList.add('lock-scroll') : document.getElementsByTagName("html")[0].classList.remove('lock-scroll') }} to={"/how-can-we-help"} style={{ textDecoration: 'none' }} >{generalData.menu_items['how-can-we-help']}</Link>
                    </div>
                    <div className="mb-2 mobile-menu-item">
                        <Link className={mobileMenuOpen ? 'd-block' : 'd-none'} onClick={() => { setMobileMenuOpen(!mobileMenuOpen); !mobileMenuOpen ? document.getElementsByTagName("html")[0].classList.add('lock-scroll') : document.getElementsByTagName("html")[0].classList.remove('lock-scroll') }} to={"/our-doctors"} style={{ textDecoration: 'none' }} >{generalData.menu_items['our-doctors']}</Link>
                    </div>
                    <div className="mb-2 mobile-menu-item">
                        <Link className={mobileMenuOpen ? 'd-block' : 'd-none'} onClick={() => { setMobileMenuOpen(!mobileMenuOpen); !mobileMenuOpen ? document.getElementsByTagName("html")[0].classList.add('lock-scroll') : document.getElementsByTagName("html")[0].classList.remove('lock-scroll') }} to={"/services"} style={{ textDecoration: 'none' }} >{generalData.menu_items['services']}</Link>
                    </div>
                    <div className="mb-2 mobile-menu-item">
                        <Link className={mobileMenuOpen ? 'd-block' : 'd-none'} onClick={() => { setMobileMenuOpen(!mobileMenuOpen); !mobileMenuOpen ? document.getElementsByTagName("html")[0].classList.add('lock-scroll') : document.getElementsByTagName("html")[0].classList.remove('lock-scroll') }} to={authUser?.id ? "/get-care-logged" : "/get-care"} style={{ textDecoration: 'none' }} >{generalData.menu_items['get-care']}</Link>
                    </div>
                </div>
            </div>
            {props.children}

            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12 py-5">
                            <Link to={"/"} style={{ textDecoration: 'none' }}><img src="/img/home-logo.png" className="footer-logo" alt="footer-logo" /></Link>
                        </div>
                        <div className="col-12 pb-5 pt-lg-2">
                            <div className="row no-gutters w-100">
                                <div className="col-12 col-lg-4 pb-4 pb-lg-0">
                                    <h4>{generalData.home_settings.company_registration}</h4>
                                    <div className="row w-100">
                                        <div className="col-auto pr-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.432" height="21.605" viewBox="0 0 15.432 21.605">
                                                <g id="noun_Location_3798120" transform="translate(-591 -352)">
                                                    <g id="_02.location" data-name="02.location" transform="translate(591 352)">
                                                        <path id="Path_4325" data-name="Path 4325" d="M13.716,23.605A.772.772,0,0,1,13.1,23.3C12.374,22.325,6,13.69,6,9.716a7.716,7.716,0,0,1,15.432,0c0,3.974-6.374,12.608-7.1,13.581A.772.772,0,0,1,13.716,23.605Zm0-20.062A6.173,6.173,0,0,0,7.543,9.716c0,2.654,4.012,8.8,6.173,11.814,2.161-3.017,6.173-9.159,6.173-11.814A6.173,6.173,0,0,0,13.716,3.543Zm0,10.031a3.858,3.858,0,1,1,3.858-3.858A3.858,3.858,0,0,1,13.716,13.574Zm0-6.173a2.315,2.315,0,1,0,2.315,2.315A2.315,2.315,0,0,0,13.716,7.4Z" transform="translate(-6 -2)" fill="#fff" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="col">
                                            <h5 className="pr-lg-4 lh-7">{generalData.home_settings.company_address}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-flex pt-2 social-icons">
                                            {
                                                generalData.social_media.map((singleSocial, index) => (
                                                    <div className="d-flex pr-3" key={index}>
                                                        <a target="_blank" href={singleSocial.url} rel="noreferrer"><img src={singleSocial.full_path_image} alt="social-icons"/></a>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2">
                                    <Link to={"/about-us"} style={{ textDecoration: 'none' }}><p>{generalData.menu_items['about-us']}</p></Link>
                                    <Link to={"/how-can-we-help"} style={{ textDecoration: 'none' }} ><p>{generalData.menu_items['how-can-we-help']}</p></Link>
                                    <Link to={"/our-doctors"} style={{ textDecoration: 'none' }}><p>{generalData.menu_items['our-doctors']}</p></Link>
                                    <Link to={"/services"} style={{ textDecoration: 'none' }}><p>{generalData.menu_items['services']}</p></Link>
                                </div>
                                <div className="col-12 col-lg-2">
                                    <Link to={"/contact-us"} style={{ textDecoration: 'none' }}><p>{generalData.menu_items['contact-us']}</p></Link>
                                    <Link to={authUser?.id ? "/get-care-logged" : "/get-care"} style={{ textDecoration: 'none' }}><p>{generalData.menu_items['get-care']}</p></Link>
                                    <Link to={"/testimonials"} style={{ textDecoration: 'none' }}><p>{generalData.menu_items['testimonials']}</p></Link>
                                    <Link to={"/faqs"} style={{ textDecoration: 'none' }}><p>{generalData.menu_items['faqs']}</p></Link>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <a style={{ textDecoration: 'none' }}><p>{generalData.home_settings.news_letter}</p></a>
                                    <h6>{generalData.home_settings.subscribe_text}</h6>
                                    <form>
                                        <div className="row pt-2">
                                            <div className="col-8 pr-0">
                                                <input className="w-100" name="news-email" type="email" placeholder={generalData.home_settings.subscribe_placeholder} />
                                            </div>
                                            <div className="col pl-0">
                                                <button>{generalData.home_settings.subscribe_button}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-none d-lg-block">
                            <div className="row no-gutters">
                                <div className="col d-flex justify-content-end order-lg-2">
                                    <p>{generalData.home_settings.designed_text} |  {generalData.home_settings.developed_text.toLowerCase().replace('hellotree','').toUpperCase()}<a className="cursor-pointer" href="https://www.hellotree.com" style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" >HELLOTREE</a></p>
                                </div>
                                <div className="col-auto">
                                    <p>{generalData.home_settings.rights_text}  |  <Link to={'/privacy-policy'} style={{ textDecoration: 'none' }}>{generalData.menu_items['privacy-policy']}</Link>  |  <Link to={'/terms-conditions'} style={{ textDecoration: 'none' }}>{generalData.menu_items['terms-conditions']}</Link></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-lg-none">
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <p className="pb-0">{generalData.home_settings.designed_text}</p>
                                </div>
                                <div className="col-12">
                                    <p className="pb-0">{generalData.home_settings.developed_text.toLowerCase().replace('hellotree','').toUpperCase()}<a className="cursor-pointer" href="https://www.hellotree.com" style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" >HELLOTREE</a></p>
                                </div>
                                <div className="col-12">
                                    <p className="pb-0"><Link to={'/privacy-policy'} style={{ textDecoration: 'none' }}>{generalData.menu_items['privacy-policy']}</Link> | <Link to={'/terms-conditions'} style={{ textDecoration: 'none' }}>{generalData.menu_items['terms-conditions']}</Link></p>
                                </div>
                                <div className="col-12">
                                    <p>{generalData.home_settings.rights_text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Main;
import { useState, useContext, useEffect } from 'react';
import Api from "../components/Api";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import axios from 'axios';
import Notification from '../components/Notification';
import moment from 'moment';


export default function DoctorNotifications(props) {
    const { usersMenuOpenGlobal,notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingForm, setLoadingForm] = useState();
    const [lastPage, setLastPage] = useState();
    const [todayNotifications, setTodayNotifications] = useState([]);
    const [yesterdayNotifications, setYesterdayNotifications] = useState([]);
    const [allNotifications, setAllNotifications] = useState([]);


    useEffect(() => {
        axios.get('reset-notifications')
            .then((r) => {
                setNotificationsCount(0);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let todayNotificationsTemp = [];
        let yesterdayNotificationsTemp = [];
        let allNotificationsTemp = [];
        setGeneralDataApiOptions({
            url: 'user-notifications?page=' + currentPage,
            method: 'get',
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                setNotifications([...notifications, ...res.data.data]);
                res.data?.data?.forEach((singleNotification) => (
                    moment(singleNotification.created_at).format('DDMMYYYY') === moment().format('DDMMYYYY') ?
                        todayNotificationsTemp.push(singleNotification)
                        :
                        moment(singleNotification.created_at).format('DDMMYYYY') === moment().add(-1, 'days').format('DDMMYYYY') ?
                            yesterdayNotificationsTemp.push(singleNotification)
                            :
                            allNotificationsTemp.push(singleNotification)
                ));
                window.triggerScroll();
                if (todayNotificationsTemp.length > 0) {
                    setTodayNotifications([...todayNotifications, ...todayNotificationsTemp]);
                }
                if (yesterdayNotificationsTemp.length > 0) {
                    setYesterdayNotifications([...yesterdayNotifications, ...yesterdayNotificationsTemp]);
                }
                if (allNotificationsTemp.length > 0) {
                    setAllNotifications([...allNotifications, ...allNotificationsTemp]);
                }
                setCurrentPage(res.data.current_page);
                setLastPage(res.data.last_page);
                setLoadingForm(false);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
    }, [yesterdayNotifications, todayNotifications, allNotifications]);


    return (
        <>
            <UserLayout
                darkMenu={true}
                activeUserPage="notifications"
            />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row">
                        <div className="col-12 col-md-6 pt-3 w-100">
                            <p className="dashboard-username m-0">Notifications</p>
                        </div>
                        <div className="col-12 w-100">
                            {todayNotifications?.length > 0 ?
                                <>
                                    <h3 className="days-titles pt-5">TODAY</h3>
                                    {todayNotifications?.map((singleNotification, index) => (
                                        <Notification notificationInfo={singleNotification} key={index} />
                                    ))}
                                </>
                                :
                                null
                            }
                        </div>
                        <div className="col-12 w-100">
                            {yesterdayNotifications?.length > 0 ?
                                <>
                                    <h3 className="days-titles pt-5">YESTERDAY</h3>
                                    {yesterdayNotifications?.map((singleNotification, index) => (
                                        <Notification notificationInfo={singleNotification} key={index} />
                                    ))}
                                </>
                                :
                                null
                            }
                        </div>
                        <div className="col-12 w-100">
                            {allNotifications?.length > 0 ?
                                <>
                                    <h3 className="days-titles pt-5">OLDER</h3>
                                    {allNotifications?.map((singleNotification, index) => (
                                        <Notification notificationInfo={singleNotification} key={index} />
                                    ))}
                                </>
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="row justify-content-center py-4">
                        <div className="col-12 d-flex text-center justify-content-center">

                            <div className={loadingForm ? "lds-ring" : "lds-ring d-none"}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            {
                                !!(currentPage !== lastPage) && (
                                    <button className={!loadingForm ? "load-more home-buttons" : " hide"} onClick={() => { setCurrentPage(currentPage + 1); }} animate="down">
                                        Load More</button>
                                )
                            }
                        </div>
                    </div>
                </div>
                <Api options={generalDataApiOptions} />
            </div>
        </>
    );
}
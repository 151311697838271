import axios from 'axios';
import { useState, useContext, useEffect } from 'react';
import Api from "../components/Api";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";


export default function DoctorPayments(props) {
    const { usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [generalDataApiOptions2, setGeneralDataApiOptions2] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [payments, setPayments] = useState([]);
    const [oldCurrentPage, setOldCurrentPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingForm, setLoadingForm] = useState();
    const [lastPage, setLastPage] = useState();
    const [outstanding, setOutstanding] = useState();
    const [filter, setFilter] = useState(0);
    const [search, setSearch] = useState('');
    const [submitSearch, setSubmitSearch] = useState(0);
    const [requestWithdrawal, setRequestWithdrawal] = useState(0);
    const [bankName, setBankName] = useState('');
    const [requestedAmount, setRequestedAmount] = useState();
    const [bankAddress, setBankAddress] = useState('');
    const [accountHolderName, setAccountHolderName] = useState('');
    const [iban, setIban] = useState('');
    const [swiftBic, setSwiftBic] = useState('');
    const [success, setSuccess] = useState(false);

    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);

    useEffect(() => {
        let page = currentPage === oldCurrentPage ? 1 : currentPage;
        setGeneralDataApiOptions({
            url: 'doctor-payments?page=' + page,
            data: {
                filter: filter,
                search: search,
            },
            method: 'post',
            headers: { "Content-Type": "multipart/form-data" },
            callback: res => {
                if (res.data?.total_amount) {
                    setOutstanding(res.data.total_amount)
                } else {
                    setOutstanding(0)
                }
                if (res.data.payments) {
                    if (currentPage !== oldCurrentPage) {
                        setPayments([...payments, ...res.data.payments.data]);
                        setCurrentPage(res.data.payments.current_page);
                        setLastPage(res.data.payments.last_page);
                        setOldCurrentPage(res.data.payments.current_page);
                    }
                    else {
                        setPayments(res.data.payments.data);
                        setCurrentPage(res.data.payments.current_page);
                        setLastPage(res.data.payments.last_page);
                        setOldCurrentPage(res.data.payments.current_page);
                    }
                    window.triggerScroll();
                    setLoadingForm(false);
                }
            }
        })
        if (submitSearch === 1) {
            setSubmitSearch(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, filter, submitSearch === 1]);

    
    const closeWithdrawal = () =>{
        setRequestWithdrawal(1);
        setRequestedAmount('');
        setBankName('');
        setBankAddress('');
        setAccountHolderName('');
        setIban('');
        setSwiftBic('');
    }

    const submitWithdrawal = (e) => {
        e.preventDefault();
        setGeneralDataApiOptions2({
            url: 'doctors-withdrawal',
            method: 'post',
            data: {
                amount: requestedAmount,
                bank_name: bankName,
                bank_address: bankAddress,
                account_holder_name: accountHolderName,
                iban: iban,
                swift_bic: swiftBic,
            },
            callback: res => {
                setRequestedAmount('');
                setBankName('');
                setBankAddress('');
                setAccountHolderName('');
                setIban('');
                setSwiftBic('');
                setSuccess(true);
                setTimeout(() => {
                    setRequestWithdrawal(0);
                    setSuccess(false);
                }, 4000);
            }
        })
    }

    return (
        <>
            <UserLayout
                darkMenu={true}
                activeUserPage="payments"
            />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row">
                        <div className="col-12 col-md-6 pt-3 w-100">
                            <p className="dashboard-username m-0">MY BALANCE</p>
                        </div>
                        <div className="col-12 w-100 py-4">
                            <div className="border-dashboard-boxes fill-dark p-5">
                                <div className="row justify-content-between">
                                    <div className="col-auto">
                                        <p className="m-0 outstanding-amount">{outstanding} GBP</p>
                                    </div>
                                    {outstanding && outstanding !== 0 ?
                                        <div className="col-auto my-auto">
                                            <button onClick={() => closeWithdrawal()} className="home-buttons fs-14">REQUEST WITHDRAWAL</button>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-5">
                        <div className="col-auto">
                            <p className="m-0 payments-title">PAYMENTS</p>
                        </div>
                        <div className="col-auto my-auto">
                            <p onClick={() => setFilter(0)} className={filter === 0 ? 'm-0 payment-status-title active' : 'm-0 payment-status-title'}>ALL</p>
                        </div>
                        <div className="col-auto my-auto">
                            <p onClick={() => setFilter(1)} className={filter === 1 ? 'm-0 payment-status-title active' : 'm-0 payment-status-title'}>PENDING</p>
                        </div>
                        <div className="col-auto my-auto">
                            <p onClick={() => setFilter(2)} className={filter === 2 ? 'm-0 payment-status-title active' : 'm-0 payment-status-title'}>PAID</p>
                        </div>
                    </div>

                    <div className="row py-5">
                        <div className="col-12">
                            <div className="border-dashboard-boxes fill-dark p-3">
                                <div className="row justify-content-end">
                                    <div className="col-12 col-sm-6 col-md-4 pb-3 py-sm-3 text-right">
                                        <input className="search-input position-relative" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
                                        <svg onClick={() => setSubmitSearch(1)} className="search-svg" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                            <g id="_001-loupe" data-name="001-loupe" transform="translate(0 -0.003)">
                                                <g id="Group_2" data-name="Group 2" transform="translate(0 0.003)">
                                                    <path id="Path_3" data-name="Path 3" d="M19.756,18.58l-5.687-5.687a7.932,7.932,0,1,0-1.178,1.178l5.687,5.687a.833.833,0,1,0,1.178-1.178ZM7.917,14.17a6.25,6.25,0,1,1,6.25-6.25A6.257,6.257,0,0,1,7.917,14.17Z" transform="translate(0 -0.003)" fill="#25d6ff" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-wrapper">
                                            <table className="payments" width="100%">
                                                <thead>
                                                    <tr>
                                                        <td>DATE</td>
                                                        <td>FROM</td>
                                                        <td>AMOUNT</td>
                                                        <td>CASE ID</td>
                                                        <td>STATUS</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        payments?.length > 0 ?
                                                            payments?.map((singlePayment, index) => (
                                                                <tr key={index}>
                                                                    <td>{singlePayment.full_date}</td>
                                                                    <td>{(singlePayment.user?.first_name || singlePayment.user?.last_name) ? singlePayment.user?.first_name + ' ' + singlePayment.user?.last_name : (singlePayment?.user?.name ? singlePayment?.user?.name : '' )}</td>
                                                                    <td>{singlePayment.doctor_amount} GBP</td>
                                                                    <td>{singlePayment.get_care_info_data_id}</td>
                                                                    <td className={singlePayment.pay_date ? 'green' : 'orange'}>{singlePayment.pay_date ? 'Paid' : 'Pending'}</td>
                                                                </tr>
                                                            ))
                                                            :
                                                            null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !payments?.length > 0 ?
                                        <div className="row justify-content-center py-3">
                                            <p className="m-0">No Payments Available</p>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    !!(currentPage !== lastPage) && (
                                        <div className="row justify-content-center pt-5">

                                            <button className={!loadingForm ? "load-more home-buttons" : " hide"} onClick={() => { setCurrentPage(currentPage + 1); }} animate="down">
                                                LOAD MORE</button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    {/* <div className="row justify-content-center py-4">
                        <div className="col-12 d-flex text-center justify-content-center">

                            <div className={loadingForm ? "lds-ring" : "lds-ring d-none"}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            {
                                !!(currentPage != lastPage) && (
                                    <button className={!loadingForm ? "load-more home-buttons" : " hide"} onClick={() => { setCurrentPage(currentPage + 1); }} animate="down">
                                        Load More</button>
                                )
                            }
                        </div>
                    </div> */}
                </div>
                {requestWithdrawal === 1 ?
                    <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box doctor-popup-width add-doc-bg">
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <svg className="close-svg" onClick={() => setRequestWithdrawal(0)} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                        <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                            <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                            <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                        </g>
                                    </svg>
                                    <div className="content background-light text-center px-1 py-3 w-100">
                                        <div className="col-12 mt-4 mt-md-2">
                                            <h1>Request Withdrawal</h1>
                                        </div>
                                        <div className="col-12 text-left">
                                            <form onSubmit={submitWithdrawal} autoComplete="off" className="w-100 pt-2">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-10 col-lg-8 d-flex text-start flex-column pt-3">
                                                        <div className="row">
                                                            <div className="col-6 d-flex text-start flex-column">
                                                                <h4 className="meetings-form-titles d-flex text-start">BANK NAME</h4>
                                                                <input className="meetings-form-input" type="text" value={bankName} onChange={(e) => setBankName(e.target.value)} />
                                                            </div>
                                                            <div className="col-6 d-flex text-start flex-column">
                                                                <h4 className="meetings-form-titles d-flex text-start">AMOUNT</h4>
                                                                <input type="number" className="meetings-form-input" value={requestedAmount} onChange={(e) => setRequestedAmount(e.target.value > 0 && e.target.value <= outstanding ? e.target.value : '')} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-10 col-lg-8 d-flex text-start flex-column pt-3">
                                                        <h4 className="meetings-form-titles d-flex text-start">BANK ADDRESS</h4>
                                                        <input className="meetings-form-input" type="text" value={bankAddress} onChange={(e) => setBankAddress(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-10 col-lg-8 d-flex text-start flex-column pt-3">
                                                        <h4 className="meetings-form-titles d-flex text-start">ACCOUNT HOLDER NAME</h4>
                                                        <input className="meetings-form-input" type="text" value={accountHolderName} onChange={(e) => setAccountHolderName(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-10 col-lg-8 d-flex text-start flex-column pt-3">
                                                        <div className="row">
                                                            <div className="col-6 d-flex text-start flex-column pt-2">
                                                                <h4 className="meetings-form-titles d-flex text-start">IBAN</h4>
                                                                <input className="meetings-form-input" type="text" value={iban} onChange={(e) => setIban(e.target.value)} />
                                                            </div>
                                                            <div className="col-6 d-flex text-start flex-column pt-2">
                                                                <h4 className="meetings-form-titles d-flex text-start">SWIFT/BIC</h4>
                                                                <input className="meetings-form-input" type="text" value={swiftBic} onChange={(e) => setSwiftBic(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {

                                                        success === true ?
                                                            <div className="col-12 text-center pt-2">
                                                                <p className="m-0 pt-1 color-green">Request Successfully Sent!</p>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    <div className="col-12 text-center pt-2">
                                                        <button onClick={submitWithdrawal} type="submit" className="home-buttons resch-meet-button pr-1 pl-1 pr-md-5 pl-md-5 mt-4">
                                                            SEND
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <Api options={generalDataApiOptions} />
                <Api options={generalDataApiOptions2} />
            </div>
        </>
    );
}
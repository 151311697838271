import { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import { Link } from "react-router-dom";
import { useParams } from 'react-router';
import Api from '../components/Api';
import axios from 'axios';


export default function DoctorFinalReport(props) {
    let { token } = useParams();
    let { email } = useParams();
    const { generalDataGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [loading] = useState(false);
    const [singleCare, setSingleCare] = useState([]);
    const [getCareCases] = useState();
    const [clientDetails, setClientDetails] = useState('');
    const [specialistDetails, setSpecialistDetails] = useState('');
    const [briefDetails, setBriefDetails] = useState('');
    const [answersDetails, setAnswersDetails] = useState('');
    const [specialistSummary, setSpecialistSummary] = useState('');
    const [followupRecommendations, setFollowupRecommendations] = useState('');
    const [commentsDetails, setCommentsDetails] = useState('');
    const [recommendationsDetails, setRecommendationsDetails] = useState('');
    const [saveSuccess, setSaveSuccess] = useState(false);

    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);

    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading]);


    useEffect(() => {
        axios.get('get-care-single-doctor/' + email + '/' + token)
            .then((r) => {
                setSingleCare(r.data);
                let title = (r.data?.doctor?.first_name && r.data?.doctor?.last_name) ? 
                r.data?.doctor?.first_name + ' ' + r.data?.doctor?.last_name :
                (r.data?.doctor?.name ? r.data?.doctor?.name : '') ;
                if(r.data?.doctor?.doctor_specialization){
                    title = title+' - '+r.data?.doctor?.doctor_specialization?.specialization;
                }
                setSpecialistDetails(title);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (singleCare.final_report?.client_details) {
            setClientDetails(singleCare.final_report?.client_details);
        }

        if (singleCare.final_report?.specialist_details) {
            setSpecialistDetails(singleCare.final_report?.specialist_details);
        }

        if (singleCare.final_report?.brief_details) {
            setBriefDetails(singleCare.final_report?.brief_details);
        }

        if (singleCare.final_report?.answers_details) {
            setAnswersDetails(singleCare.final_report?.answers_details);
        }

        if (singleCare.final_report?.specialist_summary) {
            setSpecialistSummary(singleCare.final_report?.specialist_summary);
        }

        if (singleCare.final_report?.followup_recommendations) {
            setFollowupRecommendations(singleCare.final_report?.followup_recommendations);
        }

        if (singleCare.final_report?.comments_details) {
            setCommentsDetails(singleCare.final_report?.comments_details);
        }

        if (singleCare.final_report?.recommendations_details) {
            setRecommendationsDetails(singleCare.final_report?.recommendations_details);
        }
    }, [singleCare]);


    const submitDoctorFinalReport = (e) => {
        e.preventDefault();

        setGeneralDataApiOptions({
            url: 'insert-doctor-final-report',
            method: 'post',
            data: {
                user: Number(singleCare?.user_id),
                get_care_info_data_id: singleCare?.id,
                client_details: clientDetails,
                specialist_details: specialistDetails,
                brief_details: briefDetails,
                answers_details: answersDetails,
                specialist_summary: specialistSummary,
                followup_recommendations: followupRecommendations,
                comments_details: commentsDetails,
                recommendations_details: recommendationsDetails,
            },
            callback: res => {
                setTimeout(() => {
                    setSaveSuccess(true);
                }, 200);
                setTimeout(() => {
                    setSaveSuccess(false);
                    history.push('/doctor-records/doctor-single-record/' + email + '/' + token)
                }, 3000);
            }
        })
    }

    useEffect(() => {
    }, [getCareCases]);

    let history = useHistory();

    return (
        <>
            <UserLayout
                darkMenu={true}
                activeUserPage="records"
            />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row">
                        <div className="col-12 col-md-6 pt-3 w-100 d-flex">
                            <Link to={'/doctor-records/doctor-single-record/' + email + '/' + token} className="d-flex my-auto" animate="left">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.992" height="19.986" viewBox="0 0 10.992 19.986">
                                    <path id="Path_77" data-name="Path 77" d="M-4195.109,1774l-8.579,8.578,8.579,8.579" transform="translate(4204.688 -1772.586)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </svg>
                            </Link>
                            <p className="dashboard-title d-flex m-0 pl-3" animate="left">{generalData.case_report_settings.page_title}</p>
                        </div>
                    </div>

                    <form onSubmit={submitDoctorFinalReport} className="doctor-final-report" animate="left">
                        <div className="row w-100 align-items-center justify-content-center py-5">
                            <div className="col-12 col-md-10 pb-4 w-100">
                                <h2>1. {generalData.case_report_settings.clients_detail_title}</h2>
                                <hr />
                                <input className='get-care-input w-100' type="text" name="first_name" placeholder={generalData.case_report_settings.clients_placeholder} value={clientDetails} onChange={(e) => setClientDetails(e.target.value)} />
                            </div>
                            <div className="col-12 col-md-10 pb-4 w-100">
                                <h2>2. {generalData.case_report_settings.specialist_title}</h2>
                                <hr />
                                <input className='get-care-input w-100' type="text" name="last_name" placeholder={generalData.case_report_settings.specialist_placeholder} value={specialistDetails} onChange={(e) => setSpecialistDetails(e.target.value)} />
                            </div>
                            <div className="col-12 col-md-10 pb-4 w-100">
                                <h2>3. {generalData.case_report_settings.brief_title}</h2>
                                <hr />
                                <input className='get-care-input w-100' type="text" name="last_name" placeholder={generalData.case_report_settings.brief_placeholder} value={briefDetails} onChange={(e) => setBriefDetails(e.target.value)} />
                            </div>
                            <div className="col-12 col-md-10 pb-4 w-100">
                                <h2>4. {generalData.case_report_settings.answers_title}</h2>
                                <hr />
                                <input className='get-care-input w-100' type="text" name="last_name" placeholder={generalData.case_report_settings.answers_placeholder} value={answersDetails} onChange={(e) => setAnswersDetails(e.target.value)} />
                            </div>
                            <div className="col-12 col-md-10 pb-4 w-100">
                                <h2>5. {generalData.case_report_settings.summary_title}</h2>
                                <hr />
                                <input className='get-care-input w-100' type="text" name="last_name" placeholder={generalData.case_report_settings.summary_placeholder} value={specialistSummary} onChange={(e) => setSpecialistSummary(e.target.value)} />
                            </div>
                            <div className="col-12 col-md-10 pb-4 w-100">
                                <h2>6. {generalData.case_report_settings.follow_up_title}</h2>
                                <hr />
                                <input className='get-care-input w-100' type="text" name="last_name" placeholder={generalData.case_report_settings.follow_up_placeholder} value={followupRecommendations} onChange={(e) => setFollowupRecommendations(e.target.value)} />
                            </div>
                            <div className="col-12 col-md-10 pb-4 w-100">
                                <h2>7. {generalData.case_report_settings.comments_title}</h2>
                                <hr />
                                <input className='get-care-input w-100' type="text" name="last_name" placeholder={generalData.case_report_settings.comments_placeholder} value={commentsDetails} onChange={(e) => setCommentsDetails(e.target.value)} />
                            </div>
                            <div className="col-12 col-md-10 pb-5 w-100">
                                <h2>8. {generalData.case_report_settings.recommendations_title}</h2>
                                <hr />
                                <input className='get-care-input w-100' type="text" name="last_name" placeholder={generalData.case_report_settings.recommendations_placeholder} value={recommendationsDetails} onChange={(e) => setRecommendationsDetails(e.target.value)} />
                            </div>
                            <div className="col-12 col-md-10 text-center text-md-right">
                                <button type="submit" className="home-buttons">
                                    {generalData.case_report_settings.submit_title}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                {saveSuccess ?
                    <div className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box doctor-popup-width add-doc-bg">
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <svg className="close-svg" onClick={() => setSaveSuccess(!saveSuccess)} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                                        <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                            <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                            <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                        </g>
                                    </svg>
                                    <div className="content background-light text-center px-1 py-5 w-100">
                                        <h1>Changes Successfully Saved</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <Api options={generalDataApiOptions} />
            </div>
        </>
    );
}
import React, { useState, useEffect, useContext } from 'react';
import GlobalState from "../contexts/GlobalState";
import DatePicker from "react-datepicker";
import Api from './Api';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';


// const formatDate = (date) => {
//     return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + Number(Number(date.getMonth()) + 1) : Number(date.getMonth()) + 1}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()} ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
// }

const Meetings = (props) => {
    const { authUserGlobal } = useContext(GlobalState);
    const [authUser] = authUserGlobal;
    const [formLoader] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [selectedDoctors, setSelectedDoctors] = useState();
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [subject, setSubject] = useState('');
    const [meetingUrl, setMeetingUrl] = useState('');
    const [ , setSuccessState] = useState(false);
    const [rescheduleReason, setRescheduleReason] = useState('');
    const [meetingDuration, setMeetingDuration] = useState({});
    const [meetingZone, setMeetingZone] = useState({});

    const customStyles = {
        dropdownIndicator: base => ({
            ...base,
            color: "#393939"
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontFamily: 'Rubik',
                fontWeight: '300',
                color: '#393939',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#393939',
                fontFamily: 'Rubik',
                fontWeight: '300',
                backgroundColor: 'white',
                "&:hover": {
                    backgroundColor: "#f2f9fc"
                },
            };
        },
        singleValue: base => ({
            ...base,
            backgroundColor: "red"
        }),
        control: provided => ({
            ...provided,
            minHeight: 48,
            width: '100%',
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: '#393939' },
            boxShadow: 0,
            borderRadius: "50px",
            fontFamily: 'Rubik',
            fontWeight: '300',
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "1px",
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Rubik',
            fontWeight: '300',
            marginTop: "-8px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    const allCaseDoctors = () => {
        // let allDoctors = [
        //     ...props.addedCaseDoctors,
        // ];
        // allDoctors = [ ...allDoctors, props.caseDoctor ];
        let caseDoctors = props.addedCaseDoctors?.map((singleDoctor) => (
            { value: singleDoctor.id, label: singleDoctor.name }
        )
        );
        if (authUser.id !== props.caseDoctor?.id) {
            caseDoctors = [...caseDoctors, { value: props.caseDoctor?.id, label: props.caseDoctor?.name }]
        };
        var index = caseDoctors.map(function (e) { return e.value }).indexOf(authUser?.id);
        delete caseDoctors[index];
        return caseDoctors;
    }

    useEffect(() => {
        if (props.singleMeetingInfo?.id) {
            // setStartDate(moment(props.singleMeetingInfo?.start_date).format('MM/DD/YYYY HH:MM'));
            setStartDate(new Date(props.singleMeetingInfo?.start_date.replace(/-/g, "/")));
            setRescheduleReason(props.singleMeetingInfo?.reschedule_reason);
            setMeetingDuration({ value: props.singleMeetingInfo.meeting_duration, label: meetingDurations()[meetingDurations().map(item => item.value).indexOf(props.singleMeetingInfo.meeting_duration)].label });
            setMeetingZone({ value: props.singleMeetingInfo.time_zone, label: timeZones()[timeZones().map(item => item.value).indexOf(props.singleMeetingInfo.time_zone)].label });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
    }, [startDate]);


    const submitMeeting = (e) => {
        if (!props.noti) {
            e.preventDefault();
        }
        setGeneralDataApiOptions({
            url: 'doctors-meetings',
            method: 'post',
            data: {
                meeting_id: props.singleMeetingInfo?.id ? props.singleMeetingInfo?.id : '',
                start_date: startDate.toLocaleString(),
                reschedule_reason: rescheduleReason,
                subject: subject,
                link: meetingUrl,
                time_zone: meetingZone.value,
                get_care_info_data_id: props.careId,
                meeting_duration: meetingDuration.value,
                doctors: selectedDoctors?.length > 0 ? (selectedDoctors?.map((singleDoctor, index) => (
                    singleDoctor.value
                ))).toString() : '',
            },
            callback: res => {
                setSuccessState(true);
                props.meetingPopup();
            }
        })
    }



    const meetingDurations = () => [
        { label: "15 Mins", value: 0.25 },
        { label: "30 Mins", value: 0.5 },
        { label: "1 Hour", value: 1 },
        { label: "2 Hours", value: 2 },
        { label: "3 Hours", value: 3 },
        { label: "4 Hours", value: 4 },
        { label: "5 Hours", value: 5 },
        { label: "6 Hours", value: 6 },
        { label: "7 Hours", value: 7 },
        { label: "8 Hours", value: 8 },
    ];


    const timeZones = () => [
        { label: "UTC-12:00", value: -12 },
        { label: "UTC-11:00", value: -11 },
        { label: "UTC-10:00", value: -10 },
        { label: "UTC-09:30", value: -9.5 },
        { label: "UTC-09:00", value: -9 },
        { label: "UTC-08:00", value: -8 },
        { label: "UTC-07:00", value: -7 },
        { label: "UTC-06:00", value: -6 },
        { label: "UTC-05:00", value: -5 },
        { label: "UTC-04:00", value: -4 },
        { label: "UTC-03:30", value: -3.5 },
        { label: "UTC-03:00", value: -3 },
        { label: "UTC-02:00", value: -2 },
        { label: "UTC-01:00", value: -1 },
        { label: "UTC+00:00", value: 0 },
        { label: "UTC+01:00", value: 1 },
        { label: "UTC+02:00", value: 2 },
        { label: "UTC+03:00", value: 3 },
        { label: "UTC+03:30", value: 3.5 },
        { label: "UTC+04:00", value: 4 },
        { label: "UTC+04:30", value: 4.5 },
        { label: "UTC+05:00", value: 5 },
        { label: "UTC+05:30", value: 5.5 },
        { label: "UTC+05:45", value: 5.75 },
        { label: "UTC+06:00", value: 6 },
        { label: "UTC+06:30", value: 6.5 },
        { label: "UTC+07:00", value: 7 },
        { label: "UTC+08:00", value: 8 },
        { label: "UTC+08:45", value: 8.75 },
        { label: "UTC+09:00", value: 9 },
        { label: "UTC+09:30", value: 9.5 },
        { label: "UTC+10:00", value: 10 },
        { label: "UTC+10:30", value: 10.5 },
        { label: "UTC+11:00", value: 11 },
        { label: "UTC+12:00", value: 12 },
        { label: "UTC+12:45", value: 12.75 },
        { label: "UTC+13:00", value: 13 },
        { label: "UTC+14:00", value: 14 },
    ];


    return (
        // !successState ?
        <div onClick={ (ev) => { ev.preventDefault(); ev.stopPropagation() }} className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
            <div className="pop-up-api-box doctor-popup-width add-doc-bg">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <svg className="close-svg" onClick={props.meetingPopup} xmlns="http://www.w3.org/2000/svg" width="16.672" height="17.204" viewBox="0 0 16.672 17.204">
                            <g id="Group_18" data-name="Group 18" transform="translate(-1205.242 -317.086)">
                                <line id="Line_28" data-name="Line 28" x1="13.844" y2="14.376" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                                <line id="Line_29" data-name="Line 29" x2="13.844" y2="13.844" transform="translate(1206.656 318.5)" fill="none" stroke="#25d6ff" strokeLinecap="round" strokeWidth="2" />
                            </g>
                        </svg>
                        <div className="content background-light text-center px-1 py-3 w-100">
                            <div className="col-12 mt-4 mt-md-2">
                                {props.meeting ?
                                    <h1>Schedule A Meeting</h1>
                                    :
                                    <h1>Reschedule Meeting To</h1>
                                }
                            </div>
                            <div className="col-12 text-left">
                                <form onSubmit={submitMeeting} autoComplete="off" className="w-100 pt-2">
                                    <div className="row justify-content-center">
                                        {props.meeting ?
                                            <div className="col-12 col-md-10 col-lg-8 d-flex text-start flex-column">
                                                <h4 className="meetings-form-titles d-flex text-start">Appointment Subject</h4>
                                                <input className="meetings-form-input" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                            </div>
                                            :
                                            null
                                        }
                                        {!props.meeting ?
                                            <div className="col-12 col-md-10 col-lg-8 d-flex text-start flex-column">
                                                <h4 className="meetings-form-titles d-flex text-start">Reschedule Reason</h4>
                                                <textarea className="meetings-form-input" type="text" value={rescheduleReason} onChange={(e) => setRescheduleReason(e.target.value)} />
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="col-12 col-md-10 col-lg-8 d-flex text-start flex-column pt-2 meeting-datepicker">
                                            <h4 className="meetings-form-titles d-flex text-align-start">Meeting Date</h4>
                                            <DatePicker className="clickable edit-user-profile w-100" placeholderText="MM/DD/YYYY"
                                                showMonthDropdown
                                                showYearDropdown
                                                minDate={new Date() - 1}
                                                dateFormat="MM/d/yyyy h:mm aa"
                                                showTimeSelect
                                                dropdownMode="select" selected={startDate} onChange={(newDate) => setStartDate(newDate)} />
                                        </div>
                                        <div className="col-12 col-md-10 col-lg-8 d-flex text-start flex-column pt-2">
                                            <div className="row no-gutters">
                                                <div className="col-6 d-flex text-start flex-column pr-2">
                                                    <h4 className="meetings-form-titles d-flex text-align-start">Duration</h4>
                                                    <Dropdown closeOnScroll={true} menuClassName='dropdown-menu-specialization' className="edit-user-profile" placeholder="Duration" options={meetingDurations()} onChange={setMeetingDuration} value={meetingDuration} />
                                                </div>
                                                <div className="col-6 d-flex text-start flex-column">
                                                    <h4 className="meetings-form-titles d-flex text-align-start">Time Zone</h4>
                                                    <Dropdown closeOnScroll={true} menuClassName='dropdown-menu-specialization' className="edit-user-profile" placeholder="Zone" options={timeZones()} onChange={setMeetingZone} value={meetingZone} />
                                                </div>
                                            </div>
                                        </div>
                                        {props.meeting ?
                                            <>
                                                <div className="col-12 col-md-10 col-lg-8 pt-2 d-flex flex-column align-items-start text-left">
                                                    <h3 className="meetings-form-titles d-flex text-start">ADD MDM</h3>
                                                    <Select
                                                        classNamePrefix="languages-select"
                                                        className="edit-user-profile languages-select w-100"
                                                        isClearable={false}
                                                        styles={customStyles}
                                                        isMulti={true}
                                                        placeholder="Select Doctors"
                                                        options={allCaseDoctors()}
                                                        value={selectedDoctors}
                                                        onChange={setSelectedDoctors} />
                                                </div>
                                                <div className="col-12 col-md-10 col-lg-8 d-flex text-start flex-column pt-2">
                                                    <h4 className="meetings-form-titles d-flex text-start">Meeting Link</h4>
                                                    <input className="meetings-form-input" type="text" value={meetingUrl} onChange={(e) => setMeetingUrl(e.target.value)} />
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                        <div className="col-12 text-center pt-2">
                                            <button onClick={submitMeeting} type="submit" disabled={formLoader} className="home-buttons resch-meet-button pr-1 pl-1 pr-md-5 pl-md-5 mt-4">
                                                {props.meeting ? 'REQUEST' : 'RESCHEDULE'}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </div>
        // :
        // null
    );
};

export default Meetings;
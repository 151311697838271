import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import Api from "../components/Api";
import UserLayout from "../components/UsersMenu";
import GlobalState from "../contexts/GlobalState";
import 'react-dropdown/style.css';
import axios from 'axios';


export default function MyCourses(props) {
    const { generalDataGlobal, usersMenuOpenGlobal, notificationsGlobal } = useContext(GlobalState);
    const [ , setNotificationsCount] = notificationsGlobal;
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [usersMenuOpen] = usersMenuOpenGlobal;
    const [loading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categoriesFiltered, setCategoriesFiltered] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        window.triggerScroll();
    }, [generalData, generalDataApiOptions, loading]);

    
    useEffect(() => {
        if (localStorage.getItem('auth_token')) {
            axios.get('pending-notifications')
                .then((r) => {
                    setNotificationsCount(r.data);
                });
        }
    },[]);

    useEffect(() => {
        // if (generalData?.courses_categories?.length > 0) {
        //     setCategories(generalData?.courses_categories);
        // }
        setGeneralDataApiOptions({
            url: 'get-doctor-courses',
            method: 'get',
            callback: res => {
                setCategories(res.data)
            }
        });
    }, []);

    useEffect(() => {
        let newCategoriesFiltered = [];
        let searchToLower = search.toLowerCase();
        if (categories) {
            categories.forEach(singleCategory => {
                if (!search || search === '' ||
                    (
                        singleCategory.title.toString().toLowerCase().includes(searchToLower)
                    )
                ) {
                    newCategoriesFiltered.push(singleCategory);
                }
            });
        }
        setCategoriesFiltered(newCategoriesFiltered);
    }, [categories, search, generalData]);

    useEffect(() => {
        window.triggerScroll();
    }, [search, categoriesFiltered]);

    return (
        <>
            <UserLayout
                activeUserPage="courses"
                darkMenu={true}
            />
            <div className={usersMenuOpen ? 'dashboard-content bg-white menu-open' : 'dashboard-content bg-white'}>
                <div className="container-fluid equal-spacing-cont">
                    <div className="row">
                        <div className="col-12 col-md-5 pt-3 w-100" animate="right">
                            <p className="dashboard-title m-0 pt-3">MY COURSES</p>
                        </div>
                        <div className="col-12 col-md pt-3 w-100" animate="right">
                            <div className="row justify-content-end">
                                <div className="col-12 col-md-6 pt-0 pt-sm-3 w-100 pb-3 pl-md-0">
                                    <input className="search-input-courses position-relative" placeholder="Search" onChange={e => setSearch(e.target.value)} />
                                    <svg className="search-svg" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <g id="_001-loupe" data-name="001-loupe" transform="translate(0 -0.003)">
                                            <g id="Group_2" data-name="Group 2" transform="translate(0 0.003)">
                                                <path id="Path_3" data-name="Path 3" d="M19.756,18.58l-5.687-5.687a7.932,7.932,0,1,0-1.178,1.178l5.687,5.687a.833.833,0,1,0,1.178-1.178ZM7.917,14.17a6.25,6.25,0,1,1,6.25-6.25A6.257,6.257,0,0,1,7.917,14.17Z" transform="translate(0 -0.003)" fill="#25d6ff" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row py-4">
                        {
                            categoriesFiltered.length > 0 ?
                                categoriesFiltered.map((singleCategory, index) => (
                                    <div className={index === 0 ? 'col-12 col-sm-6 col-md-3 pt-sm-4' : 'col-12 col-sm-6 col-md-3 pt-4'} key={index} animate="right">
                                        <Link style={{ textDecoration: "none" }} to={'/doctor-courses/doctor-single-course-category/'+ singleCategory?.courses_categories[0]?.slug +'/single-course/'+ singleCategory.slug} >
                                            {
                                                singleCategory.image ?
                                                    <div className="category-image-square">
                                                        <img className="category-image" src={singleCategory.full_path_image} alt={singleCategory.slug} />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <p className="m-0 pt-2 category-name w-100">{singleCategory.title}</p>
                                        </Link>
                                    </div>
                                ))
                                :
                                <div className="col-12">
                                    <p className="no-results">No Courses Available.</p>
                                </div>
                        }
                    </div>
                </div>
                <Api options={generalDataApiOptions} />
            </div>
        </>
    );
}